import { StepConnector } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

export const StepperConnector = withStyles({
	active: {
		'& $line': {
			background: 'transparent',
		},
	},
	completed: {
		'& $line': {
			background: 'transparent',
		},
	},
	vertical: {
		marginLeft: '18px',
		padding: 0,
		height: '0.5rem',
	},
	line: {
		width: 3,
		border: 0,
		backgroundColor: 'transparent',
		borderRadius: 1,
	},
})(StepConnector);
