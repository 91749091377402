import { createTheme, Shadows } from '@mui/material/styles';

const PRIMARY_MAIN = '#1976D2';
const TEXT_PRIMARY = '#1A2A3D';

// Replace elevation 1 with a light glow, this is common around cards
const shadows = ['none', '0 0 20px rgba(0, 0, 0, 0.05)', ...createTheme().shadows.slice(2)] as Shadows;

declare module '@mui/material/Chip' {
	interface ChipPropsVariantOverrides {
		sfPrefix: true;
		sfPrefixDense: true;
	}
}

// Create custom MaterialUI theme
export const theme = createTheme({
	shadows,
	palette: {
		primary: {
			100: '#eef1f6',
			200: '#e8f1fb',
			light: '#91A0BA',
			main: PRIMARY_MAIN,
			dark: '#222A43',
		},
		secondary: {
			main: '#B1BFC6',
			light: '#F6F8FC',
		},
		warning: {
			main: '#EDAC43',
			light: 'rgba(237, 172, 67, 0.4)',
		},
		success: {
			main: '#0EAE79',
			light: 'rgba(14, 174, 121, 0.4)',
		},
		error: {
			main: '#ED5531',
			light: 'rgba(237, 85, 49, 0.4)',
		},
		text: {
			primary: TEXT_PRIMARY,
		},
		grey: {
			'200': '#f4f4f4',
			'400': '#838C96',
			'500': '#838C96',
			'900': TEXT_PRIMARY,
		},
	},
	typography: {
		fontFamily: ['Lato', 'sans-serif'].join(','),
		h1: {
			fontSize: '22px',
			lineHeight: '27px',
			letterSpacing: '-0.33px',
			fontWeight: 600,
			color: TEXT_PRIMARY,
		},
		h2: {
			fontSize: '20px',
			lineHeight: '24px',
			letterSpacing: '0',
			fontWeight: 500,
			color: TEXT_PRIMARY,
		},
		h3: {
			fontSize: '18px',
			lineHeight: '22px',
			letterSpacing: '0',
			fontWeight: 500,
			color: TEXT_PRIMARY,
		},
		h4: {
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '0',
			fontWeight: 500,
			color: TEXT_PRIMARY,
		},
		h5: {
			fontSize: '15px',
			lineHeight: '22px',
			letterSpacing: '0',
			fontWeight: 500,
			color: TEXT_PRIMARY,
		},
		h6: {
			fontSize: '15px',
			lineHeight: '22px',
			letterSpacing: '0',
			fontWeight: 500,
			color: TEXT_PRIMARY,
		},
		subtitle1: {
			color: '#91A0BA',
			fontSize: '15px',
		},
		subtitle2: {
			color: TEXT_PRIMARY,
			fontSize: '13px',
			fontWeight: 400,
		},
		body1: {
			fontSize: '1rem',
			color: TEXT_PRIMARY,
		},
		body2: {
			fontSize: '0.95rem',
			color: TEXT_PRIMARY,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					height: '45px',
					borderRadius: 2,
					padding: '14px 20px',
					fontSize: '15px',
					lineHeight: '18px',
					letterSpacing: '0.23px',
					textTransform: 'none',
				},
				containedPrimary: {
					'&:hover': {
						backgroundColor: '#1F8BF3',
						color: 'white',
					},
				},
				containedSecondary: {
					'color': '#222A43',
					'backgroundColor': '#E4E5E8',
					'&:hover': { backgroundColor: '#C3DAF2' },
				},
				textPrimary: {
					color: PRIMARY_MAIN,
				},
				outlined: {
					'fontWeight': '600',
					'borderWidth': 2,
					'&:hover': {
						borderWidth: 2,
					},
				},
				outlinedPrimary: {
					'color': 'black',
					'borderColor': 'lightgrey',
					'&:hover': {
						color: 'black',
						borderColor: 'lightgrey',
					},
				},
			},
		},
		MuiDialogTitle: {
			defaultProps: {
				variant: 'h3',
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '16px 24px',
				},
			},
		},
		MuiPopover: {
			defaultProps: {
				elevation: 3,
			},
		},
		MuiMenu: {
			defaultProps: {
				elevation: 3,
			},
			styleOverrides: {
				root: {
					top: 3,
				},
				paper: {
					borderRadius: '8px !important',
				},
			},
		},

		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 2,
				},
			},
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: {
					variant: 'h2',
				},
			},
			styleOverrides: {
				subheader: { fontSize: 15 },
				root: {
					borderBottom: '2px solid #e4e7ee',
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				disableInteractive: true,
			},
			styleOverrides: {
				tooltip: {
					fontSize: '1em',
					padding: '1rem',
				},
			},
		},
		MuiChip: {
			defaultProps: {
				color: 'primary',
			},
			styleOverrides: {
				root: {
					color: 'white',
					borderRadius: '3px',
					fontSize: '13px',
				},
			},
			variants: [
				{
					props: {
						variant: 'sfPrefixDense',
					},
					style: {
						height: 'min-content',
						padding: '0.25rem 0',
					},
				},
			],
		},
		MuiTab: {
			styleOverrides: {
				root: {
					maxWidth: '265px',
					flexDirection: 'row',
					textTransform: 'none',
					fontSize: '16px',
					lineHeight: '18px',
					letterSpacing: '-0.24px',
				},
			},
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					fontSize: '20px',
					lineHeight: '24px',
					letterSpacing: '-0.3px',
					fontWeight: 600,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontWeight: 600,
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: 'rgba(14, 174, 121, 0.15) !important',
					},
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					fontWeight: 600,
					color: 'black',
					backgroundColor: 'rgba(25, 118, 210, 0.5)',
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				standardWarning: {
					'color': TEXT_PRIMARY,
					'backgroundColor': '#f8deb4',
					'& .MuiAlert-icon': { color: TEXT_PRIMARY },
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
		},
		MuiListSubheader: {
			styleOverrides: {
				root: { backgroundColor: 'inherit' },
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				root: {
					'& > * > *': {
						margin: '0',
					},
				},
			},
		},
	},
});
