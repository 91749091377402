import { Grid, Typography } from '@mui/material';
import { ReactHookFormAddress } from '../../..';
import { FormMode } from '../../../../utils/Enums';
import InfoLabel from '../../../InfoLabel/InfoLabel';
import FormDivider from '../../FormDivider';
import FormSection from '../../FormSection';
import CountrySelect from './CountrySelect';

const GeneralForm: React.FC<GeneralFormProps> = ({ mode }) => (
	<>
		<FormSection>
			<Grid item xs={12}>
				<Typography variant="h1">General Information</Typography>
			</Grid>
		</FormSection>
		<FormDivider />
		<FormSection>
			<Grid item xs={6}>
				<CountrySelect mode={mode} />
			</Grid>
			<Grid item xs={6}>
				<InfoLabel htmlFor="workAddress" infoText="Indicate your current physical work location.">
					Current Physical Location
				</InfoLabel>
				<ReactHookFormAddress mode={mode} name="workAddress" />
			</Grid>
		</FormSection>
	</>
);

export interface GeneralFormProps {
	mode: FormMode;
}

export default GeneralForm;
