import { Autocomplete, Button, Dialog, Grid, InputLabel, MenuItem, TextField, Typography, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { PartialType } from './CustomDataTable';
import { FilterItem, FilterTypeBase } from '..';
import KeywordsInput from '../FormComponents/KeywordsInput';
import { ReactHookFormCheckbox, ReactHookFormDate, ReactHookFormSelect } from '../ReactHookForm';
import ReactHookFormMultiSelect from '../ReactHookForm/ReactHookFormMultiSelect';
import ReactHookFormTextField from '../ReactHookForm/ReactHookFormTextField';

const useStyles = makeStyles({
	container: {
		position: 'fixed',
		right: 0,
		width: '450px',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '20px',
		borderBottom: '1px solid #ddd',
	},
	headerButtons: {
		display: 'flex',
		gap: '10px',
	},
	footer: {
		padding: '20px',
		display: 'flex',
		justifyContent: 'flex-end',
		borderTop: '1px solid #ddd',
	},
	body: {
		padding: '20px',
		flexGrow: 1,
		overflowY: 'auto',
	},
});

interface FilterProps<T> {
	filterIsOpen: boolean;
	setFilterIsOpen: (state: boolean) => void;
	currentFilters: PartialType<T>;
	setFilters: (filters: PartialType<T>) => void;
	filterItems: FilterItem<T>[];
	defaultFilters: PartialType<T> | undefined;
	onApplyClicked: (() => void) | undefined;
	onResetClicked: (() => void) | undefined;
}

const Filter = <T extends FilterTypeBase>({
	filterIsOpen,
	setFilterIsOpen,
	currentFilters,
	setFilters,
	filterItems,
	defaultFilters = {},
	onApplyClicked,
	onResetClicked,
}: FilterProps<T>) => {
	const classes = useStyles();
	const methods = useForm();

	const applyFilters = (data: PartialType<T>) => {
		if (onApplyClicked !== undefined) onApplyClicked();
		setFilterIsOpen(false);
		setFilters(data);
	};

	const resetFilters = () => {
		if (onResetClicked !== undefined) onResetClicked();
		const resetObject = { ...defaultFilters };
		for (const item of filterItems) {
			if (item._typename === 'field') {
				if (resetObject[item.name]) continue;
				switch (item.fieldType) {
					case 'multiselect':
						resetObject[item.name] = [];
						break;
					case 'autocomplete':
						resetObject[item.name] = [];
						break;
					default:
						resetObject[item.name] = null;
						break;
				}
			}
		}
		methods.reset(resetObject);
		setFilters(resetObject);
	};

	const getFilterComponent = (item: FilterItem<T>): JSX.Element => {
		switch (item._typename) {
			case 'label':
				if (item.variant === 'label') return <InputLabel shrink>{item.text}</InputLabel>;
				return <Typography variant={item.variant}>{item.text}</Typography>;
			case 'field':
				switch (item.fieldType) {
					case 'checkbox':
						return <ReactHookFormCheckbox name={item.name} label={item.label} defaultValue={currentFilters[item.name]} control={methods.control} />;
					case 'autocomplete':
						return (
							<Controller
								control={methods.control}
								name={item.name}
								defaultValue={currentFilters[item.name] ?? []}
								render={({ field: { value, onChange } }) => {
									return (
										<Autocomplete
											multiple
											options={item.options}
											value={value ?? []}
											onChange={(_e, value) => onChange(value)}
											renderInput={(params) => (
												<TextField {...params} variant="standard" label={item.label} InputLabelProps={{ shrink: true }} />
											)}
										/>
									);
								}}
							/>
						);
					case 'text':
						return (
							<ReactHookFormTextField
								name={item.name}
								label={item.label}
								defaultValue={currentFilters[item.name]}
								fullWidth
								InputLabelProps={{ shrink: true }}
								InputProps={{
									startAdornment: item.startAdornment,
									endAdornment: item.endAdornment,
								}}
								numericOnly={item.isNumber}
								intOnly={item.isNumber}
								numericMax={item.isNumber ? item.max : undefined}
								control={methods.control}
								maxLength={item.maxLength}
								regex={item.regex}
							/>
						);
					case 'multiselect':
						return (
							<FormProvider {...methods}>
								<ReactHookFormMultiSelect
									name={item.name}
									label={item.label}
									defaultValue={currentFilters[item.name] ?? []}
									options={item.options}
									control={methods.control}
								/>
							</FormProvider>
						);
					case 'select':
						return (
							<ReactHookFormSelect
								name={item.name}
								label={item.label}
								defaultValue={currentFilters[item.name]}
								fullWidth
								control={methods.control}>
								{item.options.length > 0 ? (
									item.options.map((option, index) => (
										<MenuItem key={index} value={option.value}>
											{option.name}
										</MenuItem>
									))
								) : (
									<MenuItem value="" disabled>
										No options
									</MenuItem>
								)}
							</ReactHookFormSelect>
						);
					case 'keywords':
						return (
							<FormProvider {...methods}>
								<KeywordsInput name={item.name} label={item.label} defaultValue={currentFilters[item.name]} allowAddNew={false} />
							</FormProvider>
						);
					case 'date':
						return (
							<ReactHookFormDate
								name={item.name}
								label={item.label}
								defaultValue={currentFilters[item.name] ?? null}
								inputFormat="MM/dd/yyyy"
								control={methods.control}
							/>
						);
					case 'hidden':
						return <Controller control={methods.control} name={item.name} defaultValue={currentFilters[item.name]} render={() => <></>} />;
				}
		}
	};

	return (
		<Dialog fullScreen open={filterIsOpen} PaperProps={{ className: classes.container }}>
			<div className={classes.header}>
				<Typography variant="h2">Filter Options</Typography>
				<div className={classes.headerButtons}>
					<Button variant="text" onClick={() => setFilterIsOpen(false)}>
						Close
					</Button>
					<Button variant="contained" onClick={resetFilters}>
						Reset Filter
					</Button>
				</div>
			</div>
			<div className={classes.body}>
				<Alert severity="info">All searches for SMEs must include at least one keyword in the skills section below.</Alert>
				<Grid container spacing={2}>
					{filterItems.map((item, index) => (
						<Grid key={index} item xs={item.size ?? 12}>
							{getFilterComponent(item)}
						</Grid>
					))}
				</Grid>
			</div>
			<div className={classes.footer}>
				<Button variant="contained" onClick={methods.handleSubmit(applyFilters)}>
					Apply
				</Button>
			</div>
		</Dialog>
	);
};

export default Filter;
