import { useMutation } from '@apollo/client';
import { Breadcrumbs, Toolbar, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { NavigateNext } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { LoadingMessage, PageBody, PageHeader } from '../..';
import { UnlockResponseEvaluations, UnlockResponseEvaluationsVariables } from '../../../apollo/generated/types/UnlockResponseEvaluations';
import { UpdateEvaluations, UpdateEvaluationsVariables } from '../../../apollo/generated/types/UpdateEvaluations';
import { UpdateFinalEvaluationScores, UpdateFinalEvaluationScoresVariables } from '../../../apollo/generated/types/UpdateFinalEvaluationScores';
import { UNLOCK_RESPONSE_EVALUATIONS, UPDATE_EVALUATIONS, UPDATE_FINAL_EVALUATION_SCORES } from '../../../apollo/mutations';
import { GET_TEAM_LEAD_SCORESHEET_DATA } from '../../../apollo/queries';
import PageBodyColumn from '../../PageWrapper/PageBodyColumn';
import { useTeamLeadScore } from '../../Providers/TeamLeadScoreProvider/TeamLeadScoreProvider';
import EvaluationHeader from './EvaluatorScores/EvaluationHeader';
import TeamLeadEvaluatorScoresDisplay from './EvaluatorScores/TeamLeadEvaluatorScoresDisplay';
import ScoreFinalization from './ScoreFinalization/ScoreFinalization';
import './scrollbar.css';

export type TeamLeadScoreFormData = {
	finalComment: string;
	includeContactInfo: boolean;
	// evaluatorContactInfoIncludedInFinal, evaluatorFinalCommentIncludedInFinal, reviewed are keyed by userId because
	// react-hook-form automatically converts integer keys to arrays.
	// See https://react-hook-form.com/v6/api
	evaluatorContactInfoIncludedInFinal?: { [key: string]: boolean };
	evaluatorFinalCommentIncludedInFinal?: { [key: string]: boolean };
	reviewed: { [key: string]: boolean };
	scores: {
		[key: string]: {
			comment: string;
			score: string;
		};
	};
};

const TeamLeadScoreForm = () => {
	const { teamLeadEvaluation, loading, response, evaluations } = useTeamLeadScore();
	const { responseId } = useParams<{ responseId: string }>();
	const { enqueueSnackbar } = useSnackbar();
	const methods = useForm<TeamLeadScoreFormData>();

	// submit the team leads scores and update reviewed evaluations
	const [updateEvaluations, { loading: updateReviewedLoading }] = useMutation<UpdateEvaluations, UpdateEvaluationsVariables>(UPDATE_EVALUATIONS);
	const [updateFinalEvalScores, { loading: updateScoresLoading }] = useMutation<UpdateFinalEvaluationScores, UpdateFinalEvaluationScoresVariables>(
		UPDATE_FINAL_EVALUATION_SCORES,
	);

	const onSubmit = (isDraft: boolean) => {
		if (!teamLeadEvaluation?.id) return;

		methods.handleSubmit(async (data: TeamLeadScoreFormData) => {
			if (!isDraft && Object.values(data.scores).some((scoreInput) => scoreInput.score === '')) {
				enqueueSnackbar('All scores must be completed before completing the evaluation.', {
					variant: 'error',
				});
				return;
			}

			try {
				(data.evaluatorContactInfoIncludedInFinal || data.evaluatorFinalCommentIncludedInFinal) &&
					(await updateEvaluations({
						variables: {
							evaluations: evaluations.map((evaluation) => ({
								responseUserId: evaluation.id,
								reviewed: data.reviewed?.[evaluation.userId],
								finalCommentIncludedInFinal: data.evaluatorFinalCommentIncludedInFinal?.[evaluation.userId],
								contactInfoIncludedInFinal: data.evaluatorContactInfoIncludedInFinal?.[evaluation.userId],
							})),
						},
					}));

				const scores =
					response?.topic?.successFactors
						.filter((sf) => !!sf.id)
						.map((sf) => ({
							// sf.id guaranteed by above filter
							stage2SuccessFactorId: sf.id as string,
							id: teamLeadEvaluation?.evaluatorScores?.find((evaluatorScore) => evaluatorScore.successFactor.id === sf.id)?.id,
							score: data.scores[sf.id as string].score === '' ? null : parseFloat(data.scores[sf.id as string].score),
							comment: data.scores[sf.id as string].comment,
						})) ?? [];

				await updateFinalEvalScores({
					variables: {
						finalEvaluation: {
							finalEvaluationId: teamLeadEvaluation.id,
							finalComment: data.finalComment,
							includeContactInfo: data.includeContactInfo,
							finalizeScores: !isDraft,
							stage2EvaluatorScores: scores,
						},
					},
					// No need to refetch if score ids have been assigned
					refetchQueries: scores.some((score) => !score.id)
						? [
								{
									query: GET_TEAM_LEAD_SCORESHEET_DATA,
									variables: { responseId },
								},
						  ]
						: undefined,
					awaitRefetchQueries: true,
				});

				enqueueSnackbar('Evaluation Saved', { variant: 'success' });
			} catch (e) {
				console.log(e);
				enqueueSnackbar(e.message, {
					variant: 'error',
				});
			}
		})();
	};

	const [unlockResponseEvaluations, { loading: unlockLoading }] = useMutation<UnlockResponseEvaluations, UnlockResponseEvaluationsVariables>(
		UNLOCK_RESPONSE_EVALUATIONS,
	);
	const unlockEvaluations = async () => {
		try {
			await unlockResponseEvaluations({
				variables: {
					responseId: teamLeadEvaluation?.stage2ResponseId,
				},
				refetchQueries: [{ query: GET_TEAM_LEAD_SCORESHEET_DATA, variables: { responseId: teamLeadEvaluation?.stage2ResponseId } }],
				awaitRefetchQueries: true,
			});
			enqueueSnackbar('Evaluations Unlocked', {
				variant: 'success',
			});
		} catch (e) {
			console.log(e);
			enqueueSnackbar(e.message, {
				variant: 'error',
			});
		}
	};

	const mutationLoading = updateReviewedLoading || updateScoresLoading || unlockLoading;

	if (loading || !response) return <LoadingMessage />;
	return (
		<>
			<PageHeader>
				<Toolbar style={{ padding: '1.5rem' }}>
					<Grid container direction="column" style={{ gap: '0.25rem' }}>
						<Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
							<Link to={`/group/${response.topic?.project?.group?.id}/edit`}>
								<Typography variant="h4" color="primary">
									{response.topic?.project?.group?.name}
								</Typography>
							</Link>
							<Link to={`/project/${response.topic?.project?.id}/edit`}>
								<Typography variant="h4" color="primary">
									{response.topic?.project?.name}
								</Typography>
							</Link>
							<Link to={`/topic/${response.topic?.id}/edit`}>
								<Typography variant="h4" color="primary">
									{response.topic?.name}
								</Typography>
							</Link>
						</Breadcrumbs>
						<Typography variant="h1">Team Lead Score Sheet</Typography>
					</Grid>
				</Toolbar>
			</PageHeader>
			<FormProvider {...methods}>
				<PageBody columns>
					<PageBodyColumn xs={7}>
						<Grid container spacing={3}>
							<Grid xs={12}>
								<EvaluationHeader />
							</Grid>

							<TeamLeadEvaluatorScoresDisplay />
						</Grid>
					</PageBodyColumn>

					<PageBodyColumn xs={5}>
						<ScoreFinalization onSubmit={onSubmit} unlockEvaluations={unlockEvaluations} loading={mutationLoading} />
					</PageBodyColumn>
				</PageBody>
			</FormProvider>
		</>
	);
};

export default TeamLeadScoreForm;
