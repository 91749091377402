import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UserProfileFormData } from '../../../../pages/Profile/ProfilePage';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import FormSection from '../../FormSection';
import DegreeItem from './DegreeItem';
import SimpleEducationItem from './SimpleEducationItem';

const EducationForm: React.FC<EducationFormProps> = ({ mode }) => {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext<UserProfileFormData>();
	const readOnly = mode === FormMode.View;

	const {
		fields: degrees,
		append: appendDegree,
		remove: removeDegree,
	} = useFieldArray({
		control,
		name: 'degrees', // unique name for your Field Array
		keyName: 'key',
	});
	const addDegree = () => {
		if (!readOnly) {
			appendDegree({
				degreeType: '',
				institution: '',
			});
		}
	};
	const {
		fields: certifications,
		append: appendCertification,
		remove: removeCertification,
	} = useFieldArray({
		control,
		name: 'certifications', // unique name for your Field Array
		keyName: 'key',
	});
	const addCertification = () => {
		if (!readOnly) {
			appendCertification({
				name: '',
			});
		}
	};
	const {
		fields: awards,
		append: appendAward,
		remove: removeAward,
	} = useFieldArray({
		control,
		name: 'awards', // unique name for your Field Array
		keyName: 'key',
	});
	const addAward = () => {
		if (!readOnly) {
			appendAward({
				name: '',
			});
		}
	};

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Education</Typography>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h3">Educational Degrees</Typography>
				</Grid>
				<Grid item xs={12}>
					{degrees.map((degree, index) => (
						<DegreeItem
							key={degree.key}
							index={index}
							readOnly={readOnly}
							remove={removeDegree}
							id={degree.id}
							degreeType={degree.degreeType ?? ''}
							institution={degree.institution ?? ''}
						/>
					))}
				</Grid>
				{!readOnly ? (
					<Grid item xs={12}>
						<Button startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />} onClick={addDegree}>
							Add Degree
						</Button>
					</Grid>
				) : null}

				<Grid item xs={12}>
					<Typography variant="h3">Certifications</Typography>
				</Grid>
				<Grid item xs={12}>
					{certifications.map((certification, index) => (
						<SimpleEducationItem
							key={certification.key}
							index={index}
							readOnly={readOnly}
							remove={removeCertification}
							fieldName="certifications"
							label="Certification"
							id={certification.id}
							name={certification.name ?? ''}
							institution={certification.institution ?? ''}
						/>
					))}
				</Grid>
				{!readOnly ? (
					<Grid item xs={12}>
						<Button startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />} onClick={addCertification}>
							Add Certification
						</Button>
					</Grid>
				) : null}

				<Grid item xs={12}>
					<Typography variant="h3">Awards</Typography>
				</Grid>
				<Grid item xs={12}>
					{awards.map((award, index) => (
						<SimpleEducationItem
							key={award.key}
							index={index}
							readOnly={readOnly}
							remove={removeAward}
							fieldName="awards"
							label="Award"
							id={award.id}
							name={award.name ?? ''}
							institution={award.institution ?? ''}
						/>
					))}
				</Grid>
				{!readOnly ? (
					<Grid item xs={12}>
						<Button startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />} onClick={addAward}>
							Add Award
						</Button>
					</Grid>
				) : null}
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="additonalCourses"
						name="additonalCourses"
						label="Additional Courses"
						inputProps={{ ...register('additonalCourses') }}
						error={!!errors.additonalCourses}
						helperText={errors.additonalCourses?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
			</FormSection>
		</>
	);
};

export interface EducationFormProps {
	mode: FormMode;
}

export default EducationForm;
