import { GetTeamLeadScoresheetData_stage2Response_topic_successFactors } from '../../../../apollo/generated/types/GetTeamLeadScoresheetData';
import { FundamentalPrimeMeasurement } from '../../../../apollo/generated/types/globalTypes';
import { ScoreSectionsType } from '../../../../utils/Enums';
import TeamLeadScoresTable from './TeamLeadScoresTable';

type TeamLeadScoresTablesProps = {
	selectedSection: ScoreSectionsType;
	successFactors: GetTeamLeadScoresheetData_stage2Response_topic_successFactors[];
};

const TeamLeadScoresTables = ({ selectedSection, successFactors }: TeamLeadScoresTablesProps) => {
	return (
		<>
			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.APPEAL}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.PRODUCT_TECHNOLOGY}
			/>
			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.VALUE}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.PRODUCT_TECHNOLOGY}
			/>
			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.RELIABILITY}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.PRODUCT_TECHNOLOGY}
			/>

			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.PERSONNEL}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.ORGANIZATION}
			/>
			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.PROCESSES}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.ORGANIZATION}
			/>
			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.FINANCES}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.ORGANIZATION}
			/>

			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.APPLICATION_SIZE}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.APPLICATION}
			/>
			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.DEMAND}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.APPLICATION}
			/>
			<TeamLeadScoresTable
				fpm={FundamentalPrimeMeasurement.DELIVERY}
				successFactorSet={successFactors}
				hidden={selectedSection !== ScoreSectionsType.APPLICATION}
			/>
		</>
	);
};

export default TeamLeadScoresTables;
