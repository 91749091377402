import { gql } from '@apollo/client';

export const ENTER_GROUP_SIGN_UP_CODE = gql`
	mutation EnterGroupSignUpCode($signUpCode: String!) {
		groupUserSubscriptionSignUp(signUpCode: $signUpCode) {
			id
			hasActiveSubscription
		}
	}
`;

export const CREATE_CHECKOUT_SESSION = gql`
	mutation CreateCheckoutSession {
		createCheckoutSession
	}
`;
