import * as React from 'react';
import { MenuItem } from '@mui/material';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { useFormContext } from 'react-hook-form';
import { FormMode } from '../../../../utils/Enums';
import { getNames } from 'country-list';
import { UserProfileInputType } from '../../../../apollo/generated/types/globalTypes';

const CountrySelect: React.FC<CountrySelectProps> = ({ mode }) => {
	const {
		control,
		getValues,
		formState: { errors },
	} = useFormContext<UserProfileInputType>();
	// Create a list of countries with the US at the top
	const USA_STR = 'United States of America';
	const countryOptions = (getNames() as string[]).filter((country) => country !== USA_STR).sort();
	countryOptions.unshift(USA_STR);

	return (
		<ReactHookFormSelect
			fullWidth
			control={control}
			label="Country of Citizenship"
			name="countryOfCitizenship"
			defaultValue={getValues('countryOfCitizenship')}
			error={!!errors.countryOfCitizenship}
			helperText={errors.countryOfCitizenship?.message}
			readOnly={mode === FormMode.View}>
			{!countryOptions || countryOptions.length <= 0 ? (
				<MenuItem disabled>No Data Available</MenuItem>
			) : (
				countryOptions.map((country, index) => (
					<MenuItem key={index} value={country}>
						{country}
					</MenuItem>
				))
			)}
		</ReactHookFormSelect>
	);
};

export interface CountrySelectProps {
	mode: FormMode;
}

export default CountrySelect;
