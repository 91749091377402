import makeStyles from '@mui/styles/makeStyles';
import { Button, Comment, Form, Header } from 'semantic-ui-react';
import { useComment, usePermissions } from '../Providers';
import CommentReplies from './CommentReplies';
import { useForm } from 'react-hook-form';
import { ReactHookFormTextArea } from '../ReactHookForm';
import { ErrorMessage } from '..';
import { Alert, Typography } from '@mui/material';

const useStyles = makeStyles({
	submitButton: {
		float: 'right',
	},
	form: {
		paddingBottom: '30px',
	},
	quill: {
		'paddingBottom': '20px',
		'& #body': { background: 'white !important' },
	},
});

interface CommentParentProps {
	heading?: string;
	subHeading?: string;
	readonly?: boolean;
	alertText?: string;
}

const CommentParent: React.FC<CommentParentProps> = ({ heading, subHeading, readonly, alertText }) => {
	const classes = useStyles();
	const { user } = usePermissions();
	const user_id = user ? user?.id : '';
	const {
		handleSubmit,
		control,
		reset,
		clearErrors,
		formState: { errors },
	} = useForm<{ comment: string }>({ reValidateMode: 'onBlur' });
	const { comments, commentUsers, commentError, addComment } = useComment();

	const onError = (errors, e) => console.log(errors, e);

	const addReply = (data, e) => {
		e.preventDefault();
		const date = new Date();
		addComment(user_id, window.location.href, data.comment, date.toUTCString());
		reset({ comment: '<p><br></p>' }, { keepIsValid: true });
		clearErrors();
	};

	return (
		<Comment.Group threaded style={{ maxWidth: '100%' }}>
			<Header as="h3" dividing>
				{heading}
			</Header>
			{subHeading && <Typography variant="body1">{subHeading}</Typography>}
			{alertText && <Alert severity="info">{alertText}</Alert>}
			<Form reply className={classes.form} onSubmit={handleSubmit(addReply, onError)}>
				<ReactHookFormTextArea
					className={classes.quill}
					fullWidth
					control={control}
					label=""
					name="comment"
					error={!!errors.comment}
					helperText={errors.comment?.message}
					defaultValue=""
					marginTop="0"
					rules={{
						required: 'Comment text required',
						validate: (value) => {
							return value.replace(/<[^>]*>?/gm, '').length > 0 || 'Comment text required';
						},
					}}
					hidden={readonly}
				/>

				<div hidden={readonly}>
					<Button className={classes.submitButton} type="submit" content="Add Remarks" labelPosition="left" icon="edit" primary />
				</div>
			</Form>
			{commentError ? (
				<ErrorMessage error={commentError} />
			) : (
				commentUsers &&
				comments &&
				comments.map((i) => {
					return i.parentId === null ? (
						<CommentReplies
							commentUsers={commentUsers}
							comment={i}
							replies={comments.filter((j) => j.parentId === i.id)}
							key={i.id}
							readonly={readonly}
						/>
					) : (
						''
					);
				})
			)}
		</Comment.Group>
	);
};

export default CommentParent;
