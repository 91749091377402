import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
	root: {
		textDecoration: 'underline',
		fontWeight: 900,
	},
});

const BoldUnderline: React.FC = ({ children }) => {
	const classes = useStyles();
	return <span className={classes.root}>{children}</span>;
};

export default BoldUnderline;
