import { useMutation } from '@apollo/client';
import { Alert, AlertTitle, Box, Button, Grid, InputLabel, List, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FileReferenceType, FileUploadInputType } from '../../apollo/generated/types/globalTypes';
import { RequestUploadToken, RequestUploadTokenVariables } from '../../apollo/generated/types/RequestUploadToken';
import { REQUEST_UPLOAD_TOKEN } from '../../apollo/mutations';
import { uploadBlobsToStorage } from '../../utils/file-storage-util';
import FileDisplay from './FileDisplay';
import UnsavedUploadedFileDisplay from './UnsavedUploadedFileDisplay';
import UploadingFileDisplay from './UploadingFileDisplay';

export interface FileDropZoneProps {
	referenceId: string;
	referenceType: FileReferenceType;
	name: string;
	header?: string;
	altStyle?: boolean;
	browseBtn?: boolean;
	openFiles?: boolean;
	fileType?: string | string[];
	readOnly?: boolean;
	lockedFileIds?: number[];
	multiple?: boolean;
	label?: string;
}

export interface FileInput extends FileUploadInputType {
	fileName?: string;
	url?: string;
}

interface FormFields {
	[x: string]: FileInput[];
}

/**
 * Handles file uploads.
 * Allows multiple files by default. Single works the same as multiple so you must still treat the field as an array.
 *
 * @param {*} {
 * 	referenceId,
 * 	referenceType,
 * 	name,
 * 	header,
 * 	altStyle,
 * 	browseBtn,
 * 	openFiles = false,
 * 	fileType,
 * 	readOnly = false,
 * 	lockedFileIds = [],
 * 	multiple = true,
 * }
 * @return {*}
 */
const FileDropZone: React.FC<FileDropZoneProps> = ({
	referenceId,
	referenceType,
	name,
	header,
	altStyle,
	browseBtn,
	openFiles = false,
	fileType,
	readOnly = false,
	lockedFileIds = [],
	multiple = true,
	label,
}) => {
	const { control } = useFormContext<FormFields>();
	const { fields, append, remove, replace } = useFieldArray({
		control,
		name, // unique name for your Field Array
		keyName: 'key',
	});

	const [requestUploadToken, { error }] = useMutation<RequestUploadToken, RequestUploadTokenVariables>(REQUEST_UPLOAD_TOKEN);

	// Null == No uploads in progress
	// key of fileName: false == file is preparing to upload
	// key of fileName: true == file is uploading
	const [uploadsInProgress, setUploadsInProgress] = useState<{ [fileName: string]: boolean } | null>(null);

	const onDrop = async (acceptedFiles: File[]) => {
		if (acceptedFiles && acceptedFiles.length > 0) {
			const uploads = acceptedFiles.reduce((acc, file) => {
				acc[file.name] = false;
				return acc;
			}, {});
			setUploadsInProgress(uploads);

			for (let index = 0; index < acceptedFiles.length; index++) {
				uploads[acceptedFiles[index].name] = true;
				setUploadsInProgress(uploads);

				try {
					const file = acceptedFiles[index];
					const tokenResult = await requestUploadToken({
						variables: {
							input: {
								referenceId: referenceId,
								referenceType: referenceType,
								fileName: file.name,
								fileSize: file.size,
								lastUpdated: new Date(file.lastModified),
							},
						},
					});

					const uploadTokenRequest = tokenResult.data?.uploadTokenRequest;
					if (uploadTokenRequest) {
						await uploadBlobsToStorage(file, uploadTokenRequest.sasUri);
						multiple
							? append({ uploadToken: uploadTokenRequest.token, fileName: file.name, url: uploadTokenRequest.sasUri })
							: replace([{ uploadToken: uploadTokenRequest.token, fileName: file.name, url: uploadTokenRequest.sasUri }]);
					} else {
						console.error('Invalid uploadTokenRequest');
					}
				} catch (e) {
					console.error(e);
				}

				delete uploads[acceptedFiles[index].name];
				setUploadsInProgress(uploads);
			}
			setUploadsInProgress(null);
		}
	};

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
		onDrop: onDrop,
		noClick: browseBtn,
		disabled: readOnly,
		accept: fileType ? fileType : undefined,
		multiple,
	});

	const singleUploadInProgress = !multiple && !!uploadsInProgress;

	return (
		<Stack spacing={1}>
			{header && <Typography variant="h3">{header}</Typography>}
			{label && <InputLabel shrink>{label}</InputLabel>}
			{!readOnly && (
				<Box
					{...getRootProps({
						sx: {
							...(altStyle ? styles.altStyle : styles.base),
							...(isDragActive && styles.active),
							...(isDragAccept && styles.accept),
							...(isDragReject && styles.reject),
						},
					})}>
					<input {...getInputProps()} />
					<p>{multiple ? "Drag 'n' drop some files here, or click to select files" : "Drag 'n' drop a file here, or click to select a file"}</p>
					{browseBtn && (
						<Button variant="contained" type="button" onClick={open}>
							Browse Files
						</Button>
					)}
					{error && (
						<Grid container xs={12}>
							<Grid item xs={12}>
								<Alert severity="error">
									<AlertTitle>File Upload Failed</AlertTitle>
									{error?.message}
								</Alert>
							</Grid>
						</Grid>
					)}
				</Box>
			)}
			<List sx={styles.listContainer} disablePadding={true}>
				{!singleUploadInProgress &&
					fields.map(({ key, id, uploadToken, fileName }, index) =>
						id ? (
							<FileDisplay
								key={key}
								id={id}
								index={index}
								onDelete={remove}
								name={name}
								openFile={openFiles}
								readOnly={readOnly || lockedFileIds.includes(id)}
							/>
						) : (
							<UnsavedUploadedFileDisplay
								key={key}
								index={index}
								uploadToken={uploadToken || ''}
								fileName={fileName || ''}
								onDelete={remove}
								name={name}
								openFile={openFiles}
								readOnly={readOnly}
							/>
						),
					)}
				{uploadsInProgress &&
					Object.keys(uploadsInProgress).map((fileName) => (
						<UploadingFileDisplay key={fileName} fileName={fileName} uploading={uploadsInProgress[fileName]} />
					))}
			</List>
		</Stack>
	);
};

const styles = {
	base: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	altStyle: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#ffffff',
		color: '#1A2A3D',
		fontWeight: 'bold',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	active: {
		borderColor: '#2196f3',
	},
	accept: {
		borderColor: '#00e676',
	},
	reject: {
		borderColor: '#ff1744',
	},
	listContainer: {
		'& .MuiTypography-body1': {
			maxWidth: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
};

export default FileDropZone;
