import { TrlRangeCategory, FundamentalPrimeMeasurement } from './../../../apollo/generated/types/globalTypes';
import { theme } from '../../../theme/theme';

/**
 * Evaluates the color for the header of the evaluation scores, based on a complex set of pre-defined rules.
 * See the corresponding spreadsheet for reference.
 *
 * @param {number} score
 * @param {number} trlRangeId
 * @param {FundamentalPrimeMeasurement} fpm
 * @return {*}
 */
const getEvaluationScoreColor = (score?: number | null, fpm?: FundamentalPrimeMeasurement, trlRangeCategory?: TrlRangeCategory | null) => {
	if (!score || score < 1 || score > 9.9 || Number.isNaN(score)) return undefined;

	const roundedScore = +score.toFixed(1);

	// TRL 1-3
	if (trlRangeCategory === TrlRangeCategory.ONE_TO_THREE) {
		switch (fpm) {
			case FundamentalPrimeMeasurement.APPEAL:
			case FundamentalPrimeMeasurement.PERSONNEL:
			case FundamentalPrimeMeasurement.PROCESSES:
			case FundamentalPrimeMeasurement.FINANCES:
			case FundamentalPrimeMeasurement.APPLICATION_SIZE:
				if (roundedScore < 7) return EvaluationScoreColor.RED;
				if (roundedScore < 8) return EvaluationScoreColor.YELLOW;
				return EvaluationScoreColor.GREEN;

			case FundamentalPrimeMeasurement.VALUE:
			case FundamentalPrimeMeasurement.RELIABILITY:
				if (roundedScore < 4) return EvaluationScoreColor.BLUE;
				return EvaluationScoreColor.BLACK;

			case FundamentalPrimeMeasurement.DEMAND:
			case FundamentalPrimeMeasurement.DELIVERY:
				return EvaluationScoreColor.GREEN;
		}
	}

	// TRL 4-6
	if (trlRangeCategory === TrlRangeCategory.FOUR_TO_SIX) {
		switch (fpm) {
			case FundamentalPrimeMeasurement.APPEAL:
			case FundamentalPrimeMeasurement.PERSONNEL:
			case FundamentalPrimeMeasurement.PROCESSES:
			case FundamentalPrimeMeasurement.FINANCES:
			case FundamentalPrimeMeasurement.APPLICATION_SIZE:
				if (roundedScore < 7) return EvaluationScoreColor.RED;
				if (roundedScore < 8) return EvaluationScoreColor.YELLOW;
				return EvaluationScoreColor.GREEN;

			case FundamentalPrimeMeasurement.DEMAND:
			case FundamentalPrimeMeasurement.DELIVERY:
				if (roundedScore < 4) return EvaluationScoreColor.RED;
				if (roundedScore < 7) return EvaluationScoreColor.YELLOW;
				return EvaluationScoreColor.GREEN;

			case FundamentalPrimeMeasurement.VALUE:
			case FundamentalPrimeMeasurement.RELIABILITY:
				if (roundedScore < 4) return EvaluationScoreColor.BLACK;
				if (roundedScore < 7) return EvaluationScoreColor.BLUE;
				return EvaluationScoreColor.BLACK;
		}
	}

	// TRL 7-9
	if (trlRangeCategory === TrlRangeCategory.SEVEN_TO_NINE) {
		if (roundedScore < 7) return EvaluationScoreColor.RED;
		if (roundedScore < 8) return EvaluationScoreColor.YELLOW;
		return EvaluationScoreColor.GREEN;
	}
};

type EvaluationScoreColor = {
	GREEN: string;
	YELLOW: string;
	RED: string;
	BLUE: string;
	BLACK: string;
};

export const EvaluationScoreColor: EvaluationScoreColor = {
	GREEN: theme.palette.success.main,
	YELLOW: theme.palette.warning.main,
	RED: theme.palette.error.main,
	BLUE: theme.palette.primary.main,
	BLACK: '#444444',
};

export default getEvaluationScoreColor;
