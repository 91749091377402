import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormSection from '../../FormSection';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import { ReactHookFormCheckbox } from '../../../ReactHookForm';

const AdditionalDetailsForm: React.FC<AdditionalDetailsFormProps> = ({ mode }) => {
	const {
        control,

        formState: {
            errors,
        },
    } = useFormContext();
	const readOnly = mode === FormMode.View;

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Activities of Interest</Typography>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				<Grid item xs={12}>
					<ReactHookFormCheckbox
						name="proposingInterest"
						label="Interest in proposing technical articles/papers/reports to be published in 9-HI"
						control={control}
						readOnly={readOnly}
					/>
				</Grid>
				<Grid item xs={12}>
					<ReactHookFormCheckbox
						name="peerReviewInterest"
						label="Interest in peer reviewing articles/papers/reports that are proposed for publishing in 9-HI"
						control={control}
						readOnly={readOnly}
					/>
				</Grid>
				<Grid item xs={12}>
					<ReactHookFormCheckbox
						name="evaluatorInterest"
						label="Interest in participating in Pitch events or other proposal reviews as a SME Evaluator"
						control={control}
						readOnly={readOnly}
					/>
				</Grid>
			</FormSection>
		</>
	);
};

export interface AdditionalDetailsFormProps {
	mode: FormMode;
}

export default AdditionalDetailsForm;
