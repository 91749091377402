// String to Hash
const hashCode = (str: string) => {
	let hash: any = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
};

// Hash to Hex
const intToRGB = (i: any) => {
	const c = (i & 0x00ffffff).toString(16).toUpperCase();
	return '00000'.substring(0, 6 - c.length) + c;
};

// Hex to RBG
const convertToRGB = (hex: any): React.CSSProperties => {
	if (hex.length != 6) {
		throw 'Only six-digit hex colors are allowed.';
	}
	const aRgbHex = hex.match(/.{1,2}/g);
	const r = 127.5 + 0.5 * parseInt(aRgbHex[0], 16);
	const g = 127.5 + 0.5 * parseInt(aRgbHex[1], 16);
	const b = 127.5 + 0.5 * parseInt(aRgbHex[2], 16);
	return {
		background: `rgb(${r},${g}, ${b})`,
		// http://stackoverflow.com/a/3943023/112731
		color: r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF',
	} as React.CSSProperties;
};

export const getColorFromString = (string: string) => {
	return convertToRGB(intToRGB(hashCode(string)));
};
