import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import FormSection from '../../FormSection';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import { useFormContext } from 'react-hook-form';

const SocialMediaForm: React.FC<SocialMediaFormProps> = ({ mode }) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const readOnly = mode === FormMode.View;

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Social Media Profiles</Typography>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="linkedInProfile"
						name="linkedInProfile"
						label="LinkedIn Profile"
						inputProps={{ ...register('linkedInProfile') }}
						error={!!errors.linkedInProfile}
						helperText={errors.linkedInProfile?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="facebookProfile"
						name="facebookProfile"
						label="Facebook Profile"
						inputProps={{ ...register('facebookProfile') }}
						error={!!errors.facebookProfile}
						helperText={errors.facebookProfile?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="twitterHandle"
						name="twitterHandle"
						label="Twitter Handle"
						inputProps={{ ...register('twitterHandle') }}
						error={!!errors.twitterHandle}
						helperText={errors.twitterHandle?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="instagramProfile"
						name="instagramProfile"
						label="Instagram Profile"
						inputProps={{ ...register('instagramProfile') }}
						error={!!errors.instagramProfile}
						helperText={errors.instagramProfile?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="youtubeChannel"
						name="youtubeChannel"
						label="YouTube Channel"
						inputProps={{ ...register('youtubeChannel') }}
						error={!!errors.youtubeChannel}
						helperText={errors.youtubeChannel?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="vimeoChannel"
						name="vimeoChannel"
						label="Vimeo Channel"
						inputProps={{ ...register('vimeoChannel') }}
						error={!!errors.vimeoChannel}
						helperText={errors.vimeoChannel?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="researchGateProfile"
						name="researchGateProfile"
						label="Research Gate Profile"
						inputProps={{ ...register('researchGateProfile') }}
						error={!!errors.researchGateProfile}
						helperText={errors.researchGateProfile?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						id="googleScholarProfile"
						name="googleScholarProfile"
						label="Google Scholar Profile"
						inputProps={{ ...register('googleScholarProfile') }}
						error={!!errors.googleScholarProfile}
						helperText={errors.googleScholarProfile?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
			</FormSection>
		</>
	);
};

export interface SocialMediaFormProps {
	mode: FormMode;
}

export default SocialMediaForm;
