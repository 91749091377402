/**
 * Example: https://community.auth0.com/t/how-to-use-react-auth0-spa-with-graphql/30516/5
 */
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const apiURL = process.env.REACT_APP_API_URL || 'https://localhost:57708';

const AuthorizedApolloProvider: React.FC = ({ children }) => {
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
	const httpLink = createHttpLink({
		uri: `${apiURL}/graphql`,
	});

	const authLink = setContext(async () => {
		try {
			const token = await getAccessTokenSilently();
			return {
				headers: {
					'Authorization': `Bearer ${token}`,
					'Access-Control-Allow-Origin': '*',
				},
				fetchOptions: {
					mode: 'cors',
				},
			};
		} catch (e) {
			if (e.error === 'login_required') {
				loginWithRedirect();
			}
			if (e.error === 'consent_required') {
				loginWithRedirect();
			}
			throw e;
		}
	});

	const apolloClient = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache({
			typePolicies: {
				Stage2ResponseType: {
					fields: {
						responseFiles: {
							merge: false,
						},
					},
				},
				ResponseRequiredDocumentsCheckboxStatusType: {
					keyFields: ['stage2ResponseId'],
				},
				DevelopmentProjectScoreType: {
					keyFields: ['successFactorId'],
				},
			},
		}),
		connectToDevTools: true,
	});

	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
