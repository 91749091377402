import { useQuery } from '@apollo/client';
import { Button, Divider, Drawer, List, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { GetUserNotifications } from '../../../apollo/generated/types/GetUserNotifications';
import { makeLocalTime } from '../../../utils/date-format';
import useSignalR from '../../../utils/hooks/useSignalR';
import { usePermissions } from '../../Providers';
import { DRAWER_WIDTH } from '../SideBar';
import DismissNotificationModal from './DismissNotificationModal';
import { DeclineInviteModal, useInvitations } from './Invitations/Invitations';
import Notification, { NotificationProps, NotificationType } from './Notification';
import { GET_USER_NOTIFICATIONS } from './Notifications.graphql';

/**
 * Notifications is a side panel/drawer that displays a list of notification objects
 * Uses z-index to appear over the page <div> in PageWrapper, and under the SideBar <aside> (for the slide animation)
 *
 * @param {*} { open, onClose }
 * @return {*}
 */
const Notifications: React.FC<{ open: boolean; onClose: () => void; setHasUnseenNotifications: (hasUnseenNotifications: boolean) => void }> = ({
	open,
	onClose,
	setHasUnseenNotifications,
}) => {
	const { user } = usePermissions();
	const { data, refetch: refetchUserNotifications } = useQuery<GetUserNotifications>(GET_USER_NOTIFICATIONS);

	// Backend already filters out dismissed notifications but this filter will catch any newly dismissed ones
	const userNotifications = data?.userNotifications?.filter((n) => !n.dismissed) ?? [];

	const { invitationNotifications, handleDecline, setHandleDecline, mutationLoading, refetch: refetchInvites } = useInvitations();

	useEffect(() => {
		setHasUnseenNotifications(userNotifications.some((userNotifcation) => !userNotifcation.acknowledged) || invitationNotifications.length > 0);
	}, [userNotifications, invitationNotifications]);

	// If dismissNotificationId is not null, the user is in the process of dismissing a notification, and the modal will be open
	const [dismissNotificationId, setDismissNotificationId] = useState<number | null>(null);

	const [tabVal, setTabVal] = useState<TabOptions>(TabOptions.ALL);

	const allNotifications: React.ReactElement<NotificationProps>[] = [];
	allNotifications.push(...invitationNotifications);
	allNotifications.push(
		...userNotifications.map((notif) => (
			<Notification
				type={NotificationType.EVENT}
				primaryText={notif.message}
				date={makeLocalTime(notif.createdAt)}
				redirectPath={notif.redirectPath}
				acknowledged={notif.acknowledged}
				key={notif.id}
				dismiss={() => setDismissNotificationId(notif.id)}
			/>
		)),
	);
	allNotifications.sort((a, b) => (a.props.date < b.props.date ? 1 : -1));

	useSignalR(
		'/hubs/notification',
		() => {
			refetchUserNotifications();
			refetchInvites();
		},
		user?.id,
	);

	return (
		<Drawer
			open={open}
			onClose={onClose}
			variant="persistent"
			anchor="left"
			// Set DRAWER_WIDTH inline to avoid a circular dependency issue
			sx={{ '& .MuiDrawer-paperAnchorLeft': { left: DRAWER_WIDTH }, ...styles }}>
			<div className="header">
				<Typography variant="h1">Notifications</Typography>
				<Button onClick={onClose}>Close</Button>
			</div>
			<Tabs
				value={tabVal}
				onChange={(_e, val) => setTabVal(val)}
				variant="scrollable"
				sx={{ maxWidth: DRAWER_WIDTH * 2 }}
				className="tabs"
				scrollButtons="auto">
				<Tab label="All" value={TabOptions.ALL} />
				<Tab label="Invitations" value={TabOptions.INVITATIONS} />
				<Tab label="Announcements" value={TabOptions.ANNOUNCEMENTS} disabled />
				<Tab label="Bulletins" value={TabOptions.BULLETINS} disabled />
				<Tab label="Reports" value={TabOptions.REPORTS} disabled />
			</Tabs>
			<Divider />

			<List className="list" sx={{ maxWidth: Number(DRAWER_WIDTH) * 2 }}>
				{tabVal === TabOptions.ALL && allNotifications}
				{tabVal === TabOptions.INVITATIONS && invitationNotifications}
			</List>

			{handleDecline && <DeclineInviteModal open={true} onClose={() => setHandleDecline(null)} onSubmit={handleDecline} loading={mutationLoading} />}
			<DismissNotificationModal notificationId={dismissNotificationId} onClose={() => setDismissNotificationId(null)} />
		</Drawer>
	);
};

enum TabOptions {
	ALL,
	INVITATIONS,
	ANNOUNCEMENTS,
	BULLETINS,
	REPORTS,
}

const styles: SxProps = {
	'borderTop': 0,
	'.header': {
		padding: '10px 20px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	'.tabs': {
		'& .MuiTabs-flexContainer': { justifyContent: 'flex-start', padding: '0 20px' },
		'& .MuiTab-root': { color: 'secondary.main', minWidth: 0, maxWidth: 'min-content' },
		'& .Mui-selected': { color: 'primary.main' },
	},
	'.list': {
		'& .invitationContext': {
			color: 'primary.light',
			display: 'block',
			margin: '0.25rem 0',
		},
		'overflowY': 'auto',
	},
};

export default Notifications;
