import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { RESEND_VERIFICATION_EMAIL } from '../../apollo/mutations';
import ScreenMessage from './ScreenMessage';

const VerifyEmailMessage: React.FC = () => {
	const { logout } = useAuth0();

	const { enqueueSnackbar } = useSnackbar();

	const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL);
	const sendEmail = async () => {
		try {
			await resendVerificationEmail();
			enqueueSnackbar('Verification email sent', {
				variant: 'success',
			});
		} catch (e) {
			console.log(e);
			enqueueSnackbar('Could not send verification email', {
				variant: 'error',
			});
		}
	};

	return (
		<ScreenMessage
			header="Please verify your email address to gain access to the application."
			subHeader="If you have verified your email address, refresh your browser to log in.">
			<div style={{ display: 'flex', gap: '10px' }}>
				<Button variant="contained" onClick={() => sendEmail()}>
					Resend Verification Email
				</Button>
				<Button variant="outlined" onClick={() => logout({ returnTo: window.location.origin })}>
					Sign Out
				</Button>
			</div>
		</ScreenMessage>
	);
};

export default VerifyEmailMessage;
