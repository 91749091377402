import * as React from 'react';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
	divider: {
		height: '2px',
		background: '#7689A9',
		opacity: '0.2',
	},
});

const FormDivider: React.FC = () => {
	const classes = useStyles();
	return <Divider className={classes.divider} />;
};

export default FormDivider;
