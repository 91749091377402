import React from 'react';
import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import makeStyles from '@mui/styles/makeStyles';
import { useFormContext } from 'react-hook-form';
import { UserProfileFormData } from '../../../../pages/Profile/ProfilePage';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		marginBottom: '15px',
	},
	removeSection: {
		width: '50px',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	headerIcon: {
		height: '100%',
	},
	space: {
		margin: '0 10px',
	},
});

const SimpleEducationItem: React.FC<SimpleEducationItemProps> = ({ index, readOnly, remove, fieldName, label, id, name, institution }) => {
	const classes = useStyles();
	const {
		register,
		formState: { errors },
	} = useFormContext<UserProfileFormData>();

	const hasError = (field: string): boolean => {
		return !!errors && !!errors[fieldName] && !!errors[fieldName]?.[index] && !!errors[fieldName]?.[index]?.[field];
	};

	const getErrorMessage = (field: string): string => {
		return hasError(field) ? errors[fieldName]?.[index]?.[field]?.message : '';
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{id ? <input {...register(`${fieldName}.${index}.id`)} type="hidden" defaultValue={id} /> : null}
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant="standard"
						name={`${fieldName}[${index}].name`}
						label={`Name of ${label}`}
						inputProps={{
							...register(`${fieldName}.${index}.name`, {
								required: `Name of ${label} is required.`,
							}),
							maxLength: 100,
						}}
						defaultValue={name}
						error={hasError(`${fieldName}.name`)}
						helperText={getErrorMessage(`${fieldName}.name`)}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant="standard"
						name={`${fieldName}[${index}].institution`}
						label="Name of Institution"
						inputProps={{ ...register(`${fieldName}.${index}.institution`), maxLength: 100 }}
						defaultValue={institution}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
			</Grid>
			{!readOnly && (
				<Tooltip title={`Delete this ${label}`} placement="top">
					<div className={classes.removeSection}>
						<IconButton aria-label="remove" onClick={() => remove(index)} size="large">
							<RemoveCircleOutlineIcon fontSize="inherit" />
						</IconButton>
					</div>
				</Tooltip>
			)}
		</div>
	);
};

export interface SimpleEducationItemProps {
	index: number;
	readOnly: boolean;
	remove: (index: number) => void;
	fieldName: 'certifications' | 'awards';
	label: string;
	id?: string | null;
	name: string;
	institution: string;
}

export default SimpleEducationItem;
