import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export interface PageBodyProps {
	divRef?: React.MutableRefObject<HTMLDivElement | null>;
	columns?: boolean;
}

const PageBody: React.FC<PageBodyProps> = ({ divRef, children, columns }) => {
	const classes = useStyles();

	if (columns) {
		return (
			<Grid container spacing={0} className={classes.pageBodyColumns}>
				{children}
			</Grid>
		);
	}

	return (
		<>
			<div ref={divRef} className={classes.pageBody}>
				{children}
			</div>
		</>
	);
};

const useStyles = makeStyles({
	pageBody: {
		flex: 1,
		padding: '25px',
		overflowX: 'hidden',
	},
	pageBodyColumns: {
		flex: 1,
		overflowY: 'hidden',
	},
});

export default PageBody;
