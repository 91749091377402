import { FormControl, FormControlProps, MenuItem, Select, SelectChangeEvent, InputLabel } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface YesNoDropdownProps extends Omit<FormControlProps, 'defaultValue'> {
	control?: Control<any>;
	name: string;
	label?: string;
	defaultValue?: boolean | null;
	required?: boolean;
	readOnly?: boolean;
}

const getOption = (value: any) => {
	if (value === true) return 'yes';
	if (value === false) return 'no';
	return '';
};

const YesNoDropdown = ({ control, name, label, defaultValue, required, readOnly, ...formProps }: YesNoDropdownProps) => {
	const labelId = `${name}-label`;

	return (
		<FormControl variant="standard" {...formProps}>
			{label && (
				<InputLabel id={labelId} required={required}>
					{label}
				</InputLabel>
			)}
			<Controller
				control={control}
				name={name}
				defaultValue={defaultValue ?? null}
				render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
					<Select
						variant="standard"
						name={name}
						inputRef={ref}
						onBlur={onBlur}
						value={getOption(value)}
						label={label}
						error={!!error}
						readOnly={readOnly}
						required={required}
						onChange={(e: SelectChangeEvent) => {
							if (e.target.value === 'yes') onChange(true);
							else if (e.target.value === 'no') onChange(false);
							else onChange(null);
						}}>
						<MenuItem value="none">No selection</MenuItem>
						<MenuItem value="yes">Yes</MenuItem>
						<MenuItem value="no">No</MenuItem>
					</Select>
				)}
			/>
		</FormControl>
	);
};

export default YesNoDropdown;
