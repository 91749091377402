import { ApolloError, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentUserFields } from '../../../apollo/generated/types/CurrentUserFields';
import { RegisterUser, RegisterUserVariables } from '../../../apollo/generated/types/RegisterUser';
import { REGISTER_USER } from '../../../apollo/mutations';

interface PermissionContextProps {
	userLoaded: boolean;
	authenticationLoading: boolean;
	error?: string | ApolloError;
	token?: string;
	hasPermission: (permission: string) => boolean;
	updateUserInfo: (newUser?: CurrentUserFields) => void;
	user?: CurrentUserFields;
}

const PermissionContext = createContext<PermissionContextProps | undefined>(undefined);

const PermissionProvider: React.FC = ({ children }) => {
	const history = useHistory();
	const [token, setToken] = useState<string | undefined>();
	const [error, setError] = useState<string | undefined>();
	const [user, setUser] = useState<CurrentUserFields>();
	const [claims, setClaims] = useState<any | undefined>();
	const { isLoading: authenticationLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect, user: auth0User } = useAuth0();
	const [register] = useMutation<RegisterUser, RegisterUserVariables>(REGISTER_USER);
	const [registerLock, setRegisterLock] = useState<boolean>(false);

	// Updating user info
	const updateUserInfo = (newUser?: CurrentUserFields) => {
		if (newUser) {
			setUser(newUser);
			fetchAccessToken(true);
		} else {
			loadUserData(true);
		}
	};

	const fetchAccessToken = async (willIgnoreCache = false) => {
		try {
			const auth0Token = await getAccessTokenSilently({ ignoreCache: willIgnoreCache });
			setToken(auth0Token);

			const decoded = jwt_decode(auth0Token);
			setClaims(decoded);
		} catch (e) {
			if (e.error === 'login_required') {
				loginWithRedirect({ appState: { returnTo: history.location.pathname } });
			}
			if (e.error === 'consent_required') {
				loginWithRedirect({ appState: { returnTo: history.location.pathname } });
			}
			throw e;
		}
	};

	const loadUserData = async (willIgnoreCache = false) => {
		// ensure register has not already been called
		if (!registerLock) {
			setRegisterLock(true); // block register
			await fetchAccessToken(willIgnoreCache);

			try {
				if (!auth0User?.email || !auth0User.sub) {
					setError('autho0User.email or auth0User.sub not defined');
					return;
				}
				const results = await register({
					variables: {
						email: auth0User.email,
						auth0Id: auth0User.sub,
					},
				});
				if (results?.data?.register) {
					setUser(results.data.register);
				} else {
					setError('User failed to register');
				}
			} catch (e) {
				setError(e);
			}

			// unblock register
			setRegisterLock(false);
		}
	};

	/**
	 * Check if token has permission
	 * @param permission
	 */
	const hasPermission = (permission: string): boolean => {
		const permissions = claims?.permissions || [];
		return permissions.includes(permission);
	};

	useEffect(() => {
		if (!authenticationLoading) {
			if (isAuthenticated) {
				loadUserData(false);
			} else if (!auth0User) {
				loginWithRedirect({ appState: { returnTo: history.location.pathname } });
			}
		}
	}, [authenticationLoading, isAuthenticated, auth0User]);

	return (
		<PermissionContext.Provider
			value={{
				userLoaded: !!user,
				authenticationLoading,
				token,
				user,
				error,
				hasPermission,
				updateUserInfo,
			}}
		>
			{children}
		</PermissionContext.Provider>
	);
};

const usePermissions = (): PermissionContextProps => {
	const context = useContext(PermissionContext);
	if (context === undefined) {
		throw new Error('usePermissions must be used within a PermissionProvider');
	}
	return context;
};

export { PermissionProvider, usePermissions };
