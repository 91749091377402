import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import FormSection from '../../FormSection';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PublicationInputType, PublicationRole } from '../../../../apollo/generated/types/globalTypes';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PublicationItem from './PublicationItem';

interface PublicationFormFields {
	publications: PublicationInputType[];
}

const PublicationForm: React.FC<PublicationFormProps> = ({ mode }) => {
	const { control } = useFormContext<PublicationFormFields>();
	const readOnly = mode === FormMode.View;

	const {
		fields: publications,
		append: appendPublication,
		remove: removePublication,
	} = useFieldArray({
		control,
		name: 'publications', // unique name for your Field Array
		keyName: 'key',
	});
	const addPublication = () => {
		if (!readOnly) {
			appendPublication({
				title: '',
				url: '',
				role: PublicationRole.AUTHOR,
			});
		}
	};

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Publications</Typography>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h3">List of Publications</Typography>
				</Grid>
				<Grid item xs={12}>
					{publications.map((publication, index) => (
						<PublicationItem
							key={publication.key}
							index={index}
							readOnly={readOnly}
							remove={removePublication}
							id={publication.id}
							title={publication.title ?? ''}
							url={publication.url ?? ''}
							role={publication.role}
						/>
					))}
				</Grid>
				{!readOnly ? (
					<Grid item xs={12}>
						<Button startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />} onClick={addPublication}>
							Add Publication
						</Button>
					</Grid>
				) : null}
			</FormSection>
		</>
	);
};

export interface PublicationFormProps {
	mode: FormMode;
}

export default PublicationForm;
