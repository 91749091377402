import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import makeStyles from '@mui/styles/makeStyles';
import { useFormContext } from 'react-hook-form';
import { PublicationRole } from '../../../../apollo/generated/types/globalTypes';
import { ReactHookFormSelect } from '../../..';
import { getRoleEnumFromString } from '../../../../utils/Enums/PublicationRoleEnum';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRef } from 'react';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		marginBottom: '15px',
	},
	removeSection: {
		width: '50px',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	headerIcon: {
		height: '100%',
	},
	space: {
		margin: '0 10px',
	},
	accordion: {
		flex: 'auto',
	},
});

const PublicationItem: React.FC<PublicationItemProps> = ({ index, readOnly, remove, id, title, url, role }) => {
	const classes = useStyles();
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const ref = useRef<HTMLDivElement>(null);

	const hasError = (field: string): boolean => {
		return !!errors && !!errors.publications && !!errors.publications[index] && !!errors.publications[index][field];
	};
	const getErrorMessage = (field: string): string => {
		return hasError(field) ? errors.publications?.[index][field]?.message : '';
	};

	const fieldNamePrefix = `publications[${index}]`;
	const roleOptions = ['Author', 'Co-author', 'Peer reviewer', 'Recommender'];

	return (
		<div className={classes.root}>
			<Accordion className={classes.accordion}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} ref={ref}>
					<div className={classes.header}>
						<Grid item xs={12}>
							<TextField
								variant="standard"
								name={`${fieldNamePrefix}.title`}
								label="Title"
								inputProps={{
									...register(`${fieldNamePrefix}.title`, {
										required: 'Title is required',
									}),
								}}
								defaultValue={title}
								error={hasError('title')}
								helperText={getErrorMessage('title')}
								fullWidth
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									readOnly: readOnly,
								}}
							/>
						</Grid>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{id ? <input {...register(`${fieldNamePrefix}.id`)} type="hidden" defaultValue={id} /> : null}
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="standard"
								name={`${fieldNamePrefix}.url`}
								label="URL"
								inputProps={{ ...register(`${fieldNamePrefix}.url`) }}
								defaultValue={url}
								error={hasError('url')}
								helperText={getErrorMessage('url')}
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									readOnly: readOnly,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<ReactHookFormSelect
								fullWidth
								control={control}
								label="Role"
								name={`${fieldNamePrefix}.role`}
								defaultValue={`${role}`}
								error={hasError('role')}
								helperText={getErrorMessage('role')}
								readOnly={readOnly}>
								{roleOptions.map((roleOption, index) => (
									<MenuItem key={index} value={getRoleEnumFromString(roleOption)}>
										{roleOption}
									</MenuItem>
								))}
							</ReactHookFormSelect>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
			{!readOnly && (
				<Tooltip title="Delete this Publication" placement="top">
					<div className={classes.removeSection}>
						<IconButton aria-label="remove" onClick={() => remove(index)} size="large">
							<RemoveCircleOutlineIcon fontSize="inherit" />
						</IconButton>
					</div>
				</Tooltip>
			)}
		</div>
	);
};

export interface PublicationItemProps {
	index: number;
	readOnly: boolean;
	remove: (index: number) => void;

	id?: string | null;
	title: string;
	url: string;
	role?: PublicationRole | null;
}

export default PublicationItem;
