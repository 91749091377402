import { DownSelectionType, ProjectDraftStatus, ProjectTypeE, SelectionProjectTypeE } from '../../../apollo/generated/types/globalTypes';
import { fullProjectTypeToString, selectionProjectTypeToString } from './EnumToStringUtil';

export type FullProjectType = Exclude<ProjectTypeE, ProjectTypeE.SELECTION> | SelectionProjectTypeE;
export const fullProjectTypeValues: FullProjectType[] = [
	...Object.values(ProjectTypeE).filter(t => t !== ProjectTypeE.SELECTION) as Exclude<ProjectTypeE, ProjectTypeE.SELECTION>[],
	...Object.values(SelectionProjectTypeE),
]

export const getProjectDraftStatusDisplayName = (value: string): string => {
	switch (value) {
		case ProjectDraftStatus.DRAFT:
			return 'Draft';
		case ProjectDraftStatus.PENDING_APPROVAL:
			return 'Pending Approval';
		case ProjectDraftStatus.APPROVED:
			return 'Approved';
		case ProjectDraftStatus.EVALUATIONS_IN_PROGRESS:
			return 'Evaluations in Progress';
		default:
			return '';
	}
};

export const getDownSelectionTypeName = (value: DownSelectionType): string => {
	switch (value) {
		case DownSelectionType.AWARDING:
			return 'Awarding';
		case DownSelectionType.DOWN_SELECTION:
			return 'Down Selection';
	}
};

export const getFullProjectType = <T extends {
	type: ProjectTypeE;
	selectionProjectInfo: {
		type: SelectionProjectTypeE;
	} | null | undefined;
}>(project: T): FullProjectType => {
	if (project.type === ProjectTypeE.SELECTION) {
		return project.selectionProjectInfo?.type as SelectionProjectTypeE
	}
	else return project.type;
}

export const getProjectTypeName = <T extends {
	type: ProjectTypeE;
	selectionProjectInfo: {
		type: SelectionProjectTypeE;
	} | null | undefined;
}>(project: T): string => {
	return fullProjectTypeToString(getFullProjectType(project));
}