import { useMutation, useQuery } from '@apollo/client';
import { Chip, SxProps, Theme } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { KeywordFragment } from '../../apollo/fragments';
import { CreateKeyword, CreateKeywordVariables } from '../../apollo/generated/types/CreateKeyword';
import { GetKeywords } from '../../apollo/generated/types/GetKeywords';
import { KeywordFields } from '../../apollo/generated/types/KeywordFields';
import { FpmCategory } from '../../apollo/generated/types/globalTypes';
import { CREATE_KEYWORD } from '../../apollo/mutations';
import { GET_KEYWORDS } from '../../apollo/queries';
import { ReactHookFormAutocompleteMulti } from '../ReactHookForm';
import { InfoLabel, SelectPlaceholder } from '../index';

const KeywordsInput: React.FC<KeywordsInputProps> = ({
	name,
	label,
	defaultValue,
	required,
	readOnly,
	allowAddNew = true,
	max = 30,
	fpmCategory,
	infoText,
	infoButtonLink,
	lockedKeywordIds = [],
	customOnChange,
	sx,
}) => {
	const { data, loading } = useQuery<GetKeywords>(GET_KEYWORDS);
	const keywords = data?.keywords ?? [];

	const [createKeyword] = useMutation<CreateKeyword, CreateKeywordVariables>(CREATE_KEYWORD, {
		update(cache, { data }) {
			if (data === null || data === undefined) return;
			cache.modify({
				fields: {
					keywords(existing, { readField }) {
						const newKeyword = cache.writeFragment({
							data: data.createKeyword,
							fragment: KeywordFragment,
						});
						return [newKeyword, ...existing.filter((x) => readField('id', x) !== data.createKeyword.id)];
					},
				},
			});
		},
	});

	const addKeyword = useCallback(
		async (name: string, fpmCategory: FpmCategory) => {
			const result = await createKeyword({
				variables: {
					name: name,
					fpmCategory: fpmCategory,
				},
			});
			if (result && result.data && result.data.createKeyword) {
				return result.data.createKeyword;
			}
			return null;
		},
		[createKeyword],
	);

	const {
		control,
		formState: { errors },
	} = useFormContext<{ [x: string]: number[] | undefined }>();
	const numKeywords = useWatch({ control, name })?.length ?? 0;

	const sortedKeywords = [...keywords]
		.filter((k) => fpmCategory === undefined || k.fpmCategory === fpmCategory)
		.filter((item, i, ar) => ar.findIndex((x) => x.name === item.name) === i)
		.sort((a, b) => (a.name.toUpperCase().trim() < b.name.toUpperCase().trim() ? -1 : 1));

	if (loading || keywords === undefined) {
		return <SelectPlaceholder label={label} />;
	}

	return (
		<>
			{infoText ? (
				<InfoLabel infoText={infoText}>{label}</InfoLabel>
			) : infoButtonLink ? (
				<InfoLabel pointerSX={{ cursor: 'pointer' }} onClick={() => window.open(infoButtonLink, '_blank')}>
					{label}
				</InfoLabel>
			) : (
				''
			)}
			<ReactHookFormAutocompleteMulti
				readOnly={readOnly}
				maxReached={max !== 0 && numKeywords >= max}
				name={name}
				label={!(infoText || infoButtonLink) ? label : ''}
				error={!!errors[name]}
				helperText={errors[name]?.message || (allowAddNew ? 'Type to filter or add new keyword' : 'Type to filter')}
				options={sortedKeywords}
				addOption={fpmCategory ? (name) => addKeyword(name, fpmCategory) : undefined}
				defaultValue={defaultValue}
				allowAddNew={allowAddNew}
				customOnChange={customOnChange}
				keepOpen
				sx={sx}
				rules={
					required
						? {
								validate: (value) => value?.length > 0 || `${label?.replace('*', '')} Keywords are required`,
						  }
						: undefined
				}
				getOptionSelected={(option: KeywordFields, value?: number[] | KeywordFields) => {
					if (!value) {
						return false;
					}
					if (Array.isArray(value)) {
						return value.includes(option.id);
					} else {
						return value.id === option.id;
					}
				}}
				getOptionValue={(options: KeywordFields[]) => [
					...lockedKeywordIds,
					...options.map((option: KeywordFields) => option.id).filter((id) => !lockedKeywordIds.includes(id)),
				]}
				getOptionLabel={(option: KeywordFields) => {
					return option.name;
				}}
				renderTags={(values: KeywordFields[], getTagProps) =>
					values.map((option: KeywordFields, index: number) => {
						return <Chip label={option.name} {...getTagProps({ index })} disabled={readOnly || lockedKeywordIds.includes(option.id)} />;
					})
				}
			/>
		</>
	);
};

export interface KeywordsInputProps {
	name: string;
	label?: string;
	defaultValue?: KeywordFields[];
	required?: boolean;
	readOnly?: boolean;
	allowAddNew?: boolean;
	max?: number; // 0 means unlimited
	fpmCategory?: FpmCategory;
	infoText?: string;
	infoButtonLink?: string;
	lockedKeywordIds?: number[];
	customOnChange?: (value: any) => void;
	sx?: SxProps<Theme> | undefined;
}

export default KeywordsInput;
