import { FormHelperText, InputLabel, Select, SelectChangeEvent, SelectProps, SxProps, Theme } from '@mui/material';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, RegisterOptions } from 'react-hook-form';

export interface ReactHookFormSelectProps extends Omit<FormControlProps, 'defaultValue'> {
	name: string;
	label?: string;
	control?: any;
	defaultValue?: string | number | string[] | any[] | null;
	helperText?: string;
	readOnly?: boolean;
	loading?: boolean;
	onSelectChange?: (event: SelectChangeEvent) => void;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	required?: boolean;
	selectProps?: SelectProps;
	shrinkLabel?: boolean;
	sx?: SxProps<Theme> | undefined;
}

const ReactHookFormSelect: React.FC<ReactHookFormSelectProps> = ({
	name,
	label,
	control,
	defaultValue,
	helperText,
	readOnly,
	rules,
	onSelectChange,
	children,
	selectProps,
	loading,
	required,
	shrinkLabel,
	error,
	sx,
	...formProps
}) => {
	const labelId = `${name}-label`;
	const classes = useStyles();

	return (
		<FormControl variant="standard" {...formProps} required={required} error={error} disabled={readOnly}>
			{label && (
				<InputLabel
					id={labelId}
					shrink={shrinkLabel || selectProps?.multiple ? true : undefined}
					className={helperText ? classes.required : ''}
					required={required}>
					{label}
				</InputLabel>
			)}
			<Controller
				control={control}
				rules={rules}
				name={name}
				defaultValue={defaultValue ?? null}
				render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
					<Select
						variant="standard"
						name={name}
						inputRef={ref}
						onChange={(e: SelectChangeEvent) => {
							e.target.value = e.target.value ?? null;
							if (onSelectChange) onSelectChange(e);
							onChange(e);
						}}
						onBlur={onBlur}
						value={value !== null && value !== undefined && !loading ? value : selectProps?.multiple ? [] : ''}
						labelId={labelId}
						label={label}
						error={!!error}
						disabled={loading}
						readOnly={readOnly}
						required={required}
						sx={sx}
						SelectDisplayProps={selectProps?.multiple ? { style: { minHeight: '2.5rem' } } : undefined}
						{...selectProps}>
						{children}
					</Select>
				)}
			/>
			{helperText ? <FormHelperText className={classes.required}>{helperText}</FormHelperText> : null}
		</FormControl>
	);
};

const useStyles = makeStyles((theme) =>
	createStyles({
		required: {
			color: theme.palette.error.main,
		},
	}),
);

export default ReactHookFormSelect;
