import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import FormSection from '../../FormSection';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import { ReactHookFormAddress, ReactHookFormPhone } from '../../..';
import { EmailRegex } from '../../../../utils/patterns';
import { useFormContext } from 'react-hook-form';
import EmployerItem from '../EmploymentForm/EmployerItem';
import { useQuery } from '@apollo/client';
import { GetGovIndAca } from '../../../../apollo/generated/types/GetGovIndAca';
import { GET_GOV_IND_ACA } from '../../../../apollo/queries';
import { Alert } from '@mui/material';

const RequiredForm: React.FC<RequiredFormProps> = ({ mode }) => {
	const {
		register,
		control,
		watch,
		getValues,

		formState: { errors },
	} = useFormContext();
	const id = watch('id');
	const currentEmployer = getValues('currentEmployer');
	const readOnly = mode === FormMode.View;

	const { data: govAcaIndOptionsData } = useQuery<GetGovIndAca>(GET_GOV_IND_ACA);

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Required Information</Typography>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="info">
						Please complete all starred fields in this Required Information section in order to participate in Groups and Projects. Also, please
						complete the starred General Information, Availability, and Profile Keywords sections identified on the left in order to enhance your
						interaction with the 9-HI™ Platform.{' '}
					</Alert>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				{id ? (
					<Grid item xs={12}>
						<input id="id" {...register('id')} type="hidden" value={id} />
					</Grid>
				) : null}
				<Grid item xs={6}>
					<TextField
						variant="standard"
						id="firstName"
						name="firstName"
						label="First Name*"
						inputProps={{
							...register('firstName', {
								required: 'First Name is required',
							}),
						}}
						error={!!errors.firstName}
						helperText={errors.firstName?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant="standard"
						id="lastName"
						name="lastName"
						label="Last Name*"
						inputProps={{
							...register('lastName', {
								required: 'Last Name is required',
							}),
						}}
						error={!!errors.lastName}
						helperText={errors.lastName?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant="standard"
						id="email"
						name="email"
						label="Email*"
						inputProps={{
							...register('email', {
								required: 'Email is required',
								pattern: {
									value: EmailRegex,
									message: 'Invalid email address',
								},
							}),
						}}
						error={!!errors.email}
						helperText={errors.email?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
							className: 'Mui-disabled',
						}}
						InputProps={{
							readOnly: true,
							className: 'Mui-disabled',
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant="standard"
						id="secondaryEmail"
						name="secondaryEmail"
						label="Secondary Email"
						inputProps={{
							...register('secondaryEmail', {
								pattern: {
									value: EmailRegex,
									message: 'Invalid email address',
								},
							}),
						}}
						error={!!errors.secondaryEmail}
						helperText={errors.secondaryEmail?.message}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<ReactHookFormPhone
						control={control}
						name="phoneNumber"
						label="Phone Number*"
						error={!!errors.phoneNumber}
						helperText={errors.phoneNumber?.message}
						rules={{
							required: 'Phone Number is required',
							pattern: {
								value: /^\+[0-9 \-,.()]{10,20}/,
								message: 'Phone Number is not valid',
							},
						}}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<EmployerItem
						readOnly={readOnly}
						remove={() => null}
						id={currentEmployer?.id}
						employerName={currentEmployer?.empoyerName ?? ''}
						title={currentEmployer?.title ?? ''}
						yearsOfExperience={currentEmployer?.yearsOfExperience}
						workDetails={currentEmployer?.workDetails ?? ''}
						govAcaIndId={currentEmployer?.govAcaIndId}
						govAcaIndOptionsData={govAcaIndOptionsData}
						currentEmployer={true}
						businessTypeId={currentEmployer?.businessTypeId ?? null}
					/>
				</Grid>
			</FormSection>
		</>
	);
};

export interface RequiredFormProps {
	mode: FormMode;
}

export default RequiredForm;
