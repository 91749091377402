import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState } from 'react';

/**
 * connects to a signalR hub
 * @param {string} url the url of the hub you want to connect to
 * @param {(...args: any[]) => void} [onReceiveMessage] a callback that is called when the connection receives an update
 * @param {string} [groupName] if provided, this connection will automatically be added to the provided group
 * @return {(...args: any[]) => void} sendMessage - a function that can be used to send a message to the connection
 * @return {boolean} connectionStarted - whether or not the connection has successfully started yet
 * @return {any} error - the error message associated with this connection, if there is one
 */
const useSignalR = (
	url: string,
	onReceiveMessage?: (...args: any[]) => void,
	groupName?: string,
): {
	sendMessage: (...args: any[]) => void;
	connectionStarted: boolean;
	error: any;
} => {
	const [savedConnection, setSavedConnection] = useState<HubConnection>();
	const [connectionStarted, setConnectionStarted] = useState(false);
	const [error, setError] = useState();

	useEffect(() => {
		let canceled = false;
		const apiURL = process.env.REACT_APP_API_URL;
		const connection = new HubConnectionBuilder()
			.withUrl(`${apiURL}${url}`, { skipNegotiation: true, transport: HttpTransportType.WebSockets })
			.withAutomaticReconnect()
			.build();
		if (onReceiveMessage) {
			connection.on('ReceiveMessage', onReceiveMessage);
		}
		connection
			.start()
			.then(() => {
				if (groupName) {
					connection.invoke('JoinGroup', groupName).catch((e) => {
						if (!canceled) setError(e);
					});
				}
				if (!canceled) setConnectionStarted(true);
			})
			.catch((e) => {
				if (!canceled) setError(e);
			});
		if (!canceled) setSavedConnection(connection);
		return () => {
			connection.stop();
			canceled = true;
		};
	}, []);

	const sendMessage = (...args: any[]) => {
		savedConnection?.invoke('SendMessage', ...args).catch((e) => setError(e));
	};

	return {
		sendMessage,
		connectionStarted,
		error,
	};
};

export default useSignalR;
