/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdminRole {
  SUPER_SME = "SUPER_SME",
  SYS_ADMIN = "SYS_ADMIN",
}

export enum BulletinNotificationFrequency {
  NONE = "NONE",
  UNLIMITED = "UNLIMITED",
  WEEKLY = "WEEKLY",
}

export enum BulletinNotificationMethod {
  BOTH = "BOTH",
  EMAIL = "EMAIL",
  NINE_HI = "NINE_HI",
}

export enum BulletinStatus {
  APPROVED = "APPROVED",
  DELIVERED = "DELIVERED",
  DISAPPROVED = "DISAPPROVED",
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
}

export enum DataPrivacy {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum DownSelectionType {
  AWARDING = "AWARDING",
  DOWN_SELECTION = "DOWN_SELECTION",
}

export enum FileReferenceType {
  GROUP_FILE = "GROUP_FILE",
  MISC = "MISC",
  PROFILE_PICTURE = "PROFILE_PICTURE",
  PROJECT_FILE = "PROJECT_FILE",
  PROJECT_TOPIC_FILE = "PROJECT_TOPIC_FILE",
  STAGE_2_RESPONDER_FILE = "STAGE_2_RESPONDER_FILE",
  STAGE_2_RESPONSE_FORM_DOCUMENT_TEMPLATE = "STAGE_2_RESPONSE_FORM_DOCUMENT_TEMPLATE",
  SUCCESS_EVIDENCE_FILE = "SUCCESS_EVIDENCE_FILE",
  USER_FILE = "USER_FILE",
}

export enum FpmCategory {
  APPLICATION = "APPLICATION",
  ORGANIZATION = "ORGANIZATION",
  PRODUCT_TECHNOLOGY = "PRODUCT_TECHNOLOGY",
}

export enum FundamentalPrimeMeasurement {
  APPEAL = "APPEAL",
  APPLICATION_SIZE = "APPLICATION_SIZE",
  DELIVERY = "DELIVERY",
  DEMAND = "DEMAND",
  FINANCES = "FINANCES",
  PERSONNEL = "PERSONNEL",
  PROCESSES = "PROCESSES",
  RELIABILITY = "RELIABILITY",
  VALUE = "VALUE",
}

export enum GroupRole {
  PROGRAM_MANAGER = "PROGRAM_MANAGER",
  SUBJECT_MATTER_EXPERT = "SUBJECT_MATTER_EXPERT",
}

export enum GroupStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}

export enum GroupSubscriptionStatus {
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
  REPLACED = "REPLACED",
  REQUEST_CANCELLED = "REQUEST_CANCELLED",
  SUBMITTED = "SUBMITTED",
  SUSPENDED = "SUSPENDED",
}

export enum GroupTypeE {
  ACADEMIA = "ACADEMIA",
  GOVERNMENT = "GOVERNMENT",
  INDUSTRY = "INDUSTRY",
  INNOVATION_EXPLORATION = "INNOVATION_EXPLORATION",
}

export enum HoursPer {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum InformationType {
  KEYWORDS = "KEYWORDS",
  RISKS = "RISKS",
  SUCCESS_EVIDENCE = "SUCCESS_EVIDENCE",
  SUCCESS_FACTORS = "SUCCESS_FACTORS",
  TECHNICAL_DOCUMENTS = "TECHNICAL_DOCUMENTS",
}

export enum InviteStatus {
  APPROVED = "APPROVED",
  INVITATION_DECLINED = "INVITATION_DECLINED",
  INVITED = "INVITED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  UNSENT = "UNSENT",
  WORK_AGREEMENT_DECLINED = "WORK_AGREEMENT_DECLINED",
}

export enum LetterType {
  AUTHORIZATION = "AUTHORIZATION",
  COLLABORATION = "COLLABORATION",
  COMMITMENT = "COMMITMENT",
  INTENT = "INTENT",
  ORGANIZATIONAL_SUPPORT = "ORGANIZATIONAL_SUPPORT",
  RECOMMENDATIONS = "RECOMMENDATIONS",
}

export enum ProjectContractType {
  COST_PLUS_FIXED_FEE = "COST_PLUS_FIXED_FEE",
  COST_PLUS_INCENTIVE_FEE = "COST_PLUS_INCENTIVE_FEE",
  FIXED_PRICE = "FIXED_PRICE",
  FIXED_PRICE_INCENTIVE_FEE = "FIXED_PRICE_INCENTIVE_FEE",
  OTHER = "OTHER",
  TIME_AND_MATERIAL = "TIME_AND_MATERIAL",
}

export enum ProjectDraftStatus {
  APPROVED = "APPROVED",
  DRAFT = "DRAFT",
  EVALUATIONS_IN_PROGRESS = "EVALUATIONS_IN_PROGRESS",
  PENDING_APPROVAL = "PENDING_APPROVAL",
}

export enum ProjectRole {
  PROGRAM_MANAGER = "PROGRAM_MANAGER",
  PROJECT_MANAGER = "PROJECT_MANAGER",
  SUBJECT_MATTER_EXPERT = "SUBJECT_MATTER_EXPERT",
}

export enum ProjectSolicitationType {
  DOWN_SELECTION = "DOWN_SELECTION",
  OPEN = "OPEN",
}

export enum ProjectStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}

export enum ProjectTopicDownSelectionStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_IN_PROGRESS = "NOT_IN_PROGRESS",
}

export enum ProjectTopicStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  CLOSED = "CLOSED",
  COMPLETED = "COMPLETED",
}

export enum ProjectTypeE {
  DEVELOPMENT = "DEVELOPMENT",
  INNOVATION_EXPLORATION = "INNOVATION_EXPLORATION",
  RESPONSE = "RESPONSE",
  SELECTION = "SELECTION",
}

export enum PublicationRole {
  AUTHOR = "AUTHOR",
  CO_AUTHOR = "CO_AUTHOR",
  PEER_REVIEWER = "PEER_REVIEWER",
  RECOMMENDER = "RECOMMENDER",
}

export enum RatePer {
  HOUR = "HOUR",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum RecommenderRoleOptions {
  GROUP_SME = "GROUP_SME",
  PROGRAM_MANAGER = "PROGRAM_MANAGER",
  PROJECT_MANAGER = "PROJECT_MANAGER",
  PROJECT_SME = "PROJECT_SME",
  TOPIC_SME = "TOPIC_SME",
  TOPIC_TEAM_LEAD = "TOPIC_TEAM_LEAD",
}

export enum RegistrantStatus {
  APPROVED = "APPROVED",
  DISAPPROVED = "DISAPPROVED",
  PENDING_REVIEW = "PENDING_REVIEW",
  UNSUBMITTED = "UNSUBMITTED",
}

export enum ResponseDraftStatus {
  DISQUALIFIED = "DISQUALIFIED",
  FINALIZED = "FINALIZED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
  SUBMITTED = "SUBMITTED",
}

export enum ResponseRequirementsDraftStatus {
  APPROVED = "APPROVED",
  DRAFT = "DRAFT",
  PENDING_APPROVAL = "PENDING_APPROVAL",
}

export enum ResponseStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

export enum ScoreDefinition {
  NINE_HI_SCORE = "NINE_HI_SCORE",
  NINE_HI_SCORE_RANK = "NINE_HI_SCORE_RANK",
  PLATFORM_ENGAGE_SCORE = "PLATFORM_ENGAGE_SCORE",
  PLATFORM_ENGAGE_SCORE_RANK = "PLATFORM_ENGAGE_SCORE_RANK",
  PRJ_CONTRIBUTION = "PRJ_CONTRIBUTION",
  PRJ_CONTRIBUTION_RANK = "PRJ_CONTRIBUTION_RANK",
  PROFILE_COMPLETENESS = "PROFILE_COMPLETENESS",
  PROFILE_COMPLETENESS_RANK = "PROFILE_COMPLETENESS_RANK",
  RECOMM_OF_NON_9_HI_PEERS_SCORE = "RECOMM_OF_NON_9_HI_PEERS_SCORE",
  RECOMM_OF_PEERS_SCORE = "RECOMM_OF_PEERS_SCORE",
  RECOMM_OF_PEERS_SCORE_RANK = "RECOMM_OF_PEERS_SCORE_RANK",
  RECOMM_RECEIVED_SCORE = "RECOMM_RECEIVED_SCORE",
  RECOMM_RECEIVED_SCORE_RANK = "RECOMM_RECEIVED_SCORE_RANK",
  REPUTATION = "REPUTATION",
  REPUTATION_RANK = "REPUTATION_RANK",
  SF_ADDING_SCORE = "SF_ADDING_SCORE",
  SF_ADDING_SCORE_RANK = "SF_ADDING_SCORE_RANK",
  SF_QUOTED_SCORE = "SF_QUOTED_SCORE",
  SF_QUOTED_SCORE_RANK = "SF_QUOTED_SCORE_RANK",
  SME_RATINGS_IN_PROJS = "SME_RATINGS_IN_PROJS",
  UTILIZATION_SCORE = "UTILIZATION_SCORE",
}

export enum SearchWithin {
  GROUP = "GROUP",
  PLATFORM = "PLATFORM",
  PROJECT = "PROJECT",
}

export enum SelectionProjectTypeE {
  PITCH_EVENT = "PITCH_EVENT",
  REQUEST_FOR_INFORMATION = "REQUEST_FOR_INFORMATION",
  REQUEST_FOR_PROPOSAL = "REQUEST_FOR_PROPOSAL",
  REQUEST_FOR_WHITE_PAPER = "REQUEST_FOR_WHITE_PAPER",
  SIMPLE_RFI = "SIMPLE_RFI",
}

export enum SelectionStatus {
  AWARD_BASKETED = "AWARD_BASKETED",
  AWARD_REJECTED = "AWARD_REJECTED",
  AWARD_SELECTED = "AWARD_SELECTED",
  CANCELLED = "CANCELLED",
  DISQUALIFIED = "DISQUALIFIED",
  DOWN_SELECTION_REJECTED = "DOWN_SELECTION_REJECTED",
  DOWN_SELECTION_SELECTED = "DOWN_SELECTION_SELECTED",
  PENDING = "PENDING",
}

export enum TopicRole {
  SUBJECT_MATTER_EXPERT = "SUBJECT_MATTER_EXPERT",
  TOPIC_LEAD = "TOPIC_LEAD",
}

export enum TrlRangeCategory {
  FOUR_TO_SIX = "FOUR_TO_SIX",
  ONE_TO_THREE = "ONE_TO_THREE",
  SEVEN_TO_NINE = "SEVEN_TO_NINE",
}

export enum UserKeywordTypeEnum {
  INTERESTS = "INTERESTS",
  SKILLS = "SKILLS",
}

export enum WorkAgreementLocationType {
  CONTRACTORS_FACILITY = "CONTRACTORS_FACILITY",
  PROVIDE_SITE_LOCATION = "PROVIDE_SITE_LOCATION",
  REMOTE = "REMOTE",
}

export interface AwardInputType {
  id?: string | null;
  name: string;
  institution?: string | null;
}

export interface BulletinInputType {
  id?: string | null;
  title?: string | null;
  informationRequest?: string | null;
  groupProjectTopicSelection?: string | null;
  projectId?: string | null;
  groupId?: number | null;
  topicId?: string | null;
  scopes?: FpmCategory[] | null;
  infoTypes?: InformationType[] | null;
  keywordIds?: number[] | null;
  submit: boolean;
}

export interface CertificationInputType {
  id?: string | null;
  name: string;
  institution?: string | null;
}

export interface CommentEditInputType {
  commentId: number;
  content: string;
  date: any;
}

export interface CommentInputType {
  id?: number | null;
  url: string;
  content: string;
  userId: string;
  parentId?: number | null;
  date: any;
}

export interface DegreeInputType {
  id?: string | null;
  degreeType: string;
  institution: string;
}

export interface DevProjectImportInputType {
  projectDetails: ProjectImportProjectDetailsType;
  projectMembers: ProjectImportProjectMembersType;
  topicDetails: ProjectImportTopicDetailsType;
  responseDetails: ProjectImportResponseDetailsType;
  newProjectDetails: DevProjectImportNewProjectDetailsType;
}

export interface DevProjectImportNewProjectDetailsType {
  name: string;
  groupId: number;
  privacy: DataPrivacy;
  securityClassificationId: number;
  description: string;
}

export interface DevelopmentProjectInputType {
  genericProjectData: GenericProjectInputType;
  privacy: DataPrivacy;
  topicName: string;
  topicSummary: string;
  securityClassificationId?: number | null;
  startTrlRangeId?: number | null;
  finishTrlRangeId?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  timezone?: string | null;
  deadlines?: ProjectDeadlineInputType[] | null;
}

export interface DevelopmentProjectScoreInputType {
  successFactorId?: string | null;
  selfAssessmentScore?: any | null;
  initialScore?: any | null;
  currentActualScore?: any | null;
  targetFinishScore?: any | null;
}

export interface EmployerInputType {
  id?: string | null;
  employerName?: string | null;
  workDetails: string;
  yearsOfExperience?: number | null;
  title: string;
  currentEmployer?: boolean | null;
  govAcaIndId?: number | null;
  businessTypeId?: string | null;
}

export interface EvaluationInputType {
  responseUserId: number;
  reviewed: boolean;
  finalCommentIncludedInFinal?: boolean | null;
  contactInfoIncludedInFinal?: boolean | null;
}

export interface FileUploadInputType {
  id?: number | null;
  uploadToken?: string | null;
}

export interface FinalEvaluationInputType {
  finalEvaluationId: number;
  finalComment?: string | null;
  includeContactInfo: boolean;
  finalizeScores: boolean;
  stage2EvaluatorScores: Stage2EvaluatorScoreInputType[];
}

export interface FundingTypeInputType {
  name: string;
}

export interface GenericProjectInputType {
  id?: string | null;
  name: string;
  groupId: number;
  description: string;
  objectives?: string | null;
  keywordIds?: number[] | null;
  responderFiles: FileUploadInputType[];
  internalFiles: FileUploadInputType[];
  projectPointsOfContact?: ShortProjectPOCInputType[] | null;
}

export interface GroupInputType {
  id: number;
  name: string;
  address: string;
  phoneNumber: string;
  contactName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  privacy: DataPrivacy;
  groupType: GroupTypeE;
  buildingNumberMailstop?: string | null;
  industryType?: string | null;
  additionalContactName?: string | null;
  additionalContactPhoneNumber?: string | null;
  additionalContactEmail?: string | null;
  naicsCode?: string | null;
  cageCode?: string | null;
  dunsNumber?: any | null;
  samUniqueEntityId?: string | null;
  tin?: number | null;
  website?: string | null;
  linkedInProfile?: string | null;
  facebookProfile?: string | null;
  twitterHandle?: string | null;
  instagramProfile?: string | null;
  youTubeChannel?: string | null;
  vimeoChannel?: string | null;
  researchGateProfile?: string | null;
  googleScholarProfile?: string | null;
  subtypeId?: string | null;
  description: string;
  keywordIds?: number[] | null;
  groupFiles: FileUploadInputType[];
  lanesOfFocus?: FpmCategory[] | null;
  groupPointsOfContact: GroupPointsOfContactInputType[];
}

export interface GroupPointsOfContactInputType {
  userId: string;
  contactEmail: string;
  contactPhoneNumber: string;
}

export interface GroupUserInputType {
  userId?: string | null;
  groupRole?: GroupRole | null;
  inviteStatus?: InviteStatus | null;
  primaryOwner?: boolean | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  invitedBy?: string | null;
  declineReason?: string | null;
}

export interface InnovationExplorationProjectInputType {
  genericProjectData: GenericProjectInputType;
  privacy: DataPrivacy;
  startTrlRangeId?: number | null;
  finishTrlRangeId?: number | null;
  topics?: ShortProjectTopicInputType[] | null;
}

export interface LibraryKeywordInputType {
  id?: number | null;
  name: string;
  fpmCategory: FpmCategory;
}

export interface LibraryRiskInputType {
  id?: string | null;
  name: string;
  definition: string;
  fpmCategory: FpmCategory;
}

export interface LibrarySuccessEvidenceInputType {
  id?: string | null;
  name: string;
}

export interface LibrarySuccessFactorInputType {
  id?: string | null;
  name: string;
  definition: string;
  fpmCategory: FundamentalPrimeMeasurement;
}

export interface LikedCommentInputType {
  id?: number | null;
  commentId: number;
  userId: string;
}

export interface MemberImportInputType {
  userId: string;
  selected: boolean;
}

export interface OpenAiRiskRequestInputType {
  projectTopicId: string;
  fpmCategory: FpmCategory;
  technologyKeywords?: number[] | null;
  applicationKeywords?: number[] | null;
}

export interface OpenAiSeRequestInputType {
  successFactorId: string;
  projectTopicId: string;
  technologyKeywords?: number[] | null;
  applicationKeywords?: number[] | null;
}

export interface OpenAiSfRequestInputType {
  projectTopicId: string;
  fpmCategory: FpmCategory;
  technologyKeywords?: number[] | null;
  applicationKeywords?: number[] | null;
}

export interface PartialWorkAgreementInputType {
  groupPocId?: string | null;
  projectPocId?: string | null;
  topicPocId?: string | null;
  providedSiteLocation?: string | null;
  workStartDate?: any | null;
  workEndDate?: any | null;
  scopeOfWork?: string | null;
  expectedDeliverables?: string | null;
  requiredFormInstructions: string;
  emailForDocuments?: string | null;
  formTemplates?: FileUploadInputType[] | null;
  workLocations?: WorkAgreementLocationType[] | null;
}

export interface ProjectDeadlineInputType {
  deadline: string;
  dateAndTime: any;
}

export interface ProjectImportNewProjectDetailsType {
  name: string;
  groupId: number;
  type: SelectionProjectTypeE;
  privacy: DataPrivacy;
  securityClassificationId: number;
  description: string;
  link: boolean;
}

export interface ProjectImportProjectDetailsType {
  deadlineTimezone: boolean;
  distributionStatement: boolean;
  downSelectionType: boolean;
  fundingEndDate: boolean;
  fundingRange: boolean;
  fundingStartDate: boolean;
  fundingType: boolean;
  objectives: boolean;
  productTechnologyKeywords: boolean;
  teamStakeholdersKeywords: boolean;
  marketApplicationKeywords: boolean;
  proprietaryInformationResponsesAllowed: boolean;
  proprietaryVendorSolutionsAllowed: boolean;
  purpose: boolean;
  registration: boolean;
  responderEligibilities: boolean;
  responderSubTypes: boolean;
  responderTypes: boolean;
  responseSubmissionRequirements: boolean;
  startTrlRange: boolean;
  finishTrlRange: boolean;
}

export interface ProjectImportProjectMembersType {
  keepRoles: boolean;
  keepPointsOfContact: boolean;
  users: MemberImportInputType[];
}

export interface ProjectImportResponseDetailsType {
  responseId?: any | null;
}

export interface ProjectImportTopicDetailsType {
  description: boolean;
  objectives: boolean;
  fundingRange: boolean;
  placeOfPerformance: boolean;
  productTechnologyKeywords: boolean;
  teamStakeholdersKeywords: boolean;
  marketApplicationKeywords: boolean;
  risks: boolean;
  successFactors: boolean;
  successFactorWeights: boolean;
  successEvidence: boolean;
}

export interface ProjectImportTopicInputType {
  topicId: string;
  topicDetails: ProjectImportTopicDetailsType;
  users: MemberImportInputType[];
}

export interface ProjectTopicInputType {
  id: string;
  description?: string | null;
  objectives?: string | null;
  fundingRange?: any | null;
  technologyApplications?: string | null;
  placeOfPerformance?: string | null;
  expectedAwards?: number | null;
  keywordIds?: number[] | null;
  responderFiles: FileUploadInputType[];
  internalFiles: FileUploadInputType[];
  pointsOfContact?: TopicPocInputType[] | null;
}

export interface ProjectTopicRiskInputType {
  id: string;
  name: string;
  definition: string;
  fpmCategory: FpmCategory;
}

export interface ProjectTopicRiskSfLinkInputType {
  stage2SuccessFactorId: string;
  projectTopicRiskId: string;
}

export interface ProjectTopicRisksFormInputType {
  projectTopicId: string;
  risks?: ProjectTopicRiskInputType[] | null;
}

export interface ProjectTopicSuccessFactorsFormInputType {
  projectTopicId: string;
  successFactors?: Stage2SuccessFactorInputType[] | null;
  riskSfLinks?: ProjectTopicRiskSfLinkInputType[] | null;
}

export interface ProjectTopicUserInputType {
  userId?: string | null;
  topicRole?: TopicRole | null;
  inviteStatus?: InviteStatus | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  invitedBy?: string | null;
  declineReason?: string | null;
}

export interface ProjectUserInputType {
  userId?: string | null;
  projectRole?: ProjectRole | null;
  inviteStatus?: InviteStatus | null;
  isPrimaryContact?: boolean | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  invitedBy?: string | null;
  declineReason?: string | null;
}

export interface PublicationInputType {
  id?: string | null;
  title: string;
  url: string;
  role?: PublicationRole | null;
}

export interface RecommendationInputType {
  groupId?: number | null;
  projectId?: string | null;
  projectTopicId?: string | null;
  recommendedRole?: RecommenderRoleOptions | null;
  email: string;
  firstName: string;
  lastName: string;
  comment: string;
}

export interface ResponseLetterTypeCheckboxStatusInputType {
  stage2RequirementsLetterTypeId?: number | null;
  checked: boolean;
  letterType: LetterType;
}

export interface ResponseOtherRequirementsCheckboxStatusInputType {
  checked: boolean;
  documentName: string;
}

export interface ResponseProjectInputType {
  genericProjectData: GenericProjectInputType;
  responseId: string;
  topicName: string;
  topicSummary: string;
  securityClassificationId?: number | null;
  startTrlRangeId?: number | null;
  finishTrlRangeId?: number | null;
  expectedStartDate?: any | null;
  expectedCompletionDate?: any | null;
  targetBudget?: any | null;
  timezone?: string | null;
  deadlines?: (ProjectDeadlineInputType | null)[] | null;
}

export interface ResponseRequiredDocumentsCheckboxStatusInputType {
  costInformationVolume?: boolean | null;
  briefingChart?: boolean | null;
  commercializationPlan?: boolean | null;
  coverLetter?: boolean | null;
  deliverables?: boolean | null;
  dfarclausesAcknowledgement?: boolean | null;
  executiveSummary?: boolean | null;
  farclausesAcknowledgement?: boolean | null;
  financialInformation?: boolean | null;
  highLevelSchedule?: boolean | null;
  intellectualPropertyDeclarations?: boolean | null;
  keyPersonnelDocumentation?: boolean | null;
  letterTypes?: boolean | null;
  milestones?: boolean | null;
  ocideclarations?: boolean | null;
  ocimititgationPlan?: boolean | null;
  organizationAccreditations?: boolean | null;
  paymentsSchedule?: boolean | null;
  pitchEventInvestors?: boolean | null;
  powerpointPresentation?: boolean | null;
  programWorkStatement?: boolean | null;
  projectPlan?: boolean | null;
  rfitechnicalEvidence?: boolean | null;
  roughOrderOfMagnitudeCost?: boolean | null;
  statementOfWork?: boolean | null;
  technicalWriteup?: boolean | null;
  transitionPlan?: boolean | null;
  videoPresentation?: boolean | null;
}

export interface SMECandidateInputType {
  searchText?: string | null;
  availability?: number | null;
  uSCitizen?: boolean | null;
  employer?: string | null;
  businessTypeIds?: (string | null)[] | null;
  jobTitle?: string | null;
  minExperience?: number | null;
  maxExperience?: number | null;
  securityClearance?: boolean | null;
  projectId?: string | null;
  productTechnologyKeywords?: number[] | null;
  teamStakeholdersKeywords?: number[] | null;
  marketApplicationKeywords?: number[] | null;
  excludeKeywords?: number[] | null;
  profileCompleteness?: number | null;
  platformEngagement?: number | null;
  projectEngagement?: number | null;
  reputation?: number | null;
  nineHiScore?: number | null;
  searchWithin?: SearchWithin | null;
  callingProjectId?: string | null;
  callingTopicId?: string | null;
}

export interface SelectionProjectImportInputType {
  projectDetails: ProjectImportProjectDetailsType;
  projectMembers: ProjectImportProjectMembersType;
  topicInputs: ProjectImportTopicInputType[];
  newProjectDetails: ProjectImportNewProjectDetailsType;
  keepTopicMemberRoles: boolean;
}

export interface SelectionProjectInputType {
  genericProjectData: GenericProjectInputType;
  type: SelectionProjectTypeE;
  privacy: DataPrivacy;
  fundingRange?: any | null;
  fundingStartDate?: any | null;
  fundingEndDate?: any | null;
  fundingTypeId?: number | null;
  fundingSourceId?: number | null;
  technologyApplications?: string | null;
  distributionStatement?: string | null;
  includeInvestors?: boolean | null;
  securityClassificationId?: number | null;
  proprietaryVendorSolutionsAllowed?: string | null;
  proprietaryInformationResponsesAllowed?: string | null;
  deadline?: any | null;
  deadlineTimezone?: string | null;
  submissionDeadline?: any | null;
  submissionDeadlineTimezone?: string | null;
  downSelectionType: DownSelectionType;
  draftStatusVal: ProjectDraftStatus;
  responderEligibilityIds?: number[] | null;
  responderTypeIds?: number[] | null;
  responderSubTypeIds?: number[] | null;
  requiresProgramManagerApproval: boolean;
  startTrlRangeId?: number | null;
  finishTrlRangeId?: number | null;
  purpose?: string | null;
  registration?: string | null;
  responseSubmissionRequirements?: string | null;
  solicitationType?: ProjectSolicitationType | null;
  fundingCategoryIds?: number[] | null;
  contractType?: ProjectContractType | null;
  contractTypeOther?: string | null;
  expectedProjectDuration?: string | null;
  costSharingFundsRequired?: boolean | null;
  costSharingInfo?: string | null;
  qandAperiod?: boolean | null;
  qandAendDate?: any | null;
  qandAinstructions?: string | null;
  exportControlInfo?: string | null;
  topics?: ShortProjectTopicInputType[] | null;
}

export interface ShortProjectPOCInputType {
  userId: string;
  displayOnAnnouncement?: boolean | null;
}

export interface ShortProjectTopicInputType {
  id?: string | null;
  name: string;
  summary: string;
}

export interface Stage2EvaluatorScoreInputType {
  id?: number | null;
  stage2SuccessFactorId: string;
  score?: any | null;
  comment?: string | null;
}

export interface Stage2RegistrantInputType {
  id?: string | null;
  status: RegistrantStatus;
  registrantGroupId?: number | null;
  yearsGroupExisting?: number | null;
  capitalization?: any | null;
  contactTitle?: string | null;
  contactUserId: string;
  projectId: string;
  projectTopicId?: string | null;
  responderTechnologyName?: string | null;
  responderTechnologyDescription?: string | null;
  keywordIds?: number[] | null;
}

export interface Stage2ResponderScoreInputType {
  stage2SuccessFactorId: string;
  score?: any | null;
}

export interface Stage2ResponseFormOtherRequirementInputType {
  documentName: string;
  required: boolean;
}

export interface Stage2ResponseFormRequirementInputType {
  id?: string | null;
  projectId: string;
  draftStatusVal: ResponseRequirementsDraftStatus;
  hostProjectManagerDisplayed: boolean;
  showHostProjectManagerEmail: boolean;
  showHostProjectManagerPhoneNumber: boolean;
  awardeeRangeDisplayed: boolean;
  fundingCategoriesDisplayed: boolean;
  contractTypeDisplayed: boolean;
  periodOfPerformanceDisplayed: boolean;
  costSharingInfoDisplayed: boolean;
  hasQaPeriodDisplayed: boolean;
  qaPeriodEndDisplayed: boolean;
  qaPeriodInfoDisplayed: boolean;
  exportControlInfoDisplayed: boolean;
  solicitationTypeDisplayed: boolean;
  briefingChartRequired: boolean;
  rfitechnicalEvidenceRequired: boolean;
  roughOrderOfMagnitudeCostRequired: boolean;
  coverLetterRequired: boolean;
  highLevelScheduleRequired: boolean;
  otherDocumentsRequired: boolean;
  stage2ResponseFormDocuments?: FileUploadInputType[] | null;
  executiveSummaryRequired: boolean;
  powerpointPresentationRequired: boolean;
  videoPresentationRequired: boolean;
  statementOfWorkRequired: boolean;
  keyPersonnelDocumentationRequired: boolean;
  organizationAccreditationsRequired: boolean;
  financialInformationRequired: boolean;
  instructionsForSubmission?: string | null;
  pitchEventVenue?: string | null;
  pitchEventInvestorsRequired: boolean;
  letterTypesRequired: boolean;
  technicalWriteupRequired: boolean;
  costInformationVolumeRequired: boolean;
  programWorkStatementRequired: boolean;
  projectPlanRequired: boolean;
  milestonesRequired: boolean;
  deliverablesRequired: boolean;
  paymentsScheduleRequired: boolean;
  intellectualPropertyDeclarationsRequired: boolean;
  transitionPlanRequired: boolean;
  commercializationPlanRequired: boolean;
  ocideclarationsRequired: boolean;
  ocimititgationPlanRequired: boolean;
  includeBudgetInfo: boolean;
  includeNumExpectedAwardsInfo: boolean;
  farclausesAcknowledgementRequired: boolean;
  dfarclausesAcknowledgementRequired: boolean;
  responderSubTypeDisplayed: boolean;
  responderSubTypeRequired: boolean;
  responderTaxIdDisplayed: boolean;
  responderTaxIdRequired: boolean;
  responderGroupPublicWebsiteDisplayed: boolean;
  responderGroupPublicWebsiteRequired: boolean;
  responderRDcategoriesProposedDisplayed: boolean;
  responderRDcategoriesProposedRequired: boolean;
  responderRevenueDisplayed: boolean;
  responderRevenueRequired: boolean;
  responderNaicsdisplayed: boolean;
  responderNaicsrequired: boolean;
  responderSicdisplayed: boolean;
  responderSicrequired: boolean;
  responderDunsdisplayed: boolean;
  responderDunsrequired: boolean;
  responderCagecodeDisplayed: boolean;
  responderCagecodeRequired: boolean;
  responderSamregistrationDisplayed: boolean;
  responderSamregistrationRequired: boolean;
  responderSbaregistrationDisplayed: boolean;
  responderSbaregistrationRequired: boolean;
  responderProposedAmountDisplayed: boolean;
  responderProposedAmountRequired: boolean;
  letterTypes?: LetterType[] | null;
  otherDocuments?: Stage2ResponseFormOtherRequirementInputType[] | null;
}

export interface Stage2ResponseInputType {
  id?: string | null;
  responderSubType?: string | null;
  responderTaxId?: string | null;
  responderGroupPublicWebsite?: string | null;
  responderRevenue?: any | null;
  responderNAICS?: string | null;
  responderSIC?: string | null;
  responderDUNS?: any | null;
  responderCAGECode?: string | null;
  responderSAMRegistration?: boolean | null;
  responderSBARegistration?: boolean | null;
  responderProposedAmount?: any | null;
  trlRangeId?: number | null;
  contactUserId?: string | null;
  contactTitle?: string | null;
  responderTechnologyName?: string | null;
  responderTechnologyDescription?: string | null;
  selectedStatus: SelectionStatus;
  responseFiles: FileUploadInputType[];
  responderScores: Stage2ResponderScoreInputType[];
  isDraft?: boolean | null;
  awardedAmount?: any | null;
  requiredDocumentsCheckboxStatuses?: ResponseRequiredDocumentsCheckboxStatusInputType | null;
  responderSubTypeIds?: number[] | null;
  letterTypeCheckboxStatuses?: ResponseLetterTypeCheckboxStatusInputType[] | null;
  otherRequirementsCheckboxStatuses?: ResponseOtherRequirementsCheckboxStatusInputType[] | null;
}

export interface Stage2ResponseUserScoreInputType {
  responseUserId: number;
  reviewed?: boolean | null;
  statusId?: ResponseStatus | null;
  finalComment?: string | null;
  includeContactInfo: boolean;
  stage2EvaluatorScores: Stage2EvaluatorScoreInputType[];
}

export interface Stage2SuccessFactorInputType {
  id?: string | null;
  name: string;
  definition: string;
  fpmCategory: FundamentalPrimeMeasurement;
  weight?: any | null;
  developmentProjectScore?: DevelopmentProjectScoreInputType | null;
}

export interface SuccessEvidenceInputType {
  id: string;
  name?: string | null;
  typeId: string;
  description?: string | null;
  link?: string | null;
  files?: FileUploadInputType[] | null;
  reviewLocation?: string | null;
  hasCopyrightRestrictions?: boolean | null;
  documentType?: string | null;
  cost?: any | null;
  authors?: string | null;
  publicationDate?: any | null;
  publicationOrConference?: string | null;
  isbnNumber?: string | null;
  additionalNotes?: string | null;
  revisionOrVersion?: string | null;
}

export interface TeamMemberEvaluationInputType {
  userId: string;
  topicId: string;
  responseRate: number;
  kpiAchievement: number;
  workQuality: number;
  efficiency: number;
  overallImpact: number;
  openToFeedback: number;
  attitude: number;
  resilience: number;
  takesInitiative: number;
  helpfulness: number;
  comment?: string | null;
}

export interface TopicPocInputType {
  userId: string;
  hideInfo?: boolean | null;
}

export interface TopicSuccessEvidenceInputType {
  successEvidence: SuccessEvidenceInputType;
  successFactorIds?: any[] | null;
  shortTermSuccessFactorIds?: any[] | null;
}

export interface UploadTokenRequestType {
  referenceId: string;
  referenceType: FileReferenceType;
  fileName: string;
  fileSize: any;
  lastUpdated: any;
}

export interface UserInputType {
  id: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  externalRoleName: string;
  externalRoleId: string;
}

export interface UserKeywordInputType {
  keywordId: number;
  type: UserKeywordTypeEnum;
}

export interface UserProfileInputType {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  independentFreelancer?: boolean | null;
  secondaryEmail?: string | null;
  workAddress?: string | null;
  countryOfCitizenship?: string | null;
  notAvailable?: boolean | null;
  availableHoursPerWeek?: number | null;
  hourlyRate?: any | null;
  additonalCourses?: string | null;
  hadSecurityClearance?: boolean | null;
  conflictsOfInterest?: string | null;
  linkedInProfile?: string | null;
  facebookProfile?: string | null;
  twitterHandle?: string | null;
  instagramProfile?: string | null;
  youtubeChannel?: string | null;
  vimeoChannel?: string | null;
  researchGateProfile?: string | null;
  googleScholarProfile?: string | null;
  peerReviewInterest?: boolean | null;
  proposingInterest?: boolean | null;
  evaluatorInterest?: boolean | null;
  acceptedPrivacyPolicy?: boolean | null;
  profilePicture?: FileUploadInputType | null;
  userKeywords?: UserKeywordInputType[] | null;
  userFiles?: FileUploadInputType[] | null;
  awards?: AwardInputType[] | null;
  certifications?: CertificationInputType[] | null;
  degrees?: DegreeInputType[] | null;
  employers?: EmployerInputType[] | null;
  publications?: PublicationInputType[] | null;
}

export interface UserSettingInputType {
  contactInformationPrivacy: boolean;
  socialMediaPrivacy: boolean;
  publicationsPrivacy: boolean;
  uploadedFilesPrivacy: boolean;
  skillsKeywordsPrivacy: boolean;
  interestsKeywordsPrivacy: boolean;
  employmentHistoryPrivacy: boolean;
  educationPrivacy: boolean;
  additionalInformationPrivacy: boolean;
}

export interface WorkAgreementInputType {
  workScheduleHours?: number | null;
  workSchedulePer?: HoursPer | null;
  totalExpectedHours?: number | null;
  offeredRateAmount?: number | null;
  offeredRatePer?: RatePer | null;
  paymentTerms?: string | null;
  paymentSchedule?: string | null;
  totalEstimatedPayout?: any | null;
  maximumAmountAuthorized?: any | null;
  hostResidenceCertification: boolean;
  smeResidenceCertification: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
