import * as React from 'react';
import { FormControl, Select, InputLabel, FormHelperText } from '@mui/material';

const SelectPlaceholder: React.FC<SelectPlaceholderProps> = ({ label, helperText }) => {
	return (
		<FormControl variant="standard" fullWidth>
			<InputLabel shrink>{label}</InputLabel>
			<Select variant="standard" value="" disabled={true} />
			{helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
		</FormControl>
	);
};

export interface SelectPlaceholderProps {
	label?: string;
	helperText?: string;
}

export default SelectPlaceholder;
