import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingButton } from '../../index';

const useStyles = makeStyles({
	dialog: {
		'& .MuiDialog-paper': {
			padding: '24px',
			maxHeight: '50vh',
		},
	},
	centerItems: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
	},
	dialogDescription: {
		color: '#1A2A3D',
		fontSize: '16px',
		lineHeight: '19px',
		textAlign: 'center',
		marginBottom: '24px',
	},
});

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	open,
	icon,
	title,
	message,
	primaryBusy,
	showPrimary = true,
	showSecondary = true,
	primaryText = 'Confirm',
	secondaryText = 'Cancel',
	onPrimaryClick,
	onSecondaryClick,
	onClose,
	children,
	...dialogProps
}) => {
	const classes = useStyles();
	return (
		<Dialog open={open} onClose={onClose} className={classes.dialog} {...dialogProps}>
			<DialogTitle>
				<div className={classes.centerItems}>
					{icon}
					{title ? <Typography variant="h1">{title}</Typography> : null}
				</div>
			</DialogTitle>
			<DialogContent className={classes.dialogDescription}>
				{message}
				{children}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center' }}>
				{showSecondary ? (
					<Button onClick={onSecondaryClick} variant="outlined">
						{secondaryText}
					</Button>
				) : null}
				{showPrimary ? (
					<LoadingButton pending={primaryBusy} variant="contained" onClick={onPrimaryClick}>
						{primaryText}
					</LoadingButton>
				) : null}
			</DialogActions>
		</Dialog>
	);
};

export interface ConfirmDialogProps extends Omit<DialogProps, 'title'> {
	open: boolean;
	icon?: React.ReactNode;
	title?: React.ReactNode;
	message?: React.ReactNode;
	primaryBusy?: boolean;
	showPrimary?: boolean;
	primaryText?: string;
	onPrimaryClick?: () => void;
	showSecondary?: boolean;
	secondaryText?: string;
	onSecondaryClick?: () => void;
}

export default ConfirmDialog;
