import { Chat, InfoOutlined } from '@mui/icons-material';
import {
	Box,
	Chip,
	Collapse,
	darken,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
	GetEvaluatorScoreSheetData_stage2Response,
	GetEvaluatorScoreSheetData_stage2Response_topic_successFactors,
} from '../../../apollo/generated/types/GetEvaluatorScoreSheetData';
import { FundamentalPrimeMeasurement } from '../../../apollo/generated/types/globalTypes';
import { FpmToPrefix, GetFPMInfo, GetFPMTitle } from '../../../utils/Enums/Helpers/FpmCategories';
import getEvaluationScoreColor, { EvaluationScoreColor } from '../../../utils/Enums/Helpers/getEvaluationScoreColor';
import ScoringGuidelinesTooltip from '../../Tooltips/ScoringGuidelinesTooltip';
import WeightingGuidelinesTooltip from '../../Tooltips/WeightingGuidelinesTooltip';

type EvaluatorScoresTableProps = {
	response: GetEvaluatorScoreSheetData_stage2Response;
	fpm: FundamentalPrimeMeasurement;
	responderScores?: { [key: string]: any };
	otherEvaluationsMinMax?: { [key: string]: { min: number | null; max: number | null } };
	readOnly: boolean;
};

/**
 * Displays the Evaluator Scores Table.
 * Receives a segment of the evaluator scores, responder scores, and success factors according to its FPM category
 *
 * @param {EvaluatorScoresTableProps} { title, responderScores, otherEvaluationsMinMax, successFactors, readOnly }
 * @return {*}
 */
const EvaluatorScoresTable = ({ response, fpm, responderScores, otherEvaluationsMinMax, readOnly }: EvaluatorScoresTableProps) => {
	const successFactors = response.topic?.successFactors.filter((sf) => sf.fpmCategory === fpm) ?? [];
	const scores = useWatch({ name: successFactors.map((sf) => `scores[${sf.id}][score]`) });

	// Calculates the weighted average by iterating through the success factors and getting the updated scores from useWatch.
	// Will be NaN if any of the scores have not yet been filled
	const scoresTotal = successFactors.reduce((prev, current) => prev + parseFloat(scores[`scores[${current.id}][score]`]) * current.weight, 0);
	const totalWeight = successFactors.reduce((prev, current) => prev + parseFloat(current.weight), 0);
	const weightedAverage = parseFloat((scoresTotal / totalWeight).toFixed(1));

	const headerColor = getEvaluationScoreColor(weightedAverage, fpm, response.topic?.project.selectionProjectInfo?.finishTrlRangeCategory);

	const classes = useStyles(headerColor);
	const getIconStyle = () => ({
		color: !!headerColor ? 'white' : EvaluationScoreColor.BLUE,
	});

	if (successFactors.length < 1) return <></>;
	return (
		<Box overflow="auto" marginBottom="1rem">
			<Table>
				<TableHead>
					<TableRow className={classes.headerRow}>
						<TableCell colSpan={4} className={`${classes.headerTitleCell} ${classes.titleCell}`}>
							<Typography variant="h3">
								{GetFPMTitle(fpm) + ' '}
								<Tooltip title={GetFPMInfo(fpm)}>
									<InfoOutlined fontSize="small" sx={getIconStyle()} />
								</Tooltip>
							</Typography>
						</TableCell>
						<TableCell align="center" colSpan={2} className={classes.headerScoreCell}>
							{!Number.isNaN(weightedAverage) && <Typography variant="h3">{weightedAverage.toFixed(1)}</Typography>}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>9-Hi™ Success Factor</TableCell>
						<TableCell align="center">
							<div className={classes.weightColumnHeader}>
								Weight
								<br />
								(1.0 - 5.0){'     '}
								<WeightingGuidelinesTooltip />
							</div>
						</TableCell>
						<TableCell align="center">All Scores</TableCell>
						<TableCell align="center">Responder Score</TableCell>
						<TableCell align="center">
							<div className={classes.evaluatorScoreHeader}>
								<Typography variant="h5">Evaluator Score</Typography>
								<ScoringGuidelinesTooltip />
							</div>
						</TableCell>
						<TableCell align="center">Comment</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{successFactors.map(
						(sf) =>
							sf.id && (
								<EvaluatorScoresTableRow
									key={sf.id}
									successFactor={sf}
									responderScore={responderScores?.[sf.id] ?? 'N/A'}
									otherEvaluationsMinMax={
										otherEvaluationsMinMax?.[sf.id].min
											? `${otherEvaluationsMinMax[sf.id].min} - ${otherEvaluationsMinMax[sf.id].max}`
											: 'N/A'
									}
									readOnly={readOnly}
								/>
							),
					)}
				</TableBody>
			</Table>
		</Box>
	);
};

type EvaluatorScoresTableRowProps = {
	successFactor: GetEvaluatorScoreSheetData_stage2Response_topic_successFactors;
	otherEvaluationsMinMax: string;
	responderScore: string;
	readOnly: boolean;
};

const EvaluatorScoresTableRow = ({ successFactor, otherEvaluationsMinMax, responderScore, readOnly }: EvaluatorScoresTableRowProps) => {
	const classes = useStyles();
	const { register, setValue } = useFormContext();
	const comment = useWatch({ name: `scores[${successFactor.id}][comment]` });

	const [commentRowOpen, setCommentRowOpen] = useState<boolean>(false);

	const onScoreBlur = (e) => {
		let score = e.target.value;
		if (!score) return;
		if (score < 1) score = 1.0;
		if (score > 9.9) score = 9.9;
		setValue(`scores[${successFactor.id}][score]`, parseFloat(score).toFixed(1));
	};

	return (
		<>
			<TableRow className={classes.row}>
				<TableCell className={classes.titleCell}>
					<Stack gap="0.5rem" alignItems="start">
						{successFactor.prefix && <Chip label={FpmToPrefix[successFactor.fpmCategory] + successFactor.prefix} variant="sfPrefixDense" />}
						<Stack direction="row" gap="0.25rem">
							<Box component="span">{successFactor.name}</Box>{' '}
							<Tooltip title={successFactor.definition} sx={{ marginTop: '-5px' }}>
								<InfoOutlined color="primary" fontSize="small" />
							</Tooltip>
						</Stack>
					</Stack>
				</TableCell>
				<TableCell align="center">{successFactor.weight}</TableCell>
				<TableCell align="center">{otherEvaluationsMinMax}</TableCell>
				<TableCell align="center">{responderScore}</TableCell>
				<TableCell align="center">
					<TextField
						name={`scores[${successFactor.id}][score]`}
						variant="outlined"
						className={classes.scoreInput}
						type="number"
						inputProps={{
							...register(`scores[${successFactor.id}][score]`, {
								min: {
									value: 1.0,
									message: 'Score must not be below 1.',
								},
								max: {
									value: 9.9,
									message: 'Score must not be above 9.9.',
								},
							}),
							min: 1.0,
							max: 9.9,
							step: 0.1,
						}}
						disabled={readOnly}
						onBlur={onScoreBlur}
					/>

					{/* Hidden inputs */}
					<input {...register(`scores[${successFactor.id}][stage2SuccessFactorId]`)} type="hidden" />
					<input {...register(`scores[${successFactor.id}][id]`)} type="hidden" />
				</TableCell>
				<TableCell align="center">
					<IconButton onClick={() => setCommentRowOpen(!commentRowOpen)} size="large">
						<Chat color={comment === '' ? 'secondary' : 'primary'} />
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow className={classes.commentRow}>
				<TableCell colSpan={6} className={classes.commentCell} align="center">
					<Collapse in={commentRowOpen}>
						<Box padding={3} paddingTop={0.5}>
							<TextField
								variant="standard"
								name={`scores[${successFactor.id}][comment]`}
								fullWidth
								disabled={readOnly}
								label="Comment"
								InputLabelProps={{ shrink: comment !== '' }}
								inputProps={{ ...register(`scores[${successFactor.id}][comment]`) }}
							/>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const useStyles = (headerColor?: string) =>
	makeStyles((theme) => ({
		titleCell: {
			width: '200px',
		},
		scoreInput: {
			width: '70px',
		},
		evaluatorScoreHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
		},
		weightColumnHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
		},
		headerRow: {
			'& .MuiTableCell-root': {
				borderBottom: 0,
			},
			'transition': 'background-color 0.5s',
			'background': headerColor,
			'& .MuiTypography-root': {
				color: headerColor ? 'white' : undefined,
				transition: 'color 0.5s',
			},
			'& .MuiSvgIcon-root': {
				color: headerColor ? 'white' : undefined,
				transition: 'color 0.5s',
			},
		},
		headerTitleCell: {
			borderTopLeftRadius: '1rem',
		},
		headerScoreCell: {
			'borderTopRightRadius': '1rem',
			'& .MuiTypography-root': {
				paddingTop: '3px',
			},
			'background': headerColor && darken(headerColor, 0.15),
			'transition': 'background-color 0.5s',
		},
		commentCell: {
			padding: 0,
		},
		row: {
			'& .MuiTableCell-root': {
				borderBottom: 0,
			},
			'&:nth-of-type(4n+1)': {
				background: theme.palette.secondary.light,
			},
		},
		commentRow: {
			'paddingTop': '1.5rem',
			'&:last-child': {
				'& .MuiTableCell-root': {
					borderBottom: 0,
				},
			},
			'&:nth-of-type(4n+2)': {
				background: theme.palette.secondary.light,
			},
		},
	}))();

export default EvaluatorScoresTable;
