import { useLazyQuery } from '@apollo/client';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GetTokenDownloadLink, GetTokenDownloadLinkVariables } from '../../apollo/generated/types/GetTokenDownloadLink';
import { GET_TOKEN_DOWNLOAD_LINK } from '../../apollo/queries';
import { downloadBlobFromStorage } from '../../utils/file-storage-util';
import ModalDeleteConfirmDialog from './ModalDeleteConfirmDialog';

export interface UnsavedUploadedFileDisplayProps {
	index: number;
	uploadToken: string;
	fileName: string;
	name: string;
	onDelete: (index: number) => void;
	openFile?: boolean;
	readOnly?: boolean;
}
/**
 * Represents files that have been uploaded (have recieved an uploadToken), but have not yet been moved into permanent storage.
 * Once they are moved to permanent storage, they will have an id.
 *
 * @param {*} {
 * 	index,
 * 	uploadToken,
 * 	name,
 * 	fileName,
 * 	onDelete,
 * 	openFile = false,
 * 	readOnly = false,
 * }
 * @return {*}
 */
const UnsavedUploadedFileDisplay: React.FC<UnsavedUploadedFileDisplayProps> = ({
	index,
	uploadToken,
	name,
	fileName,
	onDelete,
	openFile = false,
	readOnly = false,
}) => {
	const { register } = useFormContext();
	const [confirm, setConfirm] = useState(false);

	const [getTokenDownloadLink] = useLazyQuery<GetTokenDownloadLink, GetTokenDownloadLinkVariables>(GET_TOKEN_DOWNLOAD_LINK, {
		fetchPolicy: 'cache-and-network',
		variables: { token: uploadToken },
		onCompleted: (result) => result.uploadTokenURL && downloadBlobFromStorage(fileName, result.uploadTokenURL.sasUri, openFile),
		onError: (error) => console.log(error.message),
	});

	const handleConfirm = () => {
		setConfirm(false);
		onDelete(index);
	};

	const download = () => getTokenDownloadLink();

	return (
		<ListItem button onClick={download}>
			<ListItemIcon>
				<AttachFileIcon />
			</ListItemIcon>
			<ListItemText primary={fileName} />
			<ListItemSecondaryAction>
				<input {...register(`${name}[${index}].uploadToken`)} type="hidden" value={uploadToken} />
				<IconButton edge="end" aria-label="delete" onClick={() => setConfirm(true)} disabled={readOnly} size="large">
					<DeleteIcon />
				</IconButton>
				<ModalDeleteConfirmDialog
					open={confirm}
					fileName={fileName}
					onPrimaryClick={handleConfirm}
					onSecondaryClick={() => setConfirm(false)}
					onClose={() => setConfirm(false)}
				/>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

export default UnsavedUploadedFileDisplay;
