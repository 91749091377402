import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Box, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { TrlRangeCategory } from '../apollo/generated/types/globalTypes';
import evidenceScoringImage from '../assets/images/Projects/evidence_scoring.png';
import trlLevelsImage from '../assets/images/Projects/trl_levels.png';
import trl1To3Image from '../assets/images/Projects/trl_scoring_1-3.png';
import trl4to6Image from '../assets/images/Projects/trl_scoring_4-6.png';
import trl7to9Image from '../assets/images/Projects/trl_scoring_7-9.png';
import weightingScaleImage from '../assets/images/Projects/weighting_scale.jpg';
import useSessionStorage from '../utils/hooks/useSessionStorage';

type TabType = TrlRangeCategory | 'Evidence Scoring Scale' | 'TRL Levels' | 'Weighting Scale';

interface ScoringGuidanceProps {
	showOneToThree?: boolean;
	showFourToSix?: boolean;
	showSevenToNine?: boolean;
	defaultState?: boolean;
	hideToggleReplacementFunction?: (boolean) => void;
}

const ScoringGuidance = ({ showOneToThree, showFourToSix, showSevenToNine, defaultState, hideToggleReplacementFunction }: ScoringGuidanceProps) => {
	const [selectedTab, setSelectedTab] = useState<TabType>('Evidence Scoring Scale');
	const [showGraphics, setShowGraphics] = useSessionStorage('trl_show_graphics', defaultState);

	useEffect(() => {
		if (showOneToThree) setSelectedTab(TrlRangeCategory.ONE_TO_THREE);
		else if (showFourToSix) setSelectedTab(TrlRangeCategory.FOUR_TO_SIX);
		else if (showSevenToNine) setSelectedTab(TrlRangeCategory.SEVEN_TO_NINE);
	}, [showOneToThree, showFourToSix, showSevenToNine]);
	return (
		<Stack direction="row" sx={{ minHeight: '100%', backgroundColor: 'background.paper' }}>
			<Box sx={{ backgroundColor: '#eee' }}>
				<Box sx={{ position: 'sticky', top: '-16px' }}>
					<IconButton
						sx={{ borderRadius: 0 }}
						onClick={() => {
							if (hideToggleReplacementFunction) hideToggleReplacementFunction(!showGraphics);
							else setShowGraphics(!showGraphics);
						}}>
						{showGraphics ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
					</IconButton>
					<Typography
						variant="h3"
						noWrap
						sx={{
							transform: 'rotate(90deg)',
							transformOrigin: 'bottom left',
							position: 'absolute',
							top: '25px',
							left: '7px',
						}}>
						Scoring Guidance & Information
					</Typography>
				</Box>
			</Box>
			{showGraphics && (
				<Box sx={{ width: '400px' }}>
					<Box sx={{ borderBottom: '2px solid #e4e7ee' }}>
						<Tabs value={selectedTab} onChange={(_e, val) => setSelectedTab(val)} variant="scrollable">
							{showOneToThree && <Tab label="TRL 1-3" value={TrlRangeCategory.ONE_TO_THREE} />}
							{showFourToSix && <Tab label="TRL 4-6" value={TrlRangeCategory.FOUR_TO_SIX} />}
							{showSevenToNine && <Tab label="TRL 7-9" value={TrlRangeCategory.SEVEN_TO_NINE} />}
							<Tab label="Evidence Scoring Scale" value="Evidence Scoring Scale" />
							<Tab label="TRL Levels" value="TRL Levels" />
							<Tab label="Weighting Scale" value="Weighting Scale" />
						</Tabs>
					</Box>
					<Box
						component="img"
						src={trl1To3Image}
						sx={{ width: '100%', display: selectedTab === TrlRangeCategory.ONE_TO_THREE ? undefined : 'none' }}
					/>
					<Box
						component="img"
						src={trl4to6Image}
						sx={{ width: '100%', display: selectedTab === TrlRangeCategory.FOUR_TO_SIX ? undefined : 'none' }}
					/>
					<Box
						component="img"
						src={trl7to9Image}
						sx={{ width: '100%', display: selectedTab === TrlRangeCategory.SEVEN_TO_NINE ? undefined : 'none' }}
					/>
					<Box
						component="img"
						src={evidenceScoringImage}
						sx={{ width: '100%', display: selectedTab === 'Evidence Scoring Scale' ? undefined : 'none' }}
					/>
					<Box component="img" src={trlLevelsImage} sx={{ width: '100%', display: selectedTab === 'TRL Levels' ? undefined : 'none' }} />
					<Box component="img" src={weightingScaleImage} sx={{ width: '100%', display: selectedTab === 'Weighting Scale' ? undefined : 'none' }} />
				</Box>
			)}
		</Stack>
	);
};

export default ScoringGuidance;
