import { PublicationRole } from '../../apollo/generated/types/globalTypes';

export const getRoleEnumFromString = (value: string): PublicationRole => {
	switch (value) {
		case 'Author':
			return PublicationRole.AUTHOR;
		case 'Co-author':
			return PublicationRole.CO_AUTHOR;
		case 'Peer reviewer':
			return PublicationRole.PEER_REVIEWER;
		case 'Recommender':
			return PublicationRole.RECOMMENDER;
		default:
			return PublicationRole.AUTHOR;
	}
};
