import { CurrentUserProjectRoles, CurrentUserProjectRolesVariables } from './../../apollo/generated/types/CurrentUserProjectRoles';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

export const CURRENT_USER_PROJECT_ROLES = gql`
	query CurrentUserProjectRoles($projectId: Guid!) {
		project(id: $projectId) {
			id
			currentUserProjectRoles {
				projectRole
				parentGroupRole
				isParentGroupOwner
			}
		}
	}
`;

export const useCurrentUserProjectRoles = () => {
	const { id: projectId } = useParams<{ id: string }>();
	const { data, loading, error } = useQuery<CurrentUserProjectRoles, CurrentUserProjectRolesVariables>(CURRENT_USER_PROJECT_ROLES, {
		variables: { projectId },
		skip: !projectId,
	});

	if (error) console.error(error);

	return { currentUserProjectRoles: data?.project.currentUserProjectRoles, loading };
};
