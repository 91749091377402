import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeLocalTime } from '../../utils/date-format';
import { formatFileSize } from '../../utils/file-size-format';
import { downloadBlobFromStorage } from '../../utils/file-storage-util';

const FilesTable: React.FC<FilesTableProps> = ({ files }) => {
	const sortedFiles = [...files];
	sortedFiles.sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime());

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Document Title</TableCell>
					<TableCell>Document Type</TableCell>
					<TableCell>Last Updated</TableCell>
					<TableCell>File Size</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{sortedFiles.map((file, index) => (
					<TableRow key={index}>
						<TableCell>
							<Button sx={{ padding: 0, textAlign: 'left' }} color="primary" onClick={() => downloadBlobFromStorage(file.fileName, file.url)}>
								{file.fileName}
							</Button>
						</TableCell>
						<TableCell>{file.fileType}</TableCell>
						<TableCell>{file.lastUpdated ? makeLocalTime(file.lastUpdated).toLocaleString() : ''}</TableCell>
						<TableCell>{formatFileSize(file.fileSize)}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

interface FilesTableProps {
	files: {
		fileName: string;
		fileType: string;
		lastUpdated: any | null;
		fileSize: any;
		url: string;
	}[];
}

export default FilesTable;
