import { TextField, TextFieldProps } from '@mui/material';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';

type ReactHookFormTextFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = UseControllerProps<TFieldValues, TName> &
	TextFieldProps & {
		numericOnly?: boolean;
		intOnly?: boolean;
		numericMin?: number;
		numericMax?: number;
		maxLines?: number;
		maxLength?: number;
		regex?: RegExp;
	};

const ReactHookFormTextField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	name,
	rules,
	defaultValue,
	control,
	numericOnly,
	intOnly,
	numericMin,
	numericMax,
	maxLines,
	maxLength,
	regex,
	...textFieldProps
}: ReactHookFormTextFieldProps<TFieldValues, TName>) => {
	return (
		<Controller
			name={name}
			rules={rules}
			defaultValue={defaultValue}
			control={control}
			render={({ field: { value, onChange, ref, onBlur }, fieldState: { error } }) => (
				<TextField
					variant={textFieldProps.variant ?? 'standard'}
					{...textFieldProps}
					onBlur={onBlur}
					inputRef={ref}
					inputProps={{
						...textFieldProps.inputProps,
						max: textFieldProps.inputProps?.max ?? numericMax,
						min: textFieldProps.inputProps?.min ?? numericMin,
						maxLength,
						value: value !== null && value !== undefined ? value : '',
						onChange: (e) => {
							if (regex && !regex.test(e.currentTarget.value)) return;

							if (maxLength && e.currentTarget.value.length > maxLength) return;

							if (numericOnly && !/^\d*\.?\d*$/.test(e.currentTarget.value)) return;
							if (numericOnly && intOnly && !/^\d*$/.test(e.currentTarget.value)) return;
							if (numericOnly && numericMin != null && parseFloat(e.currentTarget.value) < numericMin) return;
							if (numericOnly && numericMax != null && parseFloat(e.currentTarget.value) > numericMax) return;

							var newLines = e.currentTarget.value.split('\n').length - 1;
							if (maxLines && newLines >= maxLines) return;

							if (numericOnly && e.currentTarget.value === '') {
								onChange(null);
							} else {
								onChange(e);
							}
						},
					}}
					error={!!error}
					helperText={!!error ? error.message : textFieldProps.helperText}
					type={textFieldProps.type ?? numericOnly ? 'number' : undefined}
					autoComplete={textFieldProps.type ?? numericOnly ? 'off' : undefined}
				/>
			)}
		/>
	);
};

export default ReactHookFormTextField;
