import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { CREATE_GROUP_SUBTYPE } from '../../../../apollo/mutations';
import { GET_GROUP_SUBTYPES } from '../../../../apollo/queries';
import { CreateGroupSubtype, CreateGroupSubtypeVariables } from '../../../../apollo/generated/types/CreateGroupSubtype';
import { GetGroupSubtypes } from '../../../../apollo/generated/types/GetGroupSubtypes';
import { OptionFields } from '../../../../apollo/generated/types/OptionFields';
import { GetGovIndAca } from '../../../../apollo/generated/types/GetGovIndAca';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { UserProfileFormData } from '../../../../pages/Profile/ProfilePage';
import { CREATE_BUSINESS_TYPE } from '../../../../apollo/mutations';
import { GET_BUSINESS_TYPE } from '../../../../apollo/queries';
import { CreateBusinessType, CreateBusinessTypeVariables } from '../../../../apollo/generated/types/CreateBusinessType';
import ReactHookFormAutocompleteSingle from '../../../ReactHookForm/ReactHookFormAutocompleteSingle';
import { GetBusinessType } from '../../../../apollo/generated/types/GetBusinessType';

export interface EmployerItemProps {
	index?: number;
	readOnly: boolean;
	remove: (index: number) => void;
	id?: string | null;
	employerName: string;
	title: string;
	workDetails: string;
	yearsOfExperience?: number | null;
	currentEmployer?: boolean | null;
	govAcaIndId?: number | null;
	govAcaIndOptionsData?: GetGovIndAca;
	businessTypeId?: string | null;
}

const EmployerItem: React.FC<EmployerItemProps> = ({
	index,
	readOnly,
	remove,
	id,
	employerName,
	title,
	workDetails,
	yearsOfExperience,
	currentEmployer,
	govAcaIndId,
	govAcaIndOptionsData,
	businessTypeId,
}) => {
	const classes = useStyles();
	const {
		register,
		setValue,
		control,
		formState: { errors },
	} = useFormContext<UserProfileFormData>();
	const ref = useRef<HTMLDivElement>(null);
	const isCurrentEmployer = !!currentEmployer;

	const hasError = (field: string): boolean => {
		if (index == undefined || isCurrentEmployer) return !!errors && !!errors.currentEmployer && !!errors.currentEmployer[field];
		return !!errors && !!errors.pastEmployers && !!errors.pastEmployers[index] && !!errors.pastEmployers[index]?.[field];
	};

	const getErrorMessage = (field: string): string => {
		return hasError(field)
			? index == undefined || isCurrentEmployer
				? errors.currentEmployer?.[field]?.message
				: errors.pastEmployers?.[index]?.[field]?.message
			: '';
	};

	const fieldNamePrefix: 'currentEmployer' | `pastEmployers.${number}` =
		isCurrentEmployer || index === undefined ? 'currentEmployer' : `pastEmployers.${index}`;
	const employerLabel = isCurrentEmployer ? 'Current Employer' : 'Past Employer';
	const requiredStr = isCurrentEmployer ? '*' : '';

	const { data: subtypeData, loading } = useQuery<GetGroupSubtypes>(GET_GROUP_SUBTYPES);
	const [createSubtype] = useMutation<CreateGroupSubtype, CreateGroupSubtypeVariables>(CREATE_GROUP_SUBTYPE, {
		refetchQueries: [GET_GROUP_SUBTYPES],
		awaitRefetchQueries: true,
	});

	const businessTypes = subtypeData?.groupSubtypes ?? [];

	const addBusinessType = async (name: string) => {
		const newSubtype = await createSubtype({
			variables: { name: name },
		});
		if (newSubtype.data?.createGroupSubtype) {
			return newSubtype.data.createGroupSubtype;
		}
		return null;
	};

	return (
		<div className={classes.root}>
			<Accordion className={classes.accordion} defaultExpanded={isCurrentEmployer}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} ref={ref}>
					<div className={classes.header}>
						<Grid item xs={6}>
							<TextField
								variant="standard"
								name={`${fieldNamePrefix}.employerName`}
								label={`${employerLabel}*`}
								inputProps={{ ...register(`${fieldNamePrefix}.employerName`, { required: true }), maxLength: 100 }}
								defaultValue={employerName}
								error={hasError('employerName')}
								helperText={getErrorMessage('employerName')}
								fullWidth
								InputLabelProps={{ shrink: true }}
								InputProps={{ readOnly }}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}}
							/>
						</Grid>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{id ? <input {...register(`${fieldNamePrefix}.id`)} type="hidden" defaultValue={id} /> : null}
							<input {...register(`${fieldNamePrefix}.currentEmployer`)} type="hidden" defaultValue={`${isCurrentEmployer}`} />
						</Grid>
						<Grid item xs={8}>
							<TextField
								variant="standard"
								name={`${fieldNamePrefix}.title`}
								label={`Title${requiredStr}`}
								inputProps={{
									...register(`${fieldNamePrefix}.title`, { required: isCurrentEmployer ? 'Title is required' : false }),
									maxLength: 100,
								}}
								defaultValue={title}
								error={hasError('title')}
								helperText={getErrorMessage('title')}
								fullWidth
								InputLabelProps={{ shrink: true }}
								InputProps={{ readOnly }}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								variant="standard"
								name={`${fieldNamePrefix}.yearsOfExperience`}
								label={`Years at Position${requiredStr}`}
								type="number"
								inputProps={{
									...register(`${fieldNamePrefix}.yearsOfExperience`, {
										required: isCurrentEmployer ? 'Years at Position is required' : false,
										valueAsNumber: true,
										min: {
											value: 0,
											message: 'Years of Experience cannot be negative.',
										},
										max: {
											value: 99,
											message: 'Years of Experience cannot be greater than 99',
										},
									}),
									min: 0,
									max: 99,
									step: 1,
								}}
								InputProps={{ readOnly }}
								fullWidth
								onChange={(e) => setValue(`${fieldNamePrefix}.yearsOfExperience`, parseInt(parseInt(e.target.value).toString().slice(0, 2)))}
								defaultValue={yearsOfExperience}
								error={hasError('yearsOfExperience')}
								helperText={getErrorMessage('yearsOfExperience')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="standard"
								name={`${fieldNamePrefix}.workDetails`}
								label="Project or Work Details"
								inputProps={{ ...register(`${fieldNamePrefix}.workDetails`), maxLength: 100 }}
								defaultValue={workDetails}
								error={hasError('workDetails')}
								helperText={getErrorMessage('workDetails')}
								fullWidth
								InputLabelProps={{ shrink: true }}
								InputProps={{ readOnly }}
							/>
						</Grid>
						<Grid item xs={6}>
							<ReactHookFormSelect
								control={control}
								name={`${fieldNamePrefix}.govAcaIndId`}
								defaultValue={govAcaIndId}
								label={`Sector${requiredStr}`}
								readOnly={readOnly}
								rules={{ required: isCurrentEmployer ? 'Sector is required' : false }}
								helperText={getErrorMessage('govAcaIndId')}
								fullWidth>
								{govAcaIndOptionsData?.governmentIndustryAcademia?.map((govAcaIndOption) => (
									<MenuItem key={govAcaIndOption.id} value={govAcaIndOption.id}>
										{govAcaIndOption.name}
									</MenuItem>
								))}
							</ReactHookFormSelect>
						</Grid>
						<Grid item xs={6}>
							<ReactHookFormAutocompleteSingle
								name={`${fieldNamePrefix}.businessTypeId`}
								label={`Business Type`}
								helperText={getErrorMessage('businessTypeId') ? getErrorMessage('businessTypeId') : 'Type to filter or add new value'}
								options={businessTypes}
								rules={{ required: false }}
								loading={loading}
								addOption={addBusinessType}
								readOnly={readOnly}
								error={hasError('businessTypeId')}
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
			{!readOnly && !isCurrentEmployer && (
				<Tooltip title="Delete this Past Employer" placement="top">
					<div className={classes.removeSection}>
						<IconButton aria-label="remove" onClick={() => (index !== undefined ? remove(index) : null)} size="large">
							<RemoveCircleOutlineIcon fontSize="inherit" />
						</IconButton>
					</div>
				</Tooltip>
			)}
		</div>
	);
};

const useStyles = makeStyles({
	root: {
		display: 'flex',
		marginBottom: '15px',
		width: '100%',
	},
	removeSection: {
		width: '50px',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	space: {
		margin: '0 10px',
	},
	accordion: {
		flex: 'auto',
	},
});

export default EmployerItem;
