import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { LoadingButton, usePermissions } from '../../..';
import { EditGroupUserInviteStatus, EditGroupUserInviteStatusVariables } from '../../../../apollo/generated/types/EditGroupUserInviteStatus';
import {
	EditProjectTopicUserInviteStatus,
	EditProjectTopicUserInviteStatusVariables,
} from '../../../../apollo/generated/types/EditProjectTopicUserInviteStatus';
import { EditProjectUserInviteStatus, EditProjectUserInviteStatusVariables } from '../../../../apollo/generated/types/EditProjectUserInviteStatus';
import { GetInvites, GetInvitesVariables } from '../../../../apollo/generated/types/GetInvites';
import { InviteStatus } from '../../../../apollo/generated/types/globalTypes';
import { makeLocalTime } from '../../../../utils/date-format';
import { groupRoleToString, projectRoleToString, topicRoleToString } from '../../../../utils/Enums/Helpers/EnumToStringUtil';
import getIconColorFromString from '../../../../utils/getIconColorFromString';
import Notification, { NotificationProps, NotificationType } from '../Notification';
import { EDIT_GROUP_USER_INVITE_STATUS, EDIT_PROJECT_TOPIC_USER_INVITE_STATUS, EDIT_PROJECT_USER_INVITE_STATUS, GET_INVITES } from './Invitations.graphql';
import { useHistory } from 'react-router-dom';

/**
 * Hook used to fetch invites and format them into a list of invitation notifications (sorted by date)
 * Queries and mutates group users, project users, and project topic users
 *
 * @return {*}
 */
export const useInvitations = () => {
	const { user } = usePermissions();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	// handleDecline is the submit handler for the decline modal and controls the open state of the decline modal,
	// if it is not null, it will open and use the handler to submit
	const [handleDecline, setHandleDecline] = useState<((declineReason: string) => void) | null>(null);

	// mutationLoadingId tracks which mutation is loading so the loading spinner shows on the right button
	const [mutationLoadingId, setMutationLoadingId] = useState<string | number | null>(null);

	const {
		data: invites,
		loading: invitesLoading,
		refetch,
	} = useQuery<GetInvites, GetInvitesVariables>(GET_INVITES, {
		variables: { userId: user?.id },
		skip: !user?.id,
	});

	// Filter by inviteStatus to catch anything newly updated
	const groupInvites = invites?.groupInvites.filter((gi) => gi.inviteStatus === InviteStatus.INVITED) ?? [];
	const projectInvites = invites?.projectInvites.filter((pi) => pi.inviteStatus === InviteStatus.INVITED) ?? [];
	const projectTopicInvites = invites?.projectTopicInvites.filter((pti) => pti.inviteStatus === InviteStatus.INVITED) ?? [];

	const queryLoading = invitesLoading;

	const [editGroupInviteStatus, { loading: groupInviteMutationLoading }] = useMutation<EditGroupUserInviteStatus, EditGroupUserInviteStatusVariables>(
		EDIT_GROUP_USER_INVITE_STATUS,
	);
	const [editProjectInviteStatus, { loading: projectInviteMutationLoading }] = useMutation<EditProjectUserInviteStatus, EditProjectUserInviteStatusVariables>(
		EDIT_PROJECT_USER_INVITE_STATUS,
	);
	const [editProjectTopicInviteStatus, { loading: projectTopicInviteMutationLoading }] = useMutation<
		EditProjectTopicUserInviteStatus,
		EditProjectTopicUserInviteStatusVariables
	>(EDIT_PROJECT_TOPIC_USER_INVITE_STATUS);

	const mutationLoading = groupInviteMutationLoading || projectInviteMutationLoading || projectTopicInviteMutationLoading;

	const handleInvitationAcceptSuccess = () => {
		enqueueSnackbar('Invitation Accepted!', { variant: 'success' });
		setMutationLoadingId(null);
	};
	const handleInvitationAcceptError = (err) => {
		enqueueSnackbar('A problem occured while accepting the invitation', { variant: 'error' });
		console.log(err);
		setMutationLoadingId(null);
	};

	const acceptGroupInvite = (groupId: number) => {
		setMutationLoadingId(groupId);
		return editGroupInviteStatus({ variables: { groupId, inviteStatusUpdate: InviteStatus.PENDING_APPROVAL } })
			.then(() => handleInvitationAcceptSuccess())
			.catch((err) => handleInvitationAcceptError(err));
	};
	const acceptProjectInvite = (projectId: string) => {
		setMutationLoadingId(projectId);
		return editProjectInviteStatus({ variables: { projectId, inviteStatusUpdate: InviteStatus.PENDING_APPROVAL } })
			.then(() => handleInvitationAcceptSuccess())
			.catch((err) => handleInvitationAcceptError(err));
	};
	const acceptProjectTopicInvite = (projectTopicId: string) => {
		setMutationLoadingId(projectTopicId);
		return editProjectTopicInviteStatus({ variables: { projectTopicId, inviteStatusUpdate: InviteStatus.PENDING_APPROVAL } })
			.then(() => handleInvitationAcceptSuccess())
			.catch((err) => handleInvitationAcceptError(err));
	};

	const declineGroupInvite = (groupId: number) => {
		setHandleDecline(
			() => (declineReason: string) =>
				editGroupInviteStatus({ variables: { groupId, inviteStatusUpdate: InviteStatus.INVITATION_DECLINED, declineReason } }),
		);
	};

	const declineProjectInvite = (projectId: string) => {
		setHandleDecline(
			() => (declineReason: string) =>
				editProjectInviteStatus({ variables: { projectId, inviteStatusUpdate: InviteStatus.INVITATION_DECLINED, declineReason } }),
		);
	};
	const declineProjectInviteNoReason = (projectId: string) => {
		editProjectInviteStatus({ variables: { projectId, inviteStatusUpdate: InviteStatus.INVITATION_DECLINED } });
	};

	const declineProjectTopicInvite = (projectTopicId: string) => {
		setHandleDecline(
			() => (declineReason: string) =>
				editProjectTopicInviteStatus({ variables: { projectTopicId, inviteStatusUpdate: InviteStatus.INVITATION_DECLINED, declineReason } }),
		);
	};
	const declineProjectTopicInviteNoReason = (projectTopicId: string) => {
		editProjectTopicInviteStatus({ variables: { projectTopicId, inviteStatusUpdate: InviteStatus.INVITATION_DECLINED } });
	};

	const memoizedNotifications = useMemo(() => {
		const invitationNotifications: React.ReactElement<NotificationProps>[] = [];

		invitationNotifications.push(
			...groupInvites.map((invite) => {
				const invitedByName = invite.invitedByUser?.fullName;
				return (
					<Notification
						type={NotificationType.INVITATION}
						primaryText={
							<>
								<strong>{invite.invitedByUser?.fullName}</strong> has invited you to the group <strong>{invite.group.name}</strong> as a{' '}
								<strong>{groupRoleToString(invite.groupRole)}</strong>.
							</>
						}
						date={makeLocalTime(invite.createdAt)}
						avatar={
							invitedByName ? (
								<Avatar src={invite.invitedByUser?.profilePicture?.url} style={{ background: getIconColorFromString(invitedByName) }}>{`${
									invitedByName.split(' ')[0][0]
								}${invitedByName.split(' ')[1][0]}`}</Avatar>
							) : (
								<Avatar style={{ background: getIconColorFromString() }} />
							)
						}
						primaryAction={() => acceptGroupInvite(invite.group.id)}
						secondaryAction={() => declineGroupInvite(invite.group.id)}
						primaryActionText="Accept"
						secondaryActionText="Decline"
						loading={mutationLoadingId === invite.group.id && !handleDecline}
						redirectPath={`/group/${invite.group.id}/edit`}
						key={'group-invite-' + invite.id}
					/>
				);
			}),
		);

		invitationNotifications.push(
			...projectInvites.map((invite) => {
				const invitedByName = invite.invitedByUser?.fullName;
				return (
					<Notification
						type={NotificationType.INVITATION}
						primaryText={
							<>
								<strong>{invitedByName}</strong> has invited you to the project <strong>{invite.project.name}</strong> as a{' '}
								<strong>{projectRoleToString(invite.projectRole)}</strong>
								{invite.hasWorkAgreement && <> consultant</>}.
								{invite.hasWorkAgreement && <> Please click this notification to view your Work Agreement.</>}
								<span className="invitationContext">
									{invite.project.group.name} {' > '}
									{invite.project.name}
								</span>
							</>
						}
						date={makeLocalTime(invite.createdAt)}
						avatar={
							invitedByName ? (
								<Avatar src={invite.invitedByUser?.profilePicture?.url} style={{ background: getIconColorFromString(invitedByName) }}>{`${
									invitedByName.split(' ')[0][0]
								}${invitedByName.split(' ')[1][0]}`}</Avatar>
							) : (
								<Avatar style={{ background: getIconColorFromString() }} />
							)
						}
						primaryAction={
							invite.hasWorkAgreement
								? () => history.push(`/project/${invite.project.id}/work-agreement/${invite.userId}`)
								: () => acceptProjectInvite(invite.project.id)
						}
						secondaryAction={
							invite.hasWorkAgreement ? () => declineProjectInviteNoReason(invite.project.id) : () => declineProjectInvite(invite.project.id)
						}
						primaryActionText={invite.hasWorkAgreement ? 'View Work Agreement' : 'Accept'}
						secondaryActionText="Decline"
						loading={mutationLoadingId === invite.project.id && !handleDecline}
						redirectPath={
							invite.hasWorkAgreement ? `/project/${invite.project.id}/work-agreement/${invite.userId}` : `/project/${invite.project.id}/edit`
						}
						key={invite.id}
					/>
				);
			}),
		);

		invitationNotifications.push(
			...projectTopicInvites.map((invite) => {
				const invitedByName = invite.invitedByUser?.fullName;
				return (
					<Notification
						type={NotificationType.INVITATION}
						primaryText={
							<>
								<strong>{invitedByName}</strong> has invited you to the topic <strong>{invite.projectTopic.name}</strong> as a{' '}
								<strong>{topicRoleToString(invite.topicRole)}</strong>
								{invite.hasWorkAgreement && <> consultant</>}.
								{invite.hasWorkAgreement && <> Please click this notification to view your Work Agreement.</>}
								<span className="invitationContext">
									{invite.projectTopic.project.group.name} {' > '} {invite.projectTopic.project.name} {' > '} {invite.projectTopic.name}
								</span>
							</>
						}
						date={makeLocalTime(invite.createdAt)}
						avatar={
							invitedByName ? (
								<Avatar src={invite.invitedByUser?.profilePicture?.url} style={{ background: getIconColorFromString(invitedByName) }}>{`${
									invitedByName.split(' ')[0][0]
								}${invitedByName.split(' ')[1][0]}`}</Avatar>
							) : (
								<Avatar style={{ background: getIconColorFromString() }} />
							)
						}
						primaryAction={
							invite.hasWorkAgreement
								? () => history.push(`/topic/${invite.projectTopic.id}/work-agreement/${invite.userId}`)
								: () => acceptProjectTopicInvite(invite.projectTopic.id)
						}
						secondaryAction={
							invite.hasWorkAgreement
								? () => declineProjectTopicInviteNoReason(invite.projectTopic.id)
								: () => declineProjectTopicInvite(invite.projectTopic.id)
						}
						primaryActionText={invite.hasWorkAgreement ? 'View Work Agreement' : 'Accept'}
						secondaryActionText="Decline"
						loading={mutationLoadingId === invite.projectTopic.id && !handleDecline}
						redirectPath={
							invite.hasWorkAgreement
								? `/topic/${invite.projectTopic.id}/work-agreement/${invite.userId}`
								: `/topic/${invite.projectTopic.id}/edit`
						}
						key={invite.id}
					/>
				);
			}),
		);

		invitationNotifications.sort((a, b) => (a.props.date < b.props.date ? 1 : -1));
		return invitationNotifications;
	}, [invites]);

	return {
		invitationNotifications: memoizedNotifications,
		queryLoading,
		handleDecline,
		setHandleDecline,
		mutationLoading,
		refetch,
	};
};

/**
 * Provides a unified interface for declining all types of invitations and providing a reason.
 * Takes a submit handler and passes the decline reason into that handler on submit.
 *
 * @param {*} { open, onClose, onSubmit, loading }
 * @return {*}
 */
const DeclineInviteModal = ({ open, onClose, onSubmit, loading }) => {
	const [declineReason, setDeclineReason] = useState<string>('');
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>Decline Invitation</DialogTitle>
			<DialogContent>
				<TextField variant="standard" label="Reason for declining" value={declineReason} onChange={(e) => setDeclineReason(e.target.value)} fullWidth />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<LoadingButton
					variant="contained"
					onClick={() =>
						onSubmit(declineReason)
							.then(() => {
								enqueueSnackbar('Invite Declined!', { variant: 'success' });
								onClose();
							})
							.catch((err) => {
								enqueueSnackbar('A problem occured while declining the invitation', { variant: 'error' });
								console.log(err);
							})
					}
					pending={loading}>
					Submit
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export { DeclineInviteModal };
