import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import {
	Breadcrumbs,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NavLink, useParams } from 'react-router-dom';
import { ResponseStatus } from '../../../../apollo/generated/types/globalTypes';
import { theme } from '../../../../theme/theme';
import { useTeamLeadScore } from '../../../Providers/TeamLeadScoreProvider/TeamLeadScoreProvider';
import { ReactHookFormCheckbox } from '../../../ReactHookForm';
import AllCommentsModal from './AllCommentsModal/AllCommentsModal';
import ResponseDocumentModal from './ResponseDocumentModal';

const EvaluationHeader: React.FC = () => {
	const classes = useStyles();
	const { responseId } = useParams<{ responseId: string }>();
	const {
		evaluations,
		response,
		handleNextEvaluation,
		handlePreviousEvaluation,
		handleSelectEvaluation,
		evaluationIndex,
		isAdmin,
		isLocked,
		isTopicClosed,
		isTopicCompleted,
	} = useTeamLeadScore();
	const { control } = useFormContext();
	const [showAllCommentsModal, setShowAllCommentsModal] = useState<boolean>(false);
	const [showResponseDocument, setShowResponseDocument] = useState<boolean>(false);

	const readOnly = !isAdmin || isLocked || isTopicClosed || isTopicCompleted;
	const respondingGroupName = response?.registrant?.registrantGroup?.name;

	const handleResponseDocumentModal = () => {
		setShowResponseDocument(!showResponseDocument);
	};
	const handleAllCommentsModal = () => {
		setShowAllCommentsModal(!showAllCommentsModal);
	};

	return (
		<Card>
			<ResponseDocumentModal open={showResponseDocument} close={handleResponseDocumentModal} />
			<AllCommentsModal open={showAllCommentsModal} close={handleAllCommentsModal} />

			<CardHeader
				title={`${respondingGroupName} Evaluations`}
				action={
					<Grid container alignItems="center" columnGap={1}>
						<FormControl>
							<InputLabel id="evaluator-name-label">Evaluator Name</InputLabel>
							<Select
								labelId="evaluator-name-label"
								onChange={(e) => handleSelectEvaluation((e.target.value ?? 0) as number)}
								label="Evaluator Name"
								value={evaluationIndex}>
								{evaluations.map((evaluation, index) => (
									<MenuItem key={evaluation.id} value={index}>
										{evaluation.user.fullName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<IconButton onClick={() => handlePreviousEvaluation()} size="large">
							<ArrowBackIcon />
						</IconButton>
						<IconButton onClick={() => handleNextEvaluation()} size="large">
							<ArrowForwardIcon />
						</IconButton>
					</Grid>
				}
				subheader={response?.registrant?.responderTechnologyName}
			/>

			<CardContent>
				<Grid container justifyContent="space-between" alignItems="center">
					<Breadcrumbs aria-label="breadcrumb">
						<Link component={NavLink} to={`/response/${responseId}`} target="_blank" underline="hover">
							<Typography variant="h4" style={{ color: theme.palette.grey[500] }}>
								Response Evaluation
							</Typography>
						</Link>
						<Typography variant="h4" style={{ color: theme.palette.grey[500] }}>
							{respondingGroupName}
						</Typography>
						<Typography variant="h4" style={{ color: theme.palette.primary.main }}>
							Evaluation #{evaluationIndex + 1}
						</Typography>
					</Breadcrumbs>
					<Chip
						label={evaluations[evaluationIndex].status == ResponseStatus.COMPLETE ? 'Submitted' : 'In Progress'}
						color={evaluations[evaluationIndex].status == ResponseStatus.COMPLETE ? 'success' : 'warning'}
					/>
				</Grid>
			</CardContent>

			<Divider />

			<CardActions>
				<Button onClick={() => handleResponseDocumentModal()} color="secondary" startIcon={<AttachFileIcon />}>
					Response Documents
				</Button>
				<Button onClick={() => handleAllCommentsModal()} color="secondary" startIcon={<SpeakerNotesOutlinedIcon />}>
					All Comments from this Evaluator
				</Button>
				{evaluations.map((evaluation, index) => (
					<Grid key={evaluation.id} className={index === evaluationIndex ? classes.checkBox : classes.hidden}>
						<ReactHookFormCheckbox
							name={`reviewed.${evaluation.userId}`}
							label="Mark as Reviewed"
							control={control}
							readOnly={readOnly}
							defaultValue={evaluation.reviewed}
						/>
					</Grid>
				))}
			</CardActions>
		</Card>
	);
};

const useStyles = makeStyles({
	checkBox: {
		marginRight: 0,
	},
	hidden: {
		visibility: 'hidden',
		position: 'absolute',
	},
});

export default EvaluationHeader;
