import { format } from 'date-fns';
import { format as formatTz, getTimezoneOffset } from 'date-fns-tz';
import { listTimeZones } from 'timezone-support';

// Default time zone for selectors
export const DEFAULT_TIMEZONE = 'America/New_York (EDT)';

// Create map associating time zones to their offsets
const tzOffsetMap = (() => {
	const timeZoneOffsetMap: { [offset: string]: string } = {};
	const date = new Date();
	listTimeZones().forEach((tzName) => {
		const offsetStr = `${getTimezoneOffset(tzName, date) / 1000 / 60}`; // converts offset in milliseconds to minutes
		const tzShortName = formatTz(date, 'zzz', { timeZone: tzName });
		if (tzShortName.includes('GMT')) timeZoneOffsetMap[offsetStr] = tzShortName;
	});
	return timeZoneOffsetMap;
})();

/**
 * Outputs a formatted date
 * ex: Jan 4, 2021 11:50 AM
 * @param value
 */
export const formatDate = (value: Date | string | number): string => format(new Date(value), 'MMM d, yyyy HH:mm:ss a');

/**
 * Shifts a date from UTC, based on the timezone offset (of the current user).
 * @param value Date to shift
 * @returns Date with timezone shift applied
 */
export const makeLocalTime = (value: Date | string): Date => {
	const localValue = new Date(value);
	if (typeof value === 'string' && ['z', 'Z'].includes(value.charAt(value.length - 1))) return localValue;
	localValue.setMinutes(localValue.getMinutes() - localValue.getTimezoneOffset());
	return localValue;
};

/**
 * Shifts a date to UTC, based on the timezone offset (of the current user).
 * @param value Date to shift
 * @returns Date with timezone shift applied
 */
export const makeUTCTime = (value: Date | string): Date => {
	const localValue = new Date(value);
	if (!(typeof value === 'string' && ['z', 'Z'].includes(value.charAt(value.length - 1)))) return localValue;
	localValue.setMinutes(localValue.getMinutes() + localValue.getTimezoneOffset());
	return localValue;
};

export const makeLocalWithTimezone = (value: Date | string, timezone?: string | null): Date => {
	const localValue = makeLocalTime(value);
	const offsetMillis = timezone ? getTimezoneOffset(timezone.split(' ')[0]) : 0;
	return new Date(localValue.getTime() - offsetMillis);
};

export const makeUtcWithTimezone = (value: Date | string, timezone?: string | null): Date => {
	const localValue = makeUTCTime(value);
	const offsetMillis = timezone ? getTimezoneOffset(timezone.split(' ')[0]) : 0;
	return new Date(localValue.getTime() + offsetMillis);
};

/**
 * Extracts time values from Date and creates a string of the format 'HH:mm a'
 * @param seconds
 */
export const extractTimeFromDate = (date: Date): string => {
	return format(date, 'hh:mm a');
};

/**
 * Extracts time values from Date and creates a string of the format 'HH:MM'
 * @param seconds
 */
export const extractMilitaryTimeFromDate = (date: Date): string => {
	return format(date, 'HH:mm');
};

/***
 * Converts timezone offset in minutes to a Timezone string.
 * @param utcOffsetMinutes - Timezone offset in minutes
 */
export const getTimeZoneLabel = (utcOffsetMinutes: number | null): string | null => {
	if (!utcOffsetMinutes) return null;

	let tzLabel;
	const offsetStr = `${utcOffsetMinutes}`;
	// if the offset is mapped to a time zone name, use that
	if (Object.keys(tzOffsetMap).includes(offsetStr)) tzLabel = tzOffsetMap[offsetStr];
	// otherwise, construct timezone string depicting the offset from UTC
	else {
		let offsetSign = '+';
		if (utcOffsetMinutes < 0) offsetSign = '-';
		const offsetDate = new Date(0, 0, 0, 0, Math.abs(utcOffsetMinutes));
		tzLabel = `UTC${offsetSign}${format(offsetDate, 'HH:mm')}`;
	}

	return tzLabel;
};

/**
 * Ensures major US timezones appear at the top of the options list. The remaining
 * timezones will retain their original ordering.
 * @param timeZones list of time zones
 * @returns reordered list of time zones.
 */
export const reorderTimeZoneList = (timezones: string[]): string[] => {
	const topTimezones = [
		'America/New_York (EDT)',
		'America/Chicago (CDT)',
		'America/Denver (MDT)',
		'America/Phoenix (MST)',
		'America/Los_Angeles (PDT)',
		'America/Anchorage (AKDT)',
		'Pacific/Honolulu (HST)',
	];

	const newTimezones = [...topTimezones];
	timezones.forEach((tz) => {
		if (!topTimezones.includes(tz)) newTimezones.push(tz);
	});

	return newTimezones;
};
