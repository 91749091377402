/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Dialog, DialogContent, Grid, Typography, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTeamLeadScore } from '../../../Providers/TeamLeadScoreProvider/TeamLeadScoreProvider';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GetAppIcon from '@mui/icons-material/GetApp';

interface ResponseDocumentModalProps {
	open: boolean;
	close: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		sectionTitle: {
			padding: theme.spacing(2),
			color: '#f5f5f5',
			borderRadius: 5,
			backgroundColor: '#f5f5f5',
		},
	}),
);

const ResponseDocumentModal: React.FC<ResponseDocumentModalProps> = ({ open, close }) => {
	const classes = useStyles();
	const { response, loading } = useTeamLeadScore();

	return (
		<Dialog open={open} onClose={close} maxWidth="xs" fullWidth>
			<DialogTitle className={classes.root}>
				<Typography variant="h1">Evaluation Documents</Typography>
				<IconButton aria-label="close" className={classes.closeButton} onClick={close} size="large">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box>
					{!loading
						? response?.responseFiles.map((i: any, index: any) => (
								<Grid key={index} container style={{ padding: '10px 0' }}>
									<Grid item xs={1}>
										<AttachFileIcon style={{ margin: 'auto 0' }} />
									</Grid>
									<Grid item xs={10}>
										<Typography variant="h3">{i.fileName}</Typography>
									</Grid>

									<GetAppIcon />
								</Grid>
						  ))
						: ''}

					<Button variant="contained" style={{ float: 'right', marginTop: '10px' }}>
						Download All
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
export default ResponseDocumentModal;
