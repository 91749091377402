import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingIconButton } from '../Buttons';

export interface ActionMenuItem {
	text: string;
	show?: boolean;
	onClick?: (id: number | string, index: number) => void;
	disabled?: boolean;
}

export interface TableActionMenuProps {
	id?: number | string;
	index?: number;
	busy?: boolean;
	menuItems: ActionMenuItem[];
	onMenuClick?: () => void;
}

const TableActionMenu: React.FC<TableActionMenuProps> = ({ id = 'table-action-menu', index = 0, busy = false, menuItems, onMenuClick }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (onMenuClick !== undefined) onMenuClick();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// Generates function to run onClick which prevents user from clicking on table row when clicking the action menu
	const getClickHandler = (onClick: (id: number | string, index: number) => void) => {
		return (e: React.MouseEvent<HTMLLIElement>) => {
			e.stopPropagation();
			handleClose();
			onClick(id, index);
		};
	};

	const showMenu = menuItems.some((mi) => mi.show ?? true); // show the action menu if there is at least one menu item visible

	return (
		<div>
			{showMenu ? (
				<>
					<LoadingIconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} pending={busy}>
						<MoreVertIcon />
					</LoadingIconButton>
					<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
						{menuItems.map((mi: ActionMenuItem, index: number) => {
							const showItem = mi.show ?? true;
							const onClick = mi.onClick ?? (() => null);
							return showItem ? (
								<MenuItem key={index} onClick={getClickHandler(onClick)} disabled={mi.disabled ?? false}>
									{mi.text}
								</MenuItem>
							) : null;
						})}
					</Menu>
				</>
			) : null}
		</div>
	);
};

export default TableActionMenu;
