import { ApolloError } from '@apollo/client';
import {
	Alert,
	AlertTitle,
	AutocompleteGetTagProps,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	SxProps,
	Theme,
} from '@mui/material';
import Autocomplete, { AutocompleteRenderOptionState, createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { LoadingButton } from '../index';

export interface ReactHookFormAutocompleteMultiProps<T> {
	keepOpen?: true;
	name: string;
	label?: string;
	dialogLabel?: string;
	options?: T[];
	fixedOptions?: T[];
	defaultValue?: T;
	error?: boolean;
	helperText?: string;
	// Dont catch errors let this component catch them
	addOption?: (name: string) => Promise<T>;
	getOptionSelected: (option: T, value: T) => boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getOptionValue: (option: T | null) => number | any[] | null;
	getOptionLabel: (option: T) => string;
	renderTags?: (value: T[], getTagProps: AutocompleteGetTagProps) => React.ReactNode;
	renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	loading?: boolean;
	readOnly?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: (event: any) => void;
	allowAddNew?: boolean;
	customOnChange?: (value: any) => void;
	maxReached?: boolean;
	disabled?: boolean;
	sx?: SxProps<Theme> | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filter = createFilterOptions<any>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactHookFormAutocompleteMulti: React.FC<ReactHookFormAutocompleteMultiProps<any>> = ({
	keepOpen,
	label,
	defaultValue,
	error,
	helperText,
	name,
	rules,
	addOption,
	options,
	renderTags,
	renderOption,
	getOptionSelected,
	getOptionLabel,
	getOptionValue,
	loading,
	readOnly,
	fixedOptions,
	onClick,
	allowAddNew = true,
	customOnChange,
	dialogLabel,
	maxReached,
	disabled,
	sx,
}) => {
	const { control, getValues, setValue } = useFormContext();
	const [open, toggleOpen] = useState(false);
	const [submitBusy, setSubmitBusy] = useState(false);
	const [createError, setCreateError] = useState<ApolloError | null>(null);
	const [dialogValue, setDialogValue] = useState({
		name: '',
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const convertFormValue = (value: any) => {
		return options?.filter((option) => getOptionSelected(option, value)) || [];
	};

	/**
	 * Handle Close of Add Option Popup
	 */
	const handleClose = () => {
		setCreateError(null);
		setDialogValue({
			name: '',
		});
		toggleOpen(false);
	};

	/**
	 * Handle Submit of Add Option Popup
	 */
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation(); // Prevent Parent form from submitting
		setSubmitBusy(true);
		setCreateError(null);
		try {
			const value = await addOption?.(dialogValue.name);
			const currentValues = getValues(name) ?? [];
			setValue(name, [...currentValues, value.id]);

			handleClose();
		} catch (e) {
			setCreateError(e);
		} finally {
			setSubmitBusy(false);
		}
	};

	return (
		<>
			<Controller
				rules={rules}
				name={name}
				control={control}
				defaultValue={defaultValue || null}
				render={({ field: { onChange, onBlur, value: formValue, ref } }) => (
					<Autocomplete
						multiple={true}
						disableCloseOnSelect={keepOpen}
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						value={convertFormValue(formValue)}
						forcePopupIcon={true}
						onBlur={onBlur}
						readOnly={readOnly}
						disabled={disabled ?? false}
						sx={sx}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						onChange={(_event, value: any | any[]) => {
							if (Array.isArray(value)) {
								let addNew = false;
								value.forEach((val) => {
									if (val) {
										if (typeof val === 'string') {
											addNew = true;
											setTimeout(() => {
												toggleOpen(true);
												setDialogValue({
													name: val,
												});
											});
										} else if (val.inputValue) {
											addNew = true;
											toggleOpen(true);
											setDialogValue({
												name: val.inputValue,
											});
										}
									}
								});
								if (!addNew) {
									if (fixedOptions) {
										fixedOptions.forEach((x) => value.push(x));
									}
									onChange(getOptionValue(value));
								}
							} else if (typeof value === 'string') {
								// timeout to avoid instant validation of the dialog's form.
								setTimeout(() => {
									toggleOpen(true);
									setDialogValue({
										name: value,
									});
								});
							} else if (value && value.inputValue) {
								toggleOpen(true);
								setDialogValue({
									name: value.inputValue,
								});
							} else {
								onChange(getOptionValue(value));
							}
							if (customOnChange) {
								customOnChange(getOptionValue(value));
							}
						}}
						filterOptions={(options, params) => {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							const filtered = filter(options, params) as any[];

							// Add Option to add new Option
							if (allowAddNew) {
								if (options.length === 0 && params.inputValue === '') {
									filtered.push({
										disabled: true,
										name: `No filter found. Start typing to add new...`,
									});
								} else if (params.inputValue !== '') {
									filtered.push({
										inputValue: params.inputValue,
										name: `Add "${params.inputValue}"`,
									});
								}
							} else {
								if (options.length === 0) {
									filtered.push({
										disabled: true,
										name: 'No options',
									});
								}
							}

							return filtered;
						}}
						options={options || []}
						isOptionEqualToValue={getOptionSelected}
						getOptionDisabled={(option) => (!readOnly && !maxReached ? !!option.disabled : true)}
						getOptionLabel={getOptionLabel}
						renderOption={renderOption}
						renderTags={renderTags}
						freeSolo
						disableClearable={readOnly}
						renderInput={(params) => (
							<TextField
								variant="standard"
								{...params}
								label={label}
								error={error}
								disabled={loading || disabled}
								helperText={helperText}
								onClick={onClick}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									...params.InputProps,
									readOnly: readOnly,
									style: {
										minHeight: '3.5rem',
										paddingTop: '6px',
										paddingBottom: '6px',
									},
								}}
								inputRef={ref}
							/>
						)}
					/>
				)}
			/>
			<Dialog open={open} onClose={handleClose} aria-labelledby={`form-create-${name}-title`}>
				<form id={`form-create-${name}`} onSubmit={handleSubmit}>
					<DialogTitle id={`form-create-${name}-title`}>Add a new {dialogLabel ?? label}</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant="standard"
									autoFocus
									id="name"
									fullWidth
									value={dialogValue.name}
									onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
									label="Name"
									type="text"
									required={true}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							{createError ? (
								<Grid item xs={12}>
									<Alert severity="error">
										<AlertTitle>Error</AlertTitle>
										{createError?.message}
									</Alert>
								</Grid>
							) : null}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} variant="text">
							Cancel
						</Button>
						<LoadingButton pending={submitBusy} form={`form-create-${name}`} type="submit" variant="contained">
							Add
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default ReactHookFormAutocompleteMulti;
