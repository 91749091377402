import { TextField } from '@mui/material';
import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
	root: {
		'&> .MuiInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'none',
		},
		'&> .MuiFormLabel-root.Mui-disabled': {
			color: '#808080',
		},
		'&> .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.87)',
		},
	},
});

const StaticField: React.FC<StaticFieldProps> = ({ label, value }) => {
	const classes = useStyles();
	return (
		<TextField
			variant="standard"
			className={classes.root}
			label={label}
			fullWidth
			InputLabelProps={{
				shrink: true,
			}}
			InputProps={{
				readOnly: true,
			}}
			disabled={true}
			value={value}
		/>
	);
};

export interface StaticFieldProps {
	label?: string;
	value?: string;
}

export default StaticField;
