import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import { Controller, RegisterOptions, UseFormSetValue } from 'react-hook-form';

export interface ReactHookFormPhoneProps extends MuiPhoneNumberProps {
	name: string;
	label?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control?: any;
	defaultValue?: string;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	setValue?: UseFormSetValue<any>;
}

const ReactHookFormPhone: React.FC<ReactHookFormPhoneProps> = ({ name, label, control, defaultValue, rules, setValue, children, ...props }) => {
	const onPhoneChange = (value: string) => {
		const newValue = value === '+' || value === '+1' ? '' : value;
		setValue && setValue(name, newValue, { shouldDirty: true });
	};
	return (
		<Controller
			render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
				<MuiPhoneNumber
					defaultCountry="us"
					fullWidth
					sx={{
						'& .MuiPhoneNumber-flagButton': {
							backgroundColor: 'transparent',
							width: 'min-content',
						},
					}}
					name={name}
					label={label}
					onBlur={onBlur}
					placeholder="Enter Phone Number"
					error={!!error}
					value={value}
					onChange={setValue ? onPhoneChange : onChange}
					{...props}
				/>
			)}
			name={name}
			control={control}
			defaultValue={defaultValue ?? null}
			rules={rules}
		/>
	);
};

export default ReactHookFormPhone;
