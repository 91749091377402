import { Card, CardContent, CardHeader, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePermissions } from '../../..';
import { getScoreSectionsTypeDisplayName, ScoreSectionsType } from '../../../../utils/Enums';
import { LoadingButton } from '../../../Buttons';
import { useTeamLeadScore } from '../../../Providers/TeamLeadScoreProvider/TeamLeadScoreProvider';
import { ReactHookFormCheckbox } from '../../../ReactHookForm';
import EvaluatorReportDetails from './EvaluatorReportDetails';
import TeamLeadScoresTables from './TeamLeadScoresTables';
import ReactHookFormTextField from '../../../ReactHookForm/ReactHookFormTextField';

type ScoreFinalizationProps = {
	onSubmit: (isDraft: boolean) => void;
	unlockEvaluations: () => Promise<void>;
	loading: boolean;
};

const SECTIONS = [ScoreSectionsType.PRODUCT_TECHNOLOGY, ScoreSectionsType.ORGANIZATION, ScoreSectionsType.APPLICATION];

const ScoreFinalization = ({ onSubmit, unlockEvaluations, loading }: ScoreFinalizationProps) => {
	const { user } = usePermissions();
	const { control } = useFormContext();
	const { teamLeadEvaluation, evaluations, isAdmin, isLocked, isTopicClosed, isTopicCompleted, evaluationsComplete, response } = useTeamLeadScore();

	const [selectedSection, setSelectedSection] = useState<ScoreSectionsType>(ScoreSectionsType.PRODUCT_TECHNOLOGY);

	const handleSetSelectedSectionBack = () =>
		setSelectedSection(SECTIONS.indexOf(selectedSection) - 1 < 0 ? SECTIONS.length - 1 : SECTIONS.indexOf(selectedSection) - 1);
	const handleSelectedSectionForward = () =>
		setSelectedSection(SECTIONS.indexOf(selectedSection) + 1 > SECTIONS.length - 1 ? 0 : SECTIONS.indexOf(selectedSection) + 1);

	const readOnly = !isAdmin || isLocked || isTopicClosed || isTopicCompleted;

	return (
		<Card>
			<CardHeader
				title="Score Finalization"
				subheader={
					<div style={{ display: 'flex' }}>
						<Typography>
							Based on <strong>{evaluations.length} Evaluations</strong>
						</Typography>
						{isAdmin && ( // Ensure that the scorer's name only appears if a team lead is on this page
							<>
								<Typography style={{ margin: '0px 10px' }}>
									<span style={{ opacity: '0.5' }}>|</span>
								</Typography>
								<Typography>
									Scoring as <strong>{user?.fullName}</strong>
								</Typography>
							</>
						)}
					</div>
				}
			/>
			<CardContent>
				<Grid container spacing={2} alignItems="center">
					<Grid xs={8} xl={9}>
						<Select variant="outlined" fullWidth onChange={(e) => setSelectedSection(e.target.value as ScoreSectionsType)} value={selectedSection}>
							{SECTIONS.map((category, index) => (
								<MenuItem key={index} value={category}>
									{getScoreSectionsTypeDisplayName(category)}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid container xs={4} xl={3} justifyContent="center" style={{ gap: '0.5rem' }}>
						<IconButton onClick={handleSetSelectedSectionBack} size="large">
							<ArrowBack />
						</IconButton>
						<IconButton onClick={handleSelectedSectionForward} size="large">
							<ArrowForward />
						</IconButton>
					</Grid>
					<Grid xs={12}>
						{response?.topic?.successFactors && (
							<TeamLeadScoresTables selectedSection={selectedSection} successFactors={response?.topic?.successFactors} />
						)}
					</Grid>

					<Grid xs={12}>
						<EvaluatorReportDetails />
					</Grid>

					<Grid xs={12}>
						<ReactHookFormCheckbox
							name="includeContactInfo"
							label="Make my contact information available for the Responder Review Report"
							control={control}
							readOnly={readOnly}
							defaultValue={teamLeadEvaluation?.includeContactInfo ?? false}
						/>
					</Grid>
					<Grid xs={12}>
						<ReactHookFormTextField
							name="finalComment"
							label="Overall Comment"
							fullWidth
							variant="outlined"
							multiline
							defaultValue={teamLeadEvaluation?.finalComment ?? ''}
							disabled={readOnly}
						/>
					</Grid>
					<Grid xs={12}>
						{!readOnly && (
							<LoadingButton fullWidth variant="outlined" style={{ margin: '10px 0' }} pending={loading} onClick={() => onSubmit(true)}>
								Save Progress
							</LoadingButton>
						)}
						{!readOnly && evaluationsComplete && (
							<LoadingButton fullWidth pending={loading} variant="contained" onClick={() => onSubmit(false)}>
								Finalize Score
							</LoadingButton>
						)}
						{isAdmin && isLocked && !isTopicClosed && !isTopicCompleted && (
							<LoadingButton fullWidth variant="contained" pending={loading} style={{ margin: '10px 0' }} onClick={() => unlockEvaluations()}>
								Change Score and Unlock Evaluations
							</LoadingButton>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ScoreFinalization;
