import { darken, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle } from '@mui/material';
import { Fragment } from 'react';
import { GetTeamLeadScoresheetData_stage2responseUsers_evaluatorScores } from '../../../../../apollo/generated/types/GetTeamLeadScoresheetData';
import { FundamentalPrimeMeasurement } from '../../../../../apollo/generated/types/globalTypes';
import { FPMS_ORDERED } from '../../../../../utils/Enums/Helpers/FpmCategories';
import getEvaluationScoreColor from '../../../../../utils/Enums/Helpers/getEvaluationScoreColor';
import { useTeamLeadScore } from '../../../../Providers/TeamLeadScoreProvider/TeamLeadScoreProvider';

interface AllCommentsModalProps {
	open: boolean;
	close: () => void;
}

const AllCommentsModal: React.FC<AllCommentsModalProps> = ({ open, close }) => {
	const classes = useStyles();
	const { evaluations, evaluationIndex } = useTeamLeadScore();
	const currentEvaluation = evaluations[evaluationIndex];
	const finalComment = currentEvaluation?.finalComment;

	const fpmsWithComments = FPMS_ORDERED.filter((fpm) =>
		currentEvaluation.evaluatorScores?.some((score) => score.successFactor.fpmCategory === fpm.fpmCategory && score.comment && score.comment !== ''),
	);

	return (
		<Dialog open={open} onClose={close} maxWidth="md" fullWidth>
			<DialogTitle>
				<Typography variant="h1">All Comments</Typography>
				<IconButton aria-label="close" className={classes.closeButton} onClick={close} size="large">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Alert icon={false} severity="info">
					<AlertTitle>
						<Typography variant="h4">Overall Comment</Typography>
					</AlertTitle>
					<p className={classes.finalComment}>{finalComment}</p>
				</Alert>
				<br />
				{fpmsWithComments.map((fpm) => (
					<Fragment key={fpm.fpmCategory}>
						<CommentsTable
							fpmCategory={fpm.fpmCategory}
							title={fpm.label}
							scores={
								currentEvaluation.evaluatorScores?.filter(
									(score) => score.successFactor.fpmCategory === fpm.fpmCategory && score.comment && score.comment !== '',
								) ?? []
							}
						/>
						<br />
					</Fragment>
				))}
			</DialogContent>
		</Dialog>
	);
};

type CommentsTableProps = {
	fpmCategory: FundamentalPrimeMeasurement;
	title: string;
	scores: GetTeamLeadScoresheetData_stage2responseUsers_evaluatorScores[];
};

const CommentsTable = ({ fpmCategory, title, scores }: CommentsTableProps) => {
	const { evaluations, evaluationIndex, response } = useTeamLeadScore();
	const successFactors = response?.topic?.successFactors.filter((sf) => sf.fpmCategory === fpmCategory) ?? [];

	const scoresTotal = successFactors.reduce(
		(total, sf) =>
			total + evaluations[evaluationIndex].evaluatorScores?.find((evaluatorScore) => evaluatorScore.successFactor.id === sf.id)?.score * sf.weight,
		0,
	);
	const totalWeight = successFactors.reduce((total, sf) => total + parseFloat(sf.weight), 0);
	const weightedAverage = scoresTotal / totalWeight;
	const headerColor = getEvaluationScoreColor(weightedAverage, fpmCategory, response?.topic?.project.selectionProjectInfo?.finishTrlRangeCategory);

	const classes = useStyles(headerColor);

	return (
		<Table>
			<TableHead>
				<TableRow className={classes.headerRow}>
					<TableCell colSpan={3} className={classes.headerTitleCell}>
						<Typography variant="h4">{title}</Typography>
					</TableCell>
					<TableCell className={`${classes.headerScoreCell}`} align="center">
						<Typography variant="h4">{weightedAverage && !Number.isNaN(weightedAverage) ? weightedAverage.toFixed(1) : 'N/A'}</Typography>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{scores.map((score) => (
					<Fragment key={score.successFactor.id}>
						<TableRow className={classes.scoreRow}>
							<TableCell colSpan={3} className={classes.titleCell}>
								<Typography variant="h5" className={classes.sfTitle}>
									{score.successFactor.name}
								</Typography>
								<div className={classes.commentContainer}>
									<SubdirectoryArrowRight />
									{score.comment}
								</div>
							</TableCell>
							<TableCell className={classes.scoreCell} align="center">
								{score.score && score.score.toFixed(1)}
							</TableCell>
						</TableRow>
					</Fragment>
				))}
			</TableBody>
		</Table>
	);
};

const useStyles = (headerColor?: string) =>
	makeStyles((theme) => ({
		titleCell: {
			borderBottom: 0,
		},
		sfTitle: {
			marginBottom: '1rem',
		},
		scoreCell: {
			width: '150px',
			borderBottom: 0,
		},
		headerRow: {
			'& .MuiTableCell-root': {
				borderBottom: 0,
			},
			'& .MuiTypography-root': {
				color: headerColor ? 'white' : undefined,
				transition: 'color 0.5s',
			},
			'background': headerColor,
		},
		headerTitleCell: {
			borderTopLeftRadius: '1rem',
		},
		headerScoreCell: {
			borderTopRightRadius: '1rem',
			background: headerColor && darken(headerColor, 0.15),
			transition: 'background-color 0.5s',
		},
		scoreRow: {
			'&:nth-of-type(odd)': {
				background: theme.palette.secondary.light,
			},
		},
		commentContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: '1rem',
			overflowWrap: 'anywhere',
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		finalComment: {
			wordBreak: 'break-all',
		},
	}))();

export default AllCommentsModal;
