import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { AdminRole } from '../../apollo/generated/types/globalTypes';
import MembershipOptionsPage from '../../pages/MembershipOptionsPage/MembershipOptionsPage';
import VerifyEmailMessage from '../ScreenMessage/VerifyEmailMessage';
import CheckoutSuccessMessage from '../ScreenMessage/CheckoutSuccessMessage';

import { ErrorMessage, LoadingMessage, SideBar, usePermissions } from '../index';
import { useQuery } from '@apollo/client';
import { GetCurrentUser } from '../../apollo/generated/types/GetCurrentUser';
import { GET_CURRENT_USER } from '../../apollo/queries';

const PageWrapper: React.FC = ({ children }) => {
	const history = useHistory();
	const classes = useStyles();
	const { authenticationLoading, error, userLoaded, user } = usePermissions();

	const {
		data: userData,
		loading: userLoading,
		error: userError,
		refetch,
	} = useQuery<GetCurrentUser>(GET_CURRENT_USER, {
		fetchPolicy: 'no-cache',
	});

	const location = useLocation();
	useEffect(() => {
		refetch();
	}, [location]);

	if (error) {
		return (
			<div className={classes.center}>
				<ErrorMessage error={error} reload={true} />
			</div>
		);
	}

	//we will have successful checkout take priority over other interrupts
	const checkoutSuccess = '/checkoutSuccess';
	if (history.location.pathname === checkoutSuccess) {
		return (
			<div className={classes.center}>
				<CheckoutSuccessMessage />
			</div>
		);
	}

	if (!authenticationLoading && userLoaded && !user?.emailVerified) {
		return (
			<div className={classes.center}>
				<VerifyEmailMessage />
			</div>
		);
	}

	if (!userLoading && !userError && userData !== undefined && !userData.currentUser.hasActiveSubscription) {
		return <MembershipOptionsPage />;
	}

	const profilePath = '/profile';
	const helpCenterPath = '/help';
	if (!!user?.isMissingRequiredFields && history.location.pathname !== profilePath && history.location.pathname !== helpCenterPath) {
		// Store previous location
		const prevPath = history.location.pathname;

		return (
			<Redirect
				to={{
					pathname: profilePath,
					state: { prevPath: prevPath },
				}}
			/>
		);
	}

	const usersPath = '/users';

	if (user?.adminRole !== AdminRole.SYS_ADMIN && history.location.pathname === usersPath) {
		return (
			<Redirect
				to={{
					pathname: '/',
				}}
			/>
		);
	}

	return (
		<div className={classes.root}>
			{authenticationLoading || !userLoaded || userLoading ? (
				<div className={classes.center}>
					<LoadingMessage />
				</div>
			) : (
				<>
					<SideBar />
					<main className={classes.page}>{children}</main>
				</>
			)}
		</div>
	);
};

const useStyles = makeStyles({
	root: {
		display: 'flex',
	},
	page: {
		height: '100vh',
		width: '100%',
		overflowY: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		background: '#F6F8FC',
		zIndex: 1,
	},
	center: {
		display: 'flex',
		height: '100vh',
		width: '100vw',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default PageWrapper;
