import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DISMISS_NOTIFICATION } from './Notifications.graphql';
import { DismissNotification, DismissNotificationVariables } from '../../../apollo/generated/types/DismissNotification';

/**
 * Modal for dismissing a notification. Is open when the notificationId prop is not null.
 *
 * @param {({ notificationId: number | null; onClose: () => void })} { notificationId, onClose }
 * @return {*}
 */
const DismissNotificationModal = ({ notificationId, onClose }: { notificationId: number | null; onClose: () => void }) => {
	const [dismissNotification, { loading }] = useMutation<DismissNotification, DismissNotificationVariables>(DISMISS_NOTIFICATION, {
		onCompleted: onClose,
	});

	const handleConfirm = () => {
		if (notificationId) dismissNotification({ variables: { notificationId } });
	};

	return (
		<Dialog open={!!notificationId} onClose={onClose}>
			<DialogTitle>Delete this notification?</DialogTitle>
			<DialogContent>You cannot undo this action. Are you sure you want to permanently delete this notification?</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined">
					Dismiss
				</Button>
				<LoadingButton variant="contained" color="error" loading={loading} onClick={handleConfirm}>
					Confirm
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default DismissNotificationModal;
