import { InfoOutlined } from '@mui/icons-material';
import { Chip, darken, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FundamentalPrimeMeasurement } from '../../../../apollo/generated/types/globalTypes';
import { FpmToPrefix, GetFPMInfo, GetFPMTitle } from '../../../../utils/Enums/Helpers/FpmCategories';
import getEvaluationScoreColor, { EvaluationScoreColor } from '../../../../utils/Enums/Helpers/getEvaluationScoreColor';
import { useTeamLeadScore } from '../../../Providers';

type TeamLeadEvaluatorScoresTableProps = {
	fpm: FundamentalPrimeMeasurement;
};

const TeamLeadEvaluatorScoresTable = ({ fpm }: TeamLeadEvaluatorScoresTableProps) => {
	const { response, evaluations, evaluationIndex } = useTeamLeadScore();
	const successFactors = response?.topic?.successFactors.filter((sf) => sf.fpmCategory === fpm) ?? [];

	const responderScoresTotal = successFactors.reduce(
		(total, sf) => total + response?.responderScores?.find((responderScore) => responderScore.stage2SuccessFactorId === sf.id)?.score * sf.weight,
		0,
	);
	const totalWeight = successFactors.reduce((total, sf) => total + parseFloat(sf.weight), 0);
	const responderWeightedAverage = responderScoresTotal / totalWeight;

	const evaluatorScoresTotal = successFactors.reduce(
		(total, sf) =>
			total + evaluations[evaluationIndex].evaluatorScores?.find((evaluatorScore) => evaluatorScore.successFactor.id === sf.id)?.score * sf.weight,
		0,
	);
	const evaluatorWeightedAverage = evaluatorScoresTotal / totalWeight;

	const headerColor = getEvaluationScoreColor(evaluatorWeightedAverage, fpm, response?.topic?.project.selectionProjectInfo?.finishTrlRangeCategory);
	const classes = useStyles(headerColor);
	const getIconStyle = () => ({
		color: !!headerColor ? 'white' : EvaluationScoreColor.BLUE,
	});

	return (
		<Table>
			<TableHead>
				<TableRow className={classes.headerRow}>
					<TableCell className={`${classes.titleCell} ${classes.headerTitleCell}`}>
						<Typography variant="h4">
							{GetFPMTitle(fpm)}
							<Tooltip title={GetFPMInfo(fpm)}>
								<InfoOutlined fontSize="small" sx={getIconStyle()} />
							</Tooltip>
						</Typography>
					</TableCell>
					<TableCell align="center" className={classes.scoreCell}>
						<Typography variant="h4">{!Number.isNaN(responderWeightedAverage) && responderWeightedAverage.toFixed(1)}</Typography>
					</TableCell>
					<TableCell align="center" className={`${classes.scoreCell} ${classes.headerEvaluatorScoreCell}`}>
						<Typography variant="h4">
							{evaluatorWeightedAverage && !Number.isNaN(evaluatorWeightedAverage) ? evaluatorWeightedAverage.toFixed(1) : 'N/A'}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Success Factors</TableCell>
					<TableCell align="center">Responder Score</TableCell>
					<TableCell align="center">Evaluator Score</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{successFactors.map((sf) => {
					const responderScore = response?.responderScores?.find((responderScore) => responderScore.stage2SuccessFactorId === sf.id)?.score;
					const evaluatorScore = evaluations[evaluationIndex].evaluatorScores?.find(
						(evaluatorScore) => evaluatorScore.successFactor.id === sf.id,
					)?.score;

					return (
						<TableRow key={sf.id} className={classes.row}>
							<TableCell className={classes.titleCell}>
								<Stack gap="1rem" direction="row" alignItems="center">
									{sf.prefix && <Chip label={FpmToPrefix[sf.fpmCategory] + sf.prefix} variant="sfPrefixDense" />}
									{sf.name}
								</Stack>
							</TableCell>
							<TableCell align="center" className={classes.scoreCell}>
								{responderScore && !Number.isNaN(responderScore) ? parseFloat(responderScore).toFixed(1) : 'N/A'}
							</TableCell>
							<TableCell align="center" className={classes.scoreCell}>
								{evaluatorScore && !Number.isNaN(evaluatorScore) ? parseFloat(evaluatorScore).toFixed(1) : 'N/A'}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};

const useStyles = (headerColor?: string) =>
	makeStyles((theme) => ({
		titleCell: {
			maxWidth: '100px',
			overflowWrap: 'break-word',
		},
		scoreCell: {
			padding: '8px',
		},
		scoreSubtitles: {
			fontSize: '12px',
			fontWeight: 600,
			padding: '0px',
			textAlign: 'center',
		},
		headerRow: {
			'& .MuiTableCell-root': {
				borderBottom: 0,
			},
			'& .MuiTypography-root': {
				color: headerColor ? 'white' : undefined,
				transition: 'color 0.5s',
			},
			'background': headerColor,
			'transition': 'background-color 0.5s',
		},
		headerTitleCell: {
			borderTopLeftRadius: '1rem',
		},
		headerEvaluatorScoreCell: {
			borderTopRightRadius: '1rem',
			background: headerColor && darken(headerColor, 0.15),
			transition: 'background-color 0.5s',
		},
		row: {
			'& .MuiTableCell-root': {
				borderBottom: 0,
			},
			'&:nth-of-type(odd)': {
				background: theme.palette.secondary.light,
			},
		},
	}))();

export default TeamLeadEvaluatorScoresTable;
