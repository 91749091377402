import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Comment, Form, Icon } from 'semantic-ui-react';
import { ReactHookFormTextArea, useComment, usePermissions } from '..';
import { GetCommentUsers_commentUsers } from '../../apollo/generated/types/GetCommentUsers';
import { CommentEditInputType, CommentInputType } from '../../apollo/generated/types/globalTypes';
import { getColorFromString } from '../../utils/getColorFromString';

interface CommentRepliesProps {
	comment: any;
	replies: any;
	commentUsers: GetCommentUsers_commentUsers[];
	readonly?: boolean;
}

const useStyles = makeStyles({
	avatar: {
		'& img': {
			borderRadius: '50% !important',
		},
	},
	customAvatar: {
		float: 'left',
		margin: '0 auto',
	},
	customContent: {
		marginLeft: '3.5em',
	},
	quill: {
		'paddingBottom': '20px',
		'& #body': { background: 'white !important' },
	},
	editButton: {
		marginBottom: '20px !important',
	},
	timeWindowInfo: {
		display: 'block !important',
	},
});

const getMinutesLeft = (date, timeWindow) => {
	const milis = date.getTime() + timeWindow * 60000 - Date.now();
	return Math.ceil(milis / 60000);
};

const CommentReplies: React.FC<CommentRepliesProps> = ({ comment, replies, commentUsers, readonly }) => {
	const timeWindow = 15; // TODO store this value somewhere

	const classes = useStyles();
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<{ comment: string; commentEdit: string; commentReply: string }>();
	const { comments, addComment, edit, removeComment, addLike, removeLike } = useComment();
	// current user
	const { user } = usePermissions();
	const [replyTo, setReplyTo] = useState(false);
	const [editing, setEditing] = useState(false);
	const [showOriginal, setShowOriginal] = useState(false);
	const [showMore, setShowMore] = useState(true);
	const [commenterData, setCommenterData] = useState<GetCommentUsers_commentUsers | undefined>(commentUsers.find((e) => e.id === comment.userId));
	const [likeLoading, setLikeLoading] = useState<any>(false);
	const [minutesLeft, setMinutesLeft] = useState(getMinutesLeft(new Date(comment.date + 'Z'), timeWindow));

	useEffect(() => {
		setCommenterData(commentUsers.find((e) => e.id === comment.userId));
	}, [commentUsers]);

	useEffect(() => {
		const interval = setInterval(() => {
			setMinutesLeft(getMinutesLeft(new Date(comment.date + 'Z'), timeWindow));
		}, 1000);
		return () => clearInterval(interval);
	}, [comment, timeWindow]);

	// prevent undefined user.id
	const user_id = user ? user?.id : '';

	// determine if the comment was made by the current user
	const isCurrentUser = comment.userId === user_id;

	const starComment = () => {
		if (comment.likes.filter((e) => e.userId === user?.id).length > 0) {
			// remove star
			removeLike(comment.id, user_id, window.location.href);
		} else {
			// add star
			addLike(comment.id, user_id, window.location.href);
		}
	};

	const addReply = (data, e) => {
		if (!readonly) {
			e.preventDefault();
			const date = new Date();
			addComment(user_id, window.location.href, data.commentReply, date.toUTCString(), comment.id);
			setReplyTo(false);
			reset();
		}
	};

	const saveEdit = (data, e) => {
		if (!readonly) {
			e.preventDefault();
			const date = new Date();
			edit(comment.id, data.commentEdit, date.toUTCString(), window.location.href);
			setEditing(false);
			reset();
		}
	};

	const deleteComment = () => {
		removeComment(comment.id, window.location.href);
	};

	const onError = (errors, e) => console.log({ errors, e });

	return (
		<>
			{commenterData && (
				<Comment>
					<Avatar
						src={commenterData.profilePicture?.url}
						className={classes.customAvatar}
						style={getColorFromString(commenterData?.firstName + ' ' + commenterData?.lastName)}>
						{commenterData.firstName?.charAt(0).toUpperCase()}
						{commenterData.lastName?.charAt(0).toUpperCase()}
					</Avatar>
					<Comment.Content className={classes.customContent}>
						<Comment.Author>
							{commenterData?.firstName} {commenterData?.lastName}
						</Comment.Author>
						<Comment.Metadata>
							<p>{new Date(comment.date + 'Z').toLocaleString() + (comment.edited ? ' (Edited)' : '')}</p>
							{isCurrentUser && minutesLeft > 0 && !comment.deleted && !readonly && (
								<p className={classes.timeWindowInfo}>Editing disabled in {minutesLeft} min</p>
							)}
						</Comment.Metadata>
						{editing ? (
							<Form onSubmit={handleSubmit(saveEdit, onError)}>
								<ReactHookFormTextArea
									className={classes.quill}
									fullWidth
									control={control}
									label=""
									name="commentEdit"
									error={!!errors.commentEdit}
									helperText={errors.commentEdit?.message}
									defaultValue={comment.content}
									rules={{
										required: 'Comment text required',
										validate: (value) => {
											if (value.replace(/<[^>]+>/g, '').length === 0) return 'Comment text required';
											else if (value === comment.content) return 'Comment must be edited';
											else return true;
										},
									}}
								/>
								<Button className={classes.editButton} type="submit" content="Save Changes" labelPosition="left" icon="edit" primary />
							</Form>
						) : (
							<Comment.Text>
								<div
									dangerouslySetInnerHTML={{
										__html: comment.deleted ? '<p>[Comment deleted]</p>' : showOriginal ? comment.audits[0]?.content : comment.content,
									}}
								/>
							</Comment.Text>
						)}
						<Comment.Actions>
							{isCurrentUser && minutesLeft > 0 && !comment.deleted && !readonly && (
								<Comment.Action
									onClick={() => {
										setEditing(!editing);
										setReplyTo(false);
									}}
									active={editing}>
									Edit
								</Comment.Action>
							)}
							{isCurrentUser && !comment.deleted && !readonly && <Comment.Action onClick={deleteComment}>Delete</Comment.Action>}
							{comment.edited && !comment.deleted && (
								<Comment.Action onClick={() => setShowOriginal(!showOriginal)}>
									{showOriginal ? 'Show Current' : 'Show Original'}
								</Comment.Action>
							)}
							{!comment.deleted && !readonly && (
								<Comment.Action
									onClick={() => {
										setReplyTo(!replyTo);
										setEditing(false);
									}}
									active={replyTo}>
									Reply
								</Comment.Action>
							)}
							<Comment.Action
								as="a"
								disabled={likeLoading || readonly}
								onClick={() => {
									if (readonly) return;
									setLikeLoading(true);
									starComment();
									setLikeLoading(false);
								}}>
								<Icon name="star" color={comment.likes.filter((e) => e.userId === user?.id).length > 0 ? 'yellow' : 'grey'} />
								{comment.likes.length} {comment.likes.length === 1 ? 'like' : 'likes'}
							</Comment.Action>
							{replies.length > 0 && showMore ? (
								<Comment.Action onClick={() => setShowMore(false)}>Hide Replies</Comment.Action>
							) : (
								replies.length > 0 && <Comment.Action onClick={() => setShowMore(true)}>Show Replies ( {replies.length} )</Comment.Action>
							)}
						</Comment.Actions>
					</Comment.Content>

					{replyTo && (
						<Form reply onSubmit={handleSubmit(addReply, onError)}>
							<ReactHookFormTextArea
								className={classes.quill}
								fullWidth
								control={control}
								label=""
								name="commentReply"
								error={!!errors.commentReply}
								helperText={errors.commentReply?.message}
								defaultValue=""
								rules={{
									required: 'Comment text required',
									validate: (value) => {
										return value.replace(/<[^>]+>/g, '').length > 0 || 'Comment text required';
									},
								}}
							/>
							<Button type="submit" content="Add Reply" labelPosition="left" icon="edit" primary />
						</Form>
					)}
					{replies.length > 0 &&
						showMore &&
						comments &&
						replies.map((i) => {
							return (
								<Comment.Group key={i.id} style={{ maxWidth: '100%' }}>
									<CommentReplies
										commentUsers={commentUsers}
										comment={i}
										replies={comments.filter((j) => j.parentId === i.id)}
										readonly={readonly}
									/>
								</Comment.Group>
							);
						})}
				</Comment>
			)}
		</>
	);
};

export default CommentReplies;
