import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Grow, IconButton, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';

const useStyles = makeStyles({
	main: {
		display: 'flex',
		flex: '1 0 auto',
		alignItems: 'center',
		gap: '5px',
	},
	searchIcon: {
		color: 'secondary',
		marginRight: '8px',
	},
	searchText: {
		flex: '0.8 0',
	},
	clearIcon: {
		'&:hover': {
			color: 'main',
		},
	},
});

const CustomTableSearch = ({ options, searchText, handleSearch, hideSearch }) => {
	const classes = useStyles();
	const textFieldRef = useRef<HTMLInputElement>();

	const debouncedHandleSearch = useCallback(debounce(handleSearch, 1000), []);

	const [controlledText, setControlledText] = useState<string>(searchText);

	const handleTextChange = (event) => {
		setControlledText(event.target.value);
		debouncedHandleSearch(event.target.value);
	};

	const onKeyDown = (event) => {
		if (event.key === 'Escape') {
			hideSearch();
		}
	};

	const clearText = () => {
		setControlledText('');
		handleSearch('');
		if (textFieldRef.current) textFieldRef.current.focus();
	};

	return (
		<Grow appear in={true} timeout={300}>
			<div className={classes.main}>
				<SearchIcon className={classes.searchIcon} />
				<TextField
					className={classes.searchText}
					autoFocus={true}
					variant={'standard'}
					InputProps={{ 'data-test-id': options.textLabels.toolbar.search }}
					inputProps={{ 'aria-label': options.textLabels.toolbar.search }}
					value={controlledText || ''}
					onKeyDown={onKeyDown}
					onChange={handleTextChange}
					fullWidth={true}
					placeholder={options.searchPlaceholder}
					inputRef={textFieldRef}
					{...(options.searchProps ? options.searchProps : {})}
				/>
				<IconButton className={classes.clearIcon} onClick={clearText} size="large">
					<ClearIcon />
				</IconButton>
			</div>
		</Grow>
	);
};
export default CustomTableSearch;
