import React from 'react';
import { PageWrapper, PageHeader, PageBody, ScreenMessage } from '../components';
import { CircularProgress } from '@mui/material';

const RedirectingPage: React.FC = () => {
	return (
		<PageWrapper>
			<PageHeader>
				<div />
			</PageHeader>
			<PageBody>
				<ScreenMessage icon={<CircularProgress />} header="Redirecting..." />
			</PageBody>
		</PageWrapper>
	);
};

export default RedirectingPage;
