import { FpmCategory, FundamentalPrimeMeasurement } from '../../../apollo/generated/types/globalTypes';

export const FPMS_ORDERED = [
	{ fpmCategory: FundamentalPrimeMeasurement.APPEAL, label: 'Appeal' },
	{ fpmCategory: FundamentalPrimeMeasurement.VALUE, label: 'Value' },
	{ fpmCategory: FundamentalPrimeMeasurement.RELIABILITY, label: 'Reliability' },
	{ fpmCategory: FundamentalPrimeMeasurement.PERSONNEL, label: 'Personnel' },
	{ fpmCategory: FundamentalPrimeMeasurement.PROCESSES, label: 'Planning/Processes' },
	{ fpmCategory: FundamentalPrimeMeasurement.FINANCES, label: 'Finances' },
	{ fpmCategory: FundamentalPrimeMeasurement.APPLICATION_SIZE, label: 'Size/Scope' },
	{ fpmCategory: FundamentalPrimeMeasurement.DEMAND, label: 'Demand' },
	{ fpmCategory: FundamentalPrimeMeasurement.DELIVERY, label: 'Access/Delivery' },
] as const;

export const GetFPMCatTitle = (fpm: FpmCategory) => {
	switch (fpm) {
		case FpmCategory.APPLICATION:
			return 'Market Application';
		case FpmCategory.ORGANIZATION:
			return 'Team & Stakeholders';
		case FpmCategory.PRODUCT_TECHNOLOGY:
			return 'Product Technology';
	}
};

export const GetFPMTitle = (fpm: FundamentalPrimeMeasurement) => {
	switch (fpm) {
		case FundamentalPrimeMeasurement.APPEAL:
			return 'Appeal';
		case FundamentalPrimeMeasurement.VALUE:
			return 'Value';
		case FundamentalPrimeMeasurement.RELIABILITY:
			return 'Reliability';
		case FundamentalPrimeMeasurement.PERSONNEL:
			return 'Personnel';
		case FundamentalPrimeMeasurement.PROCESSES:
			return 'Planning/Processes';
		case FundamentalPrimeMeasurement.FINANCES:
			return 'Finances';
		case FundamentalPrimeMeasurement.APPLICATION_SIZE:
			return 'Size/Scope';
		case FundamentalPrimeMeasurement.DEMAND:
			return 'Demand';
		case FundamentalPrimeMeasurement.DELIVERY:
			return 'Access/Delivery';
	}
};

export const GetFPMCatInfo = (fpm: FpmCategory) => {
	switch (fpm) {
		case FpmCategory.APPLICATION:
			return 'The subsystems, systems, and platforms the technology will be integrated into. A Tier 1 Lane of the 9-HI™ system focused on the delivery and deployment of technologies. More specifically, successful Market Application means that technology products are properly sized, scoped, and in high demand. The 3 FPMs that comprise Market Application are: Size/Scope, Demand, and Access/Delivery';
		case FpmCategory.ORGANIZATION:
			return 'A Tier 1 Lane of the 9-HI™ system focused on systematic team building, effective/efficient collaboration, and preservation of knowledge for future generations. The team should consider partner organizations after referred to as the extended enterprise and vendor base or downstream distribution and sales partners. The Team also needs to consider all stake holders including government regulatory bodies that may drive requirements for success. The 3 FPMs that comprise Team & Stakeholders are: Personnel, Planning/Processes, and Finances.';
		case FpmCategory.PRODUCT_TECHNOLOGY:
			return '"What is being developed or selected": A Tier 1 Lane of the 9-HI™ system focused on the continuous improvement of development and deployment processes to yield successful technology products that meet customer requirements. The 3 FPMs that comprise Product Technology are: Appeal, Value, and Reliability.';
	}
};

export const GetFPMInfo = (fpm: FundamentalPrimeMeasurement) => {
	switch (fpm) {
		case FundamentalPrimeMeasurement.APPEAL:
			return 'A Tier 2 FPM under Product Technology. Appeal focuses on what attracts people, customers, and clients prior to the initial sale. It is what customers are seeking, or the expectation of what a technology might deliver.';
		case FundamentalPrimeMeasurement.VALUE:
			return 'A Tier 2 FPM under Product Technology. Actual functionality derived from the technology. Function divided by price or cost of ownership. What users perceive is the reason to buy, sign-up, or join your product or technology for the cost/price offered.';
		case FundamentalPrimeMeasurement.RELIABILITY:
			return 'A Tier 2 FPM under Product Technology. All experiences after the sale, how they feel about the product, service, or membership.';
		case FundamentalPrimeMeasurement.PERSONNEL:
			return 'A Tier 2 Metric under Team & Stakeholders. Based on culture; People who can drive score improvement of the other 8 FPM categories on a project. Extend enterprise, create real partners.';
		case FundamentalPrimeMeasurement.PROCESSES:
			return 'A Tier 2 FPM under Team & Stakeholders. Plans become processes. Drive & monitor information, quality, teaming, and autonomy. Processes can include physical activities such as manufacturing or analytical activities related to design and development. Processes can also be related to business processes such as legal reviews and monitoring or accounting and financial processes etc.';
		case FundamentalPrimeMeasurement.FINANCES:
			return 'A Tier 2 FPM under Team & Stakeholders. Lifeblood; ROI, justification of costs, investing should result in creating, multiple continuous measures that connect all activities to sales and/or profit.';
		case FundamentalPrimeMeasurement.APPLICATION_SIZE:
			return 'A Tier 2 FPM under Market Application. Can the existing market support growth? Focus on weighing your expansion options. The total Addressable Market and plans to capture the market size or expand to adjacent/additional market segments.';
		case FundamentalPrimeMeasurement.DEMAND:
			return 'A Tier 2 FPM under Market Application. Linked to Appeal/Value/Reliability, but must be cultivated & supported with insight, customer profiles & access. Special attention needs to be given to creating demand for new technologies within the marketplace, not just projecting which customers "should" want the product.';
		case FundamentalPrimeMeasurement.DELIVERY:
			return 'A Tier 2 FPM under Market Application. Subcomponents may include sales, marketing, delivery, research, competitive landscape, influencers, connectors, and channels. Physical distribution, capacity planning, deployment, support, and training.';
	}
};

export const GetTier1Lane = (fpm: FundamentalPrimeMeasurement): FpmCategory => {
	switch (fpm) {
		case FundamentalPrimeMeasurement.APPEAL:
		case FundamentalPrimeMeasurement.VALUE:
		case FundamentalPrimeMeasurement.RELIABILITY:
			return FpmCategory.PRODUCT_TECHNOLOGY;
		case FundamentalPrimeMeasurement.PERSONNEL:
		case FundamentalPrimeMeasurement.PROCESSES:
		case FundamentalPrimeMeasurement.FINANCES:
			return FpmCategory.ORGANIZATION;
		case FundamentalPrimeMeasurement.APPLICATION_SIZE:
		case FundamentalPrimeMeasurement.DEMAND:
		case FundamentalPrimeMeasurement.DELIVERY:
			return FpmCategory.APPLICATION;
	}
};

export const FpmToPrefix = {
	[FundamentalPrimeMeasurement.APPEAL]: 'SF-A',
	[FundamentalPrimeMeasurement.VALUE]: 'SF-V',
	[FundamentalPrimeMeasurement.RELIABILITY]: 'SF-R',
	[FundamentalPrimeMeasurement.PERSONNEL]: 'SF-P',
	[FundamentalPrimeMeasurement.PROCESSES]: 'SF-PP',
	[FundamentalPrimeMeasurement.FINANCES]: 'SF-F',
	[FundamentalPrimeMeasurement.APPLICATION_SIZE]: 'SF-SS',
	[FundamentalPrimeMeasurement.DEMAND]: 'SF-D',
	[FundamentalPrimeMeasurement.DELIVERY]: 'SF-AD',
};

export const fpmCategoryToPrefix = {
	[FpmCategory.APPLICATION]: 'R-MA',
	[FpmCategory.ORGANIZATION]: 'R-TS',
	[FpmCategory.PRODUCT_TECHNOLOGY]: 'R-PT',
};
