import gql from 'graphql-tag';
import {
	CategoryFragment,
	CurrentUserFragment,
	FileFragment,
	KeywordFragment,
	MemberFragment,
	OptionFragment,
	OptionWithParentFragment,
	PocFragment,
	ProfileFragment,
	ProjectFragment,
	ProjectRegistrantFragment,
	ProjectTopicFragment,
	ResponderTypeFragment,
	ResponseRequirementDocumentsFragment,
	ResponseRequirementFragment,
	ResponseRequirementsHostInfoFragment,
	ResponseUserScoreFragment,
	RiskFragment,
	SecurityClassificationFragment,
	SelectionProjectFragment,
	Stage2SuccessFactorsFragment,
	UserFragment,
} from './fragments';

export const GET_USERS = gql`
	query GetUsers {
		users {
			...UserDisplayFields
		}
	}
	${UserFragment}
`;

export const GET_AUTH0_ROLES = gql`
	query GetAuth0Roles {
		auth0Roles {
			id
			name
		}
	}
`;

export const GET_USER = gql`
	query GetUser($userId: Guid) {
		user(userId: $userId) {
			id
			email
			firstName
			lastName
			phoneNumber
			externalRoleName
			externalRoleId
			isMissingRequiredFields
			profileComplete
		}
	}
`;

export const GET_CURRENT_USER = gql`
	query GetCurrentUser {
		currentUser {
			...CurrentUserFields
		}
	}
	${CurrentUserFragment}
`;

export const GET_PROFILE_PICTURE = gql`
	query GetProfilePicture {
		currentUser {
			profilePicture {
				url
			}
		}
	}
`;

export const GET_PROFILE = gql`
	query GetProfile($userId: Guid) {
		user(userId: $userId) {
			...ProfileFields
		}
	}
	${ProfileFragment}
`;

export const GET_FILE_DOWNLOAD_LINK = gql`
	query GetFileDownloadLink($id: Int!) {
		uploadedFile(id: $id) {
			id
			uploadId
			fileName
			url
		}
	}
`;

export const GET_TOKEN_DOWNLOAD_LINK = gql`
	query GetTokenDownloadLink($token: String!) {
		uploadTokenURL(token: $token) {
			sasUri
		}
	}
`;

export const GET_UPLOADED_FILE = gql`
	query GetUploadedFile($id: Int!) {
		uploadedFile(id: $id) {
			...FileFields
		}
	}
	${FileFragment}
`;

export const GET_UPLOADED_FILES = gql`
	query GetUploadedFiles($referenceId: Int!, $referenceType: FileReferenceType!) {
		uploadedFiles(referenceId: $referenceId, referenceType: $referenceType) {
			...FileFields
		}
	}
	${FileFragment}
`;

export const GET_COMMON_FORM_OPTIONS = gql`
	query GetCommonFormOptions {
		categories {
			...CategoryFields
		}
		securityClassifications {
			...SecurityClassificationFields
		}
		researchDevelopmentCategories {
			...OptionFields
		}
		technologyReadinessLevels {
			...OptionWithParentFields
		}
		pointOfContactOrganizations {
			...OptionFields
		}
		responderTypes {
			...ResponderTypeFields
		}
	}
	${CategoryFragment}
	${SecurityClassificationFragment}
	${OptionFragment}
	${OptionWithParentFragment}
	${ResponderTypeFragment}
`;

export const GET_SECURITY_CLASSIFICATION_OPTIONS = gql`
	query GetSecurityClassificationOptions {
		securityClassifications {
			...SecurityClassificationFields
		}
	}
	${SecurityClassificationFragment}
`;

export const GET_RFI_FORM_OPTIONS = gql`
	query GetRFIFormOptions {
		timeHorizons {
			...OptionFields
		}
		fundingSources {
			...OptionFields
		}
		technologyKeywords {
			...OptionFields
		}
	}
	${OptionFragment}
`;

export const GET_KEYWORDS = gql`
	query GetKeywords {
		keywords {
			...KeywordFields
		}
	}
	${KeywordFragment}
`;

export const GET_STAGE_2_TOPIC_SUCCESS_FACTORS = gql`
	query GetStage2TopicSuccessFactors($topicId: Guid!) {
		stage2successFactors(projectTopicId: $topicId) {
			...Stage2SuccessFactorsFields
		}
	}
	${Stage2SuccessFactorsFragment}
`;

export const GET_RESPONSE_USER = gql`
	query GetResponseUser($responseId: Guid!, $userId: Guid!) {
		stage2ResponseUser(responseId: $responseId, userId: $userId) {
			...ResponseUserScoreFields
		}
		stage2Response(id: $responseId) {
			draftStatus
		}
	}
	${ResponseUserScoreFragment}
`;

export const GET_RESPONSE_USERS = gql`
	query GetResponseUsers($responseId: Guid!) {
		stage2responseUsers(responseId: $responseId) {
			...ResponseUserScoreFields
		}
	}
	${ResponseUserScoreFragment}
`;

export const GET_EVALUATOR_SCORESHEET_DATA = gql`
	query GetEvaluatorScoreSheetData($responseId: Guid!) {
		stage2Response(id: $responseId) {
			id
			draftStatus
			displayId
			responderScores {
				id
				score
				stage2SuccessFactorId
				successFactor {
					id
					fpmCategory
				}
			}
			registrant {
				registrantGroup {
					name
				}
				responderTechnologyName
			}
			topic {
				id
				name
				status
				successFactors {
					...Stage2SuccessFactorsFields
				}
				risks {
					...RiskFields
				}
				project {
					id
					name
					type
					group {
						id
						name
					}
					selectionProjectInfo {
						id
						draftStatusVal
						startTrlRangeCategory
						finishTrlRangeCategory
					}
					responseProjectInfo {
						id
						startTrlRangeCategory
						finishTrlRangeCategory
					}
				}
				downSelectionStatus
			}
			assignedEvaluators {
				id
				evaluatorScores {
					id
					comment
					score
					stage2SuccessFactorId
					successFactor {
						id
						fpmCategory
					}
				}
				user {
					fullName
				}
				userId
				status
				includeContactInfo
				finalComment
			}
		}
	}
	${Stage2SuccessFactorsFragment}
	${RiskFragment}
`;

export const GET_TEAM_LEAD_SCORESHEET_DATA = gql`
	query GetTeamLeadScoresheetData($responseId: Guid!) {
		stage2responseUsers(responseId: $responseId, includeUnassigned: true) {
			id
			stage2ResponseId
			userId
			user {
				fullName
			}
			contactInfoIncludedInFinal
			finalCommentIncludedInFinal
			status
			reviewed
			finalComment
			includeContactInfo
			evaluatorScores {
				id
				stage2ResponseUserId
				successFactor {
					...Stage2SuccessFactorsFields
				}
				score
				comment
			}
		}
		finalEvaluation(responseId: $responseId) {
			id
			stage2ResponseId
			contactInfoIncludedInFinal
			finalCommentIncludedInFinal
			finalComment
			includeContactInfo
			evaluatorScores {
				id
				stage2ResponseUserId
				successFactor {
					...Stage2SuccessFactorsFields
				}
				score
				comment
			}
		}
		stage2Response(id: $responseId) {
			draftStatus
			responderScores {
				id
				stage2SuccessFactorId
				score
			}
			registrant {
				registrantGroup {
					name
				}
				responderTechnologyName
			}
			responseFiles {
				id
				fileName
			}
			topic {
				id
				name
				isAdmin
				status
				successFactors {
					...Stage2SuccessFactorsFields
				}
				risks {
					...RiskFields
				}
				project {
					id
					name
					group {
						id
						name
					}
					selectionProjectInfo {
						id
						startTrlRangeCategory
						finishTrlRangeCategory
					}
				}
				downSelectionStatus
			}
		}
	}
	${Stage2SuccessFactorsFragment}
	${RiskFragment}
`;

export const GET_COMMENTS = gql`
	query GetComments($url: String!) {
		comments(url: $url) {
			commentPageId
			content
			date
			id
			likes {
				id
				userId
			}
			parentId
			userId
			edited
			deleted
			audits {
				content
				date
			}
		}
	}
`;

export const GET_COMMENT_USERS = gql`
	query GetCommentUsers($url: String!) {
		commentUsers(url: $url) {
			id
			firstName
			lastName
			profilePicture {
				url
			}
		}
	}
`;

export const GET_GROUP_NAME = gql`
	query GetGroupName($id: Int!) {
		group(id: $id) {
			id
			name
		}
	}
`;

export const GET_GROUP = gql`
	query GetGroup($id: Int!) {
		group(id: $id) {
			id
			name
			isAdmin
			status
			name
			address
			phoneNumber
			contactName
			contactPhoneNumber
			contactEmail
			privacy
			groupType
			buildingNumberMailstop
			industryType
			additionalContactName
			additionalContactEmail
			additionalContactPhoneNumber
			linkedInProfile
			facebookProfile
			twitterHandle
			instagramProfile
			youTubeChannel
			vimeoChannel
			researchGateProfile
			googleScholarProfile
			naicscode
			dunsnumber
			samuniqueEntityId
			cagecode
			modifiedAt
			tin
			subtypeId
			description
			website
			groupPointsOfContact {
				userId
				contactPhoneNumber
				contactEmail
			}
			groupUsers {
				userId
				user {
					fullName
					email
					phoneNumber
				}
				groupRole
			}
			groupProjects {
				id
			}
			groupKeywords {
				keyword {
					...KeywordFields
				}
			}
			groupFiles {
				...FileFields
			}
			lanesOfFocus
		}
	}
	${FileFragment}
	${KeywordFragment}
`;

export const GET_GROUPS_PAGE = gql`
	query GetGroupsPage(
		$page: Int
		$pageSize: Int
		$search: String
		$orderBy: String
		$orderDirection: String
		$statusFilter: [GroupStatus!]
		$onlyMine: Boolean
	) {
		groupsPage(
			page: $page
			pageSize: $pageSize
			search: $search
			orderBy: $orderBy
			orderDirection: $orderDirection
			statusFilter: $statusFilter
			onlyMine: $onlyMine
		) {
			items {
				id
				name
				status
				groupType
				subtype {
					id
					name
				}
				isAdmin
				groupUsers {
					userId
					groupRole
				}
			}
			pageInfo {
				total
			}
		}
	}
`;

export const GET_GROUPS = gql`
	query GetGroups {
		groups {
			id
			name
			status
			groupType
			naicscode
			subtype {
				id
				name
			}
			groupUsers {
				userId
				groupRole
			}
			groupKeywords {
				keywordId
				keyword {
					...KeywordFields
				}
			}
		}
		currentUserAdminGroupIds
	}
	${KeywordFragment}
`;

export const GET_GROUP_SUBTYPES = gql`
	query GetGroupSubtypes {
		groupSubtypes {
			id
			name
		}
	}
`;

export const GET_PROJECT = gql`
	query GetProject($id: Guid!) {
		project(id: $id) {
			...ProjectFields
		}
	}
	${ProjectFragment}
`;

export const GET_SELECTION_PROJECT = gql`
	query GetSelectionProject($id: Guid!) {
		selectionProject(id: $id) {
			...SelectionProjectFields
		}
	}
	${SelectionProjectFragment}
`;

export const GET_PROJECTS = gql`
	query GetProjects {
		projects {
			id
			displayId
			name
			status
			group {
				id
				name
			}
			projectUsers {
				userId
				projectRole
			}
			projectKeywords {
				keywordId
			}
			topics {
				status
			}
			type
			selectionProjectInfo {
				id
				type
				draftStatusVal
				deadline
				submissionDeadline
			}
		}
		currentUser {
			groupMemberships {
				group {
					id
				}
				groupRole
				primaryOwner
			}
			projectMemberships {
				project {
					id
				}
				projectRole
			}
		}
		currentUserAdminGroupIds
		currentUserAdminProjectIds
	}
`;

export const GET_PROJECT_FORM_OPTIONS = gql`
	query GetProjectFormOptions {
		securityClassifications {
			id
			name
		}
		technologyReadinessLevels {
			id
			name
		}
		trlRanges {
			id
			name
			imageName
		}
	}
`;

export const GET_PROJECT_RESPONDER_OPTIONS = gql`
	query GetProjectResponderOptions {
		internalExternal {
			id
			name
		}
		responderTypes {
			id
			name
		}
		responderSubType {
			id
			name
		}
	}
`;

export const GET_GROUP_USERS = gql`
	query GetGroupUsers($groupId: Int!) {
		groupUsers(groupId: $groupId) {
			inviteStatus
			dateAdded
			productTechnologyMatch
			teamStakeoldersMatch
			marketApplicationMatch
			email
			firstName
			lastName
			primaryOwner
			groupRole
			user {
				...MemberFragment
			}
		}
		group(id: $groupId) {
			isAdmin
			status
			name
			groupKeywords {
				keyword {
					...KeywordFields
				}
			}
		}
	}
	${MemberFragment}
	${KeywordFragment}
`;

export const GET_PROJECT_USERS = gql`
	query GetProjectUsers($projectId: Guid!) {
		projectUsers(projectId: $projectId) {
			id
			inviteStatus
			dateAdded
			productTechnologyMatch
			teamStakeoldersMatch
			marketApplicationMatch
			email
			firstName
			lastName
			projectRole
			hasWorkAgreement
			user {
				...MemberFragment
			}
		}
		project(id: $projectId) {
			id
			type
			isAdmin
			status
			name
			projectKeywords {
				keyword {
					...KeywordFields
				}
			}
			topics {
				id
				isAdmin
				createdAt
			}
			group {
				id
				name
				createdBy
				groupUsers {
					id
					userId
					primaryOwner
					inviteStatus
					groupRole
				}
			}
			selectionProjectInfo {
				id
				type
				draftStatusVal
				deadline
				deadlineTimezone
				submissionDeadline
				submissionDeadlineTimezone
				downSelectionType
			}
		}
	}
	${MemberFragment}
	${KeywordFragment}
`;

export const GET_TOPIC_USERS = gql`
	query GetTopicUsers($topicId: Guid!) {
		topicUsers(topicId: $topicId) {
			inviteStatus
			dateAdded
			productTechnologyMatch
			teamStakeoldersMatch
			marketApplicationMatch
			topicRole
			email
			firstName
			lastName
			hasWorkAgreement
			user {
				...MemberFragment
			}
		}
		projectTopic(id: $topicId) {
			name
			isAdmin
			isMember
			status
			projectTopicKeywords {
				keyword {
					...KeywordFields
				}
			}
			projectTopicUsers {
				userId
			}
			project {
				id
				name
				type
				isAdmin
				projectUsers {
					id
					userId
					inviteStatus
					projectRole
				}
				group {
					id
					name
					createdBy
					groupUsers {
						id
						userId
						primaryOwner
						inviteStatus
						groupRole
					}
				}
				selectionProjectInfo {
					id
					deadline
					draftStatusVal
					privacy
					type
				}
			}
		}
	}
	${MemberFragment}
	${KeywordFragment}
`;

export const GET_SME_CANDIDATES = gql`
	query GetSMECandidates($filters: SMECandidateInputType!) {
		smeCandidates(filters: $filters) {
			...MemberFragment
			userKeywords {
				keyword {
					...KeywordFields
				}
			}
		}
	}
	${MemberFragment}
	${KeywordFragment}
`;

export const GET_PROJECT_POC_OPTIONS = gql`
	query GetProjectPocOptions($projectId: Guid, $groupId: Int) {
		projectPocOptions(projectId: $projectId, groupId: $groupId) {
			...PocFields
		}
	}
	${PocFragment}
`;

export const GET_TOPIC_POC_OPTIONS = gql`
	query GetTopicPocOptions($topicId: Guid!, $includeGroup: Boolean!) {
		topicPocOptions(topicId: $topicId, includeGroup: $includeGroup) {
			...PocFields
		}
	}
	${PocFragment}
`;

export const GET_PROJECT_TOPIC = gql`
	query GetProjectTopic($id: Guid!) {
		projectTopic(id: $id) {
			...ProjectTopicFields
		}
	}
	${ProjectTopicFragment}
`;

export const GET_PROJECT_REGISTRANTS = gql`
	query GetProjectRegistrants($id: Guid!) {
		projectStage2Registrants(projectId: $id, pageSize: 10000) {
			items {
				...ProjectRegistrantFields
			}
		}
		project(id: $id) {
			status
			selectionProjectInfo {
				id
				deadline
			}
			topics {
				name
			}
		}
	}
	${ProjectRegistrantFragment}
`;

export const GET_USER_GROUPS = gql`
	query GetUserGroups($userId: Guid!, $groupRole: GroupRole) {
		userGroups(userId: $userId, groupRole: $groupRole) {
			id
			name
			address
			phoneNumber
			groupType
			dunsnumber
			samuniqueEntityId
			cagecode
			tin
			website
			groupUsers {
				id
			}
		}
	}
`;

export const GET_CURRENT_USER_GROUPS = gql`
	query GetCurrentUserGroups {
		userGroups {
			id
			name
		}
	}
`;

export const GET_GROUP_OPTIONS = gql`
	query GetGroupOptions($excludeIeGroups: Boolean) {
		userGroups(groupRole: PROGRAM_MANAGER, excludeIeGroups: $excludeIeGroups) {
			id
			name
			groupKeywords {
				keywordId
				keyword {
					...KeywordFields
				}
			}
		}
	}
	${KeywordFragment}
`;

export const GET_RESPONSE_REQUIREMENTS = gql`
	query GetResponseRequirements($projectId: Guid!) {
		project(id: $projectId) {
			id
			displayId
			name
			description
			isAdmin
			status
			type
			projectPointsOfContact {
				user {
					...PocFields
				}
			}
			...ResponseRequirementsHostInfoFields
			responseProjectInfo {
				response {
					topic {
						project {
							...ResponseRequirementsHostInfoFields
							responseRequirements {
								...ResponseRequirementDocumentsFields
							}
						}
					}
				}
			}
		}
		responseFormRequirements(projectId: $projectId) {
			...ResponseRequirementFields
		}
	}
	${ResponseRequirementFragment}
	${ResponseRequirementDocumentsFragment}
	${ResponseRequirementsHostInfoFragment}
	${PocFragment}
`;

export const GET_TOPIC_BREADCRUMBS = gql`
	query GetTopicBreadcrumbs($topicId: Guid!) {
		projectTopic(id: $topicId) {
			name
			project {
				name
				group {
					name
				}
			}
		}
	}
`;

export const GET_TRL_RANGES = gql`
	query GetTrlRanges {
		trlRanges {
			id
			name
			imageName
		}
	}
`;

export const GET_BUSINESS_TYPE = gql`
	query GetBusinessType {
		businessType {
			id
			name
		}
	}
`;

export const GET_GOV_IND_ACA = gql`
	query GetGovIndAca {
		governmentIndustryAcademia {
			id
			name
		}
	}
`;

export const GET_RECOMMENDED_COLLEAGUES = gql`
	query GetRecommendedColleagues($groupId: Int, $projectId: Guid, $projectTopicId: Guid) {
		recommendedColleagues(groupId: $groupId, projectId: $projectId, projectTopicId: $projectTopicId) {
			firstName
			lastName
			email
			recommendedRole
			comment
			createdAt
		}
	}
`;

export const GET_DISQUALIFICATION_REASONS = gql`
	query GetDisqualificationReasons {
		disqualificationReasons {
			id
			reason
		}
	}
`;

export const GET_AI_SUCCESS_FACTORS = gql`
	query GetAiSuccessFactors($topicId: Guid!, $fpmCategory: FundamentalPrimeMeasurement!) {
		aiSuccessFactors(topicId: $topicId, fpmCategory: $fpmCategory) {
			id
			name
			definition
		}
	}
`;

export const GET_AI_RISKS = gql`
	query GetAiRisks($topicId: Guid!, $fpmCategory: FpmCategory!) {
		aiRisks(topicId: $topicId, fpmCategory: $fpmCategory) {
			id
			name
			definition
		}
	}
`;

export const GET_GPT_SUCCESS_FACTORS = gql`
	query GetGptSuccessFactors($sfRequest: OpenAiSfRequestInputType!) {
		openAiSfRequest(sfRequest: $sfRequest)
	}
`;

export const GET_GPT_RISKS = gql`
	query GetGptRisks($riskRequest: OpenAiRiskRequestInputType!) {
		openAiRiskRequest(riskRequest: $riskRequest)
	}
`;

export const GET_GPT_SUCCESS_EVIDENCE = gql`
	query GetGptSuccessEvidence($evidenceRequest: OpenAiSeRequestInputType!) {
		openAiEvidenceRequest(evidenceRequest: $evidenceRequest)
	}
`;

export const GET_GPT_CUSTOM_QUERY = gql`
	query GetGptCustomQuery($topicId: Guid!, $prompt: String!) {
		customOpenAIQuery(prompt: $prompt, topicId: $topicId)
	}
`;

export const GET_GROUP_ROLE = gql`
	query GetGroupRole($email: String!, $groupId: Int!) {
		groupRole(email: $email, groupId: $groupId)
	}
`;

export const GET_PROJECT_ROLE = gql`
	query GetProjectRole($email: String!, $projectId: Guid!) {
		projectRole(email: $email, projectId: $projectId)
	}
`;

export const GET_TOPIC_ROLE = gql`
	query GetTopicRole($email: String!, $topicId: Guid!) {
		topicRole(email: $email, topicId: $topicId)
	}
`;

export const GET_CURRENT_USER_INTERESTS = gql`
	query GetCurrentUserInterests {
		currentUser {
			id
			userKeywords {
				id
				type
				keyword {
					id
					fpmCategory
				}
			}
		}
	}
`;
