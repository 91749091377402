import {
	BulletinNotificationFrequency,
	BulletinNotificationMethod,
	BulletinStatus,
	DataPrivacy,
	FpmCategory,
	GroupRole,
	GroupStatus,
	GroupSubscriptionStatus,
	GroupTypeE,
	HoursPer,
	InformationType,
	InviteStatus,
	LetterType,
	ProjectContractType,
	ProjectRole,
	ProjectSolicitationType,
	ProjectStatus,
	ProjectTopicStatus,
	ProjectTypeE,
	RatePer,
	RegistrantStatus,
	SelectionProjectTypeE,
	SelectionStatus,
	TopicRole,
	WorkAgreementLocationType,
} from '../../../apollo/generated/types/globalTypes';
import { FullProjectType } from './ProjectFormFieldsUtil';

export const projectSolicitationTypeToString = (value: ProjectSolicitationType): string => {
	switch (value) {
		case ProjectSolicitationType.DOWN_SELECTION:
			return 'Include only down selected responders from prior project';
		case ProjectSolicitationType.OPEN:
			return 'Include new responders and down selected responders from prior project';
	}
};

export const projectContractTypeToString = (value: ProjectContractType): string => {
	switch (value) {
		case ProjectContractType.COST_PLUS_FIXED_FEE:
			return 'Cost plus fixed fee (CPFF)';
		case ProjectContractType.COST_PLUS_INCENTIVE_FEE:
			return 'Cost plus incentive fee (CPIF)';
		case ProjectContractType.FIXED_PRICE:
			return 'Fixed price (FP)';
		case ProjectContractType.FIXED_PRICE_INCENTIVE_FEE:
			return 'Fixed price incentive fee (FPIF)';
		case ProjectContractType.TIME_AND_MATERIAL:
			return 'Time & Material (T&M)';
		case ProjectContractType.OTHER:
			return 'Other';
	}
};

export const fpmCategoryToString = (value: FpmCategory): string => {
	switch (value) {
		case FpmCategory.APPLICATION:
			return 'Market Application';
		case FpmCategory.ORGANIZATION:
			return 'Team & Stakeholders';
		case FpmCategory.PRODUCT_TECHNOLOGY:
			return 'Product Technology';
	}
};

export const informationTypeToString = (value: InformationType): string => {
	switch (value) {
		case InformationType.KEYWORDS:
			return 'Keywords';
		case InformationType.RISKS:
			return 'Risks';
		case InformationType.SUCCESS_EVIDENCE:
			return 'Success Evidence';
		case InformationType.SUCCESS_FACTORS:
			return 'Success Factors';
		case InformationType.TECHNICAL_DOCUMENTS:
			return 'Technical Documents';
	}
};

export const selectionStatusToString = (value: SelectionStatus): string => {
	switch (value) {
		case SelectionStatus.PENDING:
			return 'Pending';
		case SelectionStatus.DOWN_SELECTION_SELECTED:
			return 'Accepted';
		case SelectionStatus.AWARD_SELECTED:
			return 'Awarded';
		case SelectionStatus.DOWN_SELECTION_REJECTED:
		case SelectionStatus.AWARD_REJECTED:
			return 'Declined';
		case SelectionStatus.AWARD_BASKETED:
			return 'Basketed';
		case SelectionStatus.CANCELLED:
			return 'Cancelled';
		case SelectionStatus.DISQUALIFIED:
			return 'Disqualified';
	}
};

export const registrantStatusToString = (value: RegistrantStatus): string => {
	switch (value) {
		case RegistrantStatus.APPROVED:
			return 'Approved';
		case RegistrantStatus.DISAPPROVED:
			return 'Disapproved';
		case RegistrantStatus.PENDING_REVIEW:
			return 'Pending Review';
		case RegistrantStatus.UNSUBMITTED:
			return 'Not Submitted';
	}
};

export const bulletinStatusToString = (value: BulletinStatus): string => {
	switch (value) {
		case BulletinStatus.APPROVED:
			return 'Approved';
		case BulletinStatus.DRAFT:
			return 'Draft in Progress';
		case BulletinStatus.SUBMITTED:
			return 'Submitted for Approval';
		case BulletinStatus.DELIVERED:
			return 'Delivered';
		case BulletinStatus.DISAPPROVED:
			return 'Disapproved';
	}
};

export const projectStatusToString = (value: ProjectStatus): string => {
	switch (value) {
		case ProjectStatus.ACTIVE:
			return 'Active';
		case ProjectStatus.CLOSED:
			return 'Closed';
	}
};

export const groupStatusToString = (value: GroupStatus): string => {
	switch (value) {
		case GroupStatus.ACTIVE:
			return 'Active';
		case GroupStatus.CLOSED:
			return 'Closed';
	}
};

export const bulletinNotificationFrequencyToString = (value: BulletinNotificationFrequency): string => {
	switch (value) {
		case BulletinNotificationFrequency.NONE:
			return 'None (Stop receiving bulletin notifications for now)';
		case BulletinNotificationFrequency.UNLIMITED:
			return 'Unlimited Notifications';
		case BulletinNotificationFrequency.WEEKLY:
			return 'Weekly Notifications (Receive a weekly summary of all bulletins that were intended for you within the past week)';
	}
};

export const bulletinNotificationMethodToString = (value: BulletinNotificationMethod): string => {
	switch (value) {
		case BulletinNotificationMethod.EMAIL:
			return 'Email';
		case BulletinNotificationMethod.NINE_HI:
			return '9-HI Notification';
		case BulletinNotificationMethod.BOTH:
			return 'Email and 9-HI Notification';
	}
};

export const groupRoleToString = (value: GroupRole): string => {
	switch (value) {
		case GroupRole.PROGRAM_MANAGER:
			return 'Program Manager';
		case GroupRole.SUBJECT_MATTER_EXPERT:
			return 'Subject Matter Expert';
	}
};

export const projectRoleToString = (value: ProjectRole): string => {
	switch (value) {
		case ProjectRole.PROGRAM_MANAGER:
			return 'Program Manager';
		case ProjectRole.PROJECT_MANAGER:
			return 'Project Manager';
		case ProjectRole.SUBJECT_MATTER_EXPERT:
			return 'Subject Matter Expert';
	}
};

export const topicRoleToString = (value: TopicRole): string => {
	switch (value) {
		case TopicRole.TOPIC_LEAD:
			return 'Team Lead';
		case TopicRole.SUBJECT_MATTER_EXPERT:
			return 'Subject Matter Expert';
	}
};

export const dataPrivacyToString = (value: DataPrivacy): string => {
	switch (value) {
		case DataPrivacy.PUBLIC:
			return 'Public';
		case DataPrivacy.PRIVATE:
			return 'Private';
	}
};

export const groupTypeToString = (value: GroupTypeE): string => {
	switch (value) {
		case GroupTypeE.ACADEMIA:
			return 'Academia';
		case GroupTypeE.GOVERNMENT:
			return 'Government';
		case GroupTypeE.INDUSTRY:
			return 'Industry';
		case GroupTypeE.INNOVATION_EXPLORATION:
			return 'Innovation Exploration';
	}
};

export const selectionProjectTypeToString = (value: SelectionProjectTypeE): string => {
	switch (value) {
		case SelectionProjectTypeE.REQUEST_FOR_INFORMATION:
			return 'RFI';
		case SelectionProjectTypeE.REQUEST_FOR_PROPOSAL:
			return 'RFP';
		case SelectionProjectTypeE.PITCH_EVENT:
			return 'Pitch Event';
		case SelectionProjectTypeE.SIMPLE_RFI:
			return 'Simple RFI';
		case SelectionProjectTypeE.REQUEST_FOR_WHITE_PAPER:
			return 'RWP';
	}
};

export const fullProjectTypeToString = (value: FullProjectType): string => {
	switch (value) {
		case ProjectTypeE.INNOVATION_EXPLORATION:
			return 'Innovation Exploration';
		case ProjectTypeE.RESPONSE:
			return 'Responder Solution';
		case ProjectTypeE.DEVELOPMENT:
			return 'Development';
		default:
			return selectionProjectTypeToString(value);
	}
};

export const topicStatusToString = (value: ProjectTopicStatus): string => {
	switch (value) {
		case ProjectTopicStatus.ACTIVE:
			return 'Active';
		case ProjectTopicStatus.ARCHIVED:
			return 'Archived';
		case ProjectTopicStatus.CLOSED:
			return 'Closed';
		case ProjectTopicStatus.COMPLETED:
			return 'Completed';
	}
};

export const letterTypeToString = (value: LetterType): string => {
	switch (value) {
		case LetterType.AUTHORIZATION:
			return 'Authorization';
		case LetterType.COLLABORATION:
			return 'Collaboration';
		case LetterType.COMMITMENT:
			return 'Commitment';
		case LetterType.INTENT:
			return 'Intent';
		case LetterType.ORGANIZATIONAL_SUPPORT:
			return 'Organizational Support';
		case LetterType.RECOMMENDATIONS:
			return 'Recommendations';
	}
};

export const workAgreementLocationTypeToString = (value: WorkAgreementLocationType): string => {
	switch (value) {
		case WorkAgreementLocationType.CONTRACTORS_FACILITY:
			return "Contractor's Facility";
		case WorkAgreementLocationType.PROVIDE_SITE_LOCATION:
			return 'Provide Site Location';
		case WorkAgreementLocationType.REMOTE:
			return 'Remote From Home';
	}
};

export const hoursPerToString = (value: HoursPer): string => {
	switch (value) {
		case HoursPer.DAY:
			return 'Per day';
		case HoursPer.WEEK:
			return 'Per week';
		case HoursPer.MONTH:
			return 'Per month';
	}
};

export const ratePerToString = (value: RatePer): string => {
	switch (value) {
		case RatePer.HOUR:
			return 'Per hour';
		case RatePer.WEEK:
			return 'Per week';
		case RatePer.MONTH:
			return 'Per month';
	}
};

export const inviteStatusToString = (value: InviteStatus): string => {
	switch (value) {
		case InviteStatus.UNSENT:
			return 'Not Sent';
		case InviteStatus.INVITED:
			return 'Pending';
		case InviteStatus.PENDING_APPROVAL:
			return 'Accepted';
		case InviteStatus.INVITATION_DECLINED:
			return 'Invitation Declined';
		case InviteStatus.WORK_AGREEMENT_DECLINED:
			return 'Work Agreement Declined';
		case InviteStatus.APPROVED:
			return 'Approved';
	}
};

export const groupSubscriptionStatusToString = (value: GroupSubscriptionStatus): string => {
	switch (value) {
		case GroupSubscriptionStatus.APPROVED:
			return 'Active';
		case GroupSubscriptionStatus.CANCELLED:
			return 'Canceled';
		case GroupSubscriptionStatus.REJECTED:
			return 'Rejected';
		case GroupSubscriptionStatus.REQUEST_CANCELLED:
			return 'Request Canceled';
		case GroupSubscriptionStatus.SUBMITTED:
			return 'Submitted';
		case GroupSubscriptionStatus.SUSPENDED:
			return 'Suspended';
		case GroupSubscriptionStatus.REPLACED:
			return 'Replaced';
	}
};
