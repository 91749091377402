import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, darken, Menu } from '@mui/material';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { ProjectDraftStatus } from '../../apollo/generated/types/globalTypes';
import { theme } from '../../theme/theme';
import { getProjectDraftStatusDisplayName } from '../../utils/Enums';
import ProjectMenuOptions from './MenuOptions/ProjectMenuOptions';

export interface StatusDropdownProps {
	name: string;
	readOnly?: boolean;
	canEnableEvaluations?: boolean;
}

const ProjectStatusDropdown = ({ name, readOnly = false, canEnableEvaluations }: StatusDropdownProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const { field } = useController({ name, defaultValue: ProjectDraftStatus.DRAFT });

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(e.currentTarget);
	};

	const changeStatus = (newStatus: ProjectDraftStatus) => {
		field.onChange(newStatus);
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				fullWidth
				variant="contained"
				endIcon={<ArrowDropDownIcon />}
				disabled={readOnly}
				onClick={(e) => handleClick(e)}
				sx={
					field.value !== ProjectDraftStatus.DRAFT
						? {
								'background': buttonColors[field.value],
								'&:hover': { background: darken(buttonColors[field.value], 0.1) },
						  }
						: {}
				}>
				{getProjectDraftStatusDisplayName(field.value)}
			</Button>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
				<ProjectMenuOptions status={field.value} changeStatus={changeStatus} canEnableEvaluations={canEnableEvaluations} />
			</Menu>
		</>
	);
};

const buttonColors = {
	[ProjectDraftStatus.APPROVED]: theme.palette.success.main,
	[ProjectDraftStatus.EVALUATIONS_IN_PROGRESS]: theme.palette.success.main,
	[ProjectDraftStatus.PENDING_APPROVAL]: '#ff9800',
};

export default ProjectStatusDropdown;
