import { Star } from '@mui/icons-material';
import { Box, Step, StepButton, StepLabel, Stepper as MuiStepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Divider } from 'semantic-ui-react';
import { StepperConnector } from './StepperConnector';

export interface StepperProps {
	steps: Step[];
	maxHeight?: string;
}

interface Step {
	label: string;
	displayStarIcon?: boolean;
	displayDivider?: boolean;
	ref: React.RefObject<HTMLDivElement>;
	header?: boolean;
	indent?: boolean;
}

const Stepper: React.FC<StepperProps> = ({ steps, maxHeight }) => {
	const activeStep = useActiveStep(steps);

	const handleStep = (ref: React.RefObject<HTMLDivElement>) => {
		if (ref && ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<Box sx={sx}>
			<p className="title">SCROLL TO</p>
			<Box sx={{ maxHeight, overflowY: 'auto', overflowX: 'hidden', paddingRight: '0.5rem' }}>
				<MuiStepper sx={{ overflow: 'hidden' }} activeStep={activeStep} orientation="vertical" nonLinear connector={<StepperConnector />}>
					{steps.map((step, index) => (
						<Step key={index}>
							<StepButton onClick={() => handleStep(step.ref)}>
								<StepLabel
									StepIconComponent={() => null}
									sx={{
										'& .MuiStepLabel-label': { fontWeight: step.header ? '600 !important' : '400', paddingLeft: step.indent ? '15px' : 0 },
									}}>
									{step.label} {step.displayStarIcon ? <Star color="secondary" fontSize="small" /> : <></>}{' '}
								</StepLabel>
							</StepButton>
							{step.displayDivider ? <Divider style={{ marginBottom: '0px', marginTop: '30px', borderTop: '1px solid #B1BFC6' }} /> : <></>}
						</Step>
					))}
				</MuiStepper>
			</Box>
		</Box>
	);
};

const useActiveStep = (steps: Step[]): number | undefined => {
	const [intersections, setIntersections] = useState(steps.map((_step) => false));
	useEffect(() => {
		const callback: IntersectionObserverCallback = (entries) => {
			setIntersections((currentIntersections) => {
				const intersectionsCopy = [...currentIntersections];
				entries.forEach((entry) => {
					const index = steps.findIndex((step) => step.ref.current === entry.target);
					intersectionsCopy[index] = entry.isIntersecting;
				});
				return intersectionsCopy;
			});
		};
		const observer = new IntersectionObserver(callback);
		steps.forEach((step) => {
			if (step.ref.current !== null) observer.observe(step.ref.current);
		});
		return () => observer.disconnect();
	}, [steps]);
	const activeStep = intersections.findIndex((i) => i);
	return activeStep === -1 ? undefined : activeStep;
};

const sx = {
	'& .MuiStepLabel-label.Mui-active': {
		color: 'primary.main',
	},
	'& .MuiStepLabel-root': {
		alignItems: 'baseline',
	},
	'& .MuiStepButton-root:hover': {
		'& .MuiStepLabel-label': {
			color: 'primary.main',
		},
	},
	'.title': {
		color: 'darkgrey',
		fontWeight: 600,
		ml: '15px',
	},
};

export default Stepper;

export * from './StepperConnector';
