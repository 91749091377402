import { gql } from '@apollo/client';
import {
	CurrentUserFragment,
	KeywordFragment,
	OptionFragment,
	ProfileFragment,
	ProjectFragment,
	ProjectRegistrantFragment,
	ProjectTopicFragment,
	ResponseRequirementFragment,
	ResponseUserScoreFragment,
	SelectionProjectFragment,
} from './fragments';

export const REGISTER_USER = gql`
	mutation RegisterUser($auth0Id: String!, $email: String!) {
		register(auth0Id: $auth0Id, email: $email) {
			...CurrentUserFields
		}
	}
	${CurrentUserFragment}
`;

export const CREATE_USER = gql`
	mutation CreateUser($user: UserInputType!) {
		createUser(user: $user) {
			id
			email
		}
	}
`;

export const UPDATE_USER = gql`
	mutation UpdateUser($user: UserInputType!) {
		updateUser(user: $user) {
			id
			email
		}
	}
`;

export const UPDATE_USER_PROFILE = gql`
	mutation UpdateUserProfile($userProfile: UserProfileInputType!) {
		updateUserProfile(user: $userProfile) {
			...ProfileFields
		}
	}
	${ProfileFragment}
`;

export const DELETE_USER = gql`
	mutation DeleteUser($userId: Guid!) {
		deleteUser(id: $userId)
	}
`;

export const REQUEST_UPLOAD_TOKEN = gql`
	mutation RequestUploadToken($input: UploadTokenRequestType!) {
		uploadTokenRequest(input: $input) {
			sasUri
			token
		}
	}
`;

export const CREATE_TIME_HORIZON = gql`
	mutation CreateTimeHorizon($name: String!) {
		createTimeHorizon(name: $name) {
			...OptionFields
		}
	}
	${OptionFragment}
`;

export const CREATE_TECHNOLOGY_KEYWORD = gql`
	mutation CreateTechnologyKeyword($name: String!) {
		createTechnologyKeyword(name: $name) {
			...OptionFields
		}
	}
	${OptionFragment}
`;

export const CREATE_FUNDING_SOURCE = gql`
	mutation CreateFundingSource($name: String!) {
		createFundingSource(name: $name) {
			...OptionFields
		}
	}
	${OptionFragment}
`;

export const CREATE_BUSINESS_TYPE = gql`
	mutation CreateBusinessType($name: String!) {
		createBusinessType(name: $name) {
			id
			name
		}
	}
`;

export const CREATE_RESPONSE_ORGANIZATION = gql`
	mutation CreateResponseOrganization($name: String!) {
		createResponseOrganization(name: $name) {
			...OptionFields
		}
	}
	${OptionFragment}
`;

export const CREATE_POINT_OF_CONTACT_ORGANIZATION = gql`
	mutation CreatePointOfContactOrganization($name: String!) {
		createPointOfContactOrganization(name: $name) {
			...OptionFields
		}
	}
	${OptionFragment}
`;

export const CREATE_KEYWORD = gql`
	mutation CreateKeyword($name: String!, $fpmCategory: FpmCategory!) {
		createKeyword(name: $name, fpmCategory: $fpmCategory) {
			...KeywordFields
		}
	}
	${KeywordFragment}
`;

export const EXPORT_PROJECT_ANNOUNCEMENT_PDF = gql`
	mutation ExportProjectAnnouncementPDF($id: Guid!, $showLogo: Boolean!) {
		exportProjectAnnouncement(id: $id, showLogo: $showLogo)
	}
`;

export const UPDATE_EVALUATOR_SCORES = gql`
	mutation UpdateEvaluatorScores($responseUser: Stage2ResponseUserScoreInputType!) {
		updateStage2EvaluatorScores(responseUser: $responseUser) {
			...ResponseUserScoreFields
		}
	}
	${ResponseUserScoreFragment}
`;

export const UPDATE_FINAL_EVALUATION_SCORES = gql`
	mutation UpdateFinalEvaluationScores($finalEvaluation: FinalEvaluationInputType!) {
		updateFinalEvaluationScores(finalEvaluation: $finalEvaluation) {
			id
		}
	}
`;

export const CREATE_COMMENT = gql`
	mutation CreateComment($comment: CommentInputType!) {
		createComment(comment: $comment) {
			commentPageId
			content
			date
			id
			likes {
				id
				userId
			}
			parentId
			userId
		}
	}
`;

export const EDIT_COMMENT = gql`
	mutation EditComment($commentEdit: CommentEditInputType!) {
		editComment(commentEdit: $commentEdit) {
			commentPageId
			content
			date
			id
			likes {
				id
				userId
			}
			parentId
			userId
		}
	}
`;

export const DELETE_COMMENT = gql`
	mutation DeleteComment($commentId: Int!) {
		deleteComment(commentId: $commentId) {
			commentPageId
			content
			date
			id
			likes {
				id
				userId
			}
			parentId
			userId
		}
	}
`;

export const CREATE_LIKED_COMMENT = gql`
	mutation CreateLikedComment($likedComment: LikedCommentInputType!) {
		createLikedComment(likedComment: $likedComment) {
			id
			commentId
			userId
		}
	}
`;

export const DELETE_LIKED_COMMENT = gql`
	mutation DeleteLikedComment($likedComment: LikedCommentInputType!) {
		deleteLikedComment(likedComment: $likedComment) {
			id
			commentId
			userId
		}
	}
`;

export const CREATE_GROUP = gql`
	mutation CreateGroup($group: GroupInputType!) {
		createGroup(group: $group) {
			id
			name
			status
			name
			address
			contactName
			contactPhoneNumber
			contactEmail
			privacy
			groupType
			description
			buildingNumberMailstop
			industryType
			additionalContactName
			additionalContactEmail
			additionalContactPhoneNumber
			naicscode
			dunsnumber
			samuniqueEntityId
			cagecode
			modifiedAt
			groupPointsOfContact {
				userId
				contactPhoneNumber
				contactEmail
			}
		}
	}
`;

export const CREATE_SELECTION_PROJECT = gql`
	mutation CreateSelectionProject($project: SelectionProjectInputType!) {
		createSelectionProject(project: $project) {
			...SelectionProjectFields
		}
	}
	${SelectionProjectFragment}
`;

export const UPDATE_PROJECT_DRAFT_STATUS = gql`
	mutation UpdateProjectDraftStatus($projectId: Guid!, $status: ProjectDraftStatus!) {
		updateProjectDraftStatus(projectId: $projectId, status: $status) {
			id
		}
	}
`;

export const CLOSE_GROUP = gql`
	mutation CloseGroup($groupId: Int!) {
		closeGroup(groupId: $groupId) {
			id
		}
	}
`;

export const OPEN_GROUP = gql`
	mutation OpenGroup($groupId: Int!) {
		openGroup(groupId: $groupId) {
			id
		}
	}
`;

export const CLOSE_PROJECT = gql`
	mutation CloseProject($projectId: Guid!) {
		closeProject(projectId: $projectId) {
			...ProjectFields
		}
	}
	${ProjectFragment}
`;

export const OPEN_PROJECT = gql`
	mutation OpenProject($projectId: Guid!) {
		openProject(projectId: $projectId) {
			...ProjectFields
		}
	}
	${ProjectFragment}
`;

export const CLOSE_TOPIC = gql`
	mutation CloseTopic($topicId: Guid!) {
		closeTopic(topicId: $topicId) {
			...ProjectTopicFields
		}
	}
	${ProjectTopicFragment}
`;

export const OPEN_TOPIC = gql`
	mutation OpenTopic($topicId: Guid!) {
		openTopic(topicId: $topicId) {
			...ProjectTopicFields
		}
	}
	${ProjectTopicFragment}
`;

export const ADD_GROUP_USERS = gql`
	mutation AddGroupUsers($groupId: Int!, $users: [GroupUserInputType!]!, $includeSignUpCode: Boolean) {
		addGroupUsers(groupId: $groupId, users: $users, includeSignUpCode: $includeSignUpCode) {
			userId
		}
	}
`;

export const ADD_PROJECT_USERS = gql`
	mutation AddProjectUsers($projectId: Guid!, $users: [ProjectUserInputType!]!) {
		addProjectUsers(projectId: $projectId, users: $users) {
			userId
		}
	}
`;

export const ADD_TOPIC_USERS = gql`
	mutation AddTopicUsers($topicId: Guid!, $users: [ProjectTopicUserInputType!]!) {
		addProjectTopicUsers(projectTopicId: $topicId, users: $users) {
			userId
		}
	}
`;

export const REMOVE_GROUP_USERS = gql`
	mutation RemoveGroupUsers($groupId: Int!, $users: [GroupUserInputType!]!, $sendEmail: Boolean) {
		removeGroupUsers(groupId: $groupId, users: $users, sendEmail: $sendEmail) {
			userId
		}
	}
`;

export const REMOVE_PROJECT_USERS = gql`
	mutation RemoveProjectUsers($projectId: Guid!, $users: [ProjectUserInputType!]!, $sendEmail: Boolean) {
		removeProjectUsers(projectId: $projectId, users: $users, sendEmail: $sendEmail) {
			userId
		}
	}
`;

export const REMOVE_TOPIC_USERS = gql`
	mutation RemoveTopicUsers($topicId: Guid!, $users: [ProjectTopicUserInputType!]!, $sendEmail: Boolean) {
		removeProjectTopicUsers(projectTopicId: $topicId, users: $users, sendEmail: $sendEmail) {
			userId
		}
	}
`;

export const EDIT_GROUP_USERS = gql`
	mutation EditGroupUsers($groupId: Int!, $users: [GroupUserInputType!]!) {
		editGroupUsers(groupId: $groupId, users: $users) {
			userId
		}
	}
`;

export const CHANGE_GROUP_USER_ROLE = gql`
	mutation ChangeGroupUserRole($groupId: Int!, $userId: Guid!, $groupRole: GroupRole!, $assignerName: String!) {
		changeGroupUserRole(groupId: $groupId, userId: $userId, groupRole: $groupRole, assignerName: $assignerName) {
			userId
		}
	}
`;

export const EDIT_PROJECT_USERS = gql`
	mutation EditProjectUsers($projectId: Guid!, $users: [ProjectUserInputType!]!) {
		editProjectUsers(projectId: $projectId, users: $users) {
			userId
		}
	}
`;

export const CHANGE_PROJECT_USER_ROLE = gql`
	mutation ChangeProjectUserRole($projectId: Guid!, $userId: Guid!, $projectRole: ProjectRole!, $assignerName: String!) {
		changeProjectUserRole(projectId: $projectId, userId: $userId, projectRole: $projectRole, assignerName: $assignerName) {
			userId
		}
	}
`;

export const EDIT_TOPIC_USERS = gql`
	mutation EditTopicUsers($topicId: Guid!, $users: [ProjectTopicUserInputType!]!) {
		editProjectTopicUsers(projectTopicId: $topicId, users: $users) {
			userId
		}
	}
`;

export const EDIT_PROJECT_TOPIC = gql`
	mutation EditProjectTopic($projectTopic: ProjectTopicInputType!) {
		editProjectTopic(projectTopic: $projectTopic) {
			...ProjectTopicFields
		}
	}
	${ProjectTopicFragment}
`;

export const CHANGE_PROJECT_TOPIC_USER_ROLE = gql`
	mutation ChangeProjectTopicUserRole($projectTopicId: Guid!, $userId: Guid!, $projectTopicRole: TopicRole!, $assignerName: String!) {
		changeProjectTopicUserRole(projectTopicId: $projectTopicId, userId: $userId, projectTopicRole: $projectTopicRole, assignerName: $assignerName) {
			userId
		}
	}
`;

export const SET_REGISTRANT_STATUS = gql`
	mutation SetRegistrantStatus($id: Guid!, $status: RegistrantStatus!, $disapprovalReason: String) {
		setStage2RegistrantStatus(registrantId: $id, registrantStatus: $status, disapprovalReason: $disapprovalReason) {
			...ProjectRegistrantFields
		}
	}
	${ProjectRegistrantFragment}
`;

export const CREATE_RESPONSE_REQUIREMENTS = gql`
	mutation CreateResponseRequirements($responseFormRequirements: Stage2ResponseFormRequirementInputType!) {
		createStage2ResponseFormRequirements(responseFormRequirements: $responseFormRequirements) {
			...ResponseRequirementFields
		}
	}
	${ResponseRequirementFragment}
`;

export const UNLOCK_RESPONSE_EVALUATIONS = gql`
	mutation UnlockResponseEvaluations($responseId: Guid!) {
		unlockResponseEvaluations(responseId: $responseId) {
			id
		}
	}
`;

export const UPDATE_EVALUATIONS = gql`
	mutation UpdateEvaluations($evaluations: [EvaluationInputType!]!) {
		updateEvaluations(evaluations: $evaluations) {
			id
			stage2ResponseId
			finalCommentIncludedInFinal
			contactInfoIncludedInFinal
		}
	}
`;

export const RESEND_VERIFICATION_EMAIL = gql`
	mutation ResendVerificationEmail {
		resendVerificationEmail
	}
`;

export const REGISTER_CHECKOUT_SUCCESS = gql`
	mutation RegisterCheckoutSuccess($stripeSessionId: String!) {
		registerCheckoutSuccess(stripeSessionId: $stripeSessionId)
	}
`;

export const CREATE_RESPONDER_SUBTYPE = gql`
	mutation CreateResponderSubType($name: String!) {
		createResponderSubtype(name: $name) {
			...OptionFields
		}
	}
	${OptionFragment}
`;

export const CREATE_TEAM_MEMBER_EVALUATION = gql`
	mutation CreateTeamMemberEvaluation($teamMemberEvaluation: TeamMemberEvaluationInputType!) {
		createTeamMemberEvaluation(teamMemberEvaluation: $teamMemberEvaluation) {
			id
		}
	}
`;

export const SET_PROJECT_TOPIC_DOWN_SELECTION_STATUS = gql`
	mutation SetProjectTopicDownSelectionStatus($projectTopicId: Guid!, $downSelectionStatus: ProjectTopicDownSelectionStatus!) {
		setProjectTopicDownSelectionStatus(projectTopicId: $projectTopicId, downSelectionStatus: $downSelectionStatus) {
			id
			downSelectionStatus
		}
	}
`;

export const ADD_RECOMMENDED_COLLEAGUE = gql`
	mutation AddRecommendedColleagues($recommendationInput: RecommendationInputType!) {
		addRecommendation(recommendationInput: $recommendationInput) {
			id
		}
	}
`;

export const CREATE_GROUP_SUBTYPE = gql`
	mutation CreateGroupSubtype($name: String!) {
		createGroupSubtype(name: $name) {
			id
			name
		}
	}
`;
