import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import RedirectingPage from '../../pages/RedirectingPage';
import { usePermissions } from '../Providers';

type ProtectedRouteProps = RouteProps & {
	permission?: string;
};

const ProtectedRoute = ({ permission, ...routeProps }: ProtectedRouteProps) => {
	const { hasPermission } = usePermissions();

	if (permission !== undefined && !hasPermission(permission)) {
		return <Redirect to={{ pathname: '/' }} />;
	}

	return <Route {...routeProps} />;
};

export default withAuthenticationRequired(ProtectedRoute, {
	onRedirecting: () => <RedirectingPage />,
});
