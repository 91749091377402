import makeStyles from '@mui/styles/makeStyles';
import { TopBar } from '../index';

const PageHeader: React.FC<PageHeaderProps> = ({ fixedHeight = true, children }) => {
	const classes = useStyles();

	return (
		<div className={classes.PageHeader}>
			<TopBar fixedHeight={fixedHeight}>{children}</TopBar>
		</div>
	);
};

const useStyles = makeStyles({
	PageHeader: {
		'borderBottom': '1px solid lightgrey',
		'& .MuiAppBar-root': {
			justifyContent: 'center',
			height: '100%',
		},
		'& .MuiToolbar-root': {
			justifyContent: 'space-between',
		},
	},
});

interface PageHeaderProps {
	fixedHeight?: boolean;
}

export default PageHeader;
