import { Box, SxProps, Typography } from '@mui/material';

export interface ScreenMessageProps {
	icon?: React.ReactNode;
	header?: string;
	subHeader?: string;
	sx?: SxProps;
}

const styles = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '100%',
};

const ScreenMessage: React.FC<ScreenMessageProps> = ({ icon, header, subHeader, children, sx }) => {
	return (
		<Box sx={Object.assign(styles, sx)}>
			{icon ? icon : null}
			{header ? (
				<Typography variant="h1" align="center" sx={{ mt: '10px', mb: '10px' }}>
					{header}
				</Typography>
			) : null}
			{subHeader ? (
				<Typography variant="h3" align="center" sx={{ mt: '10px', mb: '20px' }}>
					{subHeader}
				</Typography>
			) : null}
			{children}
		</Box>
	);
};

export default ScreenMessage;
