import gql from 'graphql-tag';

export const FileFragment = gql`
	fragment FileFields on UploadedFileType {
		id
		fileName
		fileSize
		fileType
	}
`;

export const KeywordFragment = gql`
	fragment KeywordFields on KeywordType {
		id
		fpmCategory
		name
	}
`;

export const FundingTypeFragment = gql`
	fragment FundingTypeFields on FundingTypeType {
		id
		name
	}
`;

export const UserSubscriptionFragment = gql`
	fragment UserSubscriptionFields on UserSubscriptionType {
		userId
		status
		stripeSubscriptionId
		subscriptionPeriodEnd
	}
`;

export const CurrentUserFragment = gql`
	fragment CurrentUserFields on UserType {
		id
		email
		fullName
		phoneNumber
		externalRoleName
		isMissingRequiredFields
		profileComplete
		remindersSent
		emailVerified
		hasActiveSubscription
		adminRole
		isFirstLogin
		profilePicture {
			url
		}
		stage2Registrants {
			id
			status
			createdAt
			projectTopic {
				id
				name
				project {
					id
					name
					group {
						id
						name
					}
					# deadline
				}
			}
		}
	}
`;

export const UserFragment = gql`
	fragment UserDisplayFields on UserType {
		id
		email
		fullName
		phoneNumber
		externalRoleName
		isMissingRequiredFields
		profileComplete
		remindersSent
	}
`;

export const ProfileFragment = gql`
	fragment ProfileFields on UserType {
		# General
		id
		isMissingRequiredFields
		profileComplete
		userScores {
			score
			scoreDefinitionType
		}
		externalRoleName
		remindersSent
		firstName
		lastName
		fullName
		countryOfCitizenship
		email
		secondaryEmail
		phoneNumber
		workAddress
		emailVerified
		sharedProfile
		userSetting {
			contactInformationPrivacy
			socialMediaPrivacy
			publicationsPrivacy
			uploadedFilesPrivacy
			skillsKeywordsPrivacy
			interestsKeywordsPrivacy
			employmentHistoryPrivacy
			educationPrivacy
			additionalInformationPrivacy

			bulletinNotificationFrequency
			bulletinNotificationMethod
		}
		# Education
		degrees {
			id
			degreeType
			institution
		}
		certifications {
			id
			name
			institution
		}
		awards {
			id
			name
			institution
		}
		additonalCourses
		# Employment History
		independentFreelancer
		notAvailable
		availableHoursPerWeek
		hourlyRate
		employers {
			id
			employerName
			workDetails
			yearsOfExperience
			title
			currentEmployer
			govAcaIndId
			businessTypeId
		}
		# Publications
		publications {
			id
			title
			url
			role
		}
		# Other
		hadSecurityClearance
		conflictsOfInterest
		# Social Media Profiles
		linkedInProfile
		facebookProfile
		twitterHandle
		instagramProfile
		youtubeChannel
		vimeoChannel
		researchGateProfile
		googleScholarProfile
		# Additional Information
		peerReviewInterest
		proposingInterest
		evaluatorInterest
		acceptedPrivacyPolicy
		privacyPolicyAcceptanceDate
		# Keywords
		userKeywords {
			type
			keyword {
				...KeywordFields
			}
		}
		# User Files
		files {
			...FileFields
		}
		profilePicture {
			...FileFields
			url
		}
		userSubscription {
			...UserSubscriptionFields
		}
	}
	${FileFragment}
	${KeywordFragment}
	${UserSubscriptionFragment}
`;

export const CategoryFragment = gql`
	fragment CategoryFields on CategoryType {
		id
		name
		description
	}
`;

export const LibrarySuccessFactorFragment = gql`
	fragment LibrarySuccessFactorFields on LibrarySuccessFactorType {
		id
		name
		definition
		fpmCategory
		createdAt
	}
`;

export const OptionFragment = gql`
	fragment OptionFields on FormFieldValueType {
		id
		name
	}
`;

export const OptionWithParentFragment = gql`
	fragment OptionWithParentFields on FormFieldValueType {
		id
		name
		parents {
			id
		}
	}
`;

export const ResponderTypeFragment = gql`
	fragment ResponderTypeFields on ResponderTypeType {
		id
		name
	}
`;

export const SecurityClassificationFragment = gql`
	fragment SecurityClassificationFields on SecurityClassificationType {
		id
		name
	}
`;

export const Stage2SuccessFactorsFragment = gql`
	fragment Stage2SuccessFactorsFields on Stage2SuccessFactorType {
		id
		name
		definition
		fpmCategory
		weight
		prefix
	}
`;

export const RiskFragment = gql`
	fragment RiskFields on ProjectTopicRiskType {
		id
		libraryRiskId
		locked
		prefix
		libraryRisk {
			name
			definition
			fpmCategory
		}
	}
`;

export const ResponseUserScoreFragment = gql`
	fragment ResponseUserScoreFields on Stage2ResponseUserType {
		id
		stage2ResponseId
		userId
		user {
			fullName
		}
		contactInfoIncludedInFinal
		finalCommentIncludedInFinal
		response {
			displayId
			registrant {
				responderTechnologyName
				registrantGroup {
					name
				}
			}
			topic {
				id
				name
				project {
					id
					name
					selectionProjectInfo {
						id
						draftStatusVal
					}
					group {
						id
						name
					}
				}
				risks {
					...RiskFields
				}
			}
			responseFiles {
				...FileFields
			}
			responderScores {
				id
				stage2SuccessFactorId
				score
			}
		}
		status
		reviewed
		evaluatorScores {
			id
			stage2ResponseUserId
			successFactor {
				...Stage2SuccessFactorsFields
			}
			score
			comment
		}
		finalComment
		includeContactInfo
	}
	${Stage2SuccessFactorsFragment}
	${FileFragment}
	${RiskFragment}
`;

export const Stage2RegistrantFragment = gql`
	fragment Stage2RegistrantFields on Stage2RegistrantType {
		id
		status
		registrantGroupId
		yearsGroupExisting
		capitalization
		contactTitle
		contactUser {
			id
			fullName
			email
			phoneNumber
		}
		projectId
		projectTopicId
		responderTechnologyName
		responderTechnologyDescription
		stage2RegistrantKeywords {
			keyword {
				...OptionFields
			}
		}
		disapprovalReason
	}
	${OptionFragment}
`;

export const SelectionProjectInfoFragment = gql`
	fragment SelectionProjectInfoFields on SelectionProjectInfoType {
		id
		privacy
		type
		privacy
		fundingRange
		fundingStartDate
		fundingEndDate
		fundingTypeId
		technologyApplications
		includeInvestors
		securityClassificationId
		proprietaryVendorSolutionsAllowed
		proprietaryInformationResponsesAllowed
		deadline
		deadlineTimezone
		submissionDeadline
		submissionDeadlineTimezone
		draftStatusVal
		distributionStatement
		requiresProgramManagerApproval
		startTrlRangeId
		finishTrlRangeId
		purpose
		registration
		responseSubmissionRequirements
		downSelectionType
		responderEligibilities {
			responderEligibilityId
		}
		responderTypes {
			responderTypeId
		}
		responderSubTypes {
			responderSubTypeId
		}
		solicitationType
		fundingCategories {
			fundingCategoryId
		}
		contractType
		contractTypeOther
		expectedProjectDuration
		costSharingFundsRequired
		costSharingInfo
		qandAperiod
		qandAendDate
		qandAinstructions
		exportControlInfo
		hasBeenApprovedStatus
		parentProjectId
	}
`;

export const ProjectFragment = gql`
	fragment ProjectFields on ProjectType {
		id
		displayId
		name
		groupId
		group {
			id
			name
			groupType
			createdBy
			status
		}
		description
		type
		status
		modifiedAt
		objectives
		projectKeywords {
			keyword {
				...KeywordFields
			}
		}
		responderFiles {
			...FileFields
			url
			lastUpdated
		}
		internalFiles {
			...FileFields
		}
		projectUsers {
			id
			isPrimaryContact
			projectRole
			user {
				id
				fullName
				email
				phoneNumber
			}
		}
		topics {
			id
			displayId
			name
			summary
			status
		}
		projectPointsOfContact {
			userId
			displayOnAnnouncement
			user {
				id
				fullName
				email
				phoneNumber
			}
		}
		isAdmin
		isMember
		allResponsesFinalized
		allTopicsCompleted
		isTopicTeamLead
		selectionProjectInfo {
			...SelectionProjectInfoFields
		}
	}
	${FileFragment}
	${KeywordFragment}
	${SelectionProjectInfoFragment}
`;

export const SelectionProjectFragment = gql`
	fragment SelectionProjectFields on SelectionProjectType {
		id
		displayId
		name
		groupId
		group {
			id
			name
			groupType
			createdBy
			status
		}
		description
		type
		status
		modifiedAt
		objectives
		projectKeywords {
			keyword {
				...KeywordFields
			}
		}
		responderFiles {
			...FileFields
		}
		internalFiles {
			...FileFields
		}
		projectUsers {
			id
			isPrimaryContact
			projectRole
			user {
				id
				fullName
				email
				phoneNumber
			}
		}
		topics {
			id
			displayId
			name
			summary
			hasSuccessFactorIssues
		}
		projectPointsOfContact {
			userId
			displayOnAnnouncement
			user {
				id
				fullName
				email
				phoneNumber
			}
		}
		isAdmin
		isMember
		allResponsesFinalized
		allTopicsCompleted
		isTopicTeamLead
		isGroupAdmin
		selectionProjectInfo {
			...SelectionProjectInfoFields
		}
	}
	${FileFragment}
	${KeywordFragment}
	${SelectionProjectInfoFragment}
`;

export const ProjectTopicFragment = gql`
	fragment ProjectTopicFields on ProjectTopicType {
		id
		displayId
		name
		summary
		description
		objectives
		fundingRange
		placeOfPerformance
		status
		expectedAwards
		responderFiles {
			...FileFields
		}
		internalFiles {
			...FileFields
		}
		projectTopicUsers {
			id
		}
		projectTopicKeywords {
			keyword {
				...KeywordFields
			}
		}
		project {
			status
			name
			type
			group {
				name
			}
			innovationExplorationProjectInfo {
				id
				privacy
				startTrlRangeId
				finishTrlRangeId
				startTrlRange {
					id
					name
				}
				finishTrlRange {
					id
					name
				}
			}
			selectionProjectInfo {
				id
				type
				privacy
				startTrlRangeId
				finishTrlRangeId
				startTrlRange {
					id
					name
				}
				finishTrlRange {
					id
					name
				}
			}
			responseProjectInfo {
				id
				startTrlRangeId
				finishTrlRangeId
				startTrlRange {
					id
					name
				}
				finishTrlRange {
					id
					name
				}
				response {
					topic {
						projectTopicKeywords {
							keyword {
								...KeywordFields
							}
						}
					}
				}
			}
			developmentProjectInfo {
				id
				privacy
				startTrlRangeId
				finishTrlRangeId
				startTrlRange {
					id
					name
				}
				finishTrlRange {
					id
					name
				}
			}
		}
		isAdmin
		isMember
		pointsOfContact {
			userId
			hideInfo
		}
	}
	${FileFragment}
	${KeywordFragment}
`;

export const MemberFragment = gql`
	fragment MemberFragment on UserType {
		id
		fullName
		email
		availableHoursPerWeek
		currentEmployer {
			employerName
			title
			yearsOfExperience
		}
		hadSecurityClearance
		userScores {
			score
			scoreDefinitionType
		}
	}
`;

export const ResponseRequirementDocumentsFragment = gql`
	fragment ResponseRequirementDocumentsFields on Stage2ResponseFormRequirementType {
		documentTemplates {
			...FileFields
		}
		otherDocuments {
			documentName
			required
		}
		letterTypeIds
		briefingChartRequired
		commercializationPlanRequired
		costInformationVolumeRequired
		coverLetterRequired
		dfarclausesAcknowledgementRequired
		executiveSummaryRequired
		farclausesAcknowledgementRequired
		financialInformationRequired
		highLevelScheduleRequired
		intellectualPropertyDeclarationsRequired
		keyPersonnelDocumentationRequired
		ocideclarationsRequired
		ocimititgationPlanRequired
		organizationAccreditationsRequired
		powerpointPresentationRequired
		programWorkStatementRequired
		projectPlanRequired
		rfitechnicalEvidenceRequired
		deliverablesRequired
		milestonesRequired
		paymentsScheduleRequired
		roughOrderOfMagnitudeCostRequired
		statementOfWorkRequired
		technicalWriteupRequired
		transitionPlanRequired
		videoPresentationRequired
		otherDocumentsRequired
		letterTypesRequired
	}
	${FileFragment}
`;

export const ResponseRequirementFragment = gql`
	fragment ResponseRequirementFields on Stage2ResponseFormRequirementType {
		id
		projectId
		draftStatusVal
		awardeeRangeDisplayed
		contractTypeDisplayed
		costSharingInfoDisplayed
		exportControlInfoDisplayed
		fundingCategoriesDisplayed
		hasQaPeriodDisplayed
		periodOfPerformanceDisplayed
		qaPeriodEndDisplayed
		qaPeriodInfoDisplayed
		solicitationTypeDisplayed
		draftStatusVal
		hostProjectManagerDisplayed
		showHostProjectManagerEmail
		showHostProjectManagerPhoneNumber
		includeBudgetInfo
		includeNumExpectedAwardsInfo
		instructionsForSubmission
		pitchEventVenue
		responderSubTypeDisplayed
		responderSubTypeRequired
		responderTaxIdDisplayed
		responderTaxIdRequired
		responderGroupPublicWebsiteDisplayed
		responderGroupPublicWebsiteRequired
		responderRevenueDisplayed
		responderRevenueRequired
		responderRDcategoriesProposedDisplayed
		responderRDcategoriesProposedRequired
		responderNaicsdisplayed
		responderNaicsrequired
		responderSicdisplayed
		responderSicrequired
		responderDunsdisplayed
		responderDunsrequired
		responderCagecodeDisplayed
		responderCagecodeRequired
		responderSamregistrationDisplayed
		responderSamregistrationRequired
		responderSbaregistrationDisplayed
		responderSbaregistrationRequired
		pitchEventInvestorsRequired
		responderProposedAmountDisplayed
		responderProposedAmountRequired
		...ResponseRequirementDocumentsFields
	}
	${ResponseRequirementDocumentsFragment}
`;

export const ProjectRegistrantFragment = gql`
	fragment ProjectRegistrantFields on Stage2RegistrantType {
		projectTopic {
			name
		}
		registrantGroup {
			groupType
			name
		}
		stage2Response {
			displayId
		}
		id
		status
		responderTechnologyDescription
	}
`;

export const BulletinFragment = gql`
	fragment BulletinFields on BulletinType {
		id
		displayId
		title
		informationRequest
		submissionDate
		userId
		createdAt
		status
		groupId
		projectId
		topicId
		infoTypes
		scopes
		keywords {
			keyword {
				...KeywordFields
			}
		}
		recipientCount
	}
	${KeywordFragment}
`;

export const UserSettingsFragment = gql`
	fragment UserSettingsFields on UserSettingsType {
		contactInformationPrivacy
		socialMediaPrivacy
		publicationsPrivacy
		uploadedFilesPrivacy
		skillsKeywordsPrivacy
		interestsKeywordsPrivacy
		employmentHistoryPrivacy
		educationPrivacy
		additionalInformationPrivacy

		bulletinNotificationFrequency
		bulletinNotificationMethod
	}
`;

export const PocFragment = gql`
	fragment PocFields on UserType {
		id
		fullName
		email
		phoneNumber
	}
`;

export const ResponseRequirementsHostInfoFragment = gql`
	fragment ResponseRequirementsHostInfoFields on ProjectType {
		group {
			id
			name
			groupType
		}
		selectionProjectInfo {
			type
			draftStatusVal
			deadlineTimezone
			submissionDeadline
			proprietaryVendorSolutionsAllowed
			proprietaryInformationResponsesAllowed
			includeInvestors
			startTrlRange {
				name
			}
			finishTrlRange {
				name
			}
		}
	}
`;

export const LibrarySuccessEvidenceFragment = gql`
	fragment LibrarySuccessEvidenceFields on LibrarySuccessEvidenceType {
		id
		name
		createdAt
	}
`;
