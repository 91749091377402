const useUrlSearchParams = () => {
	const setUrl = (params) => window.history.replaceState(null, '', `${window.location.pathname}?${params}`);
	return {
		getSearchParam: (key: string) => {
			const searchParams = new URLSearchParams(window.location.search);
			const value = searchParams.get(key);
			return value === null ? null : JSON.parse(value);
		},
		setSearchParam: (key: string, val: any) => {
			const searchParams = new URLSearchParams(window.location.search);
			val === undefined || val === null || val === false || val === '' || (Array.isArray(val) && val.length === 0)
				? searchParams.delete(key)
				: searchParams.set(key, JSON.stringify(val));
			setUrl(searchParams);
		},
		resetSearchParams: () => {
			const searchParams = new URLSearchParams(window.location.search);
			Array.from(searchParams.keys()).forEach((param) => searchParams.delete(param));
		},
		deleteSearchParam: (key) => {
			const searchParams = new URLSearchParams(window.location.search);
			searchParams.delete(key);
			setUrl(searchParams);
		},
	};
};

export default useUrlSearchParams;
