import { Alert, AlertTitle, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FpmCategory } from '../../../../apollo/generated/types/globalTypes';
import { UserProfileFormData } from '../../../../pages/Profile/ProfilePage';
import { FormMode } from '../../../../utils/Enums';
import KeywordsInput from '../../../FormComponents/KeywordsInput';
import FormDivider from '../../FormDivider';
import FormSection from '../../FormSection';

// helper function to concat two arrays without duplicates
const union = (array1: number[], array2: number[]): number[] => {
	const result = [...array1];
	array2.forEach((x) => {
		if (!result.includes(x)) result.push(x);
	});
	return result;
};

const KeywordsForm: React.FC<KeywordsFormProps> = ({ mode }) => {
	const readOnly = mode === FormMode.View;

	const { getValues, setValue, control } = useFormContext<UserProfileFormData>();
	const copyKeywords = () => {
		const values = getValues([
			'skillsProductTechnologyKeywords',
			'interestsProductTechnologyKeywords',
			'skillsTeamStakeholdersKeywords',
			'interestsTeamStakeholdersKeywords',
			'skillsMarketApplicationKeywords',
			'interestsMarketApplicationKeywords',
		]);
		const productTechnology = union(values[0] ?? [], values[1] ?? []);
		const teamStakeholders = union(values[2] ?? [], values[3] ?? []);
		const marketApplication = union(values[4] ?? [], values[5] ?? []);
		setValue('interestsProductTechnologyKeywords', productTechnology);
		setValue('interestsTeamStakeholdersKeywords', teamStakeholders);
		setValue('interestsMarketApplicationKeywords', marketApplication);
	};

	const skillsKeywords = useWatch({
		control,
		name: ['skillsProductTechnologyKeywords', 'skillsTeamStakeholdersKeywords', 'skillsMarketApplicationKeywords'],
	});
	const hasSkillsKeywords =
		(skillsKeywords[0] !== undefined && skillsKeywords[0] !== null && skillsKeywords[0].length > 0) ||
		(skillsKeywords[1] !== undefined && skillsKeywords[1] !== null && skillsKeywords[1].length > 0) ||
		(skillsKeywords[2] !== undefined && skillsKeywords[2] !== null && skillsKeywords[2].length > 0);

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Profile Keywords</Typography>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="info">
						<AlertTitle>Product Technology</AlertTitle>
						(Keywords for the things you want to develop. Example Keywords: Carbon fiber composites, Machine learning software code, Edge computing,
						laser-based sensors)
					</Alert>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="info">
						<AlertTitle>Team & Stakeholders</AlertTitle>
						(Keywords for personnel skills, plans & processes, and financials. Example Keywords: Product Design, Computational Fluid Dynamics,
						Financial Analysis, 6 Sigma Black belt, Manufacturing processes, data analysis)
					</Alert>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="info">
						<AlertTitle>Market Applications</AlertTitle>
						(Keywords for subsystems, systems, and platforms the technology will be integrated into. Example Keywords: Image recognition software,
						personal transportation, drones, guidance systems, aircraft carrier, Solar panels, crypto currency mining)
					</Alert>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h3">Skills Keywords</Typography>
				</Grid>
				<Grid item xs={6}>
					<KeywordsInput
						name="skillsProductTechnologyKeywords"
						label="Product Technology"
						readOnly={readOnly}
						fpmCategory={FpmCategory.PRODUCT_TECHNOLOGY}
					/>
				</Grid>
				<Grid item xs={6}>
					<KeywordsInput
						name="skillsTeamStakeholdersKeywords"
						label="Team & Stakeholders"
						readOnly={readOnly}
						fpmCategory={FpmCategory.ORGANIZATION}
					/>
				</Grid>
				<Grid item xs={6}>
					<KeywordsInput
						name="skillsMarketApplicationKeywords"
						label="Market Application"
						readOnly={readOnly}
						fpmCategory={FpmCategory.APPLICATION}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h3">Interests Keywords</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button variant="outlined" disabled={!hasSkillsKeywords} onClick={copyKeywords}>
						Copy From Skills Keywords
					</Button>
				</Grid>
				<Grid item xs={6}>
					<KeywordsInput
						name="interestsProductTechnologyKeywords"
						label="Product Technology"
						readOnly={readOnly}
						fpmCategory={FpmCategory.PRODUCT_TECHNOLOGY}
					/>
				</Grid>
				<Grid item xs={6}>
					<KeywordsInput
						name="interestsTeamStakeholdersKeywords"
						label="Team & Stakeholders"
						readOnly={readOnly}
						fpmCategory={FpmCategory.ORGANIZATION}
					/>
				</Grid>
				<Grid item xs={6}>
					<KeywordsInput
						name="interestsMarketApplicationKeywords"
						label="Market Application"
						readOnly={readOnly}
						fpmCategory={FpmCategory.APPLICATION}
					/>
				</Grid>
			</FormSection>
		</>
	);
};

export interface KeywordsFormProps {
	mode: FormMode;
}

export default KeywordsForm;
