import React, { useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import makeStyles from '@mui/styles/makeStyles';
import { useFormContext } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UserProfileFormData } from '../../../../pages/Profile/ProfilePage';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		marginBottom: '15px',
	},
	removeSection: {
		width: '50px',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	headerIcon: {
		height: '100%',
	},
	space: {
		margin: '0 10px',
	},
	accordion: {
		flex: 'auto',
	},
});

const DegreeItem: React.FC<DegreeItemProps> = ({ index, readOnly, remove, id, degreeType, institution }) => {
	const classes = useStyles();
	const {
		register,
		formState: { errors },
	} = useFormContext<UserProfileFormData>();
	const ref = useRef<HTMLDivElement>(null);

	const hasError = (field: string): boolean => {
		return !!errors && !!errors.degrees && !!errors.degrees[index] && !!errors.degrees[index]?.[field];
	};

	const getErrorMessage = (field: string): string => {
		return hasError(field) ? errors.degrees?.[index]?.[field]?.message : '';
	};

	const fieldNamePrefix: `degrees.${number}` = `degrees.${index}`;

	return (
		<div className={classes.root}>
			<Accordion className={classes.accordion}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} ref={ref}>
					<div className={classes.header}>
						<Grid item xs={12}>
							<TextField
								variant="standard"
								name={`${fieldNamePrefix}.degreeType`}
								label="Type of Degree"
								inputProps={{
									...register(`${fieldNamePrefix}.degreeType`, {
										required: 'Type of Degree is required.',
									}),
									maxLength: 100,
								}}
								defaultValue={degreeType}
								error={hasError('degreeType')}
								helperText={getErrorMessage('degreeType')}
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									readOnly: readOnly,
								}}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}}
							/>
						</Grid>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{id ? <input {...register(`${fieldNamePrefix}.id`)} type="hidden" defaultValue={id} /> : null}
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="standard"
								name={`${fieldNamePrefix}.institution`}
								label="Name of Institution"
								inputProps={{ ...register(`${fieldNamePrefix}.institution`), maxLength: 100 }}
								defaultValue={institution}
								error={hasError('institution')}
								helperText={getErrorMessage('institution')}
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									readOnly: readOnly,
								}}
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
			{!readOnly && (
				<Tooltip title="Delete this Educational Degree" placement="top">
					<div className={classes.removeSection}>
						<IconButton aria-label="remove" onClick={() => remove(index)} size="large">
							<RemoveCircleOutlineIcon fontSize="inherit" />
						</IconButton>
					</div>
				</Tooltip>
			)}
		</div>
	);
};

export interface DegreeItemProps {
	index: number;
	readOnly: boolean;
	remove: (index: number) => void;
	id?: string | null;
	degreeType: string;
	institution: string;
}

export default DegreeItem;
