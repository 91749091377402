import { FilterList } from '@mui/icons-material';
import { Box, GridSize, IconButton, Stack, SxProps, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import MUIDataTable, {
	CustomHeadLabelRenderOptions,
	Display,
	MUIDataTableColumnDef,
	MUIDataTableColumnOptions,
	MUIDataTableHead,
	MUIDataTableMeta,
	MUIDataTableOptions,
	MUIDataTableState,
} from 'mui-datatables';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import useUrlSearchParams from '../../utils/hooks/useUrlSearchParams';
import CustomTableSearch from '../TableComponents/CustomTableSearch';
import Filter from './Filter';

// constants
const ROWS_PER_PAGE_OPTIONS = [10, 50, 100];

// columns
export interface CDTColumn<TData> {
	customHeadLabelRender?: ((options: CustomHeadLabelRenderOptions) => string | React.ReactNode) | undefined;
	name: string;
	label: string;
	customBodyRender?: (value: any, tableMeta: MUIDataTableMeta) => string | React.ReactNode;
	getCustomBodyRenderLite?: (filteredData: TData[], toggleState: boolean) => (dataIndex: number, rowIndex: number) => string | React.ReactNode;
	sort?: boolean;
	setCellHeaderProps?: MUIDataTableColumnOptions['setCellHeaderProps'];
	setCellProps?: MUIDataTableColumnOptions['setCellProps'];
	options?: MUIDataTableColumnOptions | undefined;
	customSort?: ((order: 'asc' | 'desc') => (obj1: any, obj2: any) => number) | undefined;
	display?: Display;
}

// options
export interface CDTOptions {
	search?: boolean;
	searchPlaceholder?: string;
	getOnCellClick?: (
		filteredData: any,
	) => (colData: any, cellMeta: { colIndex: number; rowIndex: number; dataIndex: number; event: React.MouseEvent }) => void;
	getRenderExpandableRow?: (filteredData: any) => (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => React.ReactNode;
	setTableProps?: () => object;
	tableBodyMaxHeight?: string;
}

// toggle
export interface CDTToggleOptions {
	name: string;
	onText: string;
	offText: string;
	alternateTitle?: string | ReactNode;
	columnVisibilityOverrides?: {
		name: string;
		display: Display;
	}[];
}

// filters
interface FilterItemBase {
	size?: GridSize;
}

interface FilterLabel extends FilterItemBase {
	_typename: 'label';
	text: string;
	variant: Variant | 'label';
}

interface FilterFieldBase<T> extends FilterItemBase {
	_typename: 'field';
	name: Extract<keyof T, string>;
	label?: string;
}

interface CheckboxFilterField<T> extends FilterFieldBase<T> {
	fieldType: 'checkbox';
}

interface AutocompleteFilterField<T> extends FilterFieldBase<T> {
	fieldType: 'autocomplete';
	options: string[];
}

interface TextFilterField<T> extends FilterFieldBase<T> {
	fieldType: 'text';
	isNumber?: boolean;
	max?: number;
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	maxLength?: number;
	regex?: RegExp;
}

interface SelectFilterField<T> extends FilterFieldBase<T> {
	fieldType: 'select';
	options: {
		name: string;
		value: any;
	}[];
}

interface MultiselectFilterField<T> extends FilterFieldBase<T> {
	fieldType: 'multiselect';
	options: {
		name: string;
		value: any;
	}[];
}

interface KeywordsFilterField<T> extends FilterFieldBase<T> {
	fieldType: 'keywords';
}

interface DateFilterField<T> extends FilterFieldBase<T> {
	fieldType: 'date';
}

interface HiddenFilterField<T> extends FilterFieldBase<T> {
	fieldType: 'hidden';
}

type FilterField<T> =
	| CheckboxFilterField<T>
	| AutocompleteFilterField<T>
	| TextFilterField<T>
	| SelectFilterField<T>
	| MultiselectFilterField<T>
	| KeywordsFilterField<T>
	| DateFilterField<T>
	| HiddenFilterField<T>;

export type FilterItem<T> = FilterLabel | FilterField<T>;

// misc
export type PartialType<T> = {
	[key in keyof T]?: any;
};

interface CustomDataTableProps<TData, TFilter> {
	title: string | ReactNode;
	data: TData[];
	columns: CDTColumn<TData>[];
	options?: CDTOptions;
	filterItems?: FilterItem<TFilter>[];
	defaultFilters?: PartialType<TFilter>;
	getFilterFunction?: (searchText: string, filters: PartialType<TFilter>, toggle: boolean) => (data: TData) => boolean;
	toggleOptions?: CDTToggleOptions;
	sx?: SxProps;
	onFilterChange?: (searchText: string, filters: PartialType<TFilter>, toggle: boolean) => void;
	onApplyClicked?: () => void;
	onResetClicked?: () => void;
	customTableHead?: React.ReactNode;
	customTableBody?: React.ReactNode;
	toolbarItems?: React.ReactNode[];
}

export interface FilterTypeBase {
	[key: string]: any;
}

/**
 * Wrapper around MUIDataTable that adds custom filtering.
 * https://github.com/gregnb/mui-datatables
 *
 * @template T
 * @param {CustomDataTableProps<T>} {
 * 	title,
 * 	data,
 * 	columns,
 * 	options,
 * 	filterItems,
 * 	defaultFilters,
 * 	getFilterFunction,
 * 	toggleOptions,
 * }
 * @return {*}
 */
const CustomDataTable = <TData extends object, TFilter extends FilterTypeBase>({
	title,
	data,
	columns,
	options,
	filterItems,
	defaultFilters,
	getFilterFunction,
	toggleOptions,
	sx,
	onFilterChange,
	onApplyClicked,
	onResetClicked,
	customTableHead,
	customTableBody,
	toolbarItems,
}: CustomDataTableProps<TData, TFilter>) => {
	const { getSearchParam, setSearchParam, deleteSearchParam } = useUrlSearchParams();

	// get table state from url
	const [page, setPage] = useState(Number(getSearchParam('page'))); // Number(null) === 0
	const rowsUrlParam = Number(getSearchParam('rows')); // Number(null) === 0
	const [pageSize, setPageSize] = useState(
		// Make sure search param is a valid option, if not default to first option
		ROWS_PER_PAGE_OPTIONS.some((rppo) => rppo === rowsUrlParam) ? rowsUrlParam : ROWS_PER_PAGE_OPTIONS[0],
	);
	const [orderBy, setOrderBy] = useState(getSearchParam('orderBy'));
	const [orderDirection, setOrderDirection] = useState(getSearchParam('orderDirection'));
	const [search, setSearch] = useState(getSearchParam('search'));

	// toggle state
	const startingToggleState: boolean = toggleOptions ? getSearchParam(toggleOptions.name) ?? false : false;
	const [toggle, setToggle] = useState(startingToggleState);
	const handleToggleChange = (_event: React.MouseEvent<HTMLElement>, newValue: boolean | null) => {
		if (newValue !== null) {
			setToggle(newValue);
			resetPage();
			if (toggleOptions) {
				setSearchParam(toggleOptions.name, newValue);
			}
		}
	};

	// get starting filters from url
	const startingFilters: PartialType<TFilter> = {};
	let urlFiltersPresent = false;
	for (const item of filterItems ?? []) {
		if (item._typename === 'field') {
			const param = getSearchParam(item.name);
			if (param !== null) {
				startingFilters[item.name] = param;
				urlFiltersPresent = true;
			}
		}
	}

	// filter state
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [filters, setFilters] = useState<PartialType<TFilter>>(urlFiltersPresent ? startingFilters : defaultFilters ?? {});
	const [filterFunction, setFilterFunction] = useState(() => {
		if (getFilterFunction === undefined) return undefined;
		else return getFilterFunction(search ?? '', filters, toggle);
	});

	// store the previous filter states so we know when to update the filter function
	const [prevSearch, setPrevSearch] = useState(search);
	const [prevFilters, setPrevFilters] = useState(filters);
	const [prevToggle, setPrevToggle] = useState(toggle);

	// check if filters were updated
	if (prevSearch !== search || prevFilters !== filters || prevToggle !== toggle) {
		if (getFilterFunction !== undefined) setFilterFunction(() => getFilterFunction(search ?? '', filters, toggle));
		setPrevSearch(search);
		setPrevFilters(filters);
		setPrevToggle(toggle);
	}

	useEffect(() => {
		if (onFilterChange !== undefined) onFilterChange(search ?? '', filters, toggle);
	}, [search, filters, toggle]);

	// filter the data
	const filteredData = useMemo(() => {
		if (filterFunction === undefined) return data;
		return data.filter(filterFunction);
	}, [data, filterFunction]);

	// reset to the first page
	const resetPage = () => {
		setPage(0);
		deleteSearchParam('page');
	};

	// set filters and reset page and update url params
	const setFiltersUrl = (filters: PartialType<TFilter>) => {
		setFilters(filters);
		resetPage();
		Object.keys(filters).map((key) => {
			setSearchParam(key, filters[key]);
		});
	};

	const muiColumns: MUIDataTableColumnDef[] = columns.map((column) => {
		let display = column.display;
		if (toggle && toggleOptions?.columnVisibilityOverrides) {
			const override = toggleOptions.columnVisibilityOverrides.find((o) => o.name === column.name);
			if (override) display = override.display;
		}
		return {
			name: column.name,
			label: column.label,
			options: {
				customHeadLabelRender: column.customHeadLabelRender,
				customBodyRender: column.customBodyRender,
				customBodyRenderLite: column.getCustomBodyRenderLite !== undefined ? column.getCustomBodyRenderLite(filteredData, toggle) : undefined,
				sort: column.sort ?? true,
				sortCompare: column.customSort,
				display: display ?? 'true',
				setCellHeaderProps: column.setCellHeaderProps,
				setCellProps: column.setCellProps,
				sortThirdClickReset: true,
			},
		};
	});

	const muiOptions: MUIDataTableOptions = {
		search: options?.search ?? true,
		searchPlaceholder: options?.searchPlaceholder,
		onCellClick: options?.getOnCellClick !== undefined ? options.getOnCellClick(filteredData) : undefined,
		expandableRows: options?.getRenderExpandableRow ? true : false,
		expandableRowsHeader: false,
		renderExpandableRow: options?.getRenderExpandableRow !== undefined ? options.getRenderExpandableRow(filteredData) : undefined,
		download: false,
		print: false,
		viewColumns: false,
		filter: false,
		elevation: 0,
		selectableRows: 'none' as const,
		enableNestedDataAccess: '.',
		rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
		customSearch: () => true, // disable search so we can do it manually
		tableBodyMaxHeight: options?.tableBodyMaxHeight ?? '70vh',
		customToolbar: () => (
			<>
				{filterItems && (
					<Tooltip title="Filter">
						<IconButton onClick={() => setFilterIsOpen(true)} size="large">
							<FilterList />
						</IconButton>
					</Tooltip>
				)}
				{toggleOptions && (
					<ToggleButtonGroup value={toggle} exclusive onChange={handleToggleChange} size="small" style={{ marginLeft: '1rem' }}>
						<ToggleButton value={true} style={{ textTransform: 'none' }}>
							{toggleOptions.onText}
						</ToggleButton>
						<ToggleButton value={false} style={{ textTransform: 'none' }}>
							{toggleOptions.offText}
						</ToggleButton>
					</ToggleButtonGroup>
				)}
				{toolbarItems?.map((item, index) => (
					<Box key={index} sx={{ display: 'inline', pl: 1 }}>
						{item}
					</Box>
				))}
			</>
		),
		// put in all of the table states that are handled by the url
		page: page,
		rowsPerPage: pageSize,
		searchText: search,
		sortOrder: {
			name: orderBy,
			direction: orderDirection,
		},
		onTableChange: (action: string, tableState: MUIDataTableState) => {
			switch (action) {
				case 'changePage':
					setSearchParam('page', tableState.page);
					setPage(tableState.page);
					break;
				case 'changeRowsPerPage':
					setSearchParam('rows', tableState.rowsPerPage);
					resetPage();
					setPageSize(tableState.rowsPerPage);
					break;
				case 'search':
					setSearchParam('search', tableState.searchText);
					resetPage();
					setSearch(tableState.searchText);
					break;
				case 'sort':
					resetPage();
					setSearchParam('orderBy', tableState.sortOrder.name);
					setOrderBy(tableState.sortOrder.name);
					setSearchParam('orderDirection', tableState.sortOrder.direction);
					setOrderDirection(tableState.sortOrder.direction);
					break;
				default:
					break;
			}
		},
		customSearchRender: (searchText: string, handleSearch, hideSearch, options) => (
			<CustomTableSearch searchText={searchText} handleSearch={handleSearch} hideSearch={hideSearch} options={options} />
		),
		setTableProps: options?.setTableProps,
	};

	title = typeof title === 'string' ? <Typography variant="h2">{title}</Typography> : title;
	const alternateTitle =
		typeof toggleOptions?.alternateTitle === 'string' ? (
			<Typography variant="h2">{toggleOptions.alternateTitle}</Typography>
		) : (
			toggleOptions?.alternateTitle
		);

	return (
		<Box sx={{ ...styles, ...sx }}>
			<MUIDataTable
				title={toggle && alternateTitle ? alternateTitle : title}
				columns={muiColumns}
				options={muiOptions}
				data={filteredData}
				components={{
					TableHead: customTableHead,
					TableBody: customTableBody,
				}}
			/>
			{filterItems && filterItems.length > 0 && filterIsOpen && (
				<Filter
					filterIsOpen={filterIsOpen}
					setFilterIsOpen={setFilterIsOpen}
					currentFilters={filters}
					setFilters={setFiltersUrl}
					filterItems={filterItems}
					defaultFilters={defaultFilters}
					onApplyClicked={onApplyClicked}
					onResetClicked={onResetClicked}
				/>
			)}
		</Box>
	);
};

const styles = {
	// Apply default MUI Table styles
	'th': {
		fontSize: '0.95rem !important',
		fontWeight: '600 !important',
	},
	'th .MuiButtonBase-root': {
		fontSize: '0.95rem !important',
		fontWeight: '600 !important',
		textAlign: 'left',
	},
	'.MuiToolbar-root': {
		paddingLeft: '16px',
		paddingRight: '16px',
	},
};

export default CustomDataTable;
