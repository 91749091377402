import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ErrorMessage, LoadingMessage, PageWrapper, ProtectedRoute, usePermissions } from '../components';

const DashboardPage = lazy(() => import('./Dashboard/DashboardPage'));
const UserSettingsPage = lazy(() => import('./UserSettings/UserSettingsPage'));
const ProfilePage = lazy(() => import('./Profile/ProfilePage'));
const UsersPage = lazy(() => import('./Users/UsersPage'));
const EditUserPage = lazy(() => import('./Users/EditUserPage'));
const CreateGroupTypePage = lazy(() => import('./Groups/CreateGroupTypePage'));
const CreateGroupPage = lazy(() => import('./Groups/CreateGroupPage'));
const GroupsPage = lazy(() => import('./Groups/GroupsPage'));
const ProjectsPage = lazy(() => import('./Projects/ProjectsPage'));
const TopicsPage = lazy(() => import('./Topics/TopicsPage/TopicsPage'));
const CreateProjectTypePage = lazy(() => import('./Projects/CreateProjectTypePage/CreateProjectTypePage'));
const CreateSelectionProjectPage = lazy(() => import('./Projects/CreateSelectionProjectPage'));
const CreateResponseProjectPage = lazy(() => import('./Projects/ResponseProjects/CreateResponseProjectPage/CreateResponseProjectPage'));
const ImportSelectionProjectPage = lazy(() => import('./Projects/ImportProject/ImportSelectionProjectPage'));
const ResponderRegistrationPage = lazy(() => import('./Projects/Registrants/ResponderRegistrationPage'));
const ResponseFormBuilderPage = lazy(() => import('./Projects/Responses/ResponseFormBuilderPage'));
const GroupDetailsPage = lazy(() => import('./Groups/GroupDetailsPage'));
const SelectionProjectPage = lazy(() => import('./Projects/SelectionProjectPage'));
const DevelopmentProjectPage = lazy(() => import('./Projects/DevelopmentProjects/DevelopmentProjectPage'));
const TopicDetailsPage = lazy(() => import('./Topics/TopicDetailsPage'));
const ResponseFormPage = lazy(() => import('./Projects/Responses/ResponseFormPage/ResponseFormPage'));
const EvaluatorScorePage = lazy(() => import('./Projects/Evaluations/EvaluatorScorePage'));
const TeamLeadScorePage = lazy(() => import('./Projects/Evaluations/TeamLeadScorePage'));
const KnowledgeCenterPage = lazy(() => import('./HelpKnowledgeCenter/HelpKnowledgeCenterPage'));
const BulletinsPage = lazy(() => import('./Bulletins/BulletinsPage'));
const CreateBulletinPage = lazy(() => import('./Bulletins/CreateBulletinPage'));
const EditBulletinPage = lazy(() => import('./Bulletins/EditBulletinPage'));
const CreateInnovationExplorationProjectPage = lazy(() => import('./InnovationExplorationProjects/CreateInnovationExplorationProjectPage'));
const InnovationExplorationProjectPage = lazy(() => import('./InnovationExplorationProjects/InnovationExplorationProjectPage'));
const ProjectRedirectPage = lazy(() => import('./Projects/ProjectRedirect/ProjectRedirectPage'));
const ResponseProjectPage = lazy(() => import('./Projects/ResponseProjects/ResponseProjectPage'));
const LibraryPage = lazy(() => import('./Library/LibraryPage'));
const ReportsPage = lazy(() => import('./Reports/ReportsPage'));
const NewReportDetailsPage = lazy(() => import('./Reports/NewReportDetailsPage'));
const CreateLeadershipReportPage = lazy(() => import('./Reports/CreateLeadershipReportPage'));
const ResponseFormPreviewPage = lazy(() => import('./Projects/Responses/ResponseFormPage/ResponseFormPreviewPage'));
const CreateDevelopmentProjectPage = lazy(() => import('./Projects/DevelopmentProjects/CreateDevelopmentProjectPage'));
const ProjectWorkAgreementTemplatePage = lazy(() => import('./WorkAgreements/ProjectWorkAgreementTemplatePage'));
const TopicWorkAgreementTemplatePage = lazy(() => import('./WorkAgreements/TopicWorkAgreementTemplatePage'));
const ProjectUserWorkAgreementPage = lazy(() => import('./WorkAgreements/ProjectUserWorkAgreementPage'));
const TopicUserWorkAgreementPage = lazy(() => import('./WorkAgreements/TopicUserWorkAgreementPage'));
const GroupSubscriptionsAdminPage = lazy(() => import('./GroupSubscriptionsAdminPage/GroupSubscriptionsAdminPage'));
const ImportDevProjectPage = lazy(() => import('./Projects/ImportProject/ImportDevProjectPage'));
const MyRegistrationsPage = lazy(() => import('./MyRegistrations/MyRegistrationsPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage'));

const PageRoutes = () => {
	const { user, error } = usePermissions();

	return (
		<PageWrapper>
			{error && <ErrorMessage />}
			{!user && !error && <LoadingMessage />}
			{!!user && !error && (
				<Suspense fallback={<LoadingMessage />}>
					<Switch>
						<ProtectedRoute exact path="/" render={() => <DashboardPage />} />
						<ProtectedRoute exact path="/dashboard" render={() => <DashboardPage />} />
						<ProtectedRoute exact path="/settings" render={() => <UserSettingsPage />} />
						<ProtectedRoute exact path="/profile/:id?" render={() => <ProfilePage />} />
						<ProtectedRoute exact path="/profile/:id/edit" render={() => <ProfilePage />} />
						<ProtectedRoute exact path="/users" render={() => <UsersPage />} />
						<ProtectedRoute exact path="/user/:id/edit/" render={() => <EditUserPage />} />
						<ProtectedRoute exact path="/group/create-type" render={() => <CreateGroupTypePage />} />
						<ProtectedRoute exact path="/group/create" render={() => <CreateGroupPage />} />
						<ProtectedRoute exact path="/groups" render={() => <GroupsPage />} />
						<ProtectedRoute exact path="/projects" render={() => <ProjectsPage />} />
						<ProtectedRoute exact path="/topics" render={() => <TopicsPage />} />
						<ProtectedRoute exact path="/selection-project/create" render={() => <CreateSelectionProjectPage />} />
						<ProtectedRoute path="/response-project/create/:responseId" render={() => <CreateResponseProjectPage />} />
						<ProtectedRoute exact path="/development-project/create" render={() => <CreateDevelopmentProjectPage />} />
						<ProtectedRoute exact path="/development-project/import" render={() => <ImportDevProjectPage />} />
						<ProtectedRoute path="/response-project/:id" render={() => <ResponseProjectPage />} />
						<ProtectedRoute exact path="/project/create-type" render={() => <CreateProjectTypePage />} />
						<ProtectedRoute path="/selection-project/import" render={() => <ImportSelectionProjectPage />} />
						<ProtectedRoute exact path="/project/:id/register" render={() => <ResponderRegistrationPage />} />
						<ProtectedRoute exact path="/project/:id/register/:registrantId" render={() => <ResponderRegistrationPage />} />
						<ProtectedRoute exact path="/project/:id/response-builder" render={() => <ResponseFormBuilderPage />} />
						<ProtectedRoute exact path="/project/:id/work-agreement-template" render={() => <ProjectWorkAgreementTemplatePage />} />
						<ProtectedRoute exact path="/project/:projectId/work-agreement/:userId" render={() => <ProjectUserWorkAgreementPage />} />
						<ProtectedRoute path="/group/:id" render={() => <GroupDetailsPage />} />
						<ProtectedRoute path="/selection-project/:id" render={() => <SelectionProjectPage />} />
						<ProtectedRoute path="/development-project/:id" render={() => <DevelopmentProjectPage />} />
						<ProtectedRoute exact path="/topic/:id/work-agreement-template" render={() => <TopicWorkAgreementTemplatePage />} />
						<ProtectedRoute exact path="/topic/:topicId/work-agreement/:userId" render={() => <TopicUserWorkAgreementPage />} />
						<ProtectedRoute path="/topic/:id" render={() => <TopicDetailsPage />} />
						<ProtectedRoute path="/response/:responseId" render={() => <ResponseFormPage />} />
						<ProtectedRoute path="/evaluation/:responseId" render={() => <EvaluatorScorePage />} />
						<ProtectedRoute path="/evaluations/:responseId" render={() => <TeamLeadScorePage />} />
						<ProtectedRoute path="/help" render={() => <KnowledgeCenterPage />} />
						<ProtectedRoute path="/bulletins" render={() => <BulletinsPage />} />
						<ProtectedRoute path="/bulletin/create" render={() => <CreateBulletinPage />} />
						<ProtectedRoute path="/bulletin/:id" render={() => <EditBulletinPage />} />
						<ProtectedRoute exact path="/ie-project/create" render={() => <CreateInnovationExplorationProjectPage />} />
						<ProtectedRoute path="/ie-project/:id" render={() => <InnovationExplorationProjectPage />} />
						<ProtectedRoute path="/project/:id" render={() => <ProjectRedirectPage />} />
						<ProtectedRoute path="/library" render={() => <LibraryPage />} />
						<ProtectedRoute exact path="/reports" render={() => <ReportsPage />} />
						<ProtectedRoute exact path="/new-report-details" render={() => <NewReportDetailsPage />} />
						<ProtectedRoute exact path="/reports/leadership/:groupId" render={() => <CreateLeadershipReportPage />} />
						<ProtectedRoute path="/response-form-preview/:topicId" render={() => <ResponseFormPreviewPage />} />
						<ProtectedRoute path="/group-subscriptions" render={() => <GroupSubscriptionsAdminPage />} />
						<ProtectedRoute exact path="/registrations" render={() => <MyRegistrationsPage />} />
						<Route render={() => <NotFoundPage />} />
					</Switch>
				</Suspense>
			)}
		</PageWrapper>
	);
};
export default PageRoutes;
