import { Delete } from '@mui/icons-material';
import { Avatar, Button, Grid, IconButton, ListItem, SxProps, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '../..';

export enum NotificationType {
	EVENT,
	INVITATION,
}

export type NotificationProps = {
	type: NotificationType;
	primaryText: string | React.ReactElement;
	date: Date;
	avatar?: React.ReactElement;
	primaryAction?: () => void;
	secondaryAction?: () => void;
	primaryActionText?: string;
	secondaryActionText?: string;
	loading?: boolean;
	redirectPath?: string | null;
	acknowledged?: boolean;
	dismiss?: () => void;
};

/**
 * Formats the notifications shown on the dashboard
 *
 * @param {*} { primaryText, secondaryText, avatar, primaryAction, secondaryAction, loading, primaryActionText, secondaryActionText }
 * @return {*}
 */
const Notification: React.FC<NotificationProps> = ({
	type,
	primaryText,
	date,
	avatar,
	primaryAction,
	secondaryAction,
	loading,
	primaryActionText,
	secondaryActionText,
	redirectPath,
	acknowledged = true,
	dismiss,
}) => {
	const history = useHistory();

	const primaryWrapper = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		primaryAction?.();
	};

	const secondaryWrapper = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		secondaryAction?.();
	};

	const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		if (dismiss) dismiss();
	};

	return (
		<ListItem divider sx={{ ...styles, ...(redirectPath && clickable) } as SxProps} onClick={redirectPath ? () => history.push(redirectPath) : undefined}>
			<Grid container spacing={2}>
				<Grid item>{avatar ? avatar : <Avatar />}</Grid>
				<Grid item xs className="content">
					<Typography variant="body1">{primaryText}</Typography>
					{(primaryAction || secondaryAction) && (
						<div className="actions">
							<LoadingButton onClick={primaryWrapper} pending={loading} variant="contained" color="primary">
								{primaryActionText}
							</LoadingButton>
							<Button onClick={secondaryWrapper} variant="outlined">
								{secondaryActionText}
							</Button>
						</div>
					)}
					<Typography variant="subtitle1">{`${formatDistanceToNow(date)} ago`}</Typography>
				</Grid>
				{!acknowledged && (
					<Grid item>
						<div className="unreadIconContainer">
							<Typography color="primary" className="unreadIcon">
								•
							</Typography>
						</div>
					</Grid>
				)}
				{type === NotificationType.EVENT && (
					<Grid onClick={handleDelete} item sx={{ alignSelf: 'center' }}>
						<IconButton onClick={(e) => handleDelete(e)}>
							<Delete />
						</IconButton>
					</Grid>
				)}
			</Grid>
		</ListItem>
	);
};

const styles: SxProps = {
	'paddingTop': '1.5rem',
	'paddingBottom': '1rem',
	'& .MuiGrid-container': { flexWrap: 'nowrap' },
	'flexGrow': 1,
	'.actions': {
		'display': 'flex',
		'alignItems': 'stretch',
		'gap': '8px',
		'& .MuiButton-root': { height: 40 },
	},
	'.content': {
		display: 'flex',
		flexDirection: 'column',
		gap: 1,
		whiteSpace: 'pre-wrap',
	},
	'.unreadIconContainer': {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	'.unreadIcon': {
		fontSize: '20px',
		pointerEvents: 'none',
	},
};

const clickable: SxProps = {
	'cursor': 'pointer',
	'&:hover': { background: '#f7f7f7' },
};

export default Notification;
