import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const WeightingGuidelinesTooltip = () => {
	return (
		<Tooltip
			color="primary"
			title={
				<ul>
					<li>1.0 - 1.9 Rarely needed</li>
					<li>2.0 - 2.9 A minor contribution</li>
					<li>3.0 - 3.9 Good to have</li>
					<li>4.0 - 4.9 Important</li>
					<li>5.0 - Absolutely necessary</li>
				</ul>
			}
			placement="left">
			<InfoIcon fontSize="small" color="primary" />
		</Tooltip>
	);
};

export default WeightingGuidelinesTooltip;
