import { MenuItem } from '@mui/material';
import { forwardRef, ReactFragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { ProjectDraftStatus, ProjectRole } from '../../../apollo/generated/types/globalTypes';
import { useCurrentUserProjectRoles } from '../../../pages/Projects/Projects.graphql';

export interface ProjectMenuOptionsProps {
	status: string | number;
	changeStatus: (status: ProjectDraftStatus) => void;
	canEnableEvaluations?: boolean;
}

const ProjectMenuOptions = forwardRef<ReactFragment, ProjectMenuOptionsProps>(({ status, changeStatus, canEnableEvaluations }, ref) => {
	const { getValues } = useFormContext();
	const requiresProgramManagerApproval = getValues('requiresProgramManagerApproval');
	const { currentUserProjectRoles } = useCurrentUserProjectRoles();

	return (
		<>
			{status === ProjectDraftStatus.DRAFT && (
				<MenuItem ref={ref as any} onClick={() => changeStatus(ProjectDraftStatus.PENDING_APPROVAL)}>
					Submit for Approval
				</MenuItem>
			)}
			{status != ProjectDraftStatus.DRAFT && (
				<MenuItem ref={ref as any} onClick={() => changeStatus(ProjectDraftStatus.DRAFT)}>
					Revert to Draft
				</MenuItem>
			)}
			{status != ProjectDraftStatus.APPROVED && (
				<MenuItem
					ref={ref as any}
					onClick={() => changeStatus(ProjectDraftStatus.APPROVED)}
					disabled={requiresProgramManagerApproval && currentUserProjectRoles?.projectRole !== ProjectRole.PROGRAM_MANAGER}>
					Set as Approved
				</MenuItem>
			)}
			{(status === ProjectDraftStatus.APPROVED || status === ProjectDraftStatus.EVALUATIONS_IN_PROGRESS) && (
				<MenuItem ref={ref as any} onClick={() => changeStatus(ProjectDraftStatus.PENDING_APPROVAL)}>
					Revert to Pending Approval
				</MenuItem>
			)}
			{canEnableEvaluations && status != ProjectDraftStatus.EVALUATIONS_IN_PROGRESS && (
				<MenuItem ref={ref as any} onClick={() => changeStatus(ProjectDraftStatus.EVALUATIONS_IN_PROGRESS)}>
					Enable Evaluations
				</MenuItem>
			)}
		</>
	);
});
export default ProjectMenuOptions;
