import { Card, CardContent, CardHeader, Grid, TextField, Typography, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormCheckbox } from '../..';

interface OtherSectionProps {
	readOnly: boolean;
	stepRef?: React.RefObject<HTMLDivElement>;
}

const OtherSection: React.FC<OtherSectionProps> = ({ readOnly, stepRef }) => {
	const { register, control } = useFormContext();

	return (
		<Card ref={stepRef}>
			<CardHeader
				title={
					<Typography variant="h2">
						Other
						<Tooltip title="Overall comment that could be provided to the Responder">
							<InfoOutlined fontSize="small" color="primary" />
						</Tooltip>
					</Typography>
				}
			/>
			<CardContent>
				<Grid container style={{ padding: '0 16px' }}>
					<Grid item xs={12}>
						<ReactHookFormCheckbox
							name="includeContactInfo"
							label="Make my contact information available for the Responder Review Report"
							control={control}
							readOnly={readOnly}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="standard"
							label="Overall Comment"
							name="finalComment"
							inputProps={{ ...register('finalComment') }}
							fullWidth
							disabled={readOnly}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default OtherSection;
