import { ApolloError } from '@apollo/client';
import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserProfileFormData } from '../../../pages/Profile/ProfilePage';
import { FormMode } from '../../../utils/Enums';
import Stepper from '../../Stepper/Stepper';
import AdditionalDetailsForm from './AdditionalDetailsForm';
import AvailabilityForm from './AvailabilityForm';
import EducationForm from './EducationForm';
import EmploymentForm from './EmploymentForm';
import UserFileUpload from './FileUpload/ProfileFormFileUpload';
import GeneralForm from './GeneralForm';
import KeywordsForm from './KeywordsForm';
import PublicationForm from './PublicationForm';
import RequiredForm from './RequiredForm';
import SocialMediaForm from './SocialMediaForm';

const useStyles = makeStyles({
	setupMessage: {
		padding: '10px',
		borderRadius: '5px',
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
	},
	leftSide: {
		width: '320px',
		paddingLeft: '60px',
		paddingRight: '60px',
	},
	fixed: {
		position: 'fixed',
	},
	rightSide: {
		flex: 1,
		maxWidth: '1000px',
		paddingBottom: '450px',
	},
});

const ProfileForm: React.FC<UserProfileFormProps> = ({ mode, profileComplete, apiError, onSubmit }) => {
	const { handleSubmit } = useFormContext<UserProfileFormData>();
	const classes = useStyles();
	const steps = [
		{
			label: 'Required Information',
			displayStarIcon: true,
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'General Information',
			displayStarIcon: true,
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'Availability',
			displayStarIcon: true,
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'Profile Keywords',
			displayStarIcon: true,
			displayDivider: true,
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'Employment History',
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'Education',
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'Publications',
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'Social Media Profiles',
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'Activities of Interest',
			ref: useRef<HTMLDivElement | null>(null),
		},
		{
			label: 'Uploaded Files',
			ref: useRef<HTMLDivElement | null>(null),
		},
	];

	const checkKeyDown = (e) => {
		if (e.code === 'Enter') e.preventDefault();
	};

	return (
		<form id="user-profile-form" onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
			<div className={classes.root}>
				<div className={classes.leftSide}>
					<Grid container spacing={2}>
						<Grid item xs={12} className={classes.fixed}>
							<Stepper steps={steps} />
						</Grid>
					</Grid>
				</div>
				<div className={classes.rightSide}>
					<Grid container spacing={2}>
						{!profileComplete && (
							<Grid item>
								<Paper className={classes.setupMessage}>
									<em>
										<Typography variant="h2">Profile setup is required.</Typography>
									</em>
								</Paper>
							</Grid>
						)}
						<Grid item xs={12}>
							<Paper ref={steps[0].ref}>
								<RequiredForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[1].ref}>
								<GeneralForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[2].ref}>
								<AvailabilityForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[3].ref}>
								<KeywordsForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[4].ref}>
								<EmploymentForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[5].ref}>
								<EducationForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[6].ref}>
								<PublicationForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[7].ref}>
								<SocialMediaForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[8].ref}>
								<AdditionalDetailsForm mode={mode} />
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper ref={steps[9].ref}>
								<UserFileUpload mode={mode} />
							</Paper>
						</Grid>
						{/* Error Display */}
						{apiError ? (
							<Grid item xs={12}>
								<Alert severity="error">
									<AlertTitle>Error</AlertTitle>
									{apiError?.message}
								</Alert>
							</Grid>
						) : null}
					</Grid>
				</div>
			</div>
		</form>
	);
};

export interface UserProfileFormProps {
	mode: FormMode;
	profileComplete: boolean;
	apiError?: ApolloError | null;
	onSubmit: (data: UserProfileFormData) => void;
}

export default ProfileForm;
