import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthorizedApolloProvider, PermissionProvider } from './components';
import Auth0ProviderWithHistory from './components/Auth0ProviderWithHistory';
import './index.css';
import LoginPage from './pages/LoginPage';
import PageRoutes from './pages/PageRoutes';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme/theme';

ReactDOM.render(
	<Router>
		<Auth0ProviderWithHistory>
			<AuthorizedApolloProvider>
				<PermissionProvider>
					<DndProvider backend={HTML5Backend}>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<SnackbarProvider
									maxSnack={6}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<CssBaseline />
										<Switch>
											<Route exact path="/Login" component={LoginPage} />
											<Route path="/" component={PageRoutes} />
										</Switch>
									</LocalizationProvider>
								</SnackbarProvider>
							</ThemeProvider>
						</StyledEngineProvider>
					</DndProvider>
				</PermissionProvider>
			</AuthorizedApolloProvider>
		</Auth0ProviderWithHistory>
	</Router>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}
