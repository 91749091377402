import { useQuery } from '@apollo/client';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { GetGovIndAca } from '../../../../apollo/generated/types/GetGovIndAca';
import { EmployerInputType } from '../../../../apollo/generated/types/globalTypes';
import { GET_GOV_IND_ACA } from '../../../../apollo/queries';
import { FormMode } from '../../../../utils/Enums';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import FormDivider from '../../FormDivider';
import FormSection from '../../FormSection';
import EmployerItem from './EmployerItem';
import { UserProfileFormData } from '../../../../pages/Profile/ProfilePage';
import YesNoDropdown from '../../../FormComponents/YesNoDropdown';

const EmploymentForm: React.FC<EmploymentFormProps> = ({ mode }) => {
	const {
		control,
		formState: { errors },
	} = useFormContext<UserProfileFormData>();
	const readOnly = mode === FormMode.View;

	const { data: govAcaIndOptionsData } = useQuery<GetGovIndAca>(GET_GOV_IND_ACA);

	const {
		fields: pastEmployers,
		append,
		remove,
	} = useFieldArray({
		control,
		name: 'pastEmployers', // unique name for your Field Array
		keyName: 'key',
	});
	const addPastEmployer = () => {
		if (!readOnly) {
			append({
				employerName: '',
				title: '',
				workDetails: '',
			});
		}
	};

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Employment History</Typography>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				<Grid item xs={6}>
					<YesNoDropdown label="Have you ever had a security clearance?" name="hadSecurityClearance" readOnly={readOnly} fullWidth />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h3">Past Employers</Typography>
				</Grid>
				<Grid item xs={12}>
					{pastEmployers.map((pastEmployer, index) => (
						<EmployerItem
							key={pastEmployer.key}
							index={index}
							readOnly={readOnly}
							remove={remove}
							id={pastEmployer.id}
							employerName={pastEmployer.employerName ?? ''}
							title={pastEmployer.title ?? ''}
							yearsOfExperience={pastEmployer.yearsOfExperience}
							workDetails={pastEmployer.workDetails ?? ''}
							govAcaIndId={pastEmployer?.govAcaIndId}
							govAcaIndOptionsData={govAcaIndOptionsData}
							businessTypeId={pastEmployer?.businessTypeId ?? null}
						/>
					))}
				</Grid>
				{!readOnly ? (
					<Grid item xs={12}>
						<Button startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />} onClick={addPastEmployer}>
							Add Past Employer
						</Button>
					</Grid>
				) : null}
			</FormSection>
		</>
	);
};

export interface EmploymentFormProps {
	mode: FormMode;
}

export default EmploymentForm;
