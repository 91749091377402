import { FpmCategory } from '../../apollo/generated/types/globalTypes';

// FPM Categories
export enum ScoreSectionsType {
	PRODUCT_TECHNOLOGY = 0,
	ORGANIZATION = 1,
	APPLICATION = 2,
}

export const getScoreSectionsTypeDisplayName = (value: ScoreSectionsType): string => {
	switch (value) {
		case ScoreSectionsType.PRODUCT_TECHNOLOGY:
			return 'Product Technology';
		case ScoreSectionsType.ORGANIZATION:
			return 'Team & Stakeholders ';
		case ScoreSectionsType.APPLICATION:
			return 'Market Application';
		default:
			return 'None';
	}
};

export const getFpmCategoryFromScoreSection = (value: ScoreSectionsType): FpmCategory | null => {
	switch (value) {
		case ScoreSectionsType.PRODUCT_TECHNOLOGY:
			return FpmCategory.PRODUCT_TECHNOLOGY;
		case ScoreSectionsType.ORGANIZATION:
			return FpmCategory.ORGANIZATION;
		case ScoreSectionsType.APPLICATION:
			return FpmCategory.APPLICATION;
		default:
			return null;
	}
};
