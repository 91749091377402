import { useState } from 'react';

const useSessionStorage = <T>(key: string, defaultValue: T): [T, (value: T) => void] => {
	const [value, _setValue] = useState<T>(() => {
		try {
			const rawValue = window.sessionStorage.getItem(key);
			if (rawValue !== null) {
				return JSON.parse(rawValue);
			} else {
				return defaultValue;
			}
		} catch {
			return defaultValue;
		}
	});

	const setValue = (newValue: T) => {
		try {
			window.sessionStorage.setItem(key, JSON.stringify(newValue));
		} catch {
			// just incase
		}
		_setValue(newValue);
	};

	return [value, setValue];
};

export default useSessionStorage;
