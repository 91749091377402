import { useMutation, ApolloError } from '@apollo/client';
import { CircularProgress, Box, Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { REGISTER_CHECKOUT_SUCCESS } from '../../apollo/mutations';
import ScreenMessage from './ScreenMessage';
import { RegisterCheckoutSuccess, RegisterCheckoutSuccessVariables } from '../../apollo/generated/types/RegisterCheckoutSuccess';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const CheckoutSuccessMessage: React.FC = () => {
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const history = useHistory();

	const [registerCheckoutSuccess, { loading: registerLoading }] = useMutation<RegisterCheckoutSuccess, RegisterCheckoutSuccessVariables>(
		REGISTER_CHECKOUT_SUCCESS,
	);
	//we will just fire on pageload as this is only used as an interstitial
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		var success = searchParams.get('success') as boolean | null;
		var sessionId = searchParams.get('session_id') as string | null;
		if (!success || !sessionId) {
			setErrorMessage('The checkout was not successful, please try again');
			setShowErrorModal(true);
		} else {
			registerCheckoutSuccess({ variables: { stripeSessionId: sessionId } })
				.then(() => {
					history.push('/');
				})
				.catch((e: ApolloError) => {
					setErrorMessage(e.message);
					setShowErrorModal(true);
				});
		}
	});

	return (
		<Box>
			<ScreenMessage header="Checkout complete; please wait while we set up your subscription.">
				{registerLoading && <CircularProgress size="5rem" sx={{ ml: '0.75rem' }} />}
			</ScreenMessage>
			<Dialog open={showErrorModal}>
				<DialogTitle>Error Registering Subscription</DialogTitle>
				<DialogContent>
					<Alert severity="error">{errorMessage}</Alert>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						onClick={() => {
							history.push('/');
						}}>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default CheckoutSuccessMessage;
