import { Chat, InfoOutlined } from '@mui/icons-material';
import { Box, Collapse, darken, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { GetTeamLeadScoresheetData_stage2Response_topic_successFactors } from '../../../../apollo/generated/types/GetTeamLeadScoresheetData';
import { FundamentalPrimeMeasurement } from '../../../../apollo/generated/types/globalTypes';
import { GetFPMInfo, GetFPMTitle } from '../../../../utils/Enums/Helpers/FpmCategories';
import getEvaluationScoreColor, { EvaluationScoreColor } from '../../../../utils/Enums/Helpers/getEvaluationScoreColor';
import { useTeamLeadScore } from '../../../Providers';
import ScoringGuidelinesTooltip from '../../../Tooltips/ScoringGuidelinesTooltip';
import WeightingGuidelinesTooltip from '../../../Tooltips/WeightingGuidelinesTooltip';

type TeamLeadScoresTableProps = {
	fpm: FundamentalPrimeMeasurement;
	successFactorSet: GetTeamLeadScoresheetData_stage2Response_topic_successFactors[];
	hidden: boolean;
};

const TeamLeadScoresTable = ({ fpm, successFactorSet, hidden }: TeamLeadScoresTableProps) => {
	const successFactors = successFactorSet.filter((sf) => sf.fpmCategory === fpm);
	const { teamLeadEvaluation, response } = useTeamLeadScore();
	const scores = useWatch({
		name: successFactors.map((sf) => `scores[${sf.id}][score]`),
		defaultValue: teamLeadEvaluation?.evaluatorScores?.reduce((scores, score) => {
			scores[`scores[${score.successFactor.id}][score]`] = score.score;
			return scores;
		}, {}) as FieldValues,
	});

	// Calculates the weighted average by iterating through the success factors and getting the updated scores from useWatch.
	// Will be NaN if any of the scores have not yet been filled
	const scoresTotal = successFactors.reduce((total, sf) => total + parseFloat(scores[`scores[${sf.id}][score]`]) * sf.weight, 0);
	const totalWeight = successFactors.reduce((total, sf) => total + parseFloat(sf.weight), 0);
	const weightedAverage = scoresTotal / totalWeight;

	const headerColor = getEvaluationScoreColor(
		weightedAverage,
		successFactors?.[0]?.fpmCategory,
		response?.topic?.project.selectionProjectInfo?.finishTrlRangeCategory,
	);

	const classes = useStyles(headerColor);
	const getIconStyle = () => ({
		color: !!headerColor ? 'white' : EvaluationScoreColor.BLUE,
	});

	return (
		<Box overflow="auto" className={hidden ? classes.hidden : 'undefined'}>
			<Table>
				<TableHead>
					<TableRow className={classes.headerRow}>
						<TableCell colSpan={2} className={`${classes.headerTitleCell} ${classes.titleCell}`}>
							<Typography variant="h4">
								{GetFPMTitle(fpm)}
								<Tooltip title={GetFPMInfo(fpm)}>
									<InfoOutlined fontSize="small" sx={getIconStyle()} />
								</Tooltip>
							</Typography>
						</TableCell>
						<TableCell align="center" colSpan={2} className={classes.headerScoreCell}>
							{!Number.isNaN(weightedAverage) && <Typography variant="h3">{weightedAverage.toFixed(1)}</Typography>}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>9-Hi™ Success Factor</TableCell>
						<TableCell align="center">
							<div className={classes.weightColumnHeader}>
								Weight
								<br />
								(1.0 - 5.0){'     '}
								<WeightingGuidelinesTooltip />
							</div>
						</TableCell>
						<TableCell align="center">
							<div className={classes.teamLeadScoreHeader}>
								<Typography variant="h5">TL Score</Typography>
								<ScoringGuidelinesTooltip />
							</div>
						</TableCell>
						<TableCell align="center">Comment</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{successFactors.map((sf) => sf.id && <TeamLeadScoresTableRow key={sf.id} successFactor={sf} />)}</TableBody>
			</Table>
			<Divider />
			<br />
		</Box>
	);
};

const TeamLeadScoresTableRow = ({ successFactor }: { successFactor: GetTeamLeadScoresheetData_stage2Response_topic_successFactors }) => {
	const { teamLeadEvaluation, isAdmin, isLocked, isTopicClosed, isTopicCompleted } = useTeamLeadScore();
	const { register, setValue } = useFormContext();
	const classes = useStyles();
	const comment = useWatch({
		name: `scores[${successFactor.id}][comment]`,
		defaultValue: teamLeadEvaluation?.evaluatorScores?.find((score) => score.successFactor.id === successFactor.id)?.comment ?? '',
	});

	const [commentRowOpen, setCommentRowOpen] = useState<boolean>(false);

	const readOnly = !isAdmin || isLocked || isTopicClosed || isTopicCompleted;

	const onScoreBlur = (e) => {
		let score = e.target.value;
		if (!score) return;
		if (score < 1) score = 1.0;
		if (score > 9.9) score = 9.9;
		setValue(`scores[${successFactor.id}][score]`, parseFloat(score).toFixed(1));
	};

	return (
		<>
			<TableRow className={classes.row}>
				<TableCell className={classes.titleCell}>{successFactor.name}</TableCell>
				<TableCell align="center">{successFactor.weight?.toFixed(1)}</TableCell>
				<TableCell align="center">
					<TextField
						name={`scores[${successFactor.id}][score]`}
						variant="outlined"
						className={classes.scoreInput}
						type="number"
						inputProps={{
							...register(`scores[${successFactor.id}][score]`, {
								min: {
									value: 1.0,
									message: 'Score must not be below 1.',
								},
								max: {
									value: 9.9,
									message: 'Score must not be above 9.9.',
								},
							}),
							min: 1.0,
							max: 9.9,
							step: 0.1,
						}}
						onBlur={onScoreBlur}
						defaultValue={
							teamLeadEvaluation?.evaluatorScores?.find((score) => score.successFactor.id === successFactor.id)?.score?.toFixed(1) ?? ''
						}
						disabled={readOnly}
					/>
				</TableCell>
				<TableCell align="center" className={classes.commentCell}>
					<IconButton onClick={() => setCommentRowOpen(!commentRowOpen)} size="large">
						<Chat color={comment === '' || !comment ? 'secondary' : 'primary'} />
					</IconButton>
				</TableCell>
			</TableRow>

			<TableRow className={classes.commentRow}>
				<TableCell colSpan={6} className={classes.commentCell} align="center">
					<Collapse in={commentRowOpen}>
						<Box padding={3} paddingTop={0.5}>
							<TextField
								variant="standard"
								name={`scores[${successFactor.id}][comment]`}
								inputProps={{ ...register(`scores[${successFactor.id}][comment]`) }}
								fullWidth
								label="Comment"
								defaultValue={teamLeadEvaluation?.evaluatorScores?.find((score) => score.successFactor.id === successFactor.id)?.comment ?? ''}
								disabled={readOnly}
							/>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const useStyles = (headerColor?: string) =>
	makeStyles((theme) => ({
		titleCell: {
			width: '150px',
		},
		scoreInput: {
			width: '70px',
		},
		teamLeadScoreHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
		},
		headerRow: {
			'& .MuiTableCell-root': {
				borderBottom: 0,
			},
			'background': headerColor,
			'transition': 'background-color 0.5s',
			'& .MuiTypography-root': {
				color: headerColor ? 'white' : undefined,
				transition: 'color 0.5s',
			},
		},
		headerTitleCell: {
			borderTopLeftRadius: '1rem',
		},
		headerScoreCell: {
			borderTopRightRadius: '1rem',
			background: headerColor && darken(headerColor, 0.15),
			transition: 'background-color 0.5s',
		},
		weightColumnHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
		},
		commentCell: {
			padding: 0,
			width: '125px',
		},
		row: {
			'& .MuiTableCell-root': {
				borderBottom: 0,
			},
			'&:nth-of-type(4n+1)': {
				background: theme.palette.secondary.light,
			},
		},
		commentRow: {
			'paddingTop': '1.5rem',
			'&:last-child': {
				'& .MuiTableCell-root': {
					borderBottom: 0,
				},
			},
			'&:nth-of-type(4n+2)': {
				background: theme.palette.secondary.light,
			},
		},
		hidden: {
			display: 'none',
		},
	}))();

export default TeamLeadScoresTable;
