import { useQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetResponseUsers_stage2responseUsers_evaluatorScores } from '../../../apollo/generated/types/GetResponseUsers';
import {
	GetTeamLeadScoresheetData,
	GetTeamLeadScoresheetDataVariables,
	GetTeamLeadScoresheetData_finalEvaluation,
	GetTeamLeadScoresheetData_stage2Response,
	GetTeamLeadScoresheetData_stage2responseUsers,
} from '../../../apollo/generated/types/GetTeamLeadScoresheetData';
import { ProjectTopicDownSelectionStatus, ProjectTopicStatus, ResponseDraftStatus, ResponseStatus } from '../../../apollo/generated/types/globalTypes';
import { GET_TEAM_LEAD_SCORESHEET_DATA } from '../../../apollo/queries';

interface TeamLeadScoreContextProps {
	teamLeadEvaluation?: GetTeamLeadScoresheetData_finalEvaluation;
	evaluations: GetTeamLeadScoresheetData_stage2responseUsers[];
	response?: GetTeamLeadScoresheetData_stage2Response;
	loading: boolean;
	evaluationIndex: number;
	handleNextEvaluation: () => void;
	handlePreviousEvaluation: () => void;
	handleSelectEvaluation: (i: number) => void;
	isAdmin: boolean;
	isLocked: boolean;
	isTopicClosed: boolean;
	isTopicCompleted: boolean;
	evaluationsComplete: boolean;
}

const TeamLeadScoreContext = createContext<TeamLeadScoreContextProps | undefined>(undefined);

const TeamLeadScoreProvider = ({ children }) => {
	const { responseId } = useParams<{ responseId: string }>();
	const [evaluationIndex, setEvaluationIndex] = useState<number>(0);

	const { data, loading: dataLoading } = useQuery<GetTeamLeadScoresheetData, GetTeamLeadScoresheetDataVariables>(GET_TEAM_LEAD_SCORESHEET_DATA, {
		fetchPolicy: 'cache-and-network',
		variables: { responseId },
	});

	const responseUsers = data?.stage2responseUsers ?? [];
	const evaluations = responseUsers.filter((user) => user.status === ResponseStatus.COMPLETE || user.status === ResponseStatus.IN_PROGRESS);
	const evaluationsComplete = responseUsers.every((user) => user.status === ResponseStatus.COMPLETE);
	const successFactors = data?.stage2Response.topic?.successFactors ?? [];

	// Isolate Team Lead
	const teamLeadEvaluation = cloneDeep(data?.finalEvaluation);
	if (teamLeadEvaluation && (!teamLeadEvaluation.evaluatorScores || teamLeadEvaluation.evaluatorScores.length < 1)) {
		teamLeadEvaluation.evaluatorScores = successFactors.map(
			(sf) =>
				({
					successFactor: sf,
				} as GetResponseUsers_stage2responseUsers_evaluatorScores),
		);
	}

	// Evaluation Navigation functions
	const handleNextEvaluation = () => {
		if (evaluationIndex >= evaluations.length - 1) {
			setEvaluationIndex(0);
		} else {
			setEvaluationIndex(evaluationIndex + 1);
		}
	};

	const handlePreviousEvaluation = () => {
		if (evaluationIndex <= 0) {
			setEvaluationIndex(evaluations.length - 1);
		} else {
			setEvaluationIndex(evaluationIndex - 1);
		}
	};

	const handleSelectEvaluation = (index: number) => {
		setEvaluationIndex(index);
	};

	return (
		<TeamLeadScoreContext.Provider
			value={{
				teamLeadEvaluation,
				evaluations,
				response: data?.stage2Response,
				loading: dataLoading,
				evaluationIndex,
				handleNextEvaluation,
				handlePreviousEvaluation,
				handleSelectEvaluation,
				isAdmin: data?.stage2Response.topic?.isAdmin ?? false,
				isLocked: data?.stage2Response.draftStatus === ResponseDraftStatus.FINALIZED,
				isTopicClosed: data?.stage2Response.topic?.status === ProjectTopicStatus.CLOSED,
				isTopicCompleted:
					data?.stage2Response.topic.downSelectionStatus === ProjectTopicDownSelectionStatus.COMPLETED ||
					data?.stage2Response.topic.downSelectionStatus === ProjectTopicDownSelectionStatus.CANCELLED,
				evaluationsComplete,
			}}>
			{children}
		</TeamLeadScoreContext.Provider>
	);
};

const useTeamLeadScore = (): TeamLeadScoreContextProps => {
	const context = useContext(TeamLeadScoreContext);
	if (context === undefined) {
		throw new Error('useTeamLeadScore must be used within a TeamLeadScore');
	}
	return context;
};

export { TeamLeadScoreProvider, useTeamLeadScore };
