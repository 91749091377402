import { useLazyQuery, useQuery } from '@apollo/client';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GetFileDownloadLink, GetFileDownloadLinkVariables } from '../../apollo/generated/types/GetFileDownloadLink';
import { GetUploadedFile, GetUploadedFileVariables } from '../../apollo/generated/types/GetUploadedFile';
import { GET_FILE_DOWNLOAD_LINK, GET_UPLOADED_FILE } from '../../apollo/queries';
import { downloadBlobFromStorage } from '../../utils/file-storage-util';
import ModalDeleteConfirmDialog from './ModalDeleteConfirmDialog';

export interface FileDisplayProps {
	index: number;
	id: number;
	name: string;
	onDelete: (index: number) => void;
	openFile?: boolean;
	readOnly?: boolean;
}

/**
 * Represents files that are in permanent storage. (They have an id)
 *
 * @param {*} { index, id, name, onDelete, openFile = false, readOnly = false }
 * @return {*}
 */
const FileDisplay: React.FC<FileDisplayProps> = ({ index, id, name, onDelete, openFile = false, readOnly = false }) => {
	const { register } = useFormContext();
	const [confirm, setConfirm] = useState(false);

	const { data, loading } = useQuery<GetUploadedFile, GetUploadedFileVariables>(GET_UPLOADED_FILE, {
		fetchPolicy: 'cache-and-network',
		variables: { id },
	});

	const [getFileDownloadLink] = useLazyQuery<GetFileDownloadLink, GetFileDownloadLinkVariables>(GET_FILE_DOWNLOAD_LINK, {
		fetchPolicy: 'cache-and-network',
		variables: { id },
		onCompleted: (result) => result.uploadedFile && downloadBlobFromStorage(result.uploadedFile.fileName, result.uploadedFile.url, openFile),
	});

	const handleConfirm = () => {
		setConfirm(false);
		onDelete(index);
	};

	const download = () => getFileDownloadLink();

	return (
		<ListItem button onClick={download}>
			<input {...register(`${name}[${index}].id`, { valueAsNumber: true })} type="hidden" value={id} />
			<ListItemIcon>{loading ? <CircularProgress /> : <AttachFileIcon />}</ListItemIcon>
			<ListItemText primary={loading ? 'Getting file information...' : data?.uploadedFile.fileName} />
			{!loading && (
				<ListItemSecondaryAction>
					{!readOnly && (
						<IconButton edge="end" aria-label="delete" onClick={() => setConfirm(true)} size="large">
							<DeleteIcon />
						</IconButton>
					)}
					<ModalDeleteConfirmDialog
						open={confirm}
						fileName={data?.uploadedFile.fileName || ''}
						onPrimaryClick={handleConfirm}
						onSecondaryClick={() => setConfirm(false)}
						onClose={() => setConfirm(false)}
					/>
				</ListItemSecondaryAction>
			)}
		</ListItem>
	);
};

export default FileDisplay;
