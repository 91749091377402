import { gql } from '@apollo/client';

export const DISMISS_NOTIFICATION = gql`
	mutation DismissNotification($notificationId: Int!) {
		dismissNotification(notificationId: $notificationId) {
			id
			dismissed
		}
	}
`;

export const GET_USER_NOTIFICATIONS = gql`
	query GetUserNotifications {
		userNotifications {
			id
			notificationType
			redirectPath
			redirectPath
			objectGuidId
			objectIntId
			createdAt
			acknowledged
			dismissed
			message
		}
	}
`;

export const ACKNOWLEDGE_NOTIFICATIONS = gql`
	mutation AcknowledgeNotifications($notificationIds: [Int!]!) {
		acknowledgeNotifications(notificationIds: $notificationIds) {
			id
			acknowledged
		}
	}
`;
