import { Announcement, Assignment, AttachMoney, Help, ListAlt, MenuBook, Notifications as NotificationsIcon, RecordVoiceOver } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Box, Link, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import { HasPermission } from '../index';

interface NavigationProps {
	notificationsOpen: boolean;
	toggleNotificationsOpen: () => void;
	reduce: boolean;
	hasUnseenNoitifications: boolean;
	isSysAdmin: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ notificationsOpen, toggleNotificationsOpen, reduce, hasUnseenNoitifications, isSysAdmin }) => {
	const classes = useStyles();

	return (
		<List className={classes.root}>
			<ListItemButton
				exact
				to="/"
				component={NavLink}
				className={classes.link}
				activeClassName={notificationsOpen ? classes.link : classes.activeLink}
				disabled={reduce}>
				<ListItemIcon className={classes.iconWrapper}>
					<DashboardIcon />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Dashboard" />
			</ListItemButton>

			<ListItemButton
				className={notificationsOpen ? classes.notificationsLinkActive : classes.notificationsLink}
				onClick={toggleNotificationsOpen}
				disabled={reduce}>
				<ListItemIcon className={classes.iconWrapper}>
					<NotificationsIcon />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Notifications" />
				{hasUnseenNoitifications && <Box sx={(theme) => ({ background: theme.palette.error.main, borderRadius: '999px', p: '4px' })} />}
			</ListItemButton>

			<ListItemButton
				to="/groups"
				component={NavLink}
				className={classes.link}
				activeClassName={notificationsOpen ? classes.link : classes.activeLink}
				disabled={reduce}>
				<ListItemIcon className={classes.iconWrapper}>
					<GroupIcon />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Groups" />
			</ListItemButton>

			<ListItemButton
				to="/projects"
				component={NavLink}
				className={classes.link}
				activeClassName={notificationsOpen ? classes.link : classes.activeLink}
				disabled={reduce}>
				<ListItemIcon className={classes.iconWrapper}>
					<AccountTreeIcon />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Projects" />
			</ListItemButton>

			<ListItemButton
				to="/topics"
				component={NavLink}
				className={classes.link}
				activeClassName={notificationsOpen ? classes.link : classes.activeLink}
				disabled={reduce}>
				<ListItemIcon className={classes.iconWrapper}>
					<ListAlt />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Topics" />
			</ListItemButton>

			{isSysAdmin && (
				<HasPermission permission="read:users">
					<ListItemButton
						to="/users"
						component={NavLink}
						className={classes.link}
						activeClassName={notificationsOpen ? classes.link : classes.activeLink}
						disabled={reduce}>
						<ListItemIcon className={classes.iconWrapper}>
							<PermContactCalendarIcon />
						</ListItemIcon>
						<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Users" />
					</ListItemButton>
				</HasPermission>
			)}

			<ListItemButton
				to="/bulletins"
				component={NavLink}
				className={classes.link}
				activeClassName={notificationsOpen ? classes.link : classes.activeLink}
				disabled={reduce}>
				<ListItemIcon className={classes.iconWrapper}>
					<Announcement />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Bulletins" />
			</ListItemButton>

			{isSysAdmin && (
				<ListItemButton
					to="/library"
					component={NavLink}
					className={classes.link}
					activeClassName={notificationsOpen ? classes.link : classes.activeLink}
					disabled={reduce}>
					<ListItemIcon className={classes.iconWrapper}>
						<MenuBook />
					</ListItemIcon>
					<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Library" />
				</ListItemButton>
			)}

			{isSysAdmin && (
				<ListItemButton
					to="/group-subscriptions"
					component={NavLink}
					className={classes.link}
					activeClassName={notificationsOpen ? classes.link : classes.activeLink}
					disabled={reduce}>
					<ListItemIcon className={classes.iconWrapper}>
						<AttachMoney />
					</ListItemIcon>
					<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Group Subscriptions Admin Page" />
				</ListItemButton>
			)}

			<ListItemButton
				to="/reports"
				component={NavLink}
				className={classes.link}
				activeClassName={notificationsOpen ? classes.link : classes.activeLink}
				disabled={reduce}>
				<ListItemIcon className={classes.iconWrapper}>
					<Assignment />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Reports" />
			</ListItemButton>

			<ListItemButton href="/help" target="_blank" className={classes.link} component="a">
				<ListItemIcon className={classes.iconWrapper}>
					<Help />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Help & Knowledge Center" />
			</ListItemButton>

			<ListItemButton component="a" className={classes.link} href="https://9-hi.helpscoutdocs.com/article/408-get-help-provide-feedback" target="_blank">
				<ListItemIcon className={classes.iconWrapper}>
					<RecordVoiceOver />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Communications Center" />
			</ListItemButton>
		</List>
	);
};

const useStyles = makeStyles((theme) => {
	const paddingLeft = '26px';
	return {
		root: {
			'& .MuiButtonBase-root:hover': {
				background: theme.palette.primary.main,
			},
			'& .MuiListItemText-root': {
				'& .MuiTypography-body2': {
					color: 'white',
				},
			},
		},
		iconWrapper: {
			minWidth: '32px',
			color: 'white',
		},
		link: {
			paddingLeft: paddingLeft,
			color: '#FFFFFF',
			letterSpacing: '-0.3px',
		},
		activeLink: {
			'color': '#FFFFFF',
			'background': '#1976D2',
			'&:hover': {
				backgroundColor: '#1F8BF3 !important',
			},
		},
		notificationsLink: {
			paddingLeft: paddingLeft,
			color: '#FFFFFF',
			letterSpacing: '-0.3px',
		},
		notificationsLinkActive: {
			'paddingLeft': paddingLeft,
			'letterSpacing': '-0.3px',
			'color': '#FFFFFF',
			'background': theme.palette.primary.main,
			'&:hover': {
				backgroundColor: '#1F8BF3 !important',
			},
		},
	};
});

export default Navigation;
