import { Controller, RegisterOptions } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { ReactElement } from 'react';

export interface ReactHookFormCheckboxProps {
	label?: string | ReactElement;
	name: string;
	control?: any;
	defaultValue?: boolean;
	readOnly?: boolean;
	error?: boolean;
	helperText?: string;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	hidden?: boolean;
	customOnChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const ReactHookFormCheckbox: React.FC<ReactHookFormCheckboxProps> = ({
	name,
	label,
	control,
	defaultValue,
	readOnly,
	error,
	helperText,
	rules,
	hidden,
	customOnChange,
}) => {
	const helperTextId = name + '-helper';
	return (
		<FormControl variant="standard">
			<Controller
				render={({ field: { onChange, onBlur, value, name, ref } }) => (
					<>
						{label ? (
							<FormControlLabel
								name={name}
								label={label}
								labelPlacement="end"
								onBlur={onBlur}
								className={error ? 'Mui-error' : ''}
								control={
									<Checkbox
										color="primary"
										disabled={readOnly}
										onChange={(e, checked) => {
											customOnChange?.(e, checked);
											onChange(e.target.checked);
										}}
										aria-describedby={helperTextId}
									/>
								}
								checked={value ?? false}
								hidden={hidden ?? false}
								inputRef={ref}
							/>
						) : (
							<Checkbox
								color="primary"
								name={name}
								inputRef={ref}
								disabled={readOnly}
								checked={value ?? false}
								hidden={hidden ?? false}
								onBlur={onBlur}
								onChange={(e) => onChange(e.target.checked)}
								aria-describedby={helperTextId}
							/>
						)}
					</>
				)}
				rules={rules}
				name={name}
				control={control}
				defaultValue={defaultValue ?? false}
			/>

			{error ? (
				<FormHelperText id={helperTextId} className="Mui-error">
					{helperText}
				</FormHelperText>
			) : null}
		</FormControl>
	);
};

export default ReactHookFormCheckbox;
