import AttachFileIcon from '@mui/icons-material/AttachFile';
import { CircularProgress, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';

type UploadingFileDisplayProps = { uploading: boolean; fileName: string };

/**
 * Displays files that are uploading or preparing to be uploaded.
 * @param {UploadingFileDisplayProps} { uploading, preparingToUpload, fileName }
 * @return {*}
 */
const UploadingFileDisplay = ({ uploading, fileName }: UploadingFileDisplayProps) => {
	return (
		<ListItem>
			<ListItemIcon>
				<AttachFileIcon sx={{ color: 'text.disabled' }} />
			</ListItemIcon>
			<ListItemText primary={fileName} primaryTypographyProps={{ sx: { color: 'text.disabled' } }} />
			<ListItemSecondaryAction>{uploading && <CircularProgress size={20} />}</ListItemSecondaryAction>
		</ListItem>
	);
};

export default UploadingFileDisplay;
