import { Grid, GridProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const PageBodyColumn: React.FC<GridProps> = (props) => {
	const classes = useStyles();
	return <Grid item className={classes.pageBodyColumn} {...props} />;
};

const useStyles = makeStyles({
	pageBodyColumn: {
		padding: '16px',
		overflowY: 'auto',
		height: '100%',
	},
});

export default PageBodyColumn;
