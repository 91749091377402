import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import React from 'react';

const LoginPage: React.FC = () => {
	const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

	if (isLoading) {
		return <div />;
	}

	if (!isAuthenticated) {
		return (
			<div>
				<h1>Login</h1>
				<button onClick={() => loginWithRedirect()}>Log In</button>
			</div>
		);
	}
	return (
		<Redirect
			to={{
				pathname: '/',
			}}
		/>
	);
};

export default LoginPage;
