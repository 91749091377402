import { ApolloError, useMutation } from '@apollo/client';
import { Breadcrumbs, Button, Grid, IconButton, MenuItem, Select, Toolbar, Typography, Box, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack, ArrowForward, NavigateNext, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { LoadingButton, PageBody, PageHeader } from '../..';
import { GetEvaluatorScoreSheetData_stage2Response } from '../../../apollo/generated/types/GetEvaluatorScoreSheetData';
import {
	FundamentalPrimeMeasurement,
	ProjectDraftStatus,
	ProjectTopicDownSelectionStatus,
	ProjectTopicStatus,
	ProjectTypeE,
	ResponseDraftStatus,
	ResponseStatus,
	Stage2EvaluatorScoreInputType,
	TrlRangeCategory,
} from '../../../apollo/generated/types/globalTypes';
import { UpdateEvaluatorScores, UpdateEvaluatorScoresVariables } from '../../../apollo/generated/types/UpdateEvaluatorScores';
import { UPDATE_EVALUATOR_SCORES } from '../../../apollo/mutations';
import Comment from '../../Comment';
import { UnsavedChangesDialog } from '../../Dialogs';
import PageBodyColumn from '../../PageWrapper/PageBodyColumn';
import Stepper from '../../Stepper/Stepper';
import EvaluatorScoresTables from './EvaluatorScoresTables';
import OtherSection from './OtherSection';
import ScoringGuidance from '../../../components/ScoringGuidance';

interface EvaluatorScoreFormProps {
	userId: string;
	response: GetEvaluatorScoreSheetData_stage2Response;
	evaluationIdToScores: { [key: number]: { [key: string]: Stage2EvaluatorScoreInputType } };
	categoryToEvaluationsMinMax: { [key in FundamentalPrimeMeasurement]?: { [key: string]: { min: number | null; max: number | null } } };
	categoryToResponderScores: { [key in FundamentalPrimeMeasurement]?: { [key: string]: number | null } };
}

interface ScoreFormData {
	scores: { [key: string]: Stage2EvaluatorScoreInputType };
	finalComment: string;
	includeContactInfo: boolean;
}

/**
 * Form for submitting evaluations and viewing other user's evaluations
 *
 * @param {EvaluatorScoreFormProps} { userId, response, evaluationIdToScores, categoryToEvaluationsMinMax, categoryToResponderScores }
 * @return {*}
 */
const EvaluatorScoreForm = ({ userId, response, evaluationIdToScores, categoryToEvaluationsMinMax, categoryToResponderScores }: EvaluatorScoreFormProps) => {
	const classes = useStyles();
	const history = useHistory();
	const { responseId } = useParams<{ responseId: string }>();
	const { enqueueSnackbar } = useSnackbar();

	// Keeps track of which index in the evaluations array we are currently viewing
	const [selectedEvaluationIndex, setSelectedEvaluationIndex] = useState<number>(0);

	//used for showing/hiding the scoring insert, since we want it to replace the commenting sidebar
	const [showScoringInsert, setShowScoringInsert] = useState<boolean>(false);

	// Current user's evaluation will always be at index 0
	const evaluations = [...response.assignedEvaluators].sort((a) => (a.userId === userId ? -1 : 1));
	const myEvaluation = evaluations[0];

	const generateDefaultValues: (evaluationIndex: number) => ScoreFormData = (evaluationIndex: number) => ({
		scores: evaluationIdToScores[evaluations[evaluationIndex].id],
		finalComment: evaluations[selectedEvaluationIndex]?.finalComment ?? '',
		includeContactInfo: evaluations[selectedEvaluationIndex]?.includeContactInfo ?? false,
	});

	const initialDefaultValues = generateDefaultValues(0);
	const methods = useForm<ScoreFormData>({ defaultValues: initialDefaultValues });

	// Since the form unmounts when we load a new user's scores, save the current user's form in state
	const [myEvaluationLocalSavedFormValues, setMyEvaluationLocalSavedFormValues] = useState<ScoreFormData>(initialDefaultValues);

	const handleSetSelectedEvaluationIndex = (index: number) => {
		if (selectedEvaluationIndex === 0) setMyEvaluationLocalSavedFormValues(methods.getValues());
		if (evaluations.length > 1)
			methods.reset(index === 0 ? myEvaluationLocalSavedFormValues : generateDefaultValues(index), {
				keepErrors: true,
				keepDirtyValues: true,
				keepDirty: true,
			});
		setSelectedEvaluationIndex(index);
	};

	const [updateScores, { loading }] = useMutation<UpdateEvaluatorScores, UpdateEvaluatorScoresVariables>(UPDATE_EVALUATOR_SCORES, {
		onError: (e: ApolloError) => {
			console.log(e);
			enqueueSnackbar('Error updating evaluation.', { variant: 'error' });
		},
		onCompleted: (data) => {
			methods.reset(methods.getValues());
			enqueueSnackbar(`Evaluation ${data.updateStage2EvaluatorScores.status === ResponseStatus.COMPLETE ? 'completed' : 'updated'} successfully`, {
				variant: 'success',
			});
			if (data.updateStage2EvaluatorScores.status === ResponseStatus.COMPLETE) {
				history.push(`/topic/${response.topic?.id}/responses`);
				return;
			}
		},
	});

	const onSubmit = (data: ScoreFormData, status: ResponseStatus) => {
		// React Hook Form sets disabled fields to undefined, so we can't use the incoming data argument here if we are changing the form status
		// back to IN_PROGRESS after clicking "Change Evaluation" because the scores, finalComment, and includeContactInfo fields will all be undefined.
		const scores =
			myEvaluation.status === ResponseStatus.COMPLETE
				? Object.entries(initialDefaultValues.scores).map((scoreInput) => ({
						...initialDefaultValues.scores[scoreInput[0]],
				  }))
				: Object.entries(data.scores).map((scoreInput) => ({
						...initialDefaultValues.scores[scoreInput[0]],
						score: scoreInput[1].score === '' ? null : scoreInput[1].score,
						comment: scoreInput[1].comment,
				  }));

		if (status === ResponseStatus.COMPLETE && Object.values(data.scores).some((scoreInput) => scoreInput.score === '')) {
			enqueueSnackbar('All scores must be entered in order to complete the evaluation.', { variant: 'error' });
			return;
		}

		updateScores({
			variables: {
				responseUser: {
					responseUserId: myEvaluation.id,
					statusId: status,
					finalComment: myEvaluation.status === ResponseStatus.COMPLETE ? initialDefaultValues.finalComment : data.finalComment,
					includeContactInfo: myEvaluation.status === ResponseStatus.COMPLETE ? initialDefaultValues.includeContactInfo : data.includeContactInfo,
					stage2EvaluatorScores: scores,
				},
			},
		});
	};

	// Evaluator Score page is read-only when the evaluation is Completed/Locked, or when the Project status is not "Evaluations In Progress"
	const buttonDisabled =
		evaluations[selectedEvaluationIndex].userId !== userId ||
		response.topic.status === ProjectTopicStatus.CLOSED ||
		response.topic.downSelectionStatus === ProjectTopicDownSelectionStatus.COMPLETED ||
		response.topic.downSelectionStatus === ProjectTopicDownSelectionStatus.CANCELLED ||
		!(
			response.topic.project.type === ProjectTypeE.RESPONSE ||
			response.topic.project.selectionProjectInfo?.draftStatusVal === ProjectDraftStatus.EVALUATIONS_IN_PROGRESS
		);

	const readOnly = buttonDisabled || evaluations[selectedEvaluationIndex].status === ResponseStatus.COMPLETE;

	const steps = [
		{ label: 'Product Technology', ref: useRef(null) },
		{ label: 'Team & Stakeholders', ref: useRef(null) },
		{ label: 'Market Application', ref: useRef(null) },
		{ label: 'Other', ref: useRef(null) },
	];

	const startTrlRange =
		response.topic.project.type === ProjectTypeE.RESPONSE
			? response.topic.project.responseProjectInfo?.startTrlRangeCategory
			: response.topic.project.selectionProjectInfo?.startTrlRangeCategory;
	const finishTrlRange =
		response.topic.project.type === ProjectTypeE.RESPONSE
			? response.topic.project.responseProjectInfo?.finishTrlRangeCategory
			: response.topic.project.selectionProjectInfo?.finishTrlRangeCategory;

	//check visability of Scoring Guidance & Information tabs
	var showOneToThree = startTrlRange == TrlRangeCategory.ONE_TO_THREE || finishTrlRange == TrlRangeCategory.ONE_TO_THREE;
	var showFourToSix = startTrlRange == TrlRangeCategory.FOUR_TO_SIX || finishTrlRange == TrlRangeCategory.FOUR_TO_SIX;
	var showSevenToNine = startTrlRange == TrlRangeCategory.SEVEN_TO_NINE || finishTrlRange == TrlRangeCategory.SEVEN_TO_NINE;

	showFourToSix = showFourToSix || (showOneToThree && showSevenToNine);

	return (
		<>
			<PageHeader>
				<Toolbar className={classes.formHeader}>
					<div className={classes.breadcrumbs}>
						<IconButton component={NavLink} to={`/topic/${response.topic?.id}/responses`} disabled={loading} size="large">
							<ArrowBackIcon />
						</IconButton>
						<div>
							<Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
								<Link to={`/group/${response.topic?.project?.group?.id}/edit`}>
									<Typography variant="h4" color="primary">
										{response.topic?.project?.group?.name}
									</Typography>
								</Link>
								<Link to={`/project/${response.topic?.project?.id}/edit`}>
									<Typography variant="h4" color="primary">
										{response.topic?.project?.name}
									</Typography>
								</Link>
								<Link to={`/topic/${response.topic?.id}/edit`}>
									<Typography variant="h4" color="primary">
										{response.topic?.name}
									</Typography>
								</Link>
							</Breadcrumbs>
							<Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
								<Typography variant="h4" color="primary">
									{response.registrant?.registrantGroup?.name}
								</Typography>
								<Typography variant="h4" color="primary">
									{response.registrant?.responderTechnologyName ?? 'Untitled'}
								</Typography>
								<Typography variant="h4" color="primary">
									{response.displayId}
								</Typography>
							</Breadcrumbs>
						</div>
					</div>
					<div>
						<Select
							value={selectedEvaluationIndex}
							variant="outlined"
							onChange={(e) => handleSetSelectedEvaluationIndex(e.target.value as number)}
							disabled={loading}>
							{evaluations.map((evaluation, i) => (
								<MenuItem value={i} key={evaluation.id}>
									{evaluation.userId === userId ? 'My Evaluation' : evaluation.user.fullName}
								</MenuItem>
							))}
						</Select>
						<IconButton
							disabled={loading}
							onClick={() =>
								handleSetSelectedEvaluationIndex(selectedEvaluationIndex - 1 < 0 ? evaluations.length - 1 : selectedEvaluationIndex - 1)
							}
							size="large">
							<ArrowBack />
						</IconButton>
						<IconButton
							disabled={loading}
							onClick={() =>
								handleSetSelectedEvaluationIndex(selectedEvaluationIndex + 1 > evaluations.length - 1 ? 0 : selectedEvaluationIndex + 1)
							}
							size="large">
							<ArrowForward />
						</IconButton>
					</div>
				</Toolbar>
			</PageHeader>
			<PageBody columns>
				<PageBodyColumn xs={9}>
					<FormProvider {...methods}>
						<Grid container spacing={3} className={classes.container}>
							<Grid container item xs={3} justifyContent="center" className={classes.stickyContainer}>
								<Grid container item xs={12} spacing={1}>
									<Grid item xs={12}>
										<LoadingButton
											fullWidth
											variant="contained"
											disabled={readOnly || response.draftStatus === ResponseDraftStatus.FINALIZED}
											pending={loading}
											onClick={methods.handleSubmit((data) => onSubmit(data, ResponseStatus.IN_PROGRESS))}>
											Save Progress
										</LoadingButton>
									</Grid>
									<Grid item xs={12}>
										<LoadingButton
											fullWidth
											onClick={methods.handleSubmit((data) =>
												onSubmit(
													data,
													myEvaluation?.status === ResponseStatus.COMPLETE ? ResponseStatus.IN_PROGRESS : ResponseStatus.COMPLETE,
												),
											)}
											variant="contained"
											pending={loading}
											disabled={buttonDisabled || response.draftStatus === ResponseDraftStatus.FINALIZED}>
											{myEvaluation?.status === ResponseStatus.COMPLETE ? 'Change Evaluation' : 'Complete Evaluation'}
										</LoadingButton>
									</Grid>
									<Grid item xs={12}>
										<Button
											component={NavLink}
											to={`/response/${responseId}/required-documents`}
											fullWidth
											style={{ textAlign: 'center' }}
											disabled={loading}>
											Responder's Documents
										</Button>
									</Grid>
								</Grid>
								<Grid item xs={12} style={{ marginTop: '1rem' }}>
									<Stepper steps={steps} />
								</Grid>
							</Grid>
							<Grid item xs={9}>
								<form>
									<Grid container spacing={2}>
										{response.draftStatus === ResponseDraftStatus.FINALIZED && (
											<Grid item xs={12}>
												<Alert severity="warning">
													This response is currently locked. Please contact an admin or Team Lead for more information.
												</Alert>
											</Grid>
										)}

										<Grid item xs={12}>
											<Alert severity="info">
												Evaluators are encouraged to enter in their own scores but to collaborate with other evaluators to arrive at a
												similar viewpoint. Should consensus not occur among evaluators, the Team Lead will make any final
												determinations. Evaluators should make comments whenever their score is significantly different than the
												Responder score.
											</Alert>
										</Grid>

										<EvaluatorScoresTables
											response={response}
											steps={steps}
											categoryToResponderScores={categoryToResponderScores}
											categoryToEvaluationsMinMax={categoryToEvaluationsMinMax}
											readOnly={readOnly}
										/>

										<Grid item xs={12}>
											<OtherSection readOnly={readOnly} stepRef={steps[3].ref} />
										</Grid>
									</Grid>

									<UnsavedChangesDialog />
								</form>
							</Grid>
						</Grid>
					</FormProvider>
				</PageBodyColumn>
				{!showScoringInsert && (
					<PageBodyColumn xs={3}>
						<Grid container sx={{ minHeight: '100%', backgroundColor: 'background.paper' }}>
							<Grid item xs>
								<Comment heading="Collaboration" readonly={readOnly} />
							</Grid>
							<Grid item sx={{ backgroundColor: '#eee' }}>
								<Box>
									<Box sx={{ position: 'sticky', top: '-16px' }}>
										<IconButton
											sx={{ borderRadius: 0 }}
											onClick={() => {
												setShowScoringInsert(true);
											}}>
											<KeyboardDoubleArrowLeft />
										</IconButton>
										<Typography
											variant="h3"
											noWrap
											sx={{
												transform: 'rotate(90deg)',
												transformOrigin: 'bottom left',
												position: 'absolute',
												top: '25px',
												left: '7px',
											}}>
											Scoring Guidance & Information
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</PageBodyColumn>
				)}
				{showScoringInsert && (
					<PageBodyColumn xs>
						<ScoringGuidance
							showOneToThree={showOneToThree}
							showFourToSix={showFourToSix}
							showSevenToNine={showSevenToNine}
							defaultState={true}
							hideToggleReplacementFunction={setShowScoringInsert}
						/>
					</PageBodyColumn>
				)}
			</PageBody>
		</>
	);
};

const useStyles = makeStyles(() => ({
	formHeader: {
		padding: '15px',
	},
	breadcrumbs: {
		display: 'flex',
		gap: '0.5rem',
	},
	container: {
		paddingBottom: '450px',
	},
	stickyContainer: {
		position: 'sticky',
		top: 0,
		height: 'min-content',
		paddingTop: '0 !important',
	},
	actionButtons: {
		display: 'flex',
		flexDirection: 'column',
	},
	sidebar: {
		backgroundColor: '#eee',
	},
}));

export default EvaluatorScoreForm;
