import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

export type ReactHookFormCurrencyProps = {
	name: string;
	control?: Control<any>;
	readOnly?: boolean;
	helperText?: string;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	allowDecimals?: boolean;
	allowNegative?: boolean;
	defaultValue?: string | null;
	maxLength?: number;
	maxValue?: number;
} & TextFieldProps;

const ReactHookFormCurrency = ({
	name,
	label,
	helperText,
	control,
	defaultValue,
	rules,
	readOnly,
	allowDecimals = false,
	allowNegative = false,
	required,
	maxLength,
	maxValue,
}: ReactHookFormCurrencyProps) => {
	return (
		<Controller
			render={({ field: { onChange, ref, value, ...controllerProps }, fieldState: { error } }) => (
				<NumericFormat
					customInput={TextField}
					thousandSeparator=","
					prefix="$"
					allowNegative={allowNegative}
					decimalScale={allowDecimals ? 2 : 0}
					variant="standard"
					fullWidth
					inputProps={{ readOnly }}
					label={label}
					helperText={!!error ? error.message : helperText}
					onValueChange={(values) => onChange(values.floatValue ?? null)}
					inputRef={ref}
					required={required}
					error={!!error}
					value={value ?? ''}
					isAllowed={(value) => {
						if (maxLength !== undefined) {
							if (value.value.length > maxLength) return false;
						}
						if (maxValue !== undefined && value.floatValue !== undefined) {
							if (value.floatValue > maxValue) return false;
						}
						return true;
					}}
					{...controllerProps}
				/>
			)}
			name={name}
			control={control}
			defaultValue={defaultValue ?? null}
			rules={rules}
		/>
	);
};

export default ReactHookFormCurrency;
