import { TextField } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { HTMLProps } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export interface ReactHookFormDateTimeProps
	extends Omit<HTMLProps<HTMLInputElement>, 'label' | 'onError' | 'defaultValue'>,
		Omit<DateTimePickerProps<Date, Date>, 'onChange' | 'value' | 'renderInput'> {
	name: string;
	control?: Control<any>;
	defaultValue?: Date | null;
	helperText?: string;
	error?: boolean;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

const ReactHookFormDateTime: React.FC<ReactHookFormDateTimeProps> = ({ name, helperText, control, defaultValue, rules, required, ref, ...datePickerProps }) => {
	return (
		<Controller
			render={({ field: { ref, ...rest }, fieldState: { error } }) => (
				<DateTimePicker
					{...datePickerProps}
					inputRef={ref}
					inputFormat={datePickerProps.inputFormat ?? 'MM/dd/yyyy hh:mm aa'}
					renderInput={(props) => <TextField {...props} error={!!error} helperText={helperText} fullWidth required={required} variant="standard" />}
					{...rest}
				/>
			)}
			rules={rules}
			name={name}
			control={control}
			defaultValue={defaultValue}
		/>
	);
};

export default ReactHookFormDateTime;
