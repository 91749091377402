import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Box, Divider, Drawer, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AcknowledgeNotifications, AcknowledgeNotificationsVariables } from '../../apollo/generated/types/AcknowledgeNotifications';
import { GetUserNotifications } from '../../apollo/generated/types/GetUserNotifications';
import { AdminRole } from '../../apollo/generated/types/globalTypes';
import { usePermissions } from '../Providers';
import Logo from './Logo';
import Navigation from './Navigation';
import Notifications from './Notifications/Notifications';
import { ACKNOWLEDGE_NOTIFICATIONS, GET_USER_NOTIFICATIONS } from './Notifications/Notifications.graphql';
import UserProfile from './UserProfile';

export const DRAWER_WIDTH = 240;

const SideBar: React.FC = () => {
	const { logout } = useAuth0();
	const { user } = usePermissions();
	const location = useLocation();

	// handle the hiding of nav links based on profile completion
	const [reduce, setReduce] = useState<boolean>(location.pathname.includes('profile') || location.pathname.includes('settings'));

	const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
	const [hasUnseenNoitifications, setHasUnseenNotifications] = useState(false);

	useEffect(() => {
		setReduce(user?.isMissingRequiredFields ?? true);
	}, [user, setReduce]);

	const { data } = useQuery<GetUserNotifications>(GET_USER_NOTIFICATIONS);
	const [acknowledgeNotificationsMutation] = useMutation<AcknowledgeNotifications, AcknowledgeNotificationsVariables>(ACKNOWLEDGE_NOTIFICATIONS);

	const acknowledgeNotifications = () => {
		const notifsToAcknowledge = data?.userNotifications?.filter((n) => !n.acknowledged).map((n) => n.id) ?? [];
		notifsToAcknowledge.length > 0 && acknowledgeNotificationsMutation({ variables: { notificationIds: notifsToAcknowledge } });
	};

	// Acknowledge the notifications whenever we close the notifications panel
	const toggleNotificationsOpen = () => {
		if (notificationsOpen) acknowledgeNotifications();
		setNotificationsOpen(!notificationsOpen);
	};

	return (
		<>
			<Notifications open={notificationsOpen} onClose={toggleNotificationsOpen} setHasUnseenNotifications={setHasUnseenNotifications} />
			<Drawer variant="permanent" sx={styles}>
				<Logo />
				<Divider />
				<UserProfile reduce={reduce} />
				<Divider />
				<Box sx={{ overflowY: 'auto' }}>
					<Navigation
						notificationsOpen={notificationsOpen}
						toggleNotificationsOpen={toggleNotificationsOpen}
						reduce={reduce}
						hasUnseenNoitifications={hasUnseenNoitifications}
						isSysAdmin={user?.adminRole === AdminRole.SYS_ADMIN}
					/>
				</Box>
				<Divider />
				<ListItemButton className="signOut" onClick={() => logout({ returnTo: window.location.origin })} sx={{ flexGrow: 0 }}>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Sign Out" primaryTypographyProps={{ variant: 'body2' }} />
				</ListItemButton>
			</Drawer>
		</>
	);
};

const styles = {
	'width': DRAWER_WIDTH,
	'overflow': 'hidden',
	'& .MuiDrawer-paper': {
		background: '#222A43',
		color: 'white',
		width: DRAWER_WIDTH,
	},
	'& .MuiDivider-root': {
		backgroundColor: 'rgba(255, 255, 255, 0.1)',
		margin: '5px 0',
	},

	'.signOut': {
		'marginTop': 'auto',
		'paddingLeft': '26px',
		'color': '#FFFFFF',
		'letterSpacing': '-0.3px',
		'&:hover': {
			backgroundColor: '#1F8BF3 !important',
		},
		'& .MuiListItemIcon-root': {
			color: 'white',
		},
		'& .MuiListItemText-root': {
			'& .MuiTypography-body2': {
				color: 'white',
			},
		},
	},
};

export default SideBar;
