import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar } from '@mui/material';

interface TopBarProps {
	fixedHeight?: boolean;
}

const useStyles = makeStyles({
	rootFixed: {
		height: '92px',
		width: '100%',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 8px #00000005',
		color: '#1A2A3D',
	},
	rootAuto: {
		height: 'auto',
		width: '100%',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 8px #00000005',
		color: '#1A2A3D',
	},
});

const TopBar: React.FC<TopBarProps> = ({ fixedHeight = true, children }) => {
	const classes = useStyles();
	return (
		<AppBar position="relative" className={fixedHeight ? classes.rootFixed : classes.rootAuto}>
			{children}
		</AppBar>
	);
};

export default TopBar;
