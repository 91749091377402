import { InfoOutlined } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Chip, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { MutableRefObject } from 'react';
import { GetEvaluatorScoreSheetData_stage2Response } from '../../../apollo/generated/types/GetEvaluatorScoreSheetData';
import { FpmCategory, FundamentalPrimeMeasurement } from '../../../apollo/generated/types/globalTypes';
import { fpmCategoryToPrefix, GetFPMCatInfo, GetFPMCatTitle } from '../../../utils/Enums/Helpers/FpmCategories';
import EvaluatorScoresTable from './EvaluatorScoresTable';

type EvaluatorScoresTablesProps = {
	response: GetEvaluatorScoreSheetData_stage2Response;
	steps: {
		label: string;
		ref: MutableRefObject<null>;
	}[];
	categoryToResponderScores: { [key in FundamentalPrimeMeasurement]?: { [key: string]: any } };
	categoryToEvaluationsMinMax: { [key in FundamentalPrimeMeasurement]?: { [key: string]: { min: number | null; max: number | null } } };
	readOnly: boolean;
};

/**
 * Divides the scores and success factors into corresponding categories and displays the tables with their help texts.
 *
 * @param {EvaluatorScoresTablesProps} {
 *  response,
 * 	steps,
 * 	categoryToResponderScores,
 * 	categoryToEvaluationsMinMax,
 * 	readOnly,
 * }
 */
const EvaluatorScoresTables = ({ response, steps, categoryToResponderScores, categoryToEvaluationsMinMax, readOnly }: EvaluatorScoresTablesProps) => {
	const productTechnologyRisks = response?.topic.risks.filter((risk) => risk.libraryRisk.fpmCategory === FpmCategory.PRODUCT_TECHNOLOGY) ?? [];
	const organizationRisks = response?.topic.risks.filter((risk) => risk.libraryRisk.fpmCategory === FpmCategory.ORGANIZATION) ?? [];
	const applicationRisks = response?.topic.risks.filter((risk) => risk.libraryRisk.fpmCategory === FpmCategory.APPLICATION) ?? [];

	return (
		<>
			<Grid item xs={12}>
				<Card ref={steps[0].ref}>
					<CardHeader
						title={
							<Typography variant="h2">
								{GetFPMCatTitle(FpmCategory.PRODUCT_TECHNOLOGY) + ' '}
								<Tooltip title={GetFPMCatInfo(FpmCategory.PRODUCT_TECHNOLOGY)}>
									<InfoOutlined fontSize="small" color="primary" />
								</Tooltip>
							</Typography>
						}
						subheader={
							<>
								<Typography sx={{ pt: '1rem' }} variant="h4">
									Risks
								</Typography>
								<Stack gap="0.25rem" flexDirection="row" flexWrap="wrap">
									{productTechnologyRisks.map((r) => (
										<Chip
											key={r.id}
											sx={{ width: 'fit-content', bgcolor: 'grey.500' }}
											size="small"
											label={(r.prefix ? fpmCategoryToPrefix[r.libraryRisk.fpmCategory] + r.prefix + ': ' : '') + r.libraryRisk.name}
										/>
									))}
								</Stack>
							</>
						}
					/>
					<CardContent>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.APPEAL}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.APPEAL]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.APPEAL]}
							readOnly={readOnly}
						/>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.VALUE}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.VALUE]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.VALUE]}
							readOnly={readOnly}
						/>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.RELIABILITY}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.RELIABILITY]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.RELIABILITY]}
							readOnly={readOnly}
						/>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card ref={steps[1].ref}>
					<CardHeader
						title={
							<Typography variant="h2">
								{GetFPMCatTitle(FpmCategory.ORGANIZATION) + ' '}
								<Tooltip title={GetFPMCatInfo(FpmCategory.ORGANIZATION)}>
									<InfoOutlined fontSize="small" color="primary" />
								</Tooltip>
							</Typography>
						}
						subheader={
							<>
								<Typography sx={{ pt: '1rem' }} variant="h4">
									Risks
								</Typography>
								<Stack gap="0.25rem" flexDirection="row" flexWrap="wrap">
									{organizationRisks.map((r) => (
										<Chip
											key={r.id}
											sx={{ width: 'fit-content', bgcolor: 'grey.500' }}
											size="small"
											label={(r.prefix ? fpmCategoryToPrefix[r.libraryRisk.fpmCategory] + r.prefix + ': ' : '') + r.libraryRisk.name}
										/>
									))}
								</Stack>
							</>
						}
					/>
					<CardContent>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.PERSONNEL}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.PERSONNEL]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.PERSONNEL]}
							readOnly={readOnly}
						/>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.PROCESSES}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.PROCESSES]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.PROCESSES]}
							readOnly={readOnly}
						/>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.FINANCES}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.FINANCES]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.FINANCES]}
							readOnly={readOnly}
						/>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card ref={steps[2].ref}>
					<CardHeader
						title={
							<Typography variant="h2">
								{GetFPMCatTitle(FpmCategory.APPLICATION) + ' '}
								<Tooltip title={GetFPMCatInfo(FpmCategory.APPLICATION)}>
									<InfoOutlined fontSize="small" color="primary" />
								</Tooltip>
							</Typography>
						}
						subheader={
							<>
								<Typography sx={{ pt: '1rem' }} variant="h4">
									Risks
								</Typography>
								<Stack gap="0.25rem" flexDirection="row" flexWrap="wrap">
									{applicationRisks.map((r) => (
										<Chip
											key={r.id}
											sx={{ width: 'fit-content', bgcolor: 'grey.500' }}
											size="small"
											label={(r.prefix ? fpmCategoryToPrefix[r.libraryRisk.fpmCategory] + r.prefix + ': ' : '') + r.libraryRisk.name}
										/>
									))}
								</Stack>
							</>
						}
					/>
					<CardContent>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.APPLICATION_SIZE}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.APPLICATION_SIZE]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.APPLICATION_SIZE]}
							readOnly={readOnly}
						/>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.DEMAND}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.DEMAND]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.DEMAND]}
							readOnly={readOnly}
						/>
						<EvaluatorScoresTable
							response={response}
							fpm={FundamentalPrimeMeasurement.DELIVERY}
							responderScores={categoryToResponderScores[FundamentalPrimeMeasurement.DELIVERY]}
							otherEvaluationsMinMax={categoryToEvaluationsMinMax[FundamentalPrimeMeasurement.DELIVERY]}
							readOnly={readOnly}
						/>
					</CardContent>
				</Card>
			</Grid>
		</>
	);
};

export default EvaluatorScoresTables;
