import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	InputLabel,
	Stack,
} from '@mui/material';
import ReactHookFormTextField from '../../components/ReactHookForm/ReactHookFormTextField';
import { useForm } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { EnterGroupSignUpCode, EnterGroupSignUpCodeVariables } from '../../apollo/generated/types/EnterGroupSignUpCode';
import { CreateCheckoutSession } from '../../apollo/generated/types/CreateCheckoutSession';
import { ENTER_GROUP_SIGN_UP_CODE, CREATE_CHECKOUT_SESSION } from './MembershipOptionsPage.graphql';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useAuth0 } from '@auth0/auth0-react';

interface FormFields {
	code: string;
}

const MembershipOptionsPage = () => {
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const { logout } = useAuth0();

	const { control, watch, handleSubmit } = useForm<FormFields>({ defaultValues: { code: '' } });
	const code = watch('code');

	const [enterSignUpCode, { loading: signUpLoading }] = useMutation<EnterGroupSignUpCode, EnterGroupSignUpCodeVariables>(ENTER_GROUP_SIGN_UP_CODE);
	const submit = (data: FormFields) => {
		enterSignUpCode({ variables: { signUpCode: data.code } })
			.then(() => window.location.reload())
			.catch((e: ApolloError) => {
				setErrorMessage(e.message);
				setShowErrorModal(true);
			});
	};

	const [createCheckoutSession, { loading: checkoutLoading }] = useMutation<CreateCheckoutSession>(CREATE_CHECKOUT_SESSION, {
		onCompleted: (data) => {
			window.location.replace(data.createCheckoutSession);
		},
		onError: (e) => {
			setErrorMessage(e.message);
			setShowErrorModal(true);
		},
	});

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
			<Card sx={{ minWidth: '400px' }}>
				<CardHeader title="Sign Up for a 9Hi™ Subscription" />
				<CardContent>
					<Stack gap={2}>
						<LoadingButton variant="contained" disabled={signUpLoading} loading={checkoutLoading} onClick={() => createCheckoutSession()}>
							Sign Up with Stripe
						</LoadingButton>
						<Divider>or</Divider>
						<Box>
							<InputLabel>Enter a Group Sign-Up Code</InputLabel>
							<ReactHookFormTextField control={control} name="code" fullWidth regex={/^[a-zA-Z0-9]*$/} />
						</Box>
						<LoadingButton loading={signUpLoading} variant="contained" disabled={!code || checkoutLoading} onClick={handleSubmit(submit)}>
							Submit
						</LoadingButton>
						<Divider>or</Divider>
						<Button variant="outlined" onClick={() => logout({ returnTo: window.location.origin })} disabled={signUpLoading || checkoutLoading}>
							Sign Out
						</Button>
					</Stack>
				</CardContent>
			</Card>
			<Dialog open={showErrorModal}>
				<DialogTitle>Error Signing Up</DialogTitle>
				<DialogContent>
					<Alert severity="error">{errorMessage}</Alert>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={() => setShowErrorModal(false)}>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default MembershipOptionsPage;
