const ICON_COLORS = ['#E6B7B7', '#E6E6B7', '#B7E6E6', '#C5E6B7', '#E1B7E6', '#B7E6CD'];

/**
 * Hashes a string to a color included in the ICON_COLORS array. These colors were selected from the mockup here:
 * https://xd.adobe.com/view/5fa7a2af-c912-4f93-bd23-05d03492d8dd-0af4/screen/9a5dea21-be4c-432c-afaf-41272054aa45/
 *
 * @param {*} str
 * @return {*}
 */
const getIconColorFromString = (str?: string) => {
	if (!str || str.length === 0) return ICON_COLORS[0];
	return ICON_COLORS[hashCode(str) % ICON_COLORS.length];
};

// String to Hash
const hashCode = (str: string) => {
	let hash: any = 0;
	for (let i = 0; i < str.length; i++) hash = str.charCodeAt(i) + ((hash << 5) - hash);
	return Math.abs(hash);
};

export default getIconColorFromString;
