import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { usePermissions } from '../Providers';
import { useQuery } from '@apollo/client';
import { GetProfilePicture } from '../../apollo/generated/types/GetProfilePicture';
import { GET_PROFILE_PICTURE } from '../../apollo/queries';

const useStyles = makeStyles((theme) => ({
	button: {
		width: '100%',
	},
	image: {
		width: '42px',
		height: '42px',
	},
	textPrimary: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: '#FFFFFF',
		fontSize: '15px',
		lineHeight: '18px',
		fontWeight: 'bold',
		fontFamily: "'Lato', sans-serif",
		textTransform: 'none',
		letterSpacing: '-0.3px',
	},
	textSecondary: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: '#FFFFFF',
		fontSize: '15px',
		lineHeight: '18px',
		opacity: '0.6',
		fontFamily: "'Lato', sans-serif",
		textTransform: 'none',
		letterSpacing: '-0.3px',
	},
	skeleton: {
		backgroundColor: 'rgba(255, 255, 255, 0.11)',
	},
	iconWrapper: {
		minWidth: '32px',
		color: 'white',
	},
	link: {
		'paddingLeft': '55px',
		'color': '#FFFFFF',
		'letterSpacing': '-0.3px',
		'& .MuiListItemText-root': {
			'& .MuiTypography-body2': {
				color: 'white',
			},
		},
	},
	activeLink: {
		'color': '#FFFFFF',
		'background': '#1976D2',
		'&:hover': {
			background: '#1F8BF3',
			color: '#FFFFFF',
			backgroundColor: '#1F8BF3 !important',
		},
	},
	innerList: {
		'& .MuiListItem-root:hover': {
			color: 'white',
			background: theme.palette.primary.main,
		},
	},
}));

export interface UserProfileProps {
	reduce?: boolean;
}

const UserProfile: React.FC<UserProfileProps> = ({ reduce }) => {
	const { user: auth0User } = useAuth0();
	const { user } = usePermissions();
	const classes = useStyles();
	const history = useHistory();
	const { data: profilePictureData } = useQuery<GetProfilePicture>(GET_PROFILE_PICTURE);

	const [open, setOpen] = React.useState(false);
	useEffect(() => {
		// Ensure that profile navigation links remain visible if on the profile page
		if (reduce) setOpen(true);
	}, [setOpen, reduce]);

	const handleClick = () => {
		let newOpen = !open;
		setOpen(newOpen);
	};

	return (
		<>
			<List>
				<ListItem button onClick={handleClick}>
					<ListItemIcon>
						{user?.fullName?.split(' ')[0][0] && user?.fullName?.split(' ')[1][0] ? (
							<Avatar
								src={profilePictureData?.currentUser.profilePicture?.url}
								className={auth0User?.image}
								onClick={() => history.push('/profile')}>
								{`${user.fullName?.split(' ')[0][0]}${user.fullName?.split(' ')[1][0]}`}
							</Avatar>
						) : (
							<Avatar />
						)}
					</ListItemIcon>
					<ListItemText
						primary={
							user ? (
								<Typography className={classes.textPrimary}>
									{user.fullName && user.fullName?.trim() !== '' ? user.fullName : user.email}
								</Typography>
							) : (
								<Skeleton variant="text" className={`${classes.textPrimary} ${classes.skeleton}`} />
							)
						}
					/>
					{
						// change icon based on open value
						open ? <ExpandLess /> : <ExpandMore />
					}
				</ListItem>
				<Collapse in={open} timeout="auto">
					<List className={classes.innerList}>
						<ListItem button to="/profile" component={NavLink} className={classes.link} activeClassName={classes.activeLink}>
							<ListItemIcon className={classes.iconWrapper}>
								<PersonIcon />
							</ListItemIcon>
							<ListItemText primary="Profile" primaryTypographyProps={{ variant: 'body2' }} />
						</ListItem>
						<ListItem button to="/settings" component={NavLink} className={classes.link} activeClassName={classes.activeLink} disabled={reduce}>
							<ListItemIcon className={classes.iconWrapper}>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText primary="Settings" primaryTypographyProps={{ variant: 'body2' }} />
						</ListItem>
					</List>
				</Collapse>
			</List>
		</>
	);
};

export default UserProfile;
