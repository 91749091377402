import { Alert, Grid, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import FileDropZone from '../../../../components/FileDropZone/FileDropZone';
import { FileReferenceType } from '../../../../apollo/generated/types/globalTypes';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import FormSection from '../../FormSection';

const UserFileUpload: React.FC<UserFileUploadProps> = ({ mode }) => {
	const id = useWatch({ name: 'id' });
	const readOnly = mode === FormMode.View;

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Uploaded Files</Typography>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="info">
						The following types of files are recommended for upload here: Resumes, CVs, published articles, peer-reviewed papers, conference
						presentations, etc.
					</Alert>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				<Grid item xs={12}>
					<FileDropZone
						referenceId={id}
						referenceType={FileReferenceType.USER_FILE}
						name="userFiles"
						altStyle
						browseBtn
						header="User Files"
						readOnly={readOnly}
					/>
				</Grid>
				<Grid item xs={12}>
					<FileDropZone
						referenceId={id}
						referenceType={FileReferenceType.PROFILE_PICTURE}
						name="profilePicture"
						altStyle
						browseBtn
						header="Upload User Photo"
						fileType="image/*"
						multiple={false}
					/>
				</Grid>
			</FormSection>
		</>
	);
};

export interface UserFileUploadProps {
	mode: FormMode;
}

export default UserFileUpload;
