import { ApolloError } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import FileSadIcon from '../../images/FileSadIcon';
import ScreenMessage from './ScreenMessage';

const useStyles = makeStyles({
	link: {
		color: '#1976D2',
	},
});

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, reload }) => {
	const classes = useStyles();
	const history = useHistory();

	if (typeof error === 'string') {
		console.error(error);
	} else if (error) {
		console.error(error.message);
	}

	return (
		<ScreenMessage
			icon={<FileSadIcon />}
			header="Oops! There was an error."
			subHeader="We couldn't load this page. Please try again, or check your internet connection.">
			{reload ? (
				<a
					className={classes.link}
					onClick={() => {
						window.location.reload();
					}}>
					Refresh
				</a>
			) : (
				<NavLink to={history.location}>Refresh</NavLink>
			)}
		</ScreenMessage>
	);
};

export interface ErrorMessageProps {
	error?: string | ApolloError;
	reload?: boolean;
}

export default ErrorMessage;
