import WarningIcon from '@mui/icons-material/Warning';
import { useEffect, useState } from 'react';
import { useFormState } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';
import { ConfirmDialog } from '../../index';

const UnsavedChangesDialog: React.FC = () => {
	const history = useHistory();
	const [nextPage, setNextPage] = useState<any>();
	const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);
	const [show, setShow] = useState(false);
	const { dirtyFields } = useFormState();
	const isDirty = Object.keys(dirtyFields).length > 0;

	const handlePageLeave = (location: any): boolean => {
		if (!confirmedNavigation) {
			setNextPage(location);
			setShow(true);
			return false;
		}
		return true;
	};

	const handleConfirmNavigationClick = () => {
		setConfirmedNavigation(true);
	};

	useEffect(() => {
		if (confirmedNavigation && nextPage) {
			setShow(false);
			setConfirmedNavigation(false);
			history.push(nextPage);
		}
	}, [nextPage, confirmedNavigation, history]);

	return (
		<>
			<Prompt when={isDirty} message={handlePageLeave} />
			<ConfirmDialog
				open={show}
				icon={<WarningIcon style={{ color: '#F0A100', fontSize: 91 }} />}
				title="Are you sure you want to leave this page?"
				message={
					<>
						You have made changes to this page.
						<br />
						Are you sure you want to leave this page without saving?
					</>
				}
				primaryText="Leave this page"
				secondaryText="Stay on this page"
				onPrimaryClick={handleConfirmNavigationClick}
				onSecondaryClick={() => setShow(false)}
				onClose={() => setShow(false)}
			/>
		</>
	);
};

export default UnsavedChangesDialog;
