import { InfoOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Chip, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FpmCategory, FundamentalPrimeMeasurement } from '../../../../apollo/generated/types/globalTypes';
import { fpmCategoryToPrefix, GetFPMCatInfo, GetFPMCatTitle } from '../../../../utils/Enums/Helpers/FpmCategories';
import { useTeamLeadScore } from '../../../Providers/TeamLeadScoreProvider/TeamLeadScoreProvider';
import TeamLeadEvaluatorScoresTable from './TeamLeadEvaluatorScoresTable';

const TeamLeadEvaluatorScoresDisplay = () => {
	const { response } = useTeamLeadScore();

	const productTechnologyRisks = response?.topic.risks.filter((risk) => risk.libraryRisk.fpmCategory === FpmCategory.PRODUCT_TECHNOLOGY) ?? [];
	const organizationRisks = response?.topic.risks.filter((risk) => risk.libraryRisk.fpmCategory === FpmCategory.ORGANIZATION) ?? [];
	const applicationRisks = response?.topic.risks.filter((risk) => risk.libraryRisk.fpmCategory === FpmCategory.APPLICATION) ?? [];

	return (
		<>
			<Grid xs={12}>
				<Card>
					<CardHeader
						title={
							<Grid container justifyContent="space-between">
								<Grid>
									<Typography variant="h2">
										{GetFPMCatTitle(FpmCategory.PRODUCT_TECHNOLOGY)}
										<Tooltip title={GetFPMCatInfo(FpmCategory.PRODUCT_TECHNOLOGY)}>
											<InfoOutlined fontSize="small" color="primary" />
										</Tooltip>
									</Typography>
								</Grid>
							</Grid>
						}
						subheader={
							<Box sx={{ p: '12px', pt: 0 }}>
								<Typography variant="h4">Risks</Typography>
								<Stack gap="0.25rem" flexDirection="row" flexWrap="wrap">
									{productTechnologyRisks.map((r) => (
										<Chip
											key={r.id}
											sx={{ width: 'fit-content', bgcolor: 'grey.500' }}
											size="small"
											label={(r.prefix ? fpmCategoryToPrefix[r.libraryRisk.fpmCategory] + r.prefix + ': ' : '') + r.libraryRisk.name}
										/>
									))}
								</Stack>
							</Box>
						}
					/>
					<CardContent>
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.APPEAL} />
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.VALUE} />
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.RELIABILITY} />
					</CardContent>
				</Card>
			</Grid>

			<Grid xs={12}>
				<Card>
					<CardHeader
						title={
							<Grid container justifyContent="space-between">
								<Grid>
									<Typography variant="h2">
										{GetFPMCatTitle(FpmCategory.ORGANIZATION)}
										<Tooltip title={GetFPMCatInfo(FpmCategory.ORGANIZATION)}>
											<InfoOutlined fontSize="small" color="primary" />
										</Tooltip>
									</Typography>
								</Grid>
							</Grid>
						}
						subheader={
							<Box sx={{ p: '12px', pt: 0 }}>
								<Typography variant="h4">Risks</Typography>
								<Stack gap="0.25rem" flexDirection="row" flexWrap="wrap">
									{organizationRisks.map((r) => (
										<Chip
											key={r.id}
											sx={{ width: 'fit-content', bgcolor: 'grey.500' }}
											size="small"
											label={(r.prefix ? fpmCategoryToPrefix[r.libraryRisk.fpmCategory] + r.prefix + ': ' : '') + r.libraryRisk.name}
										/>
									))}
								</Stack>
							</Box>
						}
					/>
					<CardContent>
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.PERSONNEL} />
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.PROCESSES} />
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.FINANCES} />
					</CardContent>
				</Card>
			</Grid>

			<Grid xs={12}>
				<Card>
					<CardHeader
						title={
							<Grid container justifyContent="space-between">
								<Grid>
									<Typography variant="h2">
										{GetFPMCatTitle(FpmCategory.APPLICATION)}
										<Tooltip title={GetFPMCatInfo(FpmCategory.APPLICATION)}>
											<InfoOutlined fontSize="small" color="primary" />
										</Tooltip>
									</Typography>
								</Grid>
							</Grid>
						}
						subheader={
							<Box sx={{ p: '12px', pt: 0 }}>
								<Typography variant="h4">Risks</Typography>
								<Stack gap="0.25rem" flexDirection="row" flexWrap="wrap">
									{applicationRisks.map((r) => (
										<Chip
											key={r.id}
											sx={{ width: 'fit-content', bgcolor: 'grey.500' }}
											size="small"
											label={(r.prefix ? fpmCategoryToPrefix[r.libraryRisk.fpmCategory] + r.prefix + ': ' : '') + r.libraryRisk.name}
										/>
									))}
								</Stack>
							</Box>
						}
					/>
					<CardContent>
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.APPLICATION_SIZE} />
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.DEMAND} />
						<TeamLeadEvaluatorScoresTable fpm={FundamentalPrimeMeasurement.DELIVERY} />
					</CardContent>
				</Card>
			</Grid>
		</>
	);
};

export default TeamLeadEvaluatorScoresDisplay;
