import React from 'react';
import NineHiLogo from '../../assets/images/NineHiLogo';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
	root: {
		'minHeight': '90px',
		'display': 'flex',
		'justifyContent': 'center',
		'alignItems': 'center',
		'&:hover': {
			cursor: 'pointer',
		},
	},
});

const Logo: React.FC = () => {
	const classes = useStyles();
	const history = useHistory();

	function handleClick() {
		history.push('/');
	}
	return (
		<div className={classes.root} onClick={handleClick}>
			<NineHiLogo />
		</div>
	);
};

export default Logo;
