import { TextField } from '@mui/material';
import { DatePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export interface ReactHookFormDateProps
	extends Omit<React.HTMLProps<HTMLInputElement>, 'label' | 'onError' | 'defaultValue'>,
		Omit<DateTimePickerProps<Date, Date>, 'onChange' | 'value' | 'renderInput'> {
	name: string;
	control?: Control<any>;
	defaultValue?: Date | null;
	helperText?: string;
	error?: boolean;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

const ReactHookFormDate: React.FC<ReactHookFormDateProps> = ({
	name,
	inputFormat = 'MM/dd/yyyy',
	label,
	helperText,
	control,
	defaultValue,
	readOnly,
	rules,
	required,
}) => {
	return (
		<Controller
			render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
				<DatePicker
					inputRef={ref}
					label={label}
					readOnly={readOnly}
					inputFormat={inputFormat}
					onChange={(date) => {
						date?.setHours(12, 0);
						onChange(date);
					}}
					renderInput={(props) => (
						<TextField
							{...props}
							fullWidth
							error={!!error}
							helperText={!!error ? error.message : helperText}
							variant="standard"
							required={required}
						/>
					)}
					{...rest}
				/>
			)}
			rules={rules}
			name={name}
			control={control}
			defaultValue={defaultValue}
		/>
	);
};

export default ReactHookFormDate;
