import { gql } from '@apollo/client';

export const GET_INVITES = gql`
	query GetInvites($userId: Guid!) {
		groupInvites(userId: $userId) {
			id
			invitedByUser {
				fullName
				profilePicture {
					url
				}
			}
			userId
			groupRole
			inviteStatus
			group {
				id
				name
			}
			createdAt
		}
		projectInvites(userId: $userId) {
			id
			invitedByUser {
				fullName
				profilePicture {
					url
				}
			}
			userId
			projectRole
			inviteStatus
			project {
				id
				name
				group {
					id
					name
				}
			}
			createdAt
			hasWorkAgreement
		}
		projectTopicInvites(userId: $userId) {
			id
			invitedByUser {
				fullName
				profilePicture {
					url
				}
			}
			userId
			topicRole
			inviteStatus
			projectTopic {
				id
				name
				project {
					id
					name
					group {
						id
						name
					}
				}
			}
			createdAt
			hasWorkAgreement
		}
	}
`;

export const EDIT_GROUP_USER_INVITE_STATUS = gql`
	mutation EditGroupUserInviteStatus($groupId: Int!, $inviteStatusUpdate: InviteStatus!, $declineReason: String) {
		editGroupUserInviteStatus(groupId: $groupId, inviteStatusUpdate: $inviteStatusUpdate, declineReason: $declineReason) {
			id
			inviteStatus
		}
	}
`;

export const EDIT_PROJECT_USER_INVITE_STATUS = gql`
	mutation EditProjectUserInviteStatus($projectId: Guid!, $inviteStatusUpdate: InviteStatus!, $declineReason: String) {
		editProjectUserInviteStatus(projectId: $projectId, inviteStatusUpdate: $inviteStatusUpdate, declineReason: $declineReason) {
			id
			inviteStatus
		}
	}
`;

export const EDIT_PROJECT_TOPIC_USER_INVITE_STATUS = gql`
	mutation EditProjectTopicUserInviteStatus($projectTopicId: Guid!, $inviteStatusUpdate: InviteStatus!, $declineReason: String) {
		editProjectTopicUserInviteStatus(projectTopicId: $projectTopicId, inviteStatusUpdate: $inviteStatusUpdate, declineReason: $declineReason) {
			id
			inviteStatus
		}
	}
`;
