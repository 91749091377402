import { Box, FormControl, FormHelperText, FormLabel, RadioGroup } from '@mui/material';
import { Controller, RegisterOptions } from 'react-hook-form';

const ReactHookFormRadio: React.FC<ReactHookFormRadioProps> = ({
	name,
	label,
	children,
	control,
	defaultValue,
	alignRadioButtons,
	error,
	helperText,
	rules,
	hidden,
	required,
	column,
}) => {
	return (
		<Controller
			render={({ field: { onChange, onBlur, value, name, ref } }) => (
				<FormControl error={!!error} required={required}>
					{/* Temporary fix for errors not being focused on error */}
					<Box ref={ref} sx={{ width: 0, height: 0, opacity: 0, userSelect: 'none' }} component="input" />
					{label && <FormLabel>{label}</FormLabel>}
					<RadioGroup name={name} row={!column} style={{ alignSelf: alignRadioButtons }} onChange={onChange} value={value} onBlur={onBlur}>
						{children}
					</RadioGroup>
					{helperText && <FormHelperText>{helperText}</FormHelperText>}
				</FormControl>
			)}
			rules={rules}
			name={name}
			control={control}
			defaultValue={defaultValue ?? null}
		/>
	);
};
export interface ReactHookFormRadioProps {
	label?: string;
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control?: any;
	defaultValue?: boolean;
	alignRadioButtons?: string;
	readOnly?: boolean;
	error?: boolean;
	helperText?: string;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	hidden?: boolean;
	required?: boolean;
	column?: boolean;
}

export default ReactHookFormRadio;
