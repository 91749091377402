import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_COMMENTS, GET_COMMENT_USERS } from '../../../apollo/queries';
import { CREATE_COMMENT, CREATE_LIKED_COMMENT, DELETE_COMMENT, DELETE_LIKED_COMMENT, EDIT_COMMENT } from '../../../apollo/mutations';
import { CreateComment, CreateCommentVariables } from '../../../apollo/generated/types/CreateComment';
import { CreateLikedComment, CreateLikedCommentVariables } from '../../../apollo/generated/types/CreateLikedComment';
import { DeleteLikedComment, DeleteLikedCommentVariables } from '../../../apollo/generated/types/DeleteLikedComment';
import { GetCommentUsers, GetCommentUsersVariables, GetCommentUsers_commentUsers } from '../../../apollo/generated/types/GetCommentUsers';

import { GetComments_comments } from '../../../apollo/generated/types/GetComments';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { EditComment, EditCommentVariables } from '../../../apollo/generated/types/EditComment';
import { useSnackbar } from 'notistack';
import { DeleteComment, DeleteCommentVariables } from '../../../apollo/generated/types/DeleteComment';

interface CommentContextReturn {
	loading: boolean;
	error?: ApolloError;
	comments?: GetComments_comments[];
	commentUsers?: GetCommentUsers_commentUsers[] | null;
	addComment: (userId: string, url: string, content: string, date: any, parentId?: number) => Promise<void>;
	edit: (commentId: number, content: string, date: any, url: string) => Promise<void>;
	removeComment: (commentId: number, url: string) => Promise<void>;
	addLike: (commentId: number, userId: string, url: string) => Promise<void>;
	removeLike: (commentId: number, userId: string, url: string) => Promise<void>;
	commentError: ApolloError | undefined;
}

const CommonFormContext = createContext<CommentContextReturn | undefined>(undefined);

const CommentProvider = ({ children }) => {
	const [connection, setConnection] = useState<HubConnection | null>(null);
	const [connectionStarted, setConnectionStarted] = useState<boolean>(false);

	const [comments, setComments] = useState<Array<GetComments_comments> | undefined>();
	const [createComment] = useMutation<CreateComment, CreateCommentVariables>(CREATE_COMMENT);
	const [editComment] = useMutation<EditComment, EditCommentVariables>(EDIT_COMMENT);
	const [deleteComment] = useMutation<DeleteComment, DeleteCommentVariables>(DELETE_COMMENT);
	const [commentUsers, setCommentUsers] = useState<GetCommentUsers_commentUsers[] | null | undefined>();
	const [commentError, setCommentError] = useState<ApolloError>();

	const { enqueueSnackbar } = useSnackbar();

	// Error handler to prevent double liking a comment
	const ErrorFunc = () => console.log('error');

	//Update Like/Dislike on comment
	const [createLikedComment] = useMutation<CreateLikedComment, CreateLikedCommentVariables>(CREATE_LIKED_COMMENT, { onError: ErrorFunc });
	const [deleteLikedComment] = useMutation<DeleteLikedComment, DeleteLikedCommentVariables>(DELETE_LIKED_COMMENT, { onError: ErrorFunc });

	const url = window.location.href;

	//GetCommentUsers
	const {
		loading: commentUsersLoading,
		error: commentUsersError,
		data: commentUsersData,
		refetch: refetchCommentUsers,
	} = useQuery<GetCommentUsers, GetCommentUsersVariables>(GET_COMMENT_USERS, {
		skip: !url,
		fetchPolicy: 'no-cache',
		variables: { url },
	});

	useEffect(() => {
		setCommentUsers(commentUsersData?.commentUsers);
	}, [commentUsersData]);

	//GetComments
	const {
		loading: commentsLoading,
		error: commentsError,
		data: commentsData,
		refetch: refetchComments,
	} = useQuery<any>(GET_COMMENTS, {
		skip: !url,
		fetchPolicy: 'no-cache',
		variables: { url },
	});

	// Set comments when the data is retrieved properly
	useEffect(() => {
		setComments(commentsData?.comments);
	}, [commentsData]);

	// check if our comments contain a user id that is not present, and refetch users
	useEffect(() => {
		if (comments) {
			if (commentUsers) {
				const commentUsersAny: any = commentUsers;
				for (const comment of comments) {
					if (!commentUsersAny.some((e) => e.id === comment.userId)) {
						refetchCommentUsers();
						break;
					}
				}
			} else {
				refetchCommentUsers();
			}
		}
	}, [comments]);

	const loading = !connectionStarted || commentsLoading || commentUsersLoading;
	const error = commentsError || commentUsersError;

	// Establish and maintain connection
	useEffect(() => {
		const apiURL = process.env.REACT_APP_API_URL;
		const newConnection = new HubConnectionBuilder()
			.withUrl(`${apiURL}/hubs/comment`, { skipNegotiation: true, transport: HttpTransportType.WebSockets })
			.withAutomaticReconnect()
			.build();

		setConnection(newConnection);
	}, []);
	useEffect(() => {
		if (connection) {
			if (!connectionStarted) {
				connection
					.start()
					.then((_result) => {
						setConnectionStarted(true);
					})
					.catch((e) => console.log('Connection failed: ', e));
			} else {
				connection.on('ReceiveMessage', (message) => {
					// only update comments if a comment was added to this url
					if (url && message.url === url) {
						refetchComments();
					}
				});
			}
		}
	}, [connection, connectionStarted, refetchComments, url]);

	// Handles sending updates to sub-cycles
	const sendUpdateMessage = useCallback(
		async (url: string) => {
			if (url) {
				const thread = {
					url: url,
				};

				if (connection && connectionStarted) {
					try {
						await connection.send('SendMessage', thread);
					} catch (e) {
						console.log(e);
					}
				} else {
					console.log('No connection to server yet.');
				}
			}
		},
		[connection, connectionStarted, url],
	);

	// Handles logic for adding a new comment
	const addComment = useCallback(
		async (userId: string, url: string, content: string, date: any, parentId?: number) => {
			if (url) {
				const result = await createComment({
					variables: {
						comment: {
							userId: userId,
							url: url,
							content: content,
							date: date,
							parentId: parentId,
						},
					},
				});

				if (result && result.data && result.data.createComment) {
					sendUpdateMessage(url);
				}
			}
		},
		[createComment, sendUpdateMessage, url],
	);

	// Handles logic for editing a comment
	const edit = useCallback(
		async (commentId: number, content: string, date: any, url: string) => {
			if (url) {
				try {
					const result = await editComment({
						variables: {
							commentEdit: {
								commentId: commentId,
								content: content,
								date: date,
							},
						},
					});

					if (result && result.data && result.data.editComment) {
						sendUpdateMessage(url);
					}
				} catch (e) {
					console.log(e);
					enqueueSnackbar('Could not edit comment.', { variant: 'error' });
				}
			}
		},
		[editComment, sendUpdateMessage, url],
	);

	// Handles logic for deleting a comment
	const removeComment = useCallback(
		async (commentId: number, url: string) => {
			if (url) {
				const result = await deleteComment({
					variables: {
						commentId: commentId,
					},
				});

				if (result && result.data && result.data.deleteComment) {
					sendUpdateMessage(url);
				}
			}
		},
		[deleteComment, sendUpdateMessage, url],
	);

	// Handles logic for adding a new comment
	const addLike = useCallback(
		async (commentId: number, userId: string, url: string) => {
			if (url) {
				const result = await createLikedComment({
					variables: {
						likedComment: {
							commentId: commentId,
							userId: userId,
						},
					},
				});

				if (result && result.data && result.data.createLikedComment) {
					sendUpdateMessage(url);
				}
			}
		},
		[createLikedComment, sendUpdateMessage, url],
	);

	const removeLike = useCallback(
		async (commentId: number, userId: string, url: string) => {
			if (url) {
				const result = await deleteLikedComment({
					variables: {
						likedComment: {
							commentId: commentId,
							userId: userId,
						},
					},
				});

				if (result && result.data && result.data.deleteLikedComment) {
					sendUpdateMessage(url);
				}
			}
		},
		[createLikedComment, sendUpdateMessage, url],
	);

	return (
		<CommonFormContext.Provider
			value={{
				loading,
				error,
				comments,
				commentUsers,
				commentError,
				addComment,
				edit,
				removeComment,
				addLike,
				removeLike,
			}}>
			{children}
		</CommonFormContext.Provider>
	);
};

const useComment = (): CommentContextReturn => {
	const context = useContext(CommonFormContext);
	if (context === undefined) {
		throw new Error('useComment must be used within a CommentProvider');
	}
	return context;
};

export { CommentProvider, useComment };
