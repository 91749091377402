import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ScoringGuidelines from './ScoringGuidelines.png';

const styles = {
	tooltip: {
		backgroundColor: 'transparent',
	},
	popper: {
		paddingRight: '50px',
	},
};

const CustomTooltip = withStyles(styles)(Tooltip);

const ScoringGuidelinesTooltip = () => {
	return (
		<CustomTooltip color="primary" title={<img src={ScoringGuidelines} width={500} height={450} />} placement="left-start">
			<InfoIcon fontSize="small" color="primary" />
		</CustomTooltip>
	);
};

export default ScoringGuidelinesTooltip;
