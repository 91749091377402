import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, InputLabel, Tooltip, tooltipClasses, TooltipProps, SxProps } from '@mui/material';
import { useState } from 'react';

export interface InfoLabelProps extends Omit<TooltipProps, 'children' | 'title' | 'onClick'> {
	infoText?: string;
	image?: JSX.Element;
	noMaxWidth?: boolean;
	htmlFor?: string;
	noWrap?: boolean;
	title?: React.ReactNode;
	error?: boolean;
	children?: React.ReactNode;
	pointerSX?: SxProps;
	onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const InfoLabel = ({ infoText, image, htmlFor, placement = 'right', noMaxWidth, noWrap = false, children, error, onClick, pointerSX }: InfoLabelProps) => {
	const [focused, setFocused] = useState(false);

	const updateFocused = () => {
		if (!htmlFor) return;
		setFocused((document.activeElement as HTMLInputElement).name === htmlFor);
	};

	return (
		<Box sx={containerStyles}>
			<InputLabel
				sx={{
					marginRight: '5px',
					color: error ? 'red' : '#808080',
					fontSize: '12px',
					lineHeight: '15px',
					letterSpacing: '0.12px',
				}}
				htmlFor={htmlFor}
				focused={focused}>
				{children}
			</InputLabel>
			<Tooltip
				title={image ?? infoText}
				placement={placement}
				PopperProps={{
					sx: noMaxWidth
						? {
								[`& .${tooltipClasses.tooltip}`]: {
									maxWidth: 'none',
								},
						  }
						: {},
				}}>
				<InfoIcon sx={pointerSX} color="primary" onClick={onClick} />
			</Tooltip>
		</Box>
	);
};

const containerStyles = {
	'display': 'flex',
	'alignItems': 'center',
	'& .MuiSvgIcon-root': {
		fontSize: '1.1rem',
	},
};

export default InfoLabel;
