import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Chat, ExpandMore } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { useTeamLeadScore } from '../../../Providers';
import { ReactHookFormCheckbox } from '../../../ReactHookForm';

const EvaluatorReportDetails = () => {
	const { evaluations, isAdmin, isLocked, isTopicClosed } = useTeamLeadScore();
	const { control } = useFormContext();
	const classes = useStyles();

	const readOnly = !isAdmin || isLocked || isTopicClosed;

	return (
		<Accordion defaultExpanded={true}>
			<AccordionSummary expandIcon={<ExpandMore />}>Evaluator Report Details</AccordionSummary>
			<AccordionDetails className={classes.content}>
				<Box overflow="auto" width={'100%'}>
					{evaluations.map((evaluation, i) => (
						<Table key={evaluation.id} className={i % 2 === 0 ? classes.shaded : undefined}>
							<TableHead>
								<TableRow className={classes.headerRow}>
									<TableCell colSpan={4}>{evaluation.user.fullName}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{evaluation.finalComment ? (
									<TableRow className={classes.row}>
										<TableCell colSpan={3}>
											<ReactHookFormCheckbox
												name={`evaluatorFinalCommentIncludedInFinal[${evaluation.userId}]`}
												label="Include overall comment"
												control={control}
												defaultValue={evaluation.finalCommentIncludedInFinal ?? false}
												readOnly={readOnly}
											/>
										</TableCell>
										<TableCell className={classes.commentCol} align="center">
											<Tooltip title={evaluation.finalComment} arrow placement="top">
												<Chat color="primary" />
											</Tooltip>
										</TableCell>
									</TableRow>
								) : null}
								{evaluation.includeContactInfo ? (
									<TableRow className={classes.row}>
										<TableCell colSpan={4}>
											<ReactHookFormCheckbox
												name={`evaluatorContactInfoIncludedInFinal[${evaluation.userId}]`}
												label="Include contact information"
												control={control}
												defaultValue={evaluation.contactInfoIncludedInFinal ?? false}
												readOnly={readOnly}
											/>
										</TableCell>
									</TableRow>
								) : null}
								{!evaluation.includeContactInfo && !evaluation.finalComment ? (
									<TableRow>
										<TableCell colSpan={4}>The evaluator chose not to share their contact information or a final comment.</TableCell>
									</TableRow>
								) : null}
							</TableBody>
						</Table>
					))}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

const useStyles = makeStyles((theme) => ({
	content: {
		padding: 0,
	},
	headerRow: {
		'& .MuiTableCell-root': {
			borderBottom: 0,
			paddingBottom: 0,
		},
	},
	row: {
		'& .MuiTableCell-root': {
			paddingTop: 0,
		},
		'&:not(&:last-of-type) .MuiTableCell-root': {
			borderBottom: 0,
			paddingBottom: 0,
		},
	},
	shaded: {
		background: theme.palette.secondary.light,
	},
	commentCol: {
		width: '125px',
		alignItems: 'center',
	},
}));

export default EvaluatorReportDetails;
