import {
	Checkbox,
	Chip,
	FormHelperText,
	Input,
	InputLabel,
	ListItem,
	ListItemText,
	MenuItem,
	MenuProps,
	FormLabel,
	Select,
	SelectChangeEvent,
	Tooltip,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, RegisterOptions, useWatch } from 'react-hook-form';
import { InfoLabel } from '../index';
import InfoIcon from '@mui/icons-material/InfoOutlined';

export interface ReactHookFormMultiSelectProps {
	name: string;
	label?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	defaultValue?: any[];
	helperText?: string;
	error?: boolean;
	readOnly?: boolean;
	loading?: boolean;
	tooltip?: string;
	menuProps?: Partial<MenuProps>;
	onSelectChange?: (event: SelectChangeEvent) => void;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	required?: boolean;
	options?: {
		value: any;
		name: string;
		isLabel?: boolean;
	}[];
}

const ReactHookFormMultiSelect: React.FC<ReactHookFormMultiSelectProps> = ({
	name,
	label,
	control,
	defaultValue,
	error,
	helperText,
	readOnly,
	menuProps,
	rules,
	tooltip,
	onSelectChange,
	loading,
	required,
	options,
}) => {
	const labelId = `${name}-label`;
	const classes = useStyles();
	const selectedOptions: any[] | undefined = useWatch({ control: control, name: name }) ?? defaultValue;

	return (
		<FormControl variant="standard" required={required} fullWidth>
			{tooltip ? (
				<>
					<FormLabel>
						{label}{' '}
						<Tooltip title={tooltip}>
							<InfoIcon color="primary" fontSize="small" />
						</Tooltip>
					</FormLabel>
				</>
			) : label ? (
				<InputLabel id={labelId} shrink className={error ? classes.required : ''} required={required}>
					{label}
				</InputLabel>
			) : (
				''
			)}
			<Controller
				control={control}
				rules={rules}
				name={name}
				defaultValue={defaultValue || []}
				render={({ field: { onChange, onBlur, value, name, ref } }) => (
					<Select
						variant="standard"
						name={name}
						inputRef={ref}
						onChange={(e: SelectChangeEvent) => {
							e.target.value = e.target.value ?? [];
							if (onSelectChange) onSelectChange(e);
							onChange(e);
						}}
						onBlur={onBlur}
						value={value ?? []}
						labelId={labelId}
						label={label}
						error={error}
						MenuProps={menuProps}
						disabled={loading}
						readOnly={readOnly}
						required={required}
						SelectDisplayProps={{ style: { minHeight: '2.5rem' } }}
						fullWidth
						multiple
						input={<Input />}
						renderValue={(selected) => (
							<div className={classes.chips}>
								{(selected as unknown as any[]).map((value) => {
									if (options) {
										const option = options.find((o) => o.value === value);
										if (option) {
											return <Chip key={option.value} label={option.name} className={classes.chip} />;
										}
									}
									return null;
								})}
							</div>
						)}>
						{options && options.length > 0 ? (
							options.map((option, index) =>
								option.isLabel ? (
									<ListItem key={index}>
										<ListItemText primary={option.name} />
									</ListItem>
								) : (
									<MenuItem key={index} value={option.value}>
										<Checkbox color="primary" checked={!!selectedOptions && selectedOptions.indexOf(option.value) > -1} />
										<ListItemText primary={option.name} />
									</MenuItem>
								),
							)
						) : (
							<MenuItem value="" disabled>
								No options
							</MenuItem>
						)}
					</Select>
				)}
			/>
			{helperText ? <FormHelperText className={classes.required}>{helperText}</FormHelperText> : null}
		</FormControl>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		required: {
			color: '#ED5531',
		},
		chips: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		chip: {
			margin: 2,
		},
	}),
);

export default ReactHookFormMultiSelect;
