import { ApolloError } from '@apollo/client';
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, MenuItem } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { LoadingButton } from '../index';

export interface ReactHookFormAutocompleteSingleProps<T> {
	name: string;
	label?: string;
	options: T[];
	defaultValue?: number | null;
	error?: boolean;
	helperText?: string;
	addOption: (name: string) => Promise<T | null>;
	loading?: boolean;
	readOnly?: boolean;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	maxLength?: number;
}

type Option = {
	id: number | string;
	name: string;
};

const filter = createFilterOptions<any>();

const ReactHookFormAutocompleteSingle = <T extends Option>({
	label,
	defaultValue,
	error,
	helperText,
	name,
	addOption,
	options,
	loading,
	readOnly = false,
	rules,
	maxLength,
}: ReactHookFormAutocompleteSingleProps<T>) => {
	const { control, setValue } = useFormContext();
	const [open, toggleOpen] = useState(false);
	const [submitBusy, setSubmitBusy] = useState(false);
	const [createError, setCreateError] = useState<ApolloError | null>(null);
	const [dialogValue, setDialogValue] = useState({
		name: '',
	});

	/**
	 * Handle Close of Add Option Popup
	 */
	const handleClose = () => {
		setCreateError(null);
		setDialogValue({
			name: '',
		});
		toggleOpen(false);
	};

	/**
	 * Handle Submit of Add Option Popup
	 */
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation(); // Prevent Parent form from submitting
		setSubmitBusy(true);
		setCreateError(null);
		try {
			const value = await addOption(dialogValue.name);
			console.log(value);
			if (value) setValue(name, value.id);
			handleClose();
		} catch (e) {
			setCreateError(e);
		} finally {
			setSubmitBusy(false);
		}
	};

	return (
		<>
			<Controller
				// Temporary workaround for unexpected behavior: Displayed input is not updated when options are loaded
				// The key prop assures that we rerender once this happens
				// Key prop can be removed once this issue is resolved: https://github.com/mui/material-ui/issues/33490
				key={options?.[0]?.name ?? name}
				rules={rules}
				name={name}
				control={control}
				defaultValue={defaultValue || null}
				render={({ field: { onChange, onBlur, value: formValue, ref } }) => (
					<Autocomplete
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						forcePopupIcon={true}
						loading={loading}
						onBlur={onBlur}
						value={formValue ?? ''}
						getOptionDisabled={(option) => option.disabled || readOnly}
						readOnly={readOnly}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						onChange={(_event, value: any) => {
							if (typeof value === 'string') {
								// timeout to avoid instant validation of the dialog's form.
								setTimeout(() => {
									toggleOpen(true);
									setDialogValue({ name: value });
								});
							} else if (value && value.inputValue) {
								toggleOpen(true);
								setDialogValue({ name: value.inputValue });
							} else {
								onChange(value?.id || null);
							}
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);
							// Add Option to add new Option
							if (options.length === 0 && params.inputValue === '') {
								filtered.push({
									disabled: true,
									name: `No options found. Start typing to add new...`,
								});
							} else if (params.inputValue && !options.some((o) => o.name === params.inputValue)) {
								filtered.push({
									inputValue: params.inputValue,
									name: `Add "${params.inputValue}"`,
								});
							}

							return filtered;
						}}
						options={options}
						getOptionLabel={(value) => {
							if (typeof value === 'number' || typeof value === 'string') {
								return options?.find((option) => option.id === value)?.name ?? '';
							}
							return value?.name ?? '';
						}}
						freeSolo
						disableClearable={readOnly}
						renderInput={(params) => (
							<TextField variant="standard" {...params} label={label} error={error} disabled={loading} helperText={helperText} inputRef={ref} />
						)}
					/>
				)}
			/>
			<Dialog open={open} onClose={handleClose} aria-labelledby={`form-create-${name}-title`}>
				<form id={`form-create-${name}`} onSubmit={handleSubmit}>
					<DialogTitle id={`form-create-${name}-title`}>Add a new {label}</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant="standard"
									autoFocus
									id="name"
									fullWidth
									value={dialogValue.name}
									onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
									label="Name"
									type="text"
									required
									inputProps={{ maxLength }}
								/>
							</Grid>
							{createError && (
								<Grid item xs={12}>
									<Alert severity="error">
										<AlertTitle>Error</AlertTitle>
										{createError?.message}
									</Alert>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} variant="text">
							Cancel
						</Button>
						<LoadingButton pending={submitBusy} form={`form-create-${name}`} type="submit" variant="contained">
							Add
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default ReactHookFormAutocompleteSingle;
