import { CommentProvider } from '..';
import CommentParent from './CommentParent';

interface CommentProps {
	heading?: string;
	subHeading?: string;
	readonly?: boolean;
	alertText?: string;
}

const Comment: React.FC<CommentProps> = ({ heading, subHeading, readonly, alertText }) => {
	return (
		<CommentProvider>
			<CommentParent heading={heading} subHeading={subHeading} readonly={readonly} alertText={alertText} />
		</CommentProvider>
	);
};

export default Comment;
